import { useState, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  PreviewSharp,
  RequestPageSharp,
  ApprovalSharp,
  DeleteForeverSharp,
  Send,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function ItemJournalModel(props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { globalUser, allItems, globalToken, globalRoleSecurity } =
    useContext(ShoppingCartContext);
  const [EmployeesList, setEmployeesList] = useState(
    props.EmployeesList ? props.EmployeesList : []
  );
  const [StoresList, setStoresList] = useState(props.StoresList);
  const [id, setId] = useState(props.IssueOrder.Id);
  const [orderNo, setOrderNo] = useState(props.IssueOrder.Order);
  const [requestBy, setRequestedBy] = useState(props.IssueOrder.RequestedBy);
  const [approvedBy, setApprovedBy] = useState(props.IssueOrder.ApprovedBy);
  const [issueTo, setIssueTo] = useState(props.IssueOrder.IssuedTo);
  const [issuedBy, setIssuedBy] = useState(props.IssueOrder.IssuedBy);
  const [store, setStore] = useState(
    props.IssueOrder.Store
      ? StoresList.filter(
          (st) => parseInt(st.Id) === parseInt(props.IssueOrder.Store)
        ).length > 0
        ? StoresList.filter(
            (st) => parseInt(st.Id) === parseInt(props.IssueOrder.Store)
          )[0].Name
        : ""
      : globalRoleSecurity.filter(
          (sec) => sec.Functionality === "Issue " + props.IssueOrder.Status
        ).length > 0 &&
        (globalRoleSecurity.filter(
          (sec) => sec.Functionality === "Issue " + props.IssueOrder.Status
        )[0].Store === undefined ||
          isNaN(
            globalRoleSecurity.filter(
              (sec) => sec.Functionality === "Issue " + props.IssueOrder.Status
            )[0].Store
          ) ||
          parseInt(
            globalRoleSecurity.filter(
              (sec) => sec.Functionality === "Issue " + props.IssueOrder.Status
            )[0].Store
          ) === 0)
      ? ""
      : StoresList.filter(
          (st) =>
            st.Id ===
            globalRoleSecurity.filter(
              (sec) => sec.Functionality === "Issue Request" && sec.Security > 0
            )[0].Store
        )[0].Name
  );
  const [orderDate, setOrderDate] = useState(
    props.IssueOrder.OrderDate
      ? props.IssueOrder.OrderDate
      : format(new Date(), "yyyy-MM-dd")
  );
  const [remark, setRemark] = useState(props.IssueOrder.Remark);
  const [IssuedItemJournalLines, setIssuedItemJournalLines] = useState(
    props.IssuedItemJournalLines
  );

  const [itemjournallines, setItemJournalLines] = useState(
    props.ItemJournalLines.map((ijl) => {
      var issuedQty = 0;
      IssuedItemJournalLines &&
        IssuedItemJournalLines.filter(
          (iss) => parseInt(iss.Item) === parseInt(ijl.Item)
        ).map((IssLitem) => {
          issuedQty = parseFloat(issuedQty) + parseFloat(IssLitem.Quantity);
        });
      ijl.IssuedQuantity = issuedQty;
      return ijl;
    })
  );
  const [lineItems, setLineItems] = useState(
    props.LineItems ? props.LineItems : allItems
  );

  const [productsList] = useState(allItems);

  const handleSubmit = (status, lItems) => {
    if (issueTo === undefined || issueTo === null || issueTo.length <= 0) {
      alert("First select issued to employee");
      return;
    }

    if (store === undefined || store === null || store.length <= 0) {
      alert("First select the store");
      return;
    }
    if (
      lItems === undefined ||
      lItems.filter((li) => li.Name.length > 0).length <= 0
    ) {
      alert("First select the items: ");
      return "";
    }

    var testValues = false;
    lItems.map((li) => {
      if (isNaN(li.Quantity) || li.Quantity <= 0) {
        alert("First insert the proper quantity of product: " + li.Name);
        testValues = true;
        return "";
      }
    });
    if (testValues === true) {
      return "";
    }
    if (
      window.confirm("are you sure you want to save the Issue " + status + "?")
    ) {
      try {
        var fd = new FormData();
        fd.append("Id", id);
        fd.append(
          "Order",
          props.IssueOrder.OrderId ? props.IssueOrder.OrderId : 0
        );
        fd.append("OrderDate", orderDate);
        if (status === "Request" || status === "Direct") {
          fd.append("RequestedBy", globalUser.Id);
        } else {
          fd.append("RequestedBy", props.IssueOrder.RequestedBy);
        }
        if (status === "Approved" || status === "Direct") {
          fd.append("ApprovedBy", globalUser.Id);
        } else {
          fd.append("ApprovedBy", props.IssueOrder.ApprovedBy);
        }
        if (status === "Approved" || status === "Direct") {
          fd.append("ApprovedDate", format(new Date(), "yyyy-MM-dd"));
        } else {
          fd.append("ApprovedDate", props.IssueOrder.ApprovedDate);
        }
        if (status === "Issued" || status === "Direct") {
          fd.append("IssuedBy", globalUser.Id);
        } else {
          fd.append("IssuedBy", props.IssueOrder.IssuedBy);
        }

        if (status === "Issued" || status === "Direct") {
          fd.append("IssuedDate", format(new Date(), "yyyy-MM-dd"));
        } else {
          fd.append("IssuedDate", props.IssueOrder.IssuedDate);
        }
        fd.append("IssuedTo", issueTo);
        fd.append(
          "Store",
          StoresList.filter((st) => st.Name === store).length > 0
            ? StoresList.filter((st) => st.Name === store)[0].Id
            : 0
        );
        fd.append("Parent", globalUser.Parent);
        fd.append("Status", status);
        fd.append("Remark", remark);
        fd.append("ItemJournalLines", JSON.stringify(lItems));

        axios.post("/itemjournal", fd, {
          headers: { "Content-Type": "application/json", token: globalToken },
        });
      } catch (err) {
      } finally {
        setItemJournalLines([]);
        //setCustomer('')
        props.setParentUpdate(!props.ParentUpdate);
        setOpen(false);
      }
    }
  };
  const handleDelete = (id) => {
    try {
      axios.delete("/itemjournal?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleLineItemDelete = (id) => {
    try {
      axios.delete("/lineitems?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
    }
  };
  const handleChangeStatus = (status) => {
    try {
      axios.get(
        "/itemjournal/changeStatus?Id=" +
          props.IssueOrder.Id +
          "&Status=" +
          status,
        {
          headers: {
            token: globalToken,
          },
        }
      );
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText mt={1}>
            <DialogTitle
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                height: "15px",
              }}
            >
              {"Issue " +
                (props.IssueOrder ? props.IssueOrder.Status : "Request")}{" "}
              {/* : Issued To : {EmployeesList && EmployeesList.filter(emp=>parseInt(emp.Id)===parseInt(issueTo)).length>0 &&  EmployeesList.filter(emp=>parseInt(emp.Id)===parseInt(issueTo))[0].Name} */}
            </DialogTitle>
            <div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  pl={"35%"}
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  {"Issue " +
                    (props.IssueOrder ? props.IssueOrder.Status : "Request")}
                </Grid>
                <Grid item xs={6}>
                  <span>Doc. No.: {id}</span>
                  <br></br>
                  <span>Job Order No.: {orderNo ? orderNo : 0}</span>
                </Grid>
                <Grid item xs={6}>
                  <span>
                    Status:{" "}
                    {props.IssueOrder ? props.IssueOrder.Status : "Draft"}
                  </span>
                  <br></br>
                  Requested By:{" "}
                  {props.IssueOrder.RequestedByName
                    ? props.IssueOrder.RequestedByName
                    : globalUser.Name}
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    label="Issued To"
                    id="IssuedTo"
                    size="small"
                    variant="standard"
                    value={
                      EmployeesList &&
                      EmployeesList.filter(
                        (emp) => parseInt(emp.Id) === parseInt(issueTo)
                      ).length > 0
                        ? EmployeesList.filter(
                            (emp) => parseInt(emp.Id) === parseInt(issueTo)
                          )[0].Name
                        : ""
                    }
                    onChange={(e) => {
                      setIssueTo(
                        EmployeesList.filter(
                          (emp) => emp.Name === e.target.textContent
                        ).length > 0
                          ? EmployeesList.filter(
                              (emp) => emp.Name === e.target.textContent
                            )[0].Id
                          : 0
                      );
                      props.IssueOrder.IssuedToName = e.target.textContent;
                      props.IssueOrder.IssuedTo =
                        EmployeesList.filter(
                          (emp) => emp.Name === e.target.textContent
                        ).length > 0
                          ? EmployeesList.filter(
                              (emp) => emp.Name === e.target.textContent
                            )[0].Id
                          : 0;
                    }}
                    options={
                      !(EmployeesList === undefined) &&
                      !(EmployeesList === null) &&
                      EmployeesList.length > 0 &&
                      EmployeesList.map((pro) => {
                        return pro.Name;
                      })
                    }
                    sx={{ width: "95%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Issued To")}

                        //error={!from ? true : false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <TextField
                    size="small"
                    label="Issue Date"
                    type="date"
                    value={orderDate}
                    onChange={(e) => {
                      setOrderDate(e.target.value);
                      props.IssueOrder.OrderDate = e.target.value;
                    }}
                    sx={{ width: "70%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  {globalRoleSecurity.filter(
                    (sec) =>
                      sec.Functionality === "Issue " + props.IssueOrder.Status
                  ).length > 0 &&
                  (globalRoleSecurity.filter(
                    (sec) =>
                      sec.Functionality === "Issue " + props.IssueOrder.Status
                  )[0].Store === undefined ||
                    isNaN(
                      globalRoleSecurity.filter(
                        (sec) =>
                          sec.Functionality ===
                          "Issue " + props.IssueOrder.Status
                      )[0].Store
                    ) ||
                    parseInt(
                      globalRoleSecurity.filter(
                        (sec) =>
                          sec.Functionality ===
                          "Issue " + props.IssueOrder.Status
                      )[0].Store
                    ) === 0) ? (
                    <Autocomplete
                      label="Store"
                      id="Store"
                      size="small"
                      variant="standard"
                      value={store}
                      onChange={(e) => {
                        setStore(e.target.textContent);
                        props.IssueOrder.StoreName = e.target.textContent;
                        props.IssueOrder.Store =
                          StoresList.filter((st) => st.Name === e.target.textContent)
                            .length > 0
                            ? StoresList.filter(
                                (st) => st.Name === e.target.textContent
                              )[0].Id
                            : 0;
                      }}
                      options={
                        !(StoresList === undefined) &&
                        !(StoresList === null) &&
                        StoresList.length > 0 &&
                        StoresList.map((pro) => {
                          return pro.Name;
                        })
                      }
                      sx={{ width: "95%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Store")}

                          //error={!from ? true : false}
                        />
                      )}
                    />
                  ) : (
                    <>
                      Store:{" "}
                      {
                        StoresList.filter(
                          (st) =>
                            st.Id ===
                            globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Issue Request" &&
                                sec.Security > 0
                            )[0].Store
                        ).length>0 && StoresList.filter(
                          (st) =>
                            st.Id ===
                            globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Issue Request" &&
                                sec.Security > 0
                            )[0].Store
                        )[0].Name
                      }
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {props.IssueOrder.Status !== "Draft" &&
                    props.IssueOrder.Status !== "Request" && (
                      <span>
                        {" "}
                        Issued By:{" "}
                        {props.IssueOrder
                          ? props.IssueOrder.IssuedByName
                          : globalUser.Name}
                      </span>
                    )}
                  <br></br>
                  {props.IssueOrder.Status !== "Draft" &&
                    props.IssueOrder.Status !== "Request" && (
                      <span>
                        {" "}
                        Approved By: {props.IssueOrder.ApprovedByName}
                      </span>
                    )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    variant="standard"
                    value={remark}
                    label="Remark"
                    minRows={2}
                    sx={{ width: "95%" }}
                    onChange={(e) => {
                      setRemark(e.target.value);
                      props.IssueOrder.Remark = e.target.value;
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid direction="row" container>
                    <hr></hr>
                    <Grid item xs={12} className="hideInMobile">
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          style={{
                            paddingLeft: "5px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            color: theme.palette.primary.contrastText,
                          }}
                        >
                          No.
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            paddingLeft: "5px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            color: theme.palette.primary.contrastText,
                          }}
                        >
                          {" "}
                          Item Name
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            paddingLeft: "5px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            color: theme.palette.primary.contrastText,
                          }}
                        >
                          Issued Quantity
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            paddingLeft: "5px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            color: theme.palette.primary.contrastText,
                          }}
                        >
                          Quantity
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            paddingLeft: "5px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            color: theme.palette.primary.contrastText,
                            textAlign: "center",
                          }}
                        >
                          Remark
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          style={{
                            paddingLeft: "5px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            color: theme.palette.primary.contrastText,
                          }}
                        >
                          <IconButton
                            size="small"
                            //id={issueOrder.Id}
                            onClick={(e) => {
                              if (
                                itemjournallines &&
                                itemjournallines.filter(
                                  (itt) => itt.Item === ""
                                ).length <= 0
                              ) {
                                let newLineItem = {};
                                newLineItem.Item = "";
                                newLineItem.Name = "";
                                newLineItem.Order = props.IssueOrder.Id;
                                //newLineItem.Job=issueOrder.Id;
                                //newLineItem.IssuedTo = issueTo;
                                newLineItem.Quantity = 0;
                                //newLineItem.IssuedToName = issueToName;
                                setItemJournalLines((xx) => [
                                  ...xx,
                                  newLineItem,
                                ]);
                              } else {
                                alert("First complete the data");
                              }
                              console.log(itemjournallines);
                            }}
                          >
                            <Add
                              sx={{ color: theme.palette.primary.contrastText }}
                            />
                          </IconButton>
                        </Grid>

                        {itemjournallines &&
                          itemjournallines !== null &&
                          itemjournallines.length > 0 &&
                          itemjournallines.map((item, index) => {
                            return (
                              <>
                                <Grid item xs={12}>
                                  <hr></hr>
                                </Grid>
                                <Grid item xs={1} key={index}>
                                  {index + 1}
                                </Grid>
                                <Grid item xs={3}>
                                  <Autocomplete
                                    label="Items"
                                    id="Items"
                                    size="small"
                                    variant="standard"
                                    value={item.Name}
                                    onChange={(e) => {
                                      if (
                                        itemjournallines &&
                                        itemjournallines.length > 0 &&
                                        itemjournallines.filter(
                                          (li) => li.Name === e.target.textContent
                                        ).length > 0
                                      ) {
                                        item.Item = "";
                                        item.Name = "";
                                        item.Quantity = 0;
                                        setItemJournalLines(
                                          itemjournallines.map((litem) => {
                                            return litem;
                                          })
                                        );
                                        //alert('The selected item is already registered')
                                        return;
                                      } else {
                                        let selectedProduct =
                                          productsList.filter(
                                            (selItem) =>
                                              selItem.Name === e.target.textContent
                                          );

                                        if (
                                          selectedProduct &&
                                          selectedProduct.length > 0
                                        ) {
                                          item.Quantity =
                                            selectedProduct[0].Quantity;

                                          item.Item = selectedProduct[0].Item;
                                          item.Name = selectedProduct[0].Name;
                                          item.Order = props.IssueOrder.Id;
                                          //item.IssuedTo=issueTo;
                                          //item.IssuedToName=issueToName;
                                          //item.Job=issueOrder.Id;
                                          setItemJournalLines((ji) => [
                                            ...ji,
                                            item,
                                          ]);
                                          setItemJournalLines(
                                            itemjournallines.map((litem) => {
                                              return litem;
                                            })
                                          );
                                        } else {
                                          item.Quantity = 0;
                                          //item.IssuedTo=issueTo;
                                          //item.IssuedToName=issueToName;
                                          item.Item = "";
                                          item.Name = "";

                                          setItemJournalLines(
                                            itemjournallines.map((litem) => {
                                              return litem;
                                            })
                                          );
                                        }
                                      }
                                    }}
                                    options={
                                      !(lineItems === undefined) &&
                                      !(lineItems === null) &&
                                      lineItems.length > 0
                                        ? lineItems.map((it) => {
                                            return it.Name;
                                          })
                                        : [""]
                                    }
                                    sx={{ width: "95%" }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={item.Name}

                                        //error={!from ? true : false}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  {item.IssuedQuantity}
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    size="small"
                                    variant="standard"
                                    type="float"
                                    value={item.Quantity}
                                    sx={{ width: "70px" }}
                                    onChange={(e) => {
                                      item.Quantity = e.target.value;
                                      setItemJournalLines(
                                        itemjournallines.map((litem) => {
                                          return litem;
                                        })
                                      );
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={3}>
                                  <TextField
                                    size="small"
                                    variant="standard"
                                    value={item.Remark}
                                    sx={{ width: "95%" }}
                                    onChange={(e) => {
                                      item.Remark = e.target.value;
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Tooltip title="remove">
                                    <Button
                                      endIcon={<Delete width="10px" />}
                                      onClick={() => {
                                        if (
                                          itemjournallines.filter(
                                            (litem) =>
                                              litem.Item === item.Item &&
                                              parseInt(litem.IssuedTo) ===
                                                parseInt(item.IssuedTo)
                                          ).Id
                                        ) {
                                          handleLineItemDelete(
                                            itemjournallines.filter(
                                              (litem) =>
                                                litem.Item === item.Item &&
                                                parseInt(litem.IssuedTo) ===
                                                  parseInt(item.IssuedTo)
                                            )[0].Id
                                          );
                                        }

                                        /*    setItemJournalLines(
                  itemjournallines.filter(
                    (litem) => !(litem.Item === item.Item && parseInt(litem.Supplier)===parseInt(item.Supplier))
                  )
                ); */
                                        if (
                                          itemjournallines /*. filter(
                                  (litem) =>                                                      
                                      parseInt(
                                        litem.IssuedTo
                                      ) ===
                                        parseInt(
                                          issueTo
                                        )                                                      
                                ) */.length <= 0
                                        ) {
                                          //setAssignedEmployees(EmployeesList.filter(ssp=>parseInt(ssp.Id)!==parseInt(sup.Id)));
                                          /* setOpen(false);
                                 let newLineItem = {};
                  newLineItem.Item = "";
                  newLineItem.Name = "";
                  newLineItem.Supplier = sup.Id;
                  newLineItem.UnitCost = 0;
                  newLineItem.Quantity = 0;
                  newLineItem.UnitPrice = 0;
                  newLineItem.TotalPrice = 0;
                  setItemJournalLines((xx) => [...xx, newLineItem]); */
                                        }
                                      }}
                                    ></Button>
                                  </Tooltip>
                                </Grid>
                              </>
                            );
                          })}
                        <Grid item xs={12}>
                          <hr></hr>
                        </Grid>
                        <Grid item xs={5}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item className="hideInFull" m={1} xs={12}>
                      {itemjournallines &&
                        itemjournallines !== null &&
                        itemjournallines.length > 0 &&
                        itemjournallines
                          /* .filter(
                (it) =>
                  parseInt(it.IssuedToName) === parseInt(issueToName)
              ) */
                          .map((item, index) => {
                            return (
                              <>
                                <Card
                                  sx={{
                                    borderRadius: 10,
                                    boxShadow: 3,
                                    width: "100%",
                                  }}
                                >
                                  <CardContent>
                                    <Grid container>
                                      <Grid item xs={4}>
                                        No.:
                                      </Grid>
                                      <Grid item xs={8} pl={0}>
                                        {index + 1}
                                      </Grid>
                                      <Grid item xs={4}>
                                        Product:
                                      </Grid>
                                      <Grid item xs={8} pl={0}>
                                        <Autocomplete
                                          label="Items"
                                          id="Items"
                                          size="small"
                                          variant="standard"
                                          value={item.Name}
                                          onChange={(e) => {
                                            if (
                                              itemjournallines.filter(
                                                (li) =>
                                                  li.Name === e.target.textContent
                                              ).length > 0
                                            ) {
                                              item.Item = "";
                                              item.Name = "";
                                              item.Quantity = 0;
                                              //item.IssuedTo = issueOrder.AssignTo;

                                              setItemJournalLines(
                                                itemjournallines.map(
                                                  (litem) => {
                                                    return litem;
                                                  }
                                                )
                                              );

                                              return;
                                            } else {
                                              let selectedProduct =
                                                productsList.filter(
                                                  (selItem) =>
                                                    selItem.Name ===
                                                    e.target.textContent
                                                );
                                              if (
                                                selectedProduct &&
                                                selectedProduct.length > 0
                                              ) {
                                                item.Quantity =
                                                  selectedProduct[0].DefaultOrderQty;

                                                item.Item =
                                                  selectedProduct[0].Item;
                                                item.Name =
                                                  selectedProduct[0].Name;
                                                //item.IssuedTo = issueOrder.AssignTo;

                                                setItemJournalLines(
                                                  itemjournallines.map(
                                                    (litem) => {
                                                      return litem;
                                                    }
                                                  )
                                                );
                                              } else {
                                                item.Quantity = 0;

                                                item.Item = "";
                                                item.Name = "";
                                                // item.IssuedTo = issueOrder.AssignTo;
                                                setItemJournalLines(
                                                  itemjournallines.map(
                                                    (litem) => {
                                                      return litem;
                                                    }
                                                  )
                                                );
                                              }
                                            }
                                          }}
                                          options={
                                            !(productsList === undefined) &&
                                            !(productsList === null) &&
                                            productsList.length > 0
                                              ? productsList.map((it) => {
                                                  return it.Name;
                                                })
                                              : [""]
                                          }
                                          sx={{ width: "100%" }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label={item.Name}

                                              //error={!from ? true : false}
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        Quantity:
                                      </Grid>
                                      <Grid item xs={8} pl={0}>
                                        <TextField
                                          size="small"
                                          variant="standard"
                                          type="float"
                                          value={item.Quantity}
                                          maxRows={4}
                                          sx={{ width: "100%" }}
                                          onChange={(e) => {
                                            item.Quantity = e.target.value;
                                            setItemJournalLines(
                                              itemjournallines.map((litem) => {
                                                return litem;
                                              })
                                            );
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={4}>
                                        Remark :
                                      </Grid>
                                      <Grid item xs={8} pl={8}>
                                        {item.Remark}
                                      </Grid>
                                      <Grid item xs={12}>
                                        <hr></hr>
                                        Remove: {"   "}
                                        <Tooltip title="remove">
                                          <Button
                                            endIcon={<Delete width="10px" />}
                                            onClick={() => {
                                              handleLineItemDelete(
                                                itemjournallines.filter(
                                                  (litem) =>
                                                    litem.Item === item.Item
                                                )[0].Id
                                              );

                                              if (
                                                itemjournallines.filter(
                                                  (litem) =>
                                                    !(litem.Item === item.Item)
                                                ).length <= 0
                                              ) {
                                                let newLineItem = {};
                                                newLineItem.Item = "";
                                                newLineItem.Name = "";
                                                //newLineItem.IssuedTo = issueOrder.AssignTo;
                                                //newLineItem.IssuedToName = issueToName;
                                                newLineItem.Quantity = 0;

                                                setItemJournalLines((xx) => [
                                                  ...xx,
                                                  newLineItem,
                                                ]);
                                              }
                                            }}
                                          ></Button>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <hr></hr>
                              </>
                            );
                          })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button variant="standard" size="small" endIcon={<PreviewSharp />}>
            <InvoicePreview
              icon={<div>Preview</div>}
              data={[props.IssueOrder]}
              LineItems={itemjournallines}
              Type="IssueOrder"
              DocumentType={"Store " + props.IssueOrder.Status}
            />
          </Button>
          |
          {props.IssueOrder.Status !== "Direct" ? (
            <>
              {(props.IssueOrder.Status === "Draft" ||
                props.IssueOrder.Status === "Request") &&
                globalRoleSecurity.filter(
                  (sec) =>
                    sec.Functionality === "Issue Request" && sec.Security > 1
                ).length > 0 && (
                  <>
                    <Button
                      variant="standard"
                      size="small"
                      endIcon={<RequestPageSharp />}
                      autoFocus
                      onClick={() =>
                        handleSubmit(
                          "Request",
                          itemjournallines.filter(
                            (itt) => itt.Name && itt.Name.length > 0
                          )
                        )
                      }
                    >
                      Request
                    </Button>
                    |
                  </>
                )}
              {(props.IssueOrder.Status === "Request" ||
                props.IssueOrder.Status === "Approved") &&
                globalRoleSecurity.filter(
                  (sec) =>
                    sec.Functionality === "Issue Approval" && sec.Security > 1
                ).length > 0 && (
                  <>
                    <Button
                      variant="standard"
                      size="small"
                      endIcon={<ApprovalSharp />}
                      autoFocus
                      onClick={() =>
                        handleSubmit(
                          "Approved",
                          itemjournallines.filter(
                            (itt) => itt.Name && itt.Name.length > 0
                          )
                        )
                      }
                    >
                      Approve
                    </Button>
                    |
                  </>
                )}
              {props.IssueOrder.Status === "Approved" &&
                globalRoleSecurity.filter(
                  (sec) =>
                    sec.Functionality === "Issue Clear" && sec.Security > 1
                ).length > 0 && (
                  <>
                    <Button
                      variant="standard"
                      size="small"
                      endIcon={<ApprovalSharp />}
                      autoFocus
                      onClick={() => handleChangeStatus("Cleared")}
                    >
                      Clear
                    </Button>
                    |
                  </>
                )}

              {props.IssueOrder.Status === "Request" &&
                globalRoleSecurity.filter(
                  (sec) =>
                    sec.Functionality === "Issue " + props.IssueOrder.Status &&
                    sec.Security > 1
                ).length > 0 && (
                  <>
                    <Button
                      variant="standard"
                      size="small"
                      endIcon={<DeleteForeverSharp />}
                      autoFocus
                      onClick={() => handleDelete(id)}
                    >
                      Delete
                    </Button>
                    |
                  </>
                )}
            </>
          ) : (
            props.IssueOrder.Status === "Direct" &&
            globalRoleSecurity.filter(
              (sec) => sec.Functionality === "Issue Direct" && sec.Security > 2
            ).length > 0 && (
              <>
                <Button
                  variant="standard"
                  size="small"
                  endIcon={<Send />}
                  autoFocus
                  onClick={() =>
                    handleSubmit(
                      "Direct",
                      itemjournallines.filter(
                        (itt) => itt.Name && itt.Name.length > 0
                      )
                    )
                  }
                >
                  Direct Issue
                </Button>
                |
              </>
            )
          )}
          <Button
            variant="standard"
            size="small"
            endIcon={<Close />}
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
