import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid,Box,  } from "@mui/material";
import {AddSharp, CloseSharp, DeleteSharp, PreviewSharp, SaveSharp} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useNavigate,useLocation } from "react-router-dom";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";

import {format} from 'date-fns'
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function GeneralJournalModel(props) {
const theme=useTheme();
  const { t, i18n } = useTranslation();
  const navigate=useNavigate();
  const location = useLocation();
  const { globalUser,setPageHeader,globalToken }=useContext(ShoppingCartContext)
  const [chartofAccounts,setChartofAccounts]=useState(props.Type==='GJ'?props.ChartOfAccounts:[])
  const [journals,setJournals]=useState(props.Journals?props.Journals:[{Type:'GJ',PostingDate:format(new Date(),"yyyy-MM-dd")}])


  const handleSubmit = () => {
    try {
    journals && journals.length>0 && journals.map(jl=>{

    
    if(jl.DebitAccountName===null || jl.DebitAccountName.length<=0){
      alert('Debit Account field is required');
      return;
    }
    if(jl.CreditAccountName===null || jl.CreditAccountName.length<=0){
      alert('Credit Account field is required');
      return;
    }
    if(jl.Amount===null || jl.Amount.length<=0){
      alert('Amount field is required');
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to pay?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", jl.Id);
      fd.append("PostingDate", jl.PostingDate);
      fd.append("DocumentNo", jl.DocumentNo);
      fd.append("ReferenceTo", jl.ReferenceTo?jl.ReferenceTo:0);
      fd.append("Type", jl.Type);
      fd.append("DebitAccount", chartofAccounts.filter(ca=>ca.AccountDescription===jl.DebitAccountName)[0].Account );
      fd.append("CreditAccount", chartofAccounts.filter(ca=>ca.AccountDescription===jl.CreditAccountName)[0].Account );
      fd.append("Amount",jl.Amount );
      fd.append("User",globalUser.Id);
      fd.append("Parent",globalUser.Parent);
      fd.append("Remark", jl.Remark);
    
      axios.post("/generalLedgerEntries", fd, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      setJournals(()=>journals.filter(it=>(it.DebitAccountName!==jl.DebitAccountName && it.CreditAccountName!==jl.CreditAccountName && it.Amount!==jl.Amount)))
    }
    
  }
  
}
)
} catch (err) {
} finally {
  props.OrderData && props.setParentUpdate(!props.ParentUpdate);
 
}
};
  const loadChartOfAccountsList =  () => {
    axios.get("/chartofaccounts/getByParent?Parent="+globalUser.Parent, {
     headers: {
       token: globalToken,
     },
   })
    .then(response=>{
         if (response.data.data){
           setChartofAccounts(response.data.data);
         }else{
           alert('log out and log in again')
         }
       })
       
     };
 /*  const handleDelete = () => {
    try {
      axios.delete("/inventoryPostingSetups?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  }; */
 useEffect(()=>{
  setPageHeader(location.state.Type+' Journal')
if(props.Type!=='GJ'){
loadChartOfAccountsList();
}
},[location.state.Type])
  return (
    <div width="1000">
      
         <Box sx={{  width: "100%",display:'flex',justifyContent:'center',marginTop:1,backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
     
          <Grid container spacing={1} >
         {/*  <Grid item xs={12} sx={{display:'flex',justifyContent:'center',fontWeight:'bold',fontSize:18}}>     
          General Journal
          
          </Grid> */}
          <Grid item xs={12} mb={1} sx={{height:2}} ><hr></hr></Grid>
             <Grid item xs={12} md={4} >
          <TextField
            size="small"
            variant="standard"
            type="date"
            label={t("PostingDate")}
            
            value={journals && journals.length>0 && journals[0].PostingDate?journals[0].PostingDate:format(new Date(),"yyyy-MM-dd")}
            onChange={(e) => {
              journals[0].PostingDate=e.target.value;
            }}
            sx={{ width: "95%",marginLeft: 2 }}
            InputLabelProps={{
              shrink: true,
              sx:{
                color:theme.palette.primary.contrastText
              }
            }}
          />
          </Grid>
             <Grid item xs={12} md={4}>
          <TextField
            size="small"
            variant="standard"
            label={t("Document No")}
            defaultValue={journals && journals.length>0 && journals[0].DocumentNo}
            onChange={(e) => {
              journals[0].DocumentNo=e.target.value;
            }}
            sx={{ width: "95%", marginBottom: 1 }}
            InputLabelProps={{
              shrink: true,
              sx:{
                color:theme.palette.primary.contrastText
              }
            }}
          />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          
          </Grid>
</Box>

              <Grid container spacing={2} m={1}>
             
                    
                  <Grid item xs={12} md={12}><hr></hr></Grid>
        
            {journals && journals.length>0 && journals.map((jl,index)=>{
              
                  return(<>
                  <Grid item xs={12} md={1}>Line No: {index+1}</Grid>
                <Grid item xs={12} md={3}>
              
              <Autocomplete
              size="small"
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={jl.DebitAccountName}
                        id="debitAccoutn"
                        onSelect={(e) => {
                          jl.DebitAccountName=e.target.value;
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                          return item.AccountDescription;
                     })}
                        sx={{
                          width: "100%",
                          marginBottom: 1,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Debit Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /></Grid>
                <Grid item xs={12} md={3}>
                <Autocomplete
                  size="small"
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={jl.CreditAccountName}
                        id="creditAccount"
                        onSelect={(e) => {
                          jl.CreditAccountName=e.target.value;
                        }}
                     
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                          return item.AccountDescription;
                     })}
                        sx={{
                          width: "100%",
                          marginBottom: 1,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Credit Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> </Grid>
                <Grid item xs={12} md={2}>  
                <TextField
                    size="small"
                    variant="standard"
                    type='float'
                    label={t("Amount")}
                    defaultValue={jl.Amount}
                    onChange={(e) => {
                      jl.Amount=e.target.value;
                    }}
                    sx={{ width: "95%", marginBottom: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></Grid>
               <Grid item xs={12} md={2}>
                <TextField
                    size="small"
                    variant="standard"
                    label={t("Remark")}
                    defaultValue={jl.Remark}
                    onChange={(e) => {
                      jl.remark=e.target.value;
                    }}
                    sx={{ width: "95%", marginBottom: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></Grid>
                  <Grid item xs={12} md={1}>
                    <Button
                                            variant="standard" 
                                              endIcon={<DeleteSharp sx={{color:theme.palette.primary.main}}   />}
                                              onClick={() =>{
                                                setJournals(()=>journals.filter(it=>(it.DebitAccountName!==jl.DebitAccountName && it.CreditAccountName!==jl.CreditAccountName && it.Amount!==jl.Amount)))
                                              } } />
                                              </Grid>
              
              </>)
                })}
     </Grid>
           
   
        <Box sx={{  width: "100%",display:'flex',justifyContent:'right',alignItems:'center',backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
<Button
        size="small"
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                          journals && journals.map(jl=>{
                            return{...jl,
                            Type:"Gj",
                            PostingDate: jl.PostingDate?jl.PostingDate:format(new Date(),"yyyy-MM-dd"),
                          Amount:((isNaN(parseFloat(jl.Amount))?0:parseFloat(jl.Amount))?.toLocaleString(undefined, {minimumFractionDigits:2}))
                            }
                        })
                        }
                        Type="Posting"
                        DocumentType={ "General Journal"}
                        />
                    </Button>|
                    <Button size="small"
                    variant="standard" autoFocus onClick={()=>{
                      let lineNumber=1;
                      journals && journals.map(jl=>{
                       
                        if((!jl.DebitAccountName || jl.DebitAccountName.length<=0) || (!jl.CreditAccountName || jl.CreditAccountName.length<=0) ||jl.Amount<=0){
                          alert('First finish the journal line at :' + lineNumber)
                          return;
                        }
                        lineNumber+=1;
                      })
                      if(lineNumber===journals.length){
return;
                      }
                      setJournals([...journals,{
                        Id:0,
                        Type:'GJ',
                        PostingDate:journals && journals.length>0 && journals[0].PostingDate,
                        DocumentNo:journals && journals.length>0 && journals[0].DocumentNo,
                        ReferenceTo:journals && journals.length>0 && journals[0].ReferenceTo,
                      }])
                    }} endIcon={<AddSharp />}>
            Add Line
          </Button>|
          <Button size="small"
                    variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveSharp />}>
            Post
          </Button>|
        
          <Button size="small"
                    variant="standard" onClick={() => {navigate("/Home")}} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
          </Box>
      
    </div>
  );
}
