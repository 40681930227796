import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { AltRoute, Close, Delete, Save } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import axios from "../../axiosInstances";


export default function StoresModel(props) {
const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalToken,globalRoleSecurity }=useContext(ShoppingCartContext)

  const [id, setId] = useState(props.data.Id);
  const [name, setName] = useState(props.data.Name);
 
  const [supplier, setSupplier] =useState(props.data.Supplier);
  const [supplierName, setSupplierName] =useState(props.data.SupplierName);
  const [region, setRegion] = useState(props.data.Region);
  const [parentStoresList] = useState(props.StoresList);
  const [parentStore, setParentStore] = useState(parentStoresList && parentStoresList.filter(st=>st.Id===props.data.ParentStore).length>0 ?parentStoresList.filter(st=>st.Id===props.data.ParentStore)[0].Name:'');


  const handleSubmit = () => {
    if(name===undefined || name===null || name.length<=0){
      alert('write the name first')
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    
          try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Name", name);
      fd.append("ParentStore", parentStoresList && parentStoresList.filter(st=>st.Name===parentStore).length>0 ?parentStoresList.filter(st=>st.Name===parentStore)[0].Id:'');
      
      fd.append("Surrounding", region);
      fd.append("Parent", globalUser.Parent);

      axios.post("/stores", fd, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
         },
      });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete=async()=>{
    await axios.get("/vouchers/getByParentAndStore?Parent="+globalUser.Parent+"&Store="+id, {
      headers: {
        token: globalToken,
      },
    }).then(response=>{
      if (response.data.data.length<=0){
        deleteUserNoTransaction()
    }else{
      alert('Can not delete this store.It has transaction history');
      setOpen(false)
      return;
    }
  })
}

  const deleteUserNoTransaction = () => {
    if (
      window.confirm(
        "Are you sure you want to delete?"
      )
    ) {
   try {
      axios.delete("/stores?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  }
  };

 /*  const getListOfStores = () => {
    try {
      axios.get("/stores").then((result) => {
        setParentStoresList(result.data.data);
      });
    } catch (err) {}
  }; */


  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle color={theme.palette.primary.contrastText} bgcolor={theme.palette.primary.main}>{props.Title} </DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container >
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Store Name")}
                    defaultValue={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                   <Autocomplete
                        selectOnFocus
                        autoHighlight
                        value={parentStore}
                        id="parentStore"
                        onChange={(e) => {
                          setParentStore(e.target.textContent);
                        }}
                        options={parentStoresList && parentStoresList.map((item) => {
                          return item.Name;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Select Parent Store")}
                            
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      />

                  <TextField
                    size="small"
                    variant="standard"
                    label="Region"
                    defaultValue={region}
                    multiline
                    maxRows={4}
                    sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                <label>Supplier: </label><label>{globalUser.Id}</label><br></br>
                <label>Supplier Name:</label> <label>{globalUser.ParentName}</label>
                </Grid>
              </Grid>
           
          </DialogContentText>
        </DialogContent>
        <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        {globalRoleSecurity.filter(sec=>(sec.Functionality==="Stores" && sec.Security>1)).length>0 &&
<>
          <Button size="small"
          variant="standard" endIcon={<Save />} autoFocus onClick={handleSubmit}>
            Save
          </Button>|</>
}
{globalRoleSecurity.filter(sec=>(sec.Functionality==="Stores" && sec.Security>2)).length>0 &&
<>
          <Button size="small"
          variant="standard" endIcon={<Delete />} autoFocus onClick={handleDelete}>
            Delete
          </Button>|</>
}
          <Button size="small"
                    variant="standard" endIcon={<Close />} onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
