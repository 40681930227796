import { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Grid,
  Stack,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";
//import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import axios from "../../axiosInstances";
import {
  AlternateEmail,
  CloseSharp,
  DeleteSharp,
  Login,
  Preview,
  SaveSharp,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  Name: Yup.string().required("Name is required!!!"),
  UserName: Yup.string().required("Username is required!!!"),
  Password: Yup.string().required("Password is required!!!"),
});
export default function UsersModel(props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const {
    globalUser,
    globalStores,
    globalToken,
    globalRoleSecurity,
    globalCustomers,
  } = useContext(ShoppingCartContext);
  const [open, setOpen] = useState(false);
  const [isAddresssDisplayed, setIsAddresssDisplayed] = useState(false);
  var fileObj = [];
  var fileArray = [];
  const [forCustomer, setForCustomer] = useState(
    props.data ? props.data.ForCustomer : false
  );
  const [customer, setCustomer] = useState(
    props.data ? props.data.ParentName : globalUser.Parent
  );

  const [id, setId] = useState(props.data.Id);
  const [tinNumber, setTinNumber] = useState(props.data.TinNumber);
  const [type, setType] = useState(props.data.Type);
  const [name, setName] = useState(props.data.Name);
  const [description, setDescription] = useState(props.data.Description);
  const [category, setCategory] = useState(props.data.Category);
  const [logoFiles, setLogoFiles] = useState(splitPictures());
  const [signature, setSignature] = useState(props.data.Signature);
  const [amount, setAmount] = useState(props.data.Amount);
  const [commision, setCommission] = useState(props.data.Commision);
  const [remark, setRemark] = useState(props.data.Remark);
  const [route, setRoute] = useState(props.data.Route);
  const [routeObject, setRouteObject] = useState(props.data.Route);
  const [region, setRegion] = useState(props.data.Region);
  const [zone, setZone] = useState(props.data.Zone);
  const [wereda, setWereda] = useState(props.data.Wereda);
  const [houseNo, setHouseNo] = useState(props.data.HouseNo);
  const [surrounding, setSurrounding] = useState(props.data.Surrounding);
  const [telephone, setTelephone] = useState(props.data.Telephone);
  const [mobile, setMobile] = useState(props.data.Mobile);
  const [email, setEmail] = useState(props.data.Email);
  const [web, setWeb] = useState(props.data.Web);
  const [userName, setUserName] = useState(props.data.UserName);
  const [password, setPassword] = useState("");
  const [rolesList, setRolesList] = useState(props.Roles);
  const [role, setRole] = useState(props.data.Role);

  const [lat, setLat] = useState(props.data.Lat);
  const [longt, setLongt] = useState(props.data.Longt);
  const [parent, setParent] = useState(props.data.Parent);
  const [active, setActive] = useState(props.data.Active ? true : false);
  const [storesList, setStoresList] = useState(globalStores);
  const [storeName, setStoreName] = useState(props.data.StoreName);

  const [salesRoutesList, setSalesRoutesList] = useState([]);
  console.log('starting data')
console.log(props.data)
  function splitPictures() {
    try {
      fileArray = [];
      props.data.Logo?.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }

  const loadRoutesList = () => {
    axios
      .get(
        "/SalesRoutes/getBySupplier?Supplier=" +
          (globalUser
            ? globalUser.Parent === null || globalUser.Parent === 0
              ? globalUser.Id
              : globalUser.Parent
            : 0),
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setSalesRoutesList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };

  const loadRoutesById = () => {
    axios
      .get("/SalesRoutes/getById?Id=" + props.data.Route, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setRouteObject(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  useEffect(() => {
    loadRoutesList();
  }, [props.data]);
  useEffect(() => {
    loadRoutesById();
    console.log(props.data)
  }, []);
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setLogoFiles(fileArray);
  }

  const handleUserSubmit = (values) => {
    /*   if(name===undefined || name===null || name.length<=0){
      alert('write the name first')
      return;
    }
 
   if(userName===undefined || userName===null || userName.length<=0){
      alert('write the username first')
      return;
    }
    if(password===undefined || password===null || password.length<=0){
      alert('write the password first')
      return;
    } */
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("TinNumber", values.TinNumber);
      fd.append("Type", "Employee");
      fd.append("Name", values.Name);
      fd.append("Description", values.Description);
      fd.append("Category", values.Category);
      if (
        Array.from(document.getElementById("employeePicture").files).length > 0
      ) {
        Array.from(document.getElementById("employeePicture").files).map(
          (file) => {
            fd.append("Logo", file);
          }
        );
      } else {
        fd.append("Logo", logoFiles && logoFiles.length>0 ?logoFiles[0]:'');
      }

      fd.append("Amount", values.Amount);
      fd.append("Commision", values.Commision);
      fd.append("Remark", values.Remark);
      fd.append("Route", values.Route);
      fd.append("Region", values.Region);
      fd.append("Zone", values.Zone);
      fd.append("Wereda", values.Wereda);
      fd.append("HouseNo", values.HouseNo);
      fd.append("Surrounding", values.Surrounding);
      fd.append("Telephone", values.Telephone);
      fd.append("Mobile", values.Mobile);
      fd.append("Email", values.Email);
      fd.append("Web", values.Web);
      fd.append("UserName", values.UserName);
      fd.append("Password", values.Password);
      fd.append("Role", values.Role);
      fd.append("Lat", values.Lat);
      fd.append("Longt", values.Longt);
      if (forCustomer) {
        fd.append(
          "Parent",
          globalCustomers.filter((gc) => gc.Name === customer).length > 0
            ? globalCustomers.filter((gc) => gc.Name === customer)[0].Id
            : globalUser.Parent
        );
        fd.append("ParentofParent", globalUser.Parent);
      } else {
        fd.append("Parent", globalUser.Parent);
      }
    
      if (
        Array.from(document.getElementById("userSigniture").files).length > 0
      ) {
        Array.from(document.getElementById("userSigniture").files).map(
          (file) => {
            fd.append("Logo", file);
          }
        );
      } else {
        fd.append("Logo", logoFiles && logoFiles.length>1 ? logoFiles[1]:'');
      }
 
      fd.append(
        "Store",
        storesList &&
          storesList.length > 0 &&
          storesList.filter((st) => st.Name === values.StoreName).length > 0
          ? storesList.filter((st) => st.Name === values.StoreName)[0].Id
          : 0
      );
      fd.append("Theme", "Theme0");
      fd.append("Active", active == true ? 1 : 0);

      axios.post("/employees", fd, {
        headers: { "Content-Type": "multipart/form-data", token: globalToken },
      });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleDelete = async () => {
    try {
      await axios
        .get(
          "/vouchers/getByParentAndUser?Parent=" +
            globalUser.Parent +
            "&User=" +
            id,
          {
            headers: {
              token: globalToken,
            },
          }
        )
        .then((response) => {
          if (response.data.data.length <= 0) {
            deleteUserNoTransaction();
          } else {
            alert("Can not delete this user.It has transaction history");
            setOpen(false);
            return;
          }
        });
    } catch (err) {}
  };
  const deleteUserNoTransaction = async () => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        axios.delete("/employees/delete?Id=" + id, {
          headers: {
            token: globalToken,
          },
        });
      } catch (err) {
      } finally {
        props.setParentUpdate(!props.ParentUpdate);
        setOpen(false);
      }
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Formik
        initialValues={props.data}
        onSubmit={(values) => {
          handleUserSubmit(values);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, errors,  handleChange, handleSubmit }) => (
          <Dialog
            
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              style={{ display: "flex" }}
              bgcolor={theme.palette.primary.main}
              color={theme.palette.primary.contrastText}
            >
              <label>Parent Company: </label>
              <label hidden>{globalUser.Parent}</label>
              <label>{globalUser.ParentName}</label>
              <label style={{ flex: 1, textAlign: "right", marginRight: 10 }}>
                Active
                <input
                  type="checkbox"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />{" "}
              </label>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* <h5 style={{ fontWeight: "bold" }}>{props.Title}</h5> */}
                <hr></hr>

                <Grid container spacing={2} direction="row">
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label={t("Name")}
                      value={values.Name}
                      name="Name"
                      onChange={handleChange}
                      sx={{ width: "95%", marginBottom: 2 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.Name && (
                      <span style={{ color: "red" }}> {errors.Name}</span>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label={t("description")}
                      multiline
                      value={values.Description}
                      onChange={()=>
                        //(e) => {
                        handleChange("Description") // setName(e.target.value);
                      }
                      sx={{ width: "95%", marginBottom: 2 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {/*  <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Remark"
                  defaultValue={remark}
                  sx={{ width: "95%", marginBottom: 2 }}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                       required
                      label="Username"
                      sx={{ width: "95%", marginBottom: 2 }}
                      value={values.UserName}
                      onChange={
                        //(e) => {
                        handleChange("UserName") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.UserName && (
                      <span style={{ color: "red" }}> {errors.UserName}</span>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      type="password"
                      required
                      variant="standard"
                      label="Password"
                      sx={{ width: "95%", marginBottom: 2 }}
                      value={values.Password}
                      onChange={
                        //(e) => {
                        handleChange("Password") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.Password && (
                      <span style={{ color: "red" }}> {errors.Password}</span>
                    )}
                  </Grid>

                  {globalUser.ParentType === "Company" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <span>
                          {" "}
                          <b>{"For Customer:   "}</b>
                          <Checkbox
                            value={values.ForCustomer}
                            onChange={
                              //(e) => {
                              handleChange("ForCustomer") // setName(e.target.value);
                            }
                          />
                        </span>
                      </Grid>

                      {forCustomer === true && (
                        <Grid item xs={6} mt={1}>
                          <Autocomplete
                            label="Customers"
                            id="customer"
                            size="small"
                            variant="standard"
                            value={values.Customer}
                            onChange={
                              //(e) => {
                              handleChange("Customer") // setName(e.target.value);
                            }
                            options={
                              !(globalCustomers === undefined) &&
                              !(globalCustomers === null) &&
                              globalCustomers.length > 0 &&
                              globalCustomers.map((cust) => {
                                return cust.Name;
                              })
                            }
                            sx={{ width: "95%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Customers")}

                                //error={!from ? true : false}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={6} mt={1}>
                    <Autocomplete
                      label="Issued To"
                      id="StoreName"
                      size="small"
                      variant="standard"
                     defaultValue={values.StoreName}
                                          onChange={(e)=>{
                                            values.StoreName=e.target.textContent;
                                            handleChange("StoreName")
                                          }
                        
                      
                      }
                      options={
                        !(storesList === undefined) &&
                        !(storesList === null) &&
                        storesList.length > 0 &&
                        storesList.map((pro) => {
                          return pro.Name;
                        })
                      }
                      sx={{ width: "95%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Working Store")}

                          //error={!from ? true : false}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Autocomplete
                      id="role"
                      size="small"
                      variant="standard"
                      defaultValue={values.Role}
                      onChange={(e)=>{
                        values.Role=e.target.textContent
                        handleChange("Role")
                      }
                        //(e) => {
                         // setName(e.target.value);
                      }
                      options={
                        !(rolesList === undefined) &&
                        !(rolesList === null) &&
                        rolesList.length > 0 &&
                        rolesList.map((rl) => {
                          return rl.Description;
                        })
                      }
                      sx={{ width: "95%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Security Role")}

                          //error={!from ? true : false}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label="Mobile"
                      sx={{ width: "95%" }}
                      value={values.Mobile}
                      onChange={
                        //(e) => {
                        handleChange("Mobile") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label="Email"
                      type="email"
                      sx={{ width: "95%" }}
                      value={values.Email}
                      onChange={
                        //(e) => {
                        handleChange("Email") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        cursor: "pointer",
                        marginTop: 2,
                      }}
                      onClick={() =>
                        setIsAddresssDisplayed(!isAddresssDisplayed)
                      }
                      pl={2}
                    >
                      User Additional Information
                    </Stack>
                    <div
                      style={{
                        display: isAddresssDisplayed ? "block" : "none",
                        width: "100%",
                      }}
                    >
                      <Grid container mt={2}>
                        <Grid item xs={6} md={6}>
                          <Autocomplete
                            disablePortal
                            selectOnFocus
                            id="route"
                            autoHighlight
                            defaultValue={
                              routeObject &&
                              !(routeObject === null) &&
                              routeObject.length > 0
                                ? routeObject[0]
                                : ""
                            }
                            getOptionLabel={(option) => option.Name}
                            onChange={(e, newValue) => {
                              setRouteObject([]);
                              setRouteObject((preValue) => [
                                ...preValue,
                                newValue,
                              ]);
                              setRoute(newValue.Id);
                            }}
                            options={salesRoutesList}
                            sx={{
                              width: "95%",
                              borderRadius: "25%",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Route Name")}
                                variant="standard"
                                //error={!fromAirport ? true : false}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Region"
                            sx={{ width: "95%" }}
                            value={values.Region}
                            onChange={
                              //(e) => {
                              handleChange("Region") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Zone"
                            sx={{ width: "95%" }}
                            value={values.Zone}
                            onChange={
                              //(e) => {
                              handleChange("Zone") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Wereda"
                            sx={{ width: "95%" }}
                            value={values.Wereda}
                            onChange={
                              //(e) => {
                              handleChange("Wereda") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="HouseNo"
                            sx={{ width: "95%" }}
                            value={values.HouseNo}
                            onChange={
                              //(e) => {
                              handleChange("HouseNo") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Surrounding"
                            sx={{ width: "95%" }}
                            value={values.Surrounding}
                            onChange={
                              //(e) => {
                              handleChange("Surrounding") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="telephone"
                            sx={{ width: "95%" }}
                            value={values.Telephone}
                            onChange={
                              //(e) => {
                              handleChange("Telephone") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Web"
                            sx={{ width: "95%" }}
                            value={values.Web}
                            onChange={
                              //(e) => {
                              handleChange("Web") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Lat"
                            sx={{ width: "95%" }}
                            value={values.Lat}
                            onChange={
                              //(e) => {
                              handleChange("Lat") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Longt"
                            sx={{ width: "95%" }}
                            value={values.Longt}
                            onChange={
                              //(e) => {
                              handleChange("Longt") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} style={{ cursor: "pointer" }} p={1}>
                          <label htmlFor="employeePicture">
                            User Perosnal Photo{" "}
                            {logoFiles && (
                              <Button
                                endIcon={<CloseSharp />}
                                onClick={() => {
                                  setLogoFiles("");
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="employeePicture"
                              onChange={uploadMultipleFiles}
                              hidden
                            />
                            <div className="form-group multi-preview">
                              {(logoFiles || []).map((address, index) => (
                                <img
                                  key={index}
                                  src={address}
                                  alt="..."
                                  style={{ width: "100%", height: "100" }}
                                />
                              ))}
                            </div>
                          </label>
                        </Grid>
                        <Grid
                          item
                          pl={2}
                          xs={6}
                          style={{ cursor: "pointer" }}
                          p={1}
                        >
                          <label htmlFor="userSigniture">
                            User Signature
                            {signature && (
                              <Button
                                endIcon={<CloseSharp />}
                                onClick={() => {
                                  setSignature("");
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="userSigniture"
                              multiple
                              onChange={(e) =>
                                setSignature(
                                  URL.createObjectURL(e.target.files[0])
                                )
                              }
                              hidden
                            />
                            <div className="form-group multi-preview">
                              <img
                                src={signature}
                                alt="..."
                                style={{ width: "100%", height: "100" }}
                              />
                            </div>
                          </label>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                height: "20px",
              }}
            >
              <Button variant="standard" autoFocus endIcon={<Preview />}>
                <InvoicePreview
                  icon={<div>Preview</div>}
                  Suppliers={[props.data]}
                  Type="Supplier"
                  DocumentType="User Information"
                />
              </Button>
              |
              {globalRoleSecurity.filter(
                (sec) => sec.Functionality === "Users" && sec.Security > 1
              ).length > 0 && (
                <>
                  <Button
                    variant="standard"
                    endIcon={<SaveSharp />}
                    autoFocus
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  |
                </>
              )}
              {globalRoleSecurity.filter(
                (sec) => sec.Functionality === "Users" && sec.Security > 2
              ).length > 0 && (
                <>
                  <Button
                    variant="standard"
                    endIcon={<DeleteSharp />}
                    autoFocus
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                  |
                </>
              )}
              <Button
                variant="standard"
                endIcon={<CloseSharp />}
                onClick={() => setOpen(false)}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </div>
  );
}
