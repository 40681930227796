import { useState, useEffect,useRef,useContext } from "react";
import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  IconButton,
  Checkbox,
} from "@mui/material";
import {
  Clear, CloseSharp, DeleteSharp, SaveSharp
  } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function ItemsModel(props) {
  const theme=useTheme();
  const LogoInputRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  var fileObj = [];
  var fileArray = [];

  const [id, setId] = useState(props.data.Id);
  const [Name, setName] = useState(props.data.Name);
  const [brandName, setBrandName] = useState(props.data.BrandName);
  const [abbriviation, setAbbriviation] = useState(props.data.Abbriviation);
  const [description, setDescription] = useState(props.data.Description);
  const [category, setCategory] = useState(props.data.Category);
  const [uom, setUom] = useState(props.data.UOM);
  const [Photofiles, setPhotofiles] = useState(splitPictures());
  const [vatInclusive, setVATInclusive] = useState(props.data.VATInclusive);
  const [remark, setRemark] = useState(props.data.Remark);
  const [parentCategoriesList, setParentCategoriesList] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const { globalToken,globalRoleSecurity,globalUser,setAllItems } = useContext(ShoppingCartContext);
  function splitPictures() {
   
      try {
        fileArray = [];
        props.data.Picture?.split(",").map(function (address, index) {
          if (!fileArray.includes(address) && address.length > 0) {
            fileArray.push(address);
            //document.getElementById('file').files.push(new Blob(address))
          }
        });
        return fileArray;
      } catch (err) {
        console.log(err);
      }
 
  
  }
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setPhotofiles(fileArray);
  }
  const getAllItems=async()=>{
    try {
      await axios.get("/items/getAllItemsPerParentForTransaction?Parent="+ globalUser.Parent, {
         headers: {
           token: globalToken,
         },
       }).then(response=>{
         setAllItems(response.data.data)
     })
     } catch (err) {
    
   }
  }
  const handleSubmit = () => {
    
    if(Name===undefined || Name===null || Name.length<=0){
      alert('write the name first')
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Name", Name);
      fd.append("BrandName", brandName);
      fd.append("Abbriviation", abbriviation);
      fd.append("Description", description);
      fd.append("Category", category);
      fd.append("UOM", uom);
  
      if(Array.from(document.getElementById("itemPicture").files).length>0){
          Array.from(document.getElementById("itemPicture").files).map((file) => {
        fd.append("Picture", file);
      }); 
      }else{
        fd.append('OriginalPicture',Photofiles)            
      }
      
      fd.append("VATInclusive", vatInclusive===true?1:0);
      fd.append("Remark", remark);

      axios.post("/items", fd, {
        headers: { "Content-Type": "multipart/form-data" , 
            token: globalToken,
         },
      });
    } catch (err) {
    } finally {
      try{
        getAllItems()
      }catch(er){
      }finally{
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
      }
  
      
    }
  }
  };

  const handleDelete=async()=>{
  
    try {
     await axios.get("/items/getTransactionsByItem?Item="+id, {
        headers: {
          token: globalToken,
        },
      }).then(response=>{
        if (response.data.data.length<=0){
          deleteNoTransaction()
      }else{
        alert('Can not delete this item.It has transaction history');
        setOpen(false)
        return;
      }
    })
    } catch (err) {
   
  }
}
  const deleteNoTransaction = () => {
    if (
      window.confirm(
        "Are you sure you want to delete?"
      )
    ) {
    try {
      axios.delete("/items/delete?Id=" + id, {
        headers: {
          token: globalToken,
        },
      })
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const getListOfCategories = () => {
    try {
      axios.get("/itemCategories", {
        headers: {
          token: globalToken,
        },
      }).then((result) => {
        setParentCategoriesList(result.data.data);
      });
    } catch (err) {}
  };

  useEffect(() => {
    getListOfCategories();
  }, []);

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
       
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}> {props.Title} </DialogTitle>
        <DialogContent>
          <DialogContentText>
           
              <hr></hr>

              <Grid container direction="row">
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Item Name")}
                    defaultValue={Name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                    </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Other Name")}
                    defaultValue={brandName}
                    onChange={(e) => {
                      setBrandName(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                    </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Abbriviation")}
                    defaultValue={abbriviation}
                    onChange={(e) => {
                      setAbbriviation(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                    </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Description")}
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                    disablePortal
                    selectOnFocus
                    autoHighlight
                    contentEditable={false}
                    value={parentCategoriesList.filter(cat=>parseInt(cat.Id)===parseInt(category)).length>0? parentCategoriesList.filter(cat=>parseInt(cat.Id)===parseInt(category))[0].Name:''}
                    id="paidTo"
                    onChange={(e) => {
                      setCategory(parentCategoriesList.filter(cat=>cat.Name===e.target.textContent).length>0 ? parentCategoriesList.filter(cat=>cat.Name===e.target.textContent)[0].Id:0)}}
                    options={!(parentCategoriesList === undefined) &&
                      !(parentCategoriesList === null) &&
                      parentCategoriesList.length > 0
                        ? parentCategoriesList.map((item) => {
                            return item.Name;
                          })
                        : [""]
                    }
                    sx={{
                      width: "95%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Category"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label="Unit of Measure"
                    defaultValue={uom}
                    multiline
                    maxRows={4}
                    sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setUom(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                    </Grid>
                    <Grid item xs={12} md={6}>VAT Inclusive
                    <Checkbox checked={vatInclusive} onChange={(e)=>setVATInclusive(e.target.checked)} />
                    </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label="Remark"
                    defaultValue={remark}
                    multiline
                    maxRows={4}
                    sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                <IconButton onClick={()=>{
                  LogoInputRef.current.value = null;
                  setPhotofiles([])
               
                }}>
                {<Clear style={{ width: "20px", height: "20px" }} />}
                </IconButton>
                  <label htmlFor="itemPicture">Product Picture
                  <input
                    type="file"
                    ref={LogoInputRef} 
                    accept="image/*"
                    id="itemPicture"
                    multiple
                    onChange={uploadMultipleFiles}
                    hidden
                  />
                  
                  </label>
                  <div className="form-group multi-preview">
                    {(Photofiles || []).map((address) => (
                      <img
                        src={address}
                        alt="..."
                        style={{ width: "100%", height: "100" }}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
         
            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}}>
        {globalRoleSecurity.filter(sec=>(sec.Functionality==="Items" && sec.Security>1)).length>0 &&
          <>
          <Button variant="standard" autoFocus onClick={handleSubmit} endIcon={<SaveSharp />}>
            Save 
          </Button>|</>
}
{globalRoleSecurity.filter(sec=>(sec.Functionality==="Items" && sec.Security>2)).length>0 &&
<>
          <Button variant="standard" autoFocus onClick={handleDelete} endIcon={<DeleteSharp />}>
            Delete 
          </Button>|
          </>
}
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
