import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
       IconButton,
       Button,
    Box,
    Tooltip,
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo, useContext } from "react";
  import SuppliersModel from "../Purchase/SuppliersModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
  import { useTranslation } from "react-i18next";


import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

  function CustomersCompForApproval(props) {
    const navigate = useNavigate();
    const theme=useTheme();
    const { t, i18n } = useTranslation();
    const [update, setUpdate] = useState(false);
     ;
    const [SuppliersList, setSuppliersList] = useState([]);
    const {setPageHeader,globalUser,globalToken }=useContext(ShoppingCartContext)

 
    const columns = useMemo(
      () => [
  
        {
          header: "Tin No.",
          accessorKey: "TinNumber", 
        },
      
        {
          header: "Name",
          accessorKey: "Name", 
        },
        {
          header: "Description",
          accessorKey: "Description", 
        },
        {
          header: "Category",
          accessorKey: "Category", 
        },
      
        {
          header: "Amount",
          accessorKey: "Amount", 
        },
        {
          header: "Commission",
          accessorKey: "Commission", 
        },
        {
          header: "Route",
          accessorKey: "Route", 
        },
        {
          header: "Region",
          accessorKey: "Region", 
        },
        {
          header: "Zone",
          accessorKey: "Zone", 
        },
        {
          header: "Wereda",
          accessorKey: "Wereda", 
        },
        {
          header: "House No.",
          accessorKey: "HouseNo", 
        },
        {
          header: "Surrounding",
          accessorKey: "Surrounding", 
        },
        {
          header: "Telephone",
          accessorKey: "Telephone", 
        },
        {
          header: "Mobile",
          accessorKey: "Mobile", 
        },
        {
          header: "Email",
          accessorKey: "Email", 
        },
        {
          header: "Web",
          accessorKey: "Web", 
        },
        {
          header: "User Name",
          accessorKey: "UserName", 
        },
        {
          header: "Password",
          accessorKey: "Password", 
        },
        {
          header: "Lat",
          accessorKey: "Lat", 
        },
        {
          header: "Remark",
          accessorKey: "Remark", 
        },
      ],
  
      []
    );
  

  
    const loadSuppliersList =  () => {
      setSuppliersList([]);
     axios.get("/Suppliers/getByParentForApproval?Parent=" + globalUser.Parent, {
      headers: {
        token: globalToken,
      },
    }).then(response=>{
      console.log('Check this again')
      console.log(response.data.data)
          if (response.data.data){
           setSuppliersList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };

  
    const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/Suppliers/Excel", fdata, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
         },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(SuppliersList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "supports");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "supportsdata.xlsx");
    }
    
  
  
  
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      loadSuppliersList();
      setPageHeader('Prospects')
    }, [update]);
    return (
      <Grid container>
         

          <Grid item className="hideInMobile" m={1}>
      
            <MaterialReactTable
            title="Abdulmejid table"
              columns={columns}
              data={SuppliersList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
             enableGrouping
            //enableRowSelection //enable some features
              enableColumnOrdering
              enableColumnResizing
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <SuppliersModel
                      icon={
                        <div>Add</div>
                      }
                      data=""
                      Type="Customer"
                      Title="ADD NEW SUPPLIER"
                      setParentUpdate={setUpdate}
                      ParentUpdate={update}
                    />
                  </Button>|
                 
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                     Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>|
              
                  <Button type="file" onClick={handleDownloadFile}        size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                    Download
                  </Button>|
                  
                    <Button onClick={() => setUpdate(!update)}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                     Refresh
                    </Button>
                  
                </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("modify")}>                  
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:"25px"}}> 
                   <SuppliersModel
                  icon={<div>Approve</div>}
                  data={table.row.original}
                  Type="Customer"
                  Title="EDIT SUPPLIER"
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />   
                  </Button>
                  </Tooltip>
                  <Tooltip title={t("Edit")}>                  
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:"25px"}}> 
                   <SuppliersModel
                  icon={<div>Edit</div>}
                  data={table.row.original}
                  Type="Customer"
                  Title="EDIT SUPPLIER"
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />   
                  </Button>
                  </Tooltip>
                </Box>
              )}
            ></MaterialReactTable> 
        </Grid>
        <Grid item xs={12} className="hideInFull" mt={2}>
        <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main}}>
            <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                <SuppliersModel
                  icon={<div>Add</div>}
                  data=''
                                    Title="ADD NEW USER"
                />
            </Button>|
            <Button type="file" onClick={handleDownloadFile}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                    Download
                  </Button>|
                 
                    <Button onClick={() => setUpdate(!update)}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                      Refresh
                    </Button>
                
         
          </Box>
      </Grid>
          {!(SuppliersList === undefined) &&
            SuppliersList.length > 0 &&
            SuppliersList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                   <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={item.Name} //.substring(0, 20)}
                      subheader={item.Type}
                    />

                    <CardContent>
                      <Typography variant="body2" align="left">
                       Tin No.: {item.TinNumber}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Category: {item.Category}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Description: {item.Description}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                            <SuppliersModel
                              icon={
                                <EditSharp
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                              data={item}
                              Type="Customer"
                              Title="EDIT STOCK"
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
     
    );
  }
  
  export default CustomersCompForApproval;
  