import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
   Tooltip,
    IconButton,
    Button,
    Box,
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp,
    PreviewSharp,
    CalculateSharp,
    
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo,useContext } from "react";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
import StockBalancesModel from "./StockBalancesModel";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import * as React from 'react';
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

  function StockBalancesComp() {
    const navigate = useNavigate();
    const theme=useTheme();
    const [update, setUpdate] = useState(false);
    const {setPageHeader,globalToken,globalUser } =    useContext(ShoppingCartContext);
    //const [itemCategoriesList, setitemCategoriesList] = useState([]);
    const [stockBalancesList, setstockBalancesList] = useState([]);
   
    const { t } = useTranslation();
 
    const columns = useMemo(
      () => [
     
        {
          header: "Item Name",
          accessorKey: "Name", 
        },
        {
          header: "Store",
          accessorKey: "StoreName", 
        },
        {
          header: "Inventory",
          accessorKey: "Quantity", 
          size: "120"
        },
        {
          header: "Sales Price",
          accessorKey: "Price", 
          size: "120"
        },
        {
          header: "Default Order Qty",
          accessorKey: "DefaultOrderQty", 
        },
       /*  {
          header: "Supplier",
          accessorKey: "Supplier", 
        }, 
        {
          header: "Reference No",
          accessorKey: "ReferenceNo", 
        },*/
        {
          header: "Expiry Date",
          accessorKey: "ExpiryDate", 
          size: "120"
        },
        {
          header: "Mfr Country",
          accessorKey: "MfrCountry", 
          size: "120"
        },
        {
          header: "Mfr Date",
          accessorKey: "MfrDate", 
          size: "120"
        },
      
    /*     {
          header: "Reserved Qty",
          accessorKey: "ReservedQty", 
        },
        {
          header: "Quota Type",
          accessorKey: "_QuotaType", 
        },
        {
          header: "Quota",
          accessorKey: "Quota", 
        }, */
       
    /*     {
          header: "Approved",
          accessorKey: "Approved", 
        },
        {
          header: "Approved By",
          accessorKey: "ApprovedBy", 
        },
        {
          header: "Remark",
          accessorKey: "Remark", 
        }, */
        
      ],
      []
    );

    
    const recalculateBalancesList =  () => {
      axios.get("/stockBalances/RecalculateByParent?Parent=" + globalUser.Parent, {
       headers: {
         token: globalToken,
       },
     }).then(response=>{
          loadstockBalancesList()
         })
         
       };
     
    const loadstockBalancesList =  () => {
     axios.get("/stockBalances/getByParent?Parent=" + globalUser.Parent, {
      headers: {
        token: globalToken,
      },
    })
         .then(response=>{
          if (response.data.data){
           setstockBalancesList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };
    
    
  
    const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/stockBalances/Excel", fdata, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
         },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(stockBalancesList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "stockBlances");
      let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "stockBlancesdata.xlsx");
    }
    
  
  
  
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      setPageHeader('Stock Balances')
      loadstockBalancesList();
    }, [update]);
    return (
     
      <Grid container>
    
      
      <Grid item className="hideInMobile" m={1}>
     
            <MaterialReactTable
              columns={columns}
              data={stockBalancesList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
              enableColumnFilters
           
              enableColumnResizing
              enableRowNumbers
               muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>              
               <Button  type="file" size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileDownload />} onClick={handleDownloadFile}>
                   Download
                  </Button>|

<Button onClick={()=>setUpdate(!update)}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp />}>
Refresh
</Button>|
<Button onClick={()=>{recalculateBalancesList()}}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<CalculateSharp />}>
Recalculate
</Button>

                </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("modify")}> 
                  <IconButton > 
                   <StockBalancesModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  Title="EDIT STOCK"
                  setParentUpdate={setUpdate}
                      ParentUpdate={update}
                 
                />   
                  </IconButton>
                
                 </Tooltip>
                </Box>
              )}
            ></MaterialReactTable> 
         </Grid>
        {!(stockBalancesList === undefined) &&
          stockBalancesList.length > 0 &&
          stockBalancesList.map((item) => {
            return (
              <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                 <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                  <CardHeader
                   avatar={
                    <Avatar
                      src={
                        item.Picture != null && item.Picture.length > 0
                          ? item.Picture.split(",")[0]
                          : "/Images/Products/default.png"
                      }
                    />
                  }
                    className="CardHeader"
                    title={item.Name} //.substring(0, 20)}
                  />

                  <CardContent>
                    <Typography variant="body2" align="left">
                      Inventory: {item.Quantity}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Default Order Qty: {item.DefaultOrderQty}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Unit Sales Price: {item.Price}
                    </Typography>
                
                 </CardContent>
                  <CardActions
                  sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }} >
                    <Box>
                      <Tooltip title="Edit Payment">
                        <IconButton>
                        <StockBalancesModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={item}
                  Title="EDIT STOCK"
                  setParentUpdate={setUpdate}
                      ParentUpdate={update}
                 
                />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        

      </Grid>
     
    );
  }
  
  export default StockBalancesComp;
  