import { useState, useEffect,useContext } from "react";
import axios from "../../axiosInstances";
import { 
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  Fab,
  Tooltip,
  Rating,
  Button 
} from "@mui/material";
import { AddSharp } from '@mui/icons-material'
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import { useNavigate } from 'react-router-dom'

function NewsComp() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [newsList, setNewsList] = useState([]);
  const [newsCategories,setNewsCategories]=useState([]);
  const {
  setPageHeader,
  globalRoleSecurity
  } = useContext(ShoppingCartContext);
  /*  const [catdata, setCatData] = useState([]); */
  const loadData = async () => {
  await axios.get("/news")
     .then(response=>{
      setNewsList(response.data.data.News);
      setNewsCategories(response.data.data.Categories);
      
    })
    .catch((err) => alert('in erroe'));
    /* setCatData(response.data.data.Categories); */
  };

  useEffect(() => {
    
    setPageHeader('News');
    loadData();
  }, []);


  return (
    <Grid container direction="row" ml={{xs:0,md:1}}>
      <Grid item xs={12} sx={{display:'flex',justifyContent:'right',marginRight:12}}>
      {globalRoleSecurity &&
              globalRoleSecurity.filter(
                (sec) => sec.Functionality === 'News' && sec.Security > 2,
              ).length > 0 && 
       <Fab color="primary"  aria-label="add"  onClick={()=>{
      navigate('/EditStory',{state:{Story:{Id:0,Title:'',Body:'',Category:'',Status:'On Compilation',Image:'',
      PublishedByName:'',
      ViewCount:0,Description:'',Visibility:'false'},Categories:newsCategories}});
    }}>
  <AddSharp />
</Fab>
}
      </Grid>
       
    {newsList && newsList.map((post, index) => {
      
      return (
        <Grid item xs={12} m={1} key={post.Id} md={index === 0 ?11.5:2.8}>
   
         
           <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
              <CardHeader
                avatar={ 
                  <Avatar
                    src={
                      post.Image != null &&
                      post.Image.length > 0
                        ? post.Image
                        : "/Images/NewsPics/default.png"
                    }
                  />
                }
              title={<div dangerouslySetInnerHTML={{__html:post.Title.substring(0, index === 0 ?100:100)}}></div>}
               
                subheader={post.Category}
              />

              <CardMedia
                component="img"
                height="80px"
                sx={{
                  padding: "0.2em 0.2em 0 0.2em",
                  objectFit: "fill",
                }}
                image={
                  post.Image != null && post.Image.length > 0
                    ? post.Image
                    : "/Images/NewsPics/default.png"
                }
                alt={<div dangerouslySetInnerHTML={{__html:post.Title}}></div>}
              />
              <CardContent>
              <Typography variant="body2" align="left">
              {<div dangerouslySetInnerHTML={{__html:post.Description.substring(0, index === 0 ?300:200)+ "..."}}></div>}
                  
                </Typography>
                
                <Button fullWidth variant="contained"  onClick={()=>{
                  navigate("/SingleStory",{state:{Story: post,Categories:newsCategories}})
                }}>
                
                      {t("Read More...")} 
             
              </Button>
            
                   
             
               
                <Tooltip title={t("favorite item")} color="primary">
                  <Rating aria-label="add to favorites">Rating:</Rating>
                </Tooltip>
              </CardContent>
            </Card>

      
        </Grid>
      );
    })}
  </Grid>
  );
}

export default NewsComp;
