import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
    IconButton,
    Box,
  Tooltip
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo, useContext } from "react";
  import SalesRoutesModel from "./SalesRoutesModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles";

  function SalesRoutesComp(props) {
    const theme=useTheme();
    const [update, setUpdate] = useState(false);
    const [SalesRoutesList, setSalesRoutesList] = useState([]);
   const {globalToken}=useContext(ShoppingCartContext)
    const { t, i18n } = useTranslation();
 
    const columns = useMemo(        
      () => [
        {
          header: "Id",
          accessorKey: "Id", 
          size: "120"
        },
        {
          header: "Name",
          accessorKey: "Name", 
        },
       /*  {
          header: "Delivery Person",
          accessorKey: "DeliveryPerson", 
        }, */
        {
          header: "Delivery Person",
          accessorKey: "DeliveryPersonName", 
        },
      /*   {
          header: "Sales Representative",
          accessorKey: "SalesRepresentative", 
        }, */
        {
          header: "Sales Representative",
          accessorKey: "SalesRepresentativeName", 
        },
       /*  {
          header: "Supervisor",
          accessorKey: "Supervisor", 
        }, */
        {
          header: "Supervisor",
          accessorKey: "SupervisorName", 
        },
        {
          header: "Path Detail",
          accessorKey: "PathDetail", 
        },
       
        {
          header: "Remark",
          accessorKey: "Remark", 
        },
        
      ],
      []
    );
  

  
    const loadSalesRoutesList =  () => {
     axios.get("/SalesRoutes/getBySupplier?Supplier=" + props.Supplier, {
      headers: {
        token: globalToken,
      },
    })
     .then(response=>{
          if (response.data.data){
            setSalesRoutesList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };
     
  
    const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/salesRoutes/Excel", fdata, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
         },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(SalesRoutesList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SalesRoutes");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "SalesRoutesdata.xlsx");
    }
    
  
  
  
    useEffect(() => {
      
        loadSalesRoutesList();
    }, [update]);
    return (
      <Grid container>
     
      
      <Grid item className="hideInMobile" m={1}>
      
            <MaterialReactTable
              columns={columns}
              data={SalesRoutesList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
             
              //enableRowSelection //enable some features
              //enableColumnOrdering
              enableColumnResizing
              enableColumnFilters
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                      <Tooltip title={t("add new item category")}>
                        
                  <IconButton>
                    <SalesRoutesModel
                      icon={
                        <AddSharp style={{ width: "20px", height: "20px" }} />
                      }
                      data=""
                      Title="ADD NEW SALES ROUTE"
                      setParentUpdate={setUpdate}
                      ParentUpdate={update}
                  
                    />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title={t("upload")}>
                  <IconButton>
                    <label htmlFor="file">
                      {<FileUpload style={{ width: "20px", height: "20px" }} />}
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                   
                  </IconButton>
                  </Tooltip>
  <Tooltip title={t("download")}>
                  <IconButton type="file" onClick={handleDownloadFile}>
                    {<FileDownload style={{ width: "20px", height: "20px" }} />}
                  </IconButton>
                  </Tooltip>
                  <Tooltip title={t("refresh")}>
                    <IconButton onClick={() => setUpdate(!update)}>
                      <RefreshSharp />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("modify")}> 
                  <IconButton > 
                  
                   <SalesRoutesModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  Title="EDIT SALES ROUTE"
                  setParentUpdate={setUpdate}
                      ParentUpdate={update}
                 
                />   
                  </IconButton>
                 </Tooltip>
                </Box>
              )}
            ></MaterialReactTable> 
         </Grid>
          {!(SalesRoutesList === undefined) &&
            SalesRoutesList.length > 0 &&
            SalesRoutesList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                   <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={item.Name} //.substring(0, 20)}
                     
                    />

                    <CardContent>
                      <Typography variant="body2" align="left">
                       Delivery Person: {item.DeliveryPersonName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Sales Representative: {item.SalesRepresentativeName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Supervisor: {item.SupervisorName}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                            <SalesRoutesModel
                              icon={
                                <EditSharp
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                              data={item}
                              Title="EDIT STOCK"
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
     
    );
  }
  
  export default SalesRoutesComp;
  