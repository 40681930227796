import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  Button,
    IconButton,
    Box,
  Tooltip
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp,
    HistorySharp,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo,useContext } from "react";
  import BusinessSetupModel from "./BusinessSetupModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
  import { useTranslation } from "react-i18next";
  import { ShoppingCartContext } from "../Context/ShoppingCartContext";
  import { useTheme } from "@mui/material/styles";
  import { useNavigate } from 'react-router-dom'
 
  
  function FinanceSetupComp(props) {
     const navigate = useNavigate();
      const theme=useTheme();
    const [update, setUpdate] = useState(false);
 
    const {setPageHeader,globalUser,globalCustomers,globalProviders,globalToken } =    useContext(ShoppingCartContext);
    const { t, i18n } = useTranslation();
     const [inventoryPostingSetupList, setInventoryPostingSetupList] = useState([]);
     const [chartofAccountLists, setChartofAccounts] = useState([]);
     const [supplierGroups, setSupplierGroups] = useState([]);
     const columns = useMemo(        
      () => [
        {
          header: "Group",
          accessorKey: "SupplierGroup", 
        },
        {
            header: "Account Payable",
            accessorKey: "AccountPayableName", 
          },
          {
            header: "Account Receivable",
            accessorKey: "AccountReceivableName", 
          },
          {
            header: "VAT Account",
            accessorKey: "VATAccountName", 
          },
            {
            header: "Remark",
            accessorKey: "Remark", 
          },
        
      ],
      []
    );
  
    const loadBusinessPostingList =  () => {
     axios.get("/businesspostingsetups/getByParent?Parent="+globalUser.Parent, {
      headers: {
        token: globalToken,
      },
    })
     .then(response=>{
          if (response.data.data){
            setInventoryPostingSetupList(response.data.data);
          }else{
            alert('log out and log in again44')
          }
        })
        
      };
      const loadSupplierGroupsList =  () => {
        axios.get("/settings/getByParentAndType?Parent="+globalUser.Parent +"&Type=" +"Business Group", {
         headers: {
           token: globalToken,
         },
       })
        .then(response=>{
             if (response.data.data){
               setSupplierGroups(response.data.data);
             }else{
               alert('log out and log in again')
             }
           })
           
         };
      const loadChartOfAccountsList =  () => {
        axios.get("/chartofaccounts/getByParent?Parent="+globalUser.Parent, {
         headers: {
           token: globalToken,
         },
       })
        .then(response=>{
             if (response.data.data){
               setChartofAccounts(response.data.data);
             }else{
               alert('log out and log in again')
             }
           })
           
         };
      const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/items/Excel", fdata, {
        headers: {
          token: globalToken,
        },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(inventoryPostingSetupList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "stores");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "storesdata.xlsx");
    }
      
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      setPageHeader("Bus. Pos. Setup" );
      
      loadBusinessPostingList();
     loadChartOfAccountsList();
     loadSupplierGroupsList();
    }, [update]);
    return (   
           
      <Grid container>
   {chartofAccountLists && chartofAccountLists.length>0 ?  
   supplierGroups && supplierGroups.length>0 ? 
      <>
      <Grid item className="hideInMobile" m={1}>
    
            <MaterialReactTable
              columns={columns}
              data={inventoryPostingSetupList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
              enableColumnOrdering
              enableColumnResizing
              enableColumnFilters
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <BusinessSetupModel
                      icon={
                        <div>Add</div>
                      }
                    
                      ChartOfAccounts={chartofAccountLists.filter(ca=>ca.Type!=='Category')}
                      SupplierGroups={supplierGroups}
                      ParentUpdate={update}
                      setParentUpdate={setUpdate}
                  
                    />
                  </Button>|
             
  
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                    </Tooltip>
                    
                </Box>
              )}
             
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("History")}> 
                  <IconButton > 
                  
                   <BusinessSetupModel
                  icon={<HistorySharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  ChartOfAccounts={chartofAccountLists.filter(ca=>ca.Type!=='Category')}
                  SupplierGroups={supplierGroups}
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                 
                />   
                  </IconButton>
                 </Tooltip>
                </Box>
              )}
            ></MaterialReactTable> 
             </Grid>
             <Grid item xs={12} mt={2} className="hideInFull">
             <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <BusinessSetupModel
                      icon={
                        <div>Add</div>
                      }
                      ChartOfAccounts={chartofAccountLists.filter(ca=>ca.Type!=='Category')}
                      SupplierGroups={supplierGroups}
                    />
                  </Button>|
               {/*    <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                      Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>| */}
  
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
             </Grid>
        {!(inventoryPostingSetupList === undefined) &&
          inventoryPostingSetupList.length > 0 &&
          inventoryPostingSetupList.map((item) => {
            return (
              <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                 <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                  <CardHeader
                  
                    subheader={"Category: "+item.SupplierGroup} 
                    
                  />

                  <CardContent>
                    <Typography variant="body2" align="left">
                      Inventory Account: {item.InventoryAccount}
                    </Typography>
                    <Typography variant="body2" align="left">
                      COGS: {item.COGS}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Sales: {item.Sales}
                    </Typography>
                   
                    <Typography variant="body2" align="left">
                      Remark: {item.Remark}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                    <Box>
                    <Tooltip title={t("Edit Store")}> 
                  <IconButton > 
                  
                   <BusinessSetupModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={item}
                  ChartOfAccounts={chartofAccountLists.filter(ca=>ca.Type!=='Category')}
                  SupplierGroups={supplierGroups}
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                 
                 
                />   
                  </IconButton>
                 </Tooltip>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          </>
          :<div>There is no supplier group in this company</div>
          :<div>There is no chart of accounts in this company</div>
        }

      </Grid>
        
         );
  }
  
  export default FinanceSetupComp;