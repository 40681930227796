import { useState, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  SignalCellularNull,
  PreviewSharp,
  PaymentSharp,
  BookOnline,
  RequestPageSharp,
  DraftsSharp,
  DirectionsSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";

import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function PurchaseProformaModel(props) {
const theme=useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState(
    props.data ? props.data.VoucherDate : format(new Date(), "yyyy-MM-dd")
  );
  const {
    removeFromCart,
    setGlobalSelectedSuppliers,
    globalUser,
    globalProviders,
    globalRoleSecurity,
    globalToken
  } = useContext(ShoppingCartContext);




  const [lineItems, setLineItems] = useState(props.LineItems);

    const [supplier, setSupplier] = useState({Id:props.data.Supplier,Name:props.data.Name});
    const [Photofiles, setPhotofiles] = useState(splitPictures());


 function splitPictures() {
    try {
      fileArray = [];
      props.data.Attachment.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setPhotofiles(fileArray);
  }


  const handleSubmit = (id,status,vDate) => {
    
      if(status!=='Draft' || status!=='Request'){
      if (supplier.Id===undefined || supplier.Id===null || props.data.Id===0){
        alert("First select the supplier" );
               return;
      }
    }
     


 
    if (
      window.confirm(
        "Are you sure you want to save the purchase proforma?"
      )
    ) {
    try {
     
        var fd = new FormData();
        fd.append("OrderId",  id);
        fd.append("SourceNo", props.data.Status==='Proforma'?props.data.SourceNo:props.data.OrderId);
        fd.append("Type",(props.data.Status==='Proforma' && status==="order") ? "PTO":"Normal");
        fd.append("Supplier", supplier.Id);
        fd.append("VoucherDate", vDate);
        fd.append("RequestedBy", props.data.RequestedBy);
        fd.append("RequestedDate", props.data.RequestedDate);
        fd.append("ApprovedBy", props.data.ApprovedBy);
        fd.append("ApprovedDate", props.data.ApprovedDate);
        fd.append("Amount", props.data.Amount.toString().replace(',','').toLocaleString().toString().replace(',',''));
        fd.append("VATAmount", props.data.VATAmount.toLocaleString().toString().replace(',',''));
        fd.append("TotalAmount", (parseFloat(props.data.Amount.toString().replace(',',''))+parseFloat(props.data.VATAmount.toString().replace(',',''))).toLocaleString().toString().replace(',',''));
    
        fd.append("Customer", globalUser.Parent);
        fd.append("Status", status)
        fd.append("BillTo", supplier.Id);
        fd.append("Store", props.data.Store);
        fd.append("User", globalUser.Id);
        Array.from(document.getElementById("Attachment").files).map((file) => {
          fd.append("Attachment", file);
        });

        fd.append(
          "LineItems",
          JSON.stringify(
         status==='Proforma'?
            lineItems.map(lit=>{
              if(props.data.Status!=='Proforma'){
                lit.ExtReference=lit.Id; //reference from the purchase approval line item
                lit.Id=0;
              }
              return lit;
            }):
            lineItems.map(li=>{
              li.Id=li.ExtReference
              return li;
            })
          )
        );
       
        axios
          .post("/vouchers", fd, {
            headers: { "Content-Type": "multipart/form-data" , 
                token: globalToken,
            },
          })
          
    } catch (err) {
    } finally{
      setGlobalSelectedSuppliers([]);
     
      setLineItems([]);
      try{
 if (props.data && (props.data !== null)) {
        props.setParentUpdate(!props.ParentUpdate);
      }
      }catch(err){

      }
     
      setOpen(false);
    }
  }

  };
  const handleDelete = () => {
    try {
      axios.delete("/vouchers?Id=" + props.data.OrderId, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleLineItemDelete = (id) => {
    try {
      axios.delete("/lineItems?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }).
      then(()=>setLineItems(lineItems.filter(li=>li.Id!==id))); ; //add id
    } catch (err) {
    } finally {
    }
  };

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
             <DialogTitle style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText, height:'20px'}}>
                         
                  PP: Supplier:{" " + props.data.Name +  "          Total Amount: " + (props.data.Amount && +props.data.Amount.toString().replace(',','') +  +props.data.VATAmount.toString().replace(',',''))?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,maximumFractionDigits: 2,
                                          })}
                    </DialogTitle>
                    <Grid container key={props.data.Id}>
                    <Grid
                        item
                        xs={12}
                        pl={'35%'}
                        sx={{
                         backgroundColor:theme.palette.primary.dark,
                         color:theme.palette.primary.contrastText,
                         textDecoration:'underline',
                         fontWeight:'bold',
                        
                       }}
                      >
                        {"Purchase Proforma" }
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        
                      >
                        <span style={{}}>
                          Doc. No.: {props.data ? props.data.OrderId : ""}<br></br>
                          Store: {props.data.StoreName}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                       
                      >
                       
                          Status: Proforma<br></br>
                          Customer: {globalUser.ParentName}
                        
                      </Grid>
                    
                      <Grid
                        item
                        xs={6}
                        
                      >
                        {props.data.Status && props.data.Status==='Approved'?
                        <Autocomplete
                                            label="Supplier"
                                            id="Supplier"
                                            variant="standard"
                                            value={supplier.Name}
                                            onChange={(e) => {
                                             setSupplier(globalProviders.filter(gs=>gs.Name===e.target.textContent)[0])
                                          
                                                }
                                              }
                                            options={
                                              !(globalProviders === undefined) &&
                                              !(globalProviders === null) &&
                                              globalProviders.length > 0
                                                && globalProviders.map((pro) => {
                                                      return pro.Name;
                                                    })
                                            }
                                            sx={{ width: "95%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={t("Supplier")}

                                                //error={!from ? true : false}
                                              />
                                            )}
                                          />:
                                        'Supplier:'+  supplier.Name
                                            }
                      </Grid>
                
                     
                     
                      <Grid
                        item
                        xs={6}
                        mt={1}
                       
                      >
                        {props.data.Status && props.data.Status==='Approved'?
                        <TextField
                          size="small"
                          label="Order Date"
                          type="date"
                          value={
                            props.data.VoucherDate? props.data.VoucherDate: orderDate
                          }
                          onChange={(e) => {
                            props.data.VoucherDate=e.target.value;
                          }}
                          sx={{ width: "70%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />:
                        props.data.VoucherDate
}
                      </Grid>
                     
                      <Grid
                        item
                        xs={6}
                        
                      >
                        User:{" "}
                        {props.data?props.data.UserName : globalUser.Name}
                      </Grid>
                  

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                          
                        >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>
                             
                             <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                Unit Price
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                Total
                              </Grid>
                   
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                              
                              </Grid>

                              {lineItems && lineItems!==null && lineItems.length > 0 &&
                                lineItems.map((item, index) => {
    
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item 
                                        xs={4}>
                                        {item.Name}
                                        </Grid>

                                        <Grid item 
                                        xs={2}>
                                        {item.Quantity}
                                        </Grid>
  <Grid item xs={2}>
  {(props.data.ParentStatus &&  props.data.ParentStatus==='Approved') || props.data.Status==='Approved'?
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            value={item.UnitPrice}
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                              item.UnitPrice = e.target.value;
                                              item.TotalPrice=(item.Quantity* item.UnitPrice);
                                              item.TaxAmount=item.VATInclusive?(item.TotalPrice*0.15):0;
                                         
                                              //Unit Price Change
                                              props.data.VATAmount=0;
                                              props.data.Amount=0;
                                            lineItems && lineItems.map(it=>{
                                              props.data.VATAmount=(props.data.VATAmount +it.TaxAmount);
                                              props.data.Amount=(props.data.Amount+it.TotalPrice);
                                            })
                                              setLineItems(
                                                lineItems.map((litem) => {
                                                  return litem;
                                                })
                                              );
                                            }}
                                          
                                          />:
                                          item.UnitPrice
                                }
                                        </Grid>
                                        <Grid item xs={2}>
                                          {(
                                            item.TotalPrice
                                          )?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,maximumFractionDigits: 2,
                                          })}
                                        </Grid>
                                 
                                        <Grid item xs={1}>
                                        {props.data.Status && props.data.Status==='Approved'?
                                          <Tooltip title="remove">
                                            <Button
                                              endIcon={<Delete width="10px" />}
                                              onClick={() => {
                                                if(lineItems.filter((litem) =>
                                                    ((parseInt(litem.Item) ===
                                                    parseInt(item.Item)) )
                                                ).length>0){
                                                
                                                handleLineItemDelete(
                                                  lineItems.filter(
                                                    (litem) =>
                                                      litem.Item ===
                                                        item.Item 
                                                     
                                                  )[0].Id
                                                )  
                                              };
                                              removeFromCart(item);
                                             
                                               
                                              }}
                                            ></Button>
                                          </Tooltip>:
                                          ''
                                            }
                                        </Grid>
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  border: "solid 1",
                                  padding: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Sub Total:
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                style={{
                                  border: "solid 1",
                                  padding: "5px",
                                  textDecoration: "underline",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                {props.data.Amount && props.data.Amount.toString().replace(',','')?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,maximumFractionDigits: 2,
                                    minFractionDigits: 2,
                                  })}
                              </Grid>

                              <Grid item xs={5}></Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  border: "solid 1",
                                  padding: "5px",
                                  backgroundColor: theme.palette.primary.main,
                                  color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                VAT Amount:
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  border: "solid 1",
                                  padding: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  textDecorationLine: "underline",
                                }}
                              >
                                {(props.data.VATAmount && props.data.VATAmount!==null?props.data.VATAmount:0)?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,maximumFractionDigits: 2,
                                })}
                              </Grid>
                              <Grid item xs={5}></Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  border: "solid 1",
                                  padding: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Total Amount:
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  border: "solid 1",
                                  padding: "5px",
                                  textDecoration: "underline",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                {(Number(props.data.Amount && props.data.Amount.toString().replace(',','')) + Number(props.data.VATAmount && props.data.VATAmount!==null && props.data.VATAmount.toString().replace(',',''))).toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 2,maximumFractionDigits: 2 }
                                )}{" "}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {lineItems && lineItems!==null && lineItems.length > 0 &&
                              lineItems.map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                            {item.Name}
                                          
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.Quantity}
                                                maxRows={4}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.Quantity =
                                                    e.target.value;
                                                  setLineItems(
                                                    lineItems.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>

                                            <Grid item xs={4}>
                                              Unit Price:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.UnitPrice}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.UnitPrice =
                                                    e.target.value;
                                                    lineItems && lineItems.map(it=>{
                                                      props.data.VATAmount=(props.data.VATAmount && +props.data.VATAmount.toString().replace(',','') +it.TaxAmount);
                                                      props.data.Amount=(props.data.Amount && +props.data.Amount.toString().replace(',','')+it.TotalPrice);
                                                    })
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              Total Price:
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                              {(
                                                item.UnitPrice * item.Quantity
                                              )?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,maximumFractionDigits: 2,
                                              })}
                                            </Grid>
                                            <Grid item xs={12}>
                                              <hr></hr>
                                              Remove: {"   "}
                                              <Tooltip title="remove">
                                                <Button
                                                  endIcon={
                                                    <Delete width="10px" />
                                                  }
                                                  onClick={() => {
                                                    handleLineItemDelete(
                                                      lineItems.filter(
                                                        (litem) =>
                                                        parseInt(litem.Item) ===
                                                        parseInt(item.Item)
                                                      )[0].Id
                                                    );
                                                    removeFromCart(item);
                                              
                                                    if (
                                                      lineItems.filter(
                                                        (litem) =>
                                                          !(
                                                            litem.Item ===
                                                            item.Item
                                                          )
                                                      ).length <= 0
                                                    ) {
                                                      let newLineItem = {};
                                                      newLineItem.Item = "";
                                                      newLineItem.Name = "";
                                                      newLineItem.Supplier =
                                                        props.data.Id;
                                                      newLineItem.Quantity = 0;
                                                      newLineItem.UnitPrice = 0;
                                                      newLineItem.TotalPrice = 0;
                                                      setLineItems((xx) => [
                                                        ...xx,
                                                        newLineItem,
                                                      ]);
                                                    }
                                                  }}
                                                ></Button>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                            <Table>
                              <tr>
                                <td />
                                <td hidden />
                                <td />
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                  colSpan={2}
                                >
                                  {" "}
                                  Sub Total:
                                </td>

                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    textDecoration: "underline",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                >
                                  
                                  {props.data.Amount && props.data.Amount.toString().replace(',','')?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,maximumFractionDigits: 2,
                                    minFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                ></td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                  hidden
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>

                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                  colSpan={2}
                                >
                                  {" "}
                                  VAT Amount:
                                </td>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  {(props.data.VATAmount && props.data.VATAmount!==null?props.data.VATAmount:0)?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                  }}
                                ></td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                  hidden
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>
                                <td
                                  style={{ border: "solid 1", padding: "5px" }}
                                >
                                  {" "}
                                </td>

                                <td
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                  colSpan={2}
                                >
                                  {" "}
                                  Total Amount:
                                </td>
                                <td
                                  style={{
                                    border:"solid 1",
                                    padding: "5px",
                                    textDecoration: "underline",
                                    backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  {(props.data.Amount && +props.data.Amount.toString().replace(',','') + props.data.VATAmount && +props.data.VATAmount.toString().replace(',','')).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,maximumFractionDigits: 2,
                                      minFractionDigits: 2,
                                    }
                                  )}{" "}
                                </td>
                              </tr>
                            </Table>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          border={1}
                         
                        >
                          <label htmlFor="Attachment">
                            Uplaod necessary document here
                            <input
                              id="Attachment"
                              name="Attachment"
                              type="file"
                              multiple
                              hidden
                              onChange={uploadMultipleFiles}
                            />
                          </label>
                          {Photofiles && Photofiles.length > 0 && (
                            <Button
                              style={{ color: "red" }}
                              ml={4}
                              endIcon={<Close />}
                              onClick={() => {
                                setPhotofiles([]);
                              }}
                            />
                          )}
                          <div className="form-group multi-preview">
                            {(Photofiles || []).map((address) => (
                              <img
                                src={address}
                                alt="..."
                                style={{ width: "100%", height: "100" }}
                              />
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                 
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button variant="standard" endIcon={<PreviewSharp />}>
              
                <InvoicePreview
                  icon={<div  style={{ fontSize: "10px"}}>Preview</div>}
                  Suppliers={[{...props.data,Id:props.data.Supplier}]}
                  LineItems={lineItems}
                  data=''
                  DocumentType={"Purchase Proforma"}
                  Type="Invoice"
                />

              </Button>|
     {props.data.ParentStatus!=="Order" &&
     <>
             {props.data.Status && (props.data.Status==='Approved' || props.data.Status==="Proforma") &&
              <>
              { globalRoleSecurity.filter(sec=>(sec.Functionality==="Purchase Proforma" && (sec.Security>1))).length>0 &&
              <>
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<BookOnline />}
                autoFocus
                onClick={() =>
                  handleSubmit(
                    (props.data.Status!=='Proforma'?0:props.data.OrderId),
                    "Proforma",
                    orderDate
                    
                  )
                }
              >
                Proforma
              </Button>|
              </>}
       
              { globalRoleSecurity.filter(sec=>(sec.Functionality==="Purchase Order" && (sec.Security>1))).length>0 &&
<>
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<BookOnline />}
                autoFocus
                onClick={() =>
                  handleSubmit(
                    props.data.SourceNo,
                    "Order",
                    orderDate,
                    
                  )
                }
              >
                Order
              </Button>|
              </>}
              { props.data.Status==='Proforma' && globalRoleSecurity.filter(sec=>(sec.Functionality==="Purchase Proforma" && (sec.Security>1))).length>0 &&
<>
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<Delete />}
                autoFocus
                onClick={() =>
                  handleDelete()
                }
              >
                Delete
              </Button>|
              </>}
              </>
}
              </>

}
          <Button
          variant="standard"
            style={{ fontSize: "10px" }}
            endIcon={<Close />}
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
