import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel } from "@mui/material";
import {AddSharp, CloseSharp, DeleteSharp, Preview, PreviewSharp, SaveSharp} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function GeneralJournalModel(props) {
const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalToken }=useContext(ShoppingCartContext)
  const [chartofAccounts,setChartofAccounts]=useState(props.Type==='GJ'?props.ChartOfAccounts:[])
  const [journals,setJournals]=useState(props.Journals?props.Journals:[{}])


  const handleSubmit = () => {
    journals && journals.length>0 && journals.map(jl=>{

    
    if(jl.DebitAccountName===null || jl.DebitAccountName.length<=0){
      alert('Debit Account field is required');
      return;
    }
    if(jl.CreditAccountName===null || jl.CreditAccountName.length<=0){
      alert('Credit Account field is required');
      return;
    }
    if(jl.Amount===null || jl.Amount.length<=0){
      alert('Amount field is required');
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to pay?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", jl.Id);
      fd.append("PostingDate", jl.PostingDate);
      fd.append("DocumentNo", jl.DocumentNo);
      fd.append("ReferenceTo", jl.ReferenceTo?jl.ReferenceTo:0);
      fd.append("Type", jl.Type);
      fd.append("DebitAccount", chartofAccounts.filter(ca=>ca.AccountDescription===jl.DebitAccountName)[0].Account );
      fd.append("CreditAccount", chartofAccounts.filter(ca=>ca.AccountDescription===jl.CreditAccountName)[0].Account );
      fd.append("Amount",jl.Amount );
      fd.append("User",globalUser.Id);
      fd.append("Parent",globalUser.Parent);
      fd.append("Remark", jl.Remark);
    
      axios.post("/generalLedgerEntries", fd, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      props.OrderData && props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
})
  };
  const loadChartOfAccountsList =  () => {
    axios.get("/chartofaccounts/getByParent?Parent="+globalUser.Parent, {
     headers: {
       token: globalToken,
     },
   })
    .then(response=>{
         if (response.data.data){
           setChartofAccounts(response.data.data);
         }else{
           alert('log out and log in again')
         }
       })
       
     };
 /*  const handleDelete = () => {
    try {
      axios.delete("/inventoryPostingSetups?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  }; */
 useEffect(()=>{
if(props.Type!=='GJ'){
loadChartOfAccountsList();
}
},[])
  return (
    <div width="1000">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>General Journal</DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container spacing={2}>
             
                     <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    type="date"
                    label={t("PostingDate")}
                    value={journals && journals[0].PostingDate?journals[0].PostingDate:format(new Date(),"yyyy-MM-dd")}
                    onChange={(e) => {
                      journals[0].PostingDate=e.target.value;
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                     <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Document No")}
                    defaultValue={journals && journals[0].DocumentNo}
                    onChange={(e) => {
                      journals[0].DocumentNo=e.target.value;
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>

          <Grid item xs={12} md={12}>
            <table style={{width:'100%'}}>
            {journals && journals.length>0 && journals.map(jl=>{
                  return(<>
              <tr>
              <td style={{width:'30%'}}> <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={jl.DebitAccountName}
                        id="debitAccoutn"
                        onSelect={(e) => {
                          jl.DebitAccountName=e.target.value;
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                          return item.AccountDescription;
                     })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Debit Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /></td>
                <td style={{width:'30%'}}> <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={jl.CreditAccountName}
                        id="creditAccount"
                        onSelect={(e) => {
                          jl.CreditAccountName=e.target.value;
                        }}
                     
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                          return item.AccountDescription;
                     })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Credit Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> </td>
                <td style={{width:'20%'}}>  <TextField
                    size="small"
                    variant="standard"
                    type='float'
                    label={t("Amount")}
                    defaultValue={jl.Amount}
                    onChange={(e) => {
                      jl.Amount=e.target.value;
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></td>
                <td style={{width:'20%'}}> <TextField
                    size="small"
                    variant="standard"
                    label={t("Remark")}
                    defaultValue={jl.remark}
                    onChange={(e) => {
                      jl.remark=e.target.value;
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></td>
                  <tr><Button
                                            variant="standard" 
                                              endIcon={<DeleteSharp sx={{color:theme.palette.primary.main}}   />}
                                              onClick={() =>{
                                                setJournals(journals.map(it=>(it.DebitAccountName!==jl.DebitAccountName && it.CreditAccountName!==jl.CreditAccountName && it.Amount!==jl.Amount)))
                                              } } /></tr>
              </tr>
              </>)
                })}
            </table>
                  
  </Grid>



                
           

              </Grid>
           
          </DialogContentText>
        </DialogContent>
         <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
       
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                          journals && journals.map(jl=>{
                            jl.Type="Gj";
                            jl.PostingDate= jl.PostingDate?jl.PostingDate:format(new Date(),"yyyy-MM-dd")
                          jl.Amount=((isNaN(parseFloat(jl.Amount))?0:parseFloat(jl.Amount))?.toLocaleString(undefined, {minimumFractionDigits:2}));
                          return jl;
                        })
                        }
                        Type="Posting"
                        DocumentType={ "General Journal"}
                        />
                    </Button>|
                    <Button variant="standard" autoFocus onClick={()=>{
                      setJournals([...journals,{
                        Id:0,
                        PostingDate:journals[0].PostingDate,
                        DocumentNo:journals[0].DocumentNo,
                        ReferenceTo:journals[0].ReferenceTo,
                      }])
                    }} endIcon={<AddSharp />}>
            Add Line
          </Button>|
          <Button variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveSharp />}>
            Post
          </Button>|
        
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
