import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel } from "@mui/material";
import {CloseSharp, DeleteSharp, Preview, PreviewSharp, SaveSharp} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function GeneralLedgerEntriesModel(props) {
  const theme=useTheme();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { globalToken }=useContext(ShoppingCartContext)

  const handleDelete = () => {
    try {
      axios.delete("/inventoryPostingSetups?Id=" + props.data.Id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>General Ledger Entry </DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                 Id:{"   "+props.data.Id}
  </Grid>
  <Grid item xs={12} md={6}>
                 Document No:{"   "+props.data.DocumentNo}
  </Grid>
          <Grid item xs={12} md={6}>
                 Posting Date:{"   "+props.data.PostingDate}
  </Grid>
  <Grid item xs={12} md={6}>
  Type:{"   "+props.data.Type}
  </Grid>
  <Grid item xs={6}>Account Description</Grid>
  <Grid item xs={3}>Debit</Grid>
  <Grid item xs={3}>Credit</Grid>
  <Grid item xs={6}>
  {"   "+props.data.DebitAccountName}
  </Grid>
  <Grid item xs={6}>
  {"   "+props.data.Amount}
                  </Grid>
  <Grid item xs={6}>
  {"   "+props.data.CreditAccountName}
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
  {"   "+props.data.Amount}
                  </Grid>
                  <Grid item xs={12} md={12}>
  Remark:{"   "+props.data.Remark}
                  </Grid>
              </Grid>
           
          </DialogContentText>
        </DialogContent>
         <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                        props.data
                        }
                        Type="Posting"
                        DocumentType={ "General Ledger Entry"}
                        />
                    </Button>|
         
          <Button variant="standard" autoFocus onClick={handleDelete} endIcon={<DeleteSharp />}>
            Delete
          </Button>|
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
