import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  Button,
    IconButton,
    Box,
  Tooltip
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp,
    HistorySharp,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo,useContext } from "react";
  import FixedAssetsModel from "./FixedAssetsModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
  import { useTranslation } from "react-i18next";
  import { ShoppingCartContext } from "../Context/ShoppingCartContext";
  import FiexedAssetJournalModel from "./FiexedAssetJournalModel";
  import { useTheme } from "@mui/material/styles";
  import { useNavigate } from 'react-router-dom'
 
  
  function FixedAssetsComp(props) {
     const navigate = useNavigate();
      const theme=useTheme();
    const [update, setUpdate] = useState(false);
 
    const {setPageHeader,globalUser,globalCustomers,globalProviders,globalToken } =    useContext(ShoppingCartContext);
    const { t, i18n } = useTranslation();
     const [fixedAssetsList, setFixedAssetsList] = useState([]);
     const [employeesList, setEmployeesList] = useState([]);
     const [categories, setCategories] = useState([]);
     const columns = useMemo(        
      () => [
        {
          header: "Name",
          accessorKey: "Name", 
        },
        {
            header: "Description",
            accessorKey: "Description", 
          },
          {
            header: "Category",
            accessorKey: "Category", 
          },
          {
            header: "Book Value",
            accessorKey: "BookValue", 
          },
          {
            header: "Purchase Date",
            accessorKey: "PurchaseDate", 
          },
          {
            header: "Depreciation Type",
            accessorKey: "DepreciationType", 
          },
            /* {
            header: "Remark",
            accessorKey: "Remark", 
          }, */
        
      ],
      []
    );
  
    const loadFixedAssetsList =  () => {
     axios.get("/fixedassets/getByParent?Parent="+globalUser.Parent, {
      headers: {
        token: globalToken,
      },
    })
     .then(response=>{
          if (response.data.data){
            setFixedAssetsList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };
      const loadEmployeesList =  () => {
        axios.get("/employees/getByParent?Parent="+globalUser.Parent, {
         headers: {
           token: globalToken,
         },
       })
        .then(response=>{
             if (response.data.data){
               setEmployeesList(response.data.data);
             }else{
               alert('log out and log in again')
             }
           })
           
         };
      const loadFixedAssetCategoriesList =  () => {
        axios.get("/settings/getByParentAndType?Parent="+globalUser.Parent +"&Type=" +"Fixed Asset Group", {
         headers: {
           token: globalToken,
         },
       })
        .then(response=>{
             if (response.data.data){
               setCategories(response.data.data);
             }else{
               alert('log out and log in again')
             }
           })
           
         };
 
      const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/items/Excel", fdata, {
        headers: {
          token: globalToken,
        },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(fixedAssetsList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "stores");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "storesdata.xlsx");
    }
      
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      setPageHeader("Fixed Assets" );
      loadEmployeesList();
      loadFixedAssetsList();
       loadFixedAssetCategoriesList();
    }, [update]);
    return (   
           
      <Grid container>
   {employeesList && employeesList.length>0 ?
   categories && categories.length>0 ? 
      <>
      <Grid item className="hideInMobile" m={1}>
    
            <MaterialReactTable
              columns={columns}
              data={fixedAssetsList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
              enableColumnOrdering
              enableColumnResizing
              enableColumnFilters
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <FixedAssetsModel
                      icon={
                        <div>Add</div>
                      }
                    Categories={categories}
                    EmployeesList={employeesList}
                      ParentUpdate={update}
                      setParentUpdate={setUpdate}
                  
                    />
                  </Button>|
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                  <FiexedAssetJournalModel
                  icon={
                    <div>Depreciation
                    </div>
                  }
                  Journals={[{Id:0,Type:'Depreciation'}]}
                  ReferenceTo={props.data}
                  Type="New"
                 
                />
                  </Button>|
               
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                    </Tooltip>
                    
                </Box>
              )}
             
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("History")}> 
                  <IconButton > 
                  
                   <FixedAssetsModel
                  icon={<HistorySharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  Categories={categories}
                  EmployeesList={employeesList}
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                 
                />   
                  </IconButton>
                 </Tooltip>
                </Box>
              )}
            ></MaterialReactTable> 
             </Grid>
             <Grid item xs={12} mt={2} className="hideInFull">
             <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <FixedAssetsModel
                      icon={
                        <div>Add</div>
                      }
                      Categories={categories}
                      EmployeesList={employeesList}
                    />
                  </Button>|
               {/*    <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                      Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>| */}
  
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
             </Grid>
        {!(fixedAssetsList === undefined) &&
          fixedAssetsList.length > 0 &&
          fixedAssetsList.map((item) => {
            return (
              <Grid item key={item.Id} xs={5.5} m={1} className="hideInFull">
                <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                  <CardHeader
                  title={item.Name} 
                 />

                  <CardContent>
                    <Typography variant="body2" align="left">
                      Acquisition Amount: {item.AcqisitionAmount} </Typography> {/* .replace(',','')?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2}) */}
                    <Typography variant="body2" align="left">
                      Book Value: {item.BookValue}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Purchase Date: {item.PurchaseDate}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Depreciation Type: {item.DepreciationType}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Category: {item.Category}
                    </Typography>
                   
                    <Typography variant="body2" align="left">
                      Remark: {item.Remark}
                    </Typography>
                  </CardContent>
                  <CardActions
                  sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }} >
                    <Box>
                    <Tooltip title={t("Edit Store")}> 
                  <IconButton > 
                  
                   <FixedAssetsModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={item}
                  Categories={categories}
                  EmployeesList={employeesList}
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                 
                 
                />   
                  </IconButton>
                 </Tooltip>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          </>
          :<div>There is no fixed asset categories in this company</div>
          :<div>There is no employees list in this company</div>
        }

      </Grid>
        
         );
  }
  
  export default FixedAssetsComp;