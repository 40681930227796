import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  Button,
    IconButton,
    Box,
  Tooltip,
  MenuItem
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    PreviewSharp,
    RefreshSharp,
    HistorySharp,
    Preview,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo,useContext } from "react";
  import GeneralLedgerEntriesModel from "./GeneralLedgerEntriesModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
  import { useTranslation } from "react-i18next";
  import { ShoppingCartContext } from "../Context/ShoppingCartContext";
  import {format} from 'date-fns'
  import InvoicePreview from "../Reports/InvoicePreview";
import GeneralJournalModel from "./GeneralJournalModel";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

  
  function GeneralLedgerEntriesComp(props) {
    const navigate = useNavigate();
    const theme=useTheme();
    const [update, setUpdate] = useState(false);
 
    const {setPageHeader,globalUser,globalCustomers,globalProviders,globalToken } =    useContext(ShoppingCartContext);
    const { t, i18n } = useTranslation();
     const [generalLedgerEntriesList, setGeneralLedgerEntriesList] = useState([]);
     const [chartofAccountLists, setChartofAccounts] = useState([]);
     //const [supplierGroups, setSupplierGroups] = useState([]);
     const columns = useMemo(        
      () => [
        {
          header: "Posting Date",
          accessorKey: "PostingDate", 
        },
        {
          header: "Document No",
          accessorKey: "DocumentNo", 
        },
        {
            header: "Type",
            accessorKey: "Type", 
          },
          {
            header: "Debit Account",
            accessorKey: "DebitAccountName", 
          },
          {
            header: "Credit Account",
            accessorKey: "CreditAccountName", 
          },
          {
            header: "Amount",
            accessorKey: "Amount", 
          },
            {
            header: "Remark",
            accessorKey: "Remark", 
          },
        
      ],
      []
    );
    const loadChartOfAccountsList =  () => {
      axios.get("/chartofaccounts/getByParent?Parent="+globalUser.Parent, {
       headers: {
         token: globalToken,
       },
     })
      .then(response=>{
           if (response.data.data){
             setChartofAccounts(response.data.data);
           }else{
             alert('log out and log in again')
           }
         })
         
       };
    const loadGeneralLedgerEntriesList =  () => {
     axios.get("/generalLedgerEntries/getByParent?Parent="+globalUser.Parent, {
      headers: {
        token: globalToken,
      },
    })
     .then(response=>{
          if (response.data.data){
            setGeneralLedgerEntriesList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };
    /*   const loadSupplierGroupsList =  () => {
        axios.get("/settings/getByParentAndType?Parent="+globalUser.Parent +"&Type=" +"Business Group", {
         headers: {
           token: globalToken,
         },
       })
        .then(response=>{
             if (response.data.data){
               setSupplierGroups(response.data.data);
             }else{
               alert('log out and log in again')
             }
           })
           
         };
      const loadChartOfAccountsList =  () => {
        axios.get("/chartofaccounts/getByParent?Parent="+globalUser.Parent, {
         headers: {
           token: globalToken,
         },
       })
        .then(response=>{
             if (response.data.data){
               setChartofAccounts(response.data.data);
             }else{
               alert('log out and log in again')
             }
           })
           
         }; */
      const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/items/Excel", fdata, {
        headers: {
          token: globalToken,
        },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(generalLedgerEntriesList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "stores");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "storesdata.xlsx");
    }
      
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      setPageHeader("Ledg. Entries" );
      
      loadGeneralLedgerEntriesList();
      loadChartOfAccountsList();
     /*loadSupplierGroupsList(); */
    }, [update]);
    return (   
        
      <Grid container>
 {chartofAccountLists && chartofAccountLists.length>0 && <>
      <Grid item className="hideInMobile" m={1}>
    
            <MaterialReactTable
              columns={columns}
              data={generalLedgerEntriesList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,                
                }
                
              }}
              enableGlobalFilter
              enableColumnOrdering
              enableColumnResizing
              enableColumnFilters
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <GeneralJournalModel
                      icon={
                        <div>Add</div>
                      }
                      Journals={[{Id:0,PostingDate:format(new Date(),"yyyy-MM-dd"),Type:'GJ'}]}
                      ChartOfAccounts={chartofAccountLists}
                      ParentUpdate={update}
                      setParentUpdate={setUpdate}
                     Type="GJ"
                    />
                  </Button>|
             
  
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button      size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                    </Tooltip>
                    
                </Box>
              )}
             
              enableRowActions
              renderRowActionMenuItems={(table) => [             
              table.row.original.Type==='GJ' &&               
                   <Tooltip title={t("Edit")}> 
                  <MenuItem > 
                  
                   <GeneralJournalModel
                  icon={<div><EditSharp style={{ width: "20px", height: "20px" }} />Edit</div>}
                  Journals={[table.row.original]}
                  ChartOfAccounts={chartofAccountLists}
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                  Type="GJ"
                 
                />   
                  </MenuItem>
                 </Tooltip>,
              
                  <Tooltip title={t("Show")}> 
                  <MenuItem > 
                  
                   <GeneralLedgerEntriesModel
                  icon={<div><HistorySharp style={{ width: "20px", height: "20px" }} />Show</div>}
                  data={table.row.original}
                 
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                  Type="GJ"
                 
                />   
                  </MenuItem>
                 </Tooltip>,
                 <Tooltip title={t("Preview")}> 
                  <MenuItem>
          
                   <InvoicePreview 
                    icon={<div><Preview />Preview</div>}
                        data={table.row.original}
                        Type="Posting"
                        DocumentType={ "General Ledger Entry"}
                        />
                    </MenuItem>
                    </Tooltip>,
                
              ]
              }
            ></MaterialReactTable> 
             </Grid>
             <Grid item xs={12} mt={2} className="hideInFull">
             <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    {/* <GeneralLedgerEntriesModel
                      icon={
                        <div>Add</div>
                      }
                    
                    /> */}
                  </Button>|
               {/*    <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                      Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>| */}
  
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
             </Grid>
        {!(generalLedgerEntriesList === undefined) &&
          generalLedgerEntriesList.length > 0 &&
          generalLedgerEntriesList.map((item) => {
            return (
              <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                  <CardHeader
                  
                    className="CardHeader"
                    title={'Doc. No: '+item.DocumentNo} 
                    subheader={"Date: "+item.PostingDate}
                  />

                  <CardContent>
                    <Typography variant="body2" align="left">
                      Type: {item.Type}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Debot Account: {item.DebitAccountName}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Creadt Account: {item.CreditAccountName}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Amount: {item.Amount}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Remark: {item.Remark}
                    </Typography>
                 </CardContent>
                  <CardActions
                  sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }} >
                    <Box>
                    <Tooltip title={t("Edit Store")}> 
                  <IconButton > 
                  
                   <GeneralLedgerEntriesModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={item}
                
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                  Type="GJ"
                 
                 
                />   
                  </IconButton>
                 </Tooltip>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </>
  }
      </Grid>
        
         );
  }
  
  export default GeneralLedgerEntriesComp;