import React,{useState,useMemo,useEffect,useContext} from 'react'
import { useTheme } from "@mui/material/styles";
import MaterialReactTable from "material-react-table";
import { format } from "date-fns";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import axios from "../../axiosInstances";
import { Grid,TextField ,IconButton,Box,Autocomplete,Button, } from '@mui/material';
import {ApprovalRounded, AttachFile, EditSharp,MedicationLiquid, SaveSharp, TenMp} from "@mui/icons-material"
import NewVisit from './NewVisit';
function ImagingComp() {
  const theme = useTheme();
  
  const [update, setUpdate] = useState(false);
  const { globalUser,globalToken, globalRoleSecurity,setPageHeader } =  useContext(ShoppingCartContext);
  const [patientsList, setPatientsList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(0);
  const [reportTemplate, setReportTemplate] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({"Index":0,"Name":''});
  const mainColumn = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      {
        header: "Name",
        accessorKey: "Name",
      },
      {
        header: "Mobile",
        accessorKey: "Mobile",
        size:120
      },
    ],
    []
  );
  const [visitDate, setVisitDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [rowSelection, setRowSelection] = useState({});
  const handleSubmitResult=(status)=>{
    alert(status)
  }
  const getSelectedPatient = () => {
    Object.getOwnPropertyNames(rowSelection).map((item) => {
      if (item === "length") {
        setSelectedPatient(null);
      } else {
        setSelectedPatient(
          patientsList.filter(
            (ap) => parseInt(ap.Id) === parseInt(item)
          )[0]
        );
      }
    });
  };
  const loadReportTemplate = () => {
    axios
     .get(
       "/hostemplates/getByType?Type="+"Imaging",
       {
         headers: {
           "Content-Type": "application/json",
           token: globalToken,
         },
       }
     )
     .then((response) => {
       
       if (response.data.data) {
         setReportTemplate(response.data.data);        
       } else {
         alert("log out and log in again");
       }
     });
 };
  const loadActiveOrdersByType = () => {
     axios
      .get(
        "/hosorders/getActiveOrdersByType?Parent=" +
          globalUser.Parent +
          "&OrderDate=" +
          visitDate+
          "&OrderType="+"Investigation",
        {
          headers: {
            "Content-Type": "application/json",
            token: globalToken,
          },
        }
      )
      .then((response) => {
        
        if (response.data.data) {
          setPatientsList(response.data.data.Patients);
          setOrdersList(response.data.data.Orders.map((ord,index)=>{
            return {...ord,Index:index,Template:''}
          }));
          setSelectedPatient(response.data.data.Patients.length>0 ?response.data.data.Patients[0]:null)
        } else {
          alert("log out and log in again");
        }
      });
  };
   useEffect(() => {
    if (Object.getOwnPropertyNames(rowSelection).length > 0) {
      getSelectedPatient();
    }
  }, [rowSelection]); 
  useEffect(()=>{
loadActiveOrdersByType();
loadReportTemplate();
setPageHeader("Investigation")
  },[update,visitDate])
  return (
    <Grid container spacing={2} mt={1}>
       <Grid item xs={12} md={5}>
        <MaterialReactTable
          columns={mainColumn}
          data={patientsList}
          initialState={{
            density: "compact",
            columnVisibility: { Id: false },
            pagination: {
              pageIndex: 0,
              pageSize: 5,
            },
          }}
          enableGlobalFilter
          enableColumnResizing
          enableRowNumbers
          enableRowSelection
          enableMultiRowSelection={false}
          getRowId={(originalRow) => originalRow.Id}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
          renderTopToolbarCustomActions={() => (
             <Box style={{ display: "flex", flexDirection: "row" }}>
             
              <TextField
                size="small"
                label="Visit Date"
                type="date"
                value={visitDate}
                onChange={(e) => {
                  setVisitDate(e.target.value);
                }}
                sx={{
                  width: "15",
                  height: "5",
                  marginBottom: 1,
                  marginLeft: 1,
                  color: theme.palette.primary.contrastText,
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.palette.primary.contrastText,
                     },
                  shrink: true,
                }}
              />
            </Box> 
          )}
         
         /*  enableRowActions
          renderRowActions={({ row }) => (
             <Box>
               <Tooltip title="Edit">
              <IconButton>
                <NewVisit
                  icon={<EditSharp style={{ width: "15px", height: "15px" }} />}
                  data={row.original}
                  SubSpecialities={[]}
                  Type="Customer"
                  Physicians={[]}
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />
              </IconButton>
              </Tooltip>
              
            
            </Box> 
          )} */
        ></MaterialReactTable>
      </Grid>
   <Grid item  xs={12} md={7} p={1}> 
   {selectedPatient &&
   <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
           <Button
                  variant="standard"
                  onClick={() =>
                    handleSubmitResult('Draft')
                  }
                  endIcon={<SaveSharp />}
                >
                  Save
                </Button> |
                <Button
                  variant="standard"
                  onClick={() =>
                    handleSubmitResult('Approved')
                  }
                  endIcon={<ApprovalRounded />}
                >
                  Approved
                </Button> |
   {selectedPatient && ("   Patient Name: " +  selectedPatient.Name)}
   </Box>
}
   {selectedPatient &&
        <Grid container spacing={2} pt={2} pl={2}>
    
                  <Grid container>
<Grid item xs={12}>
                  <Box>  
          {selectedPatient && ordersList && 
            ordersList.filter(so=>parseInt(so.Patient)===parseInt(selectedPatient.Id)).map((test,index) => {
              return(<>
                 <Button
            variant="standard"
            onClick={() => {
              setSelectedOrderIndex(index)
            }}
          >
            {test.Name}
          </Button>|
          </>)
            })
          }
            </Box>
            </Grid>
            <Grid item xs={12}>
            <Autocomplete
                  disablePortal
                  selectOnFocus
                  id='template'
                  autoHighlight
                 value={
                  ordersList && 
                  ordersList.filter(ord=>(parseInt(ord.Index)===parseInt(selectedOrderIndex)))[0].Template
                  } 
                  onSelect={(e) => {
                    ordersList.filter(ord=>(parseInt(ord.Index)===parseInt(selectedOrderIndex)))[0].Result= (reportTemplate && reportTemplate.filter(temp=>temp.Name===e.target.value).length>0 &&
                    reportTemplate.filter(temp=>temp.Name===e.target.value)[0].Report);
                    ordersList.filter(ord=>(parseInt(ord.Index)===parseInt(selectedOrderIndex)))[0].Template=e.target.value;
                  
                    setSelectedOrderIndex(selectedOrderIndex)
                  }
                }
                  sx={{
                    width: "95%",
                    borderRadius: "25%",
                  }}
                  options={reportTemplate && reportTemplate.map(temp=>
                    { return temp.Name})
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Template"                      
                  placeholder='Select template here'
                      variant="standard"
                      //error={!fromAirport ? true : false}
                    />
                  )}
                />
            <TextField
                size="small"
                multiline
                minRows={10}
                 label={ordersList.filter(ord=>(parseInt(ord.Index)===parseInt(selectedOrderIndex)))[0].Name}
                value={selectedOrderIndex!==null && ordersList.filter(ord=>(parseInt(ord.Index)===parseInt(selectedOrderIndex)))[0].Result} 
                placeholder="Insert Result here"
                onChange={(e) => {
                  ordersList.filter(ord=>(parseInt(ord.Index)===parseInt(selectedOrderIndex)))[0].Result=e.target.value
                  setOrdersList((ord)=>ord)
                  /* setOrdersList(ordersList && ordersList.map(ord=>{return ord}))  */
                }}
                sx={{
                  width:"95%",
                  margin:2,
                }}
                InputLabelProps={{
                                   shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
            {ordersList.filter(ord=>(parseInt(ord.Index)===parseInt(selectedOrderIndex)))[0].Description}
            </Grid>
            <Grid item xs={12}>

            <IconButton><AttachFile /></IconButton>
            </Grid>
            </Grid>
            
        </Grid>
}

   </Grid>
  
  </Grid>
  )
}

export default ImagingComp