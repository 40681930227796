import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Card,

  CardMedia,
  CardContent,
  Avatar,
  IconButton,

  Typography,
  Stack,
  Tooltip,
} from '@mui/material'
import { EditSharp, DeleteSharp } from '@mui/icons-material'
import axios from '../../axiosInstances'
import { Link,useNavigate } from "react-router-dom";
import { ShoppingCartContext } from '../Context/ShoppingCartContext'

function NewsSingleComp() {
  const navigation=useNavigate()
  const {  globalRoleSecurity } = useContext(ShoppingCartContext)
  const location = useLocation()
  const [story, setStory] = useState(location.state.Story)
  const [categories, setCategories] = useState(location.state.Categories)

const updateView=()=>{
  if (
    window.confirm(
      "Are you sure you want to update?"
    )
  ) {
  axios.get('/news/UpdateViewCount?id=' + story.Id)
  }
}
const deleteNew=()=>{
  if (
    window.confirm(
      "Are you sure you want to delete?"
    )
  ) {
  axios.delete('/news?Id=' + story.Id)
  navigation("/News");
  }
}
  useEffect(() => {
    updateView();
  }, [story.Id])
  function LoadHtml() {
    return (
      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
       
        {story.Image != null && story.Image.length > 0 && (
          <CardMedia
            component="img"
            height="150px"
            sx={{
              padding: '20px',
              objectFit: 'fill',
            }}
            image={story.Image}
            alt={<div dangerouslySetInnerHTML={{__html:story.Title}}></div>}
          />
        )}
       <Typography variant="body1" ml={4} style={{fontWeight:'bold'}}>  {<div dangerouslySetInnerHTML={{__html:story.Title}}></div>}</Typography>
        <Stack direction="row" p={4}>
          <div className="UserInfo">
            <Stack direction="row">
              <Avatar
                height="10px"
                src={
                  story.Logo != null && story.Logo.length > 0
                    ? story.Logo
                    : '/Images/EmployeesPics/default.png'
                }
              />
              <span>{'By: ' + story.PublishedByName}</span>
            </Stack>
            <br></br>
            <span>published {story.PublishedDays} days ago</span>
            <br></br>
            <span>{story.ViewCount} views</span>
            <br></br>
            {globalRoleSecurity &&
              globalRoleSecurity.filter(
                (sec) => sec.Functionality === 'News' && sec.Security > 1,
              ).length > 0 && (
                <>
                  <Tooltip title="Edit News">
                    <IconButton>
                  <Link to="/EditStory" state={{Story: story,Categories:categories}}>
                        <EditSharp />
                      </Link>
                    </IconButton>
                  </Tooltip>
                  |
                </>
              )}
            {globalRoleSecurity &&
              globalRoleSecurity.filter(
                (sec) => sec.Functionality === 'News' && sec.Security > 2,
              ).length > 0 && (
                <Tooltip title="Delete News">
                  <IconButton
                    onClick={() => {
                      deleteNew();
                    }}
                  >
                    <DeleteSharp />
                  </IconButton>
                </Tooltip>
              )}
          </div>
        </Stack>
        <CardContent>
          <Typography
            variant="body2"
            align="left"
          
            style={{ textAlign: 'justify', columnCount:{xs:1,md:2} }}
          >
            {<div dangerouslySetInnerHTML={{__html:story.Description}}></div>}
          </Typography>
        </CardContent>
      </Card>
      /*   <div className="Single" key={story.Id}>
             { openModel && <NewsSingleComp closeModel={setOpenModel} state={story}/>}
              <div className="Content">
               
                <div className="User">
                <img 
                src={storyImage}
                />
                  <div className="UserInfo">
                    <span>Andul</span><br></br>
                    <span>storyed 2 days ago</span><br></br>
                    <span>{story.ViewCount  }  views</span><br></br>
                  </div>
                  <div className="Edit">
                    
                    <a onClick={()=>{ setStory(story); setOpenModel(true) ; }}  >
                      <img src="./Images/SystemPics/delete.png" alt="Delete" />
                    </a>
                  </div>
                
                </div>
                <div className="Body">
                    <p dangerouslySetInnerHTML={{__html: story.Description}} /> 
                </div>
              </div>
              <div className="Menu">m</div>
            </div> */
    )
  }

  return (
    <div>
      <br />

      {LoadHtml()}
    </div>
  )
}

export default NewsSingleComp
