import { useContext, useState } from "react";
import {
  Text,
  Page,
  Document,
  View,
  PDFViewer,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button,Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles";

const numberToText = require('number-to-text')
require('number-to-text/converters/en-us'); // load converter
const borderColor = "#90e5fc";
const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        objectFit:'cover'
       
    }, 
    logo: {
        width: 500,
        height: 45,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    SignaturePic: {
      width: 80,
      height: 45,
  },
  remark: {
    left: 50,
},
pageNumber: {
  position: 'absolute',
  //fontSize: 11,
  bottom: 0,
  marginTop:10,
  paddingLeft:10,
  //padding:0,
  width:'800',
  //textAlign: 'center',
  color: 'gray',
  borderTop: 1,
},
  titleContainer: {
    flexDirection: "row",
    width:'100%',
    marginTop: 24,
  },
  reportTitle: {
    color: "#61dafb",
    width:'100%',
    letterSpacing: 4,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
  },
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    marginRight:4,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    width: "100%",
  },
  description: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  signature: {
    textAlign: "left",
    left:40
  },

  qty: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
  total: {
    width: '50%',
    textAlign: 'right',
    paddingRight: 2,
    alignContent: 'right',
    alignItems:'right',
    alignSelf:'right'
},
totalAmount: {
  width: "50%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
jobdescription: {
  width: "50%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
jobRemark: {
  width: "30%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
reportFooter: {
    color: "#61dafb",
    width:'100%',
    letterSpacing: 4,
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
  Payment:{    
    letterSpacing: 4,
    fontSize: 12,
    textAlign: "left",
    textTransform: "uppercase",
    textDecoration:'underline',
    textAlign:"center"
  },
 
});

export default function HosPreview(props) {
  const theme=useTheme();
  const [open, setOpen] = useState(false);
  const { globalUser,setPageHeader }=useContext(ShoppingCartContext)
  const MyCardDocument = (props) => (    
    <Document>
      
      {props.Suppliers && (props.Suppliers!==null) && props.Suppliers.map(sup=>{
      return(
      <Page size="A4" style={styles.page} key={sup.Id}>
        {globalUser.CompanyHeader &&
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
        }
       <View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>

        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Invoice No:</Text>
          <Text style={styles.invoiceDate}>{sup.OrderId}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.invoiceDate}>{sup.VoucherDate?sup.VoucherDate.toLocaleString().substring(0, 10):format(new Date(),"yyyy-MM-dd")}</Text>
        </View>
        {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved'))) &&
        <View style={styles.headerContainer}>
          <Text style={styles.billTo}>Bill To:</Text>
          <Text>{sup.Name==='undefined'?'':sup.Name}</Text>
          <Text>{sup.Region==='undefined'?'':sup.Region}</Text>
      <Text>{sup.Mobile==='undefined'?'':sup.Mobile}</Text>
      <Text>{sup.Email==='undefined'?'':sup.Email}</Text>  
        </View>
        }
        <View style={styles.tableContainer}>
          <View style={[styles.row,{backgroundColor:'lightgrey'}]}>
            <Text style={styles.description}>Item Description</Text>
            <Text style={styles.qty}>Qty</Text>
            {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved'))) &&
            <>
            <Text style={styles.rate}>Unit Price</Text>
            <Text style={styles.amount}>Amount</Text>
            </>
      }
          </View>

          {/* <InvoiceTableRow items={invoice.items} />  */}

          {props.LineItems &&
            props.LineItems.map((item) => {
              if(parseInt(item.Supplier)===parseInt(sup.Id)){
              return (
                  <View style={styles.row} key={item.Item}>
                    <Text style={styles.description}>{item.Name}</Text>
                    <Text style={styles.qty}>
                    {props.Type==="InvoiceReceving"?
                    (item.ReceivingQuantity?item.ReceivingQuantity:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2}):
                    (item.Quantity?item.Quantity:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})
                    }
                    </Text>

                    {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved')))  &&
            <>
                    <Text style={styles.rate}>{(item.UnitPrice?item.UnitPrice:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
                    <Text style={styles.amount}>{(item.TotalPrice?item.TotalPrice:0)?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</Text>
                    </>
              }
                  </View>
                );
              }
              }
                    
            )}

         
        <View style={styles.row}>
            <Text style={styles.description}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.rate}>-</Text>
            <Text style={styles.amount}>-</Text>
        </View>
          
          {((sup.Status!==undefined) && (props.DocumentType.includes('Sales') || (sup.Status!=='Draft' && sup.Status!=='Request' && sup.Status!=='Approved')))  &&
            <>
          <View style={styles.row}>
            <Text style={styles.totalAmount}>SUB TOTAL</Text>
            <Text style={styles.total}>{((sup.Amount || sup.Amount==null)?sup.Amount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.totalAmount}>VAT</Text>
            <Text style={styles.total}>{ ((sup.VATAmount || sup.VATAmount===null)?sup.VATAmount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
        </View>
        <View style={styles.row}>     
            <Text style={styles.totalAmount}>TOTAL AMOUNT</Text>
            <Text style={styles.total}>{ (sup.TotalAmount || (sup.TotalAmount===null)?sup.TotalAmount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text>
        </View>
        </>
      }
        </View>
        {sup.Status==='Return' &&
         <View style={styles.headerContainer}>     
         <Text style={styles.billTo}>{ ("Returned By " + sup.ReturnedByName) + "   Returned Date: " + sup.ReturnedDate}</Text>
     </View>
        }
  {((sup.Status===undefined) || sup.Status==='Draft' || sup.Status==='Request' || sup.Status==='Approved' || sup.Status==='Received')   &&
  <View style={styles.headerContainer}>     
  <Text style={styles.billTo}>{ "Requested By " + sup.RequestedByName + "              Requested Date: " + sup.RequestedDate}</Text>
  {(sup.Status==='Approved')  &&
   <Text style={styles.billTo}>{ "Approved By " + sup.ApprovedByName +"                   Approved Date: " + sup.ApprovedDate}</Text>
  }
    {(sup.Status==='Received')  &&
   <Text style={styles.billTo}>{ "Received By " + sup.UserName +"                   Received Date: " + sup.VoucherDate}</Text>
  }
</View>
      }

        
        <View style={styles.titleContainer}>
        <Text style={styles.reportFooter}>Thank you for your business</Text>
    </View>
    <Text   style={styles.pageNumber} render={({ pageNumber, totalPages }) => ('Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: ' +
                        `${pageNumber} / ${totalPages}`
                    )}  fixed /> 
      </Page>
              )
      })
    }
    </Document>
  );

  const MySupplierDocument = (props) => (    
    <Document  style={{ width: "600px", height: "800px" }}>
      
      {props.Suppliers && (props.Suppliers!==null) && props.Suppliers.map(sup=>{
      return(
      <Page size="A4" style={styles.page} scale={2} key={sup.Id}>
        {globalUser.CompanyHeader &&
       <Image style={styles.logo} src={globalUser.CompanyHeader} />  
        }
       <View style={styles.row}></View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{props.DocumentType}</Text>
        </View>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>ID No:</Text>
          <Text style={styles.invoiceDate}>{sup.Id}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Print Date:</Text>
          <Text style={styles.invoiceDate}>{format(new Date(),"yyyy-MM-dd")}</Text>
        </View>
<View style={{flexDirection:'row'}}>
  <View>
      
     
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Name:</Text>
          <Text style={styles.label}>{sup.Name==='undefined'?'':sup.Name}</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Region:</Text>
          <Text style={styles.label}>{sup.Region==='undefined'?'':sup.Region}</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Mobile:</Text>
          <Text style={styles.label}>{sup.Mobile==='undefined'?'':sup.Mobile}</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.label}>Email:</Text>
          <Text style={styles.label}>{sup.Email==='undefined'?'':sup.Email}</Text>
        </View>
        </View>
        {sup.Logo && sup.logo!==null  && <View><Image style={{width:90,height:110,left:150,paddingTop:20}} src={sup.Logo} /></View>}
  </View>

        {/*   <InvoiceThankYouMsg /> */}
        <View style={styles.titleContainer}>
        <Text style={styles.reportFooter}>Thank you for your business</Text>
    </View>
      </Page>
              )
      })
    }
    </Document>
  );
  const Apps = (props) => (
   
    <PDFViewer style={{ width: "98%", height: "95%",flex:1}}>
      {(props.Type==='Card')?
      <MyCardDocument Visits={props.Suppliers} />
      :
      <MySupplierDocument Visits={props.data} />
    }
    </PDFViewer>
  );

  return (
    <div>
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
        
        PaperProps={{
          sx: {
            width: "700px",
            height: "800px"
          }
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
      <Apps Type={props.Type} Visits={props.Visits} />:

         </DialogContent>
    <DialogActions style={{
          backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
color:theme.palette.primary.contrastText,  fontSize: "8px",
          }}>
          <Box>
            <Button variant="standard" endIcon={<Close />} onClick={()=>setOpen(false)}>
              Close              
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
