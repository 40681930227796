import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
    IconButton,
    Button,
    Box,
  Tooltip
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo,useContext } from "react";
  import ItemCategoriesModel from "./ItemCategoriesModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

  function ItemCategoriesComp(props) {
    const navigate = useNavigate();
    const theme=useTheme();
    const [update, setUpdate] = useState(false);
    const [itemsCategoriesList, setSelectedItemsCategoriesList] = useState([]);
    const { setPageHeader,globalToken,globalUser }=useContext(ShoppingCartContext)
   
    const { t, i18n } = useTranslation();
 
    const columns = useMemo(        
      () => [
        {
          header: "Id",
          accessorKey: "Id", 
        },
        {
          header: "Name",
          accessorKey: "Name", 
        },
        {
          header: "Parent Category",
          accessorKey: "ParentCategoryName", 
        },
        {
          header: "Remark",
          accessorKey: "Remark", 
        },
        
      ],
      []
    );
  

  
    const loadItemCategoriesList =  () => {
     axios.get("/itemCategories", {
          headers: {
            token: globalToken,
          },
        }).then(response=>{
          if (response.data.data){
            setSelectedItemsCategoriesList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };
     
  
    const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/items/Excel", fdata, {
        headers: { "Content-Type": "application/json", 
            token: globalToken,
        } },
      );
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(itemsCategoriesList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "itemCategories");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "itemCategoriesdata.xlsx");
    }
    
  
  
  
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      setPageHeader('Item Categories')
        loadItemCategoriesList();
    }, [update]);
    return (
     
      <Grid container>
    
      {itemsCategoriesList && itemsCategoriesList.length>0 &&
      <Grid item className="hideInMobile" m={1}>
    
            <MaterialReactTable
              columns={columns}
              data={itemsCategoriesList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
             
              //enableRowSelection //enable some features
              enableColumnOrdering
              enableColumnResizing
              enableColumnFilters
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                     
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <ItemCategoriesModel
                      icon={
                        <div>Add</div>
                      }
                      data=""
                      Title="ADD NEW ITEM CATEGORY"
                      CategoriesList={itemsCategoriesList}
                      setParentUpdate={setUpdate}
                      ParentUpdate={update}
                    />
                  </Button>|
                
                {/*   <IconButton>
                    <label htmlFor="file">
                      {<FileUpload style={{ width: "20px", height: "20px" }} />}
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                   
                  </IconButton> */}
             
                  <Button size="small"
                    variant="standard" type="file" onClick={handleDownloadFile}  sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                    Download
                  </Button>|
                 
                    <Button onClick={() => setUpdate(!update)}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                      Refresh
                    </Button>
                
                </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("modify")}> 
                  <IconButton >                   
                   <ItemCategoriesModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  Title="EDIT ITEM CATEGORY"
                  CategoriesList={itemsCategoriesList}
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />   
                  </IconButton>
                 </Tooltip>
                </Box>
              )}
            ></MaterialReactTable> 
         
            </Grid>
  }
  <Grid item xs={12} className="hideInFull" mt={1}>
  <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
                   <Button size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                       <ItemCategoriesModel
                         icon={
                           <div>Add</div>
                         }
                         data=""
                         CategoriesList={itemsCategoriesList}
                         setParentUpdate={setUpdate}
                         ParentUpdate={update}
                       />
                     </Button>|
                   
                   {/*   <IconButton>
                       <label htmlFor="file">
                         {<FileUpload style={{ width: "20px", height: "20px" }} />}
                       </label>
                       <input
                         id="file"
                         name="file"
                         hidden
                         type="file"
                         onChange={handleUploadFile}
                       />
                      
                     </IconButton> */}
                
                     <Button size="small"
                    variant="standard" type="file" onClick={handleDownloadFile} sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                       Download
                     </Button>|
                    
                       <Button onClick={() => setUpdate(!update)} size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                         Refresh
                       </Button>
                   
                   </Box>
  </Grid>
          {!(itemsCategoriesList === undefined) &&
            itemsCategoriesList.length > 0 &&
            itemsCategoriesList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                  <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={'Name: '+item.Name} //.substring(0, 20)}
                    />

                    <CardContent>
                      <Typography variant="body2" align="left">
                        Parent Category: {item.ParentCategoryName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Remark: {item.Remark}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                            <ItemCategoriesModel
                              icon={
                                <EditSharp
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                              data={item}
                              Title="EDIT STOCK"
                              CategoriesList={itemsCategoriesList}
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}            
        </Grid>
         
     
    );
  }
  
  export default ItemCategoriesComp;
  