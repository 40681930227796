import { useState, useContext,useRef } from "react";
import { Button, Autocomplete, TextField, Grid, IconButton, GlobalStyles } from "@mui/material";
import {
  Clear, Close, Delete, Festival, Save
  } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "./Context/ShoppingCartContext";
import axios from "../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function SettingModel(props) {
  const theme=useTheme();
  const LogoInputRef = useRef(null);
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const {globalUser,globalToken} =    useContext(ShoppingCartContext);
   
  const [id, setId] = useState(props.data.Id);
  const [type, setType] = useState(props.data.Type);
  const [description, setDescription] = useState(props.data.Description);
  const [value, setValue] = useState(props.data.Value);
  const [remark, setRemark] = useState(props.data.Remark);


  const handleSubmit = () => {
    if(type===undefined || type===null || type.length<=0){
      alert('select type first')
      return;
    }
    if(description===undefined || description===null || description.length<=0){
      alert('Write description first')
      return;
    }
    if(value===undefined || value===null || value.length<=0){
      alert('Write value first')
      return;
    }
    window.confirm(
      "Are you sure you want to save?"
    )
{
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Type", type);
      fd.append("Description", description);
      fd.append("Value", value);
      fd.append("Parent", globalUser.Parent);
      fd.append("Remark", remark);

      axios.post("/settings", fd, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
         },
      });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    try {
      axios.delete("/settings?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };



  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
      
        <DialogTitle  style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText, height:'20px'}}>{props.Title} </DialogTitle> 
        <DialogContent>
          <DialogContentText>
              <hr></hr>

              <Grid container>
              <Grid item xs={12} md={6}>
                  <Autocomplete
                     disabled={type==='System'?true:false}
                     disablePortal
                    selectOnFocus
                    autoHighlight
                    contentEditable={false}
                    value={type}
                    id="type"
                    onSelect={(e) => {
                      setType(e.target.value)}}
                    options={["Role",'Bank','Business Group','Chart of Account Category','Fixed Asset Group','News Category','Job Title','Sub Speciality']
                    }
                    sx={{
                      width: "95%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                   </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  disabled={type==='System'?true:false}
                    size="small"
                    variant="standard"
                    label={t("Description")}
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                     </Grid>
              
                <Grid item xs={12} md={6}>
 {type==='Chart of Account Category'?
    <Autocomplete
   
    disablePortal
   selectOnFocus
   autoHighlight
   value={value}
   id="type"
   onSelect={(e) => {
     setValue(e.target.value)}}
   options={props.ChartofAccountParent && props.ChartofAccountParent.map(st=>st.Description)
   }
   sx={{
     width: "95%",
     marginBottom: 2,
     borderRadius: "25%",
   }}
   renderInput={(params) => (
     <TextField
       {...params}
       label="Parent Category"
       variant="standard"
       //error={!fromAirport ? true : false}
     />
   )}
 />:
                  <TextField
                    size="small"
                    variant="standard"
                    label="value"
                    defaultValue={value}
                    multiline
                    maxRows={4}
                    sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                }
                </Grid>
                <Grid item xs={12} md={6}>

<TextField
  size="small"
  variant="standard"
  label="Remark"
  defaultValue={remark}
  multiline
  maxRows={4}
  sx={{ width: "95%", marginBottom: 2 }}
  onChange={(e) => {
    setRemark(e.target.value);
  }}
  InputLabelProps={{
    shrink: true,
  }}
/>
</Grid>
               
              </Grid>
          
            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions  style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}}>
          <Button variant="standard" endIcon={<Save />} autoFocus onClick={()=>handleSubmit()}>
            Save
          </Button>|
          <Button variant="standard" endIcon={<Delete />}  autoFocus onClick={()=>handleDelete()}>
            Delete
          </Button>|
          <Button variant="standard" endIcon={<Close />}  onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
