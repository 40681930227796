import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  IconButton,
  Button,
  Box,
  Tooltip,
  MenuItem,
  Stack,
  TextField,
  Autocomplete,
  RadioGroup,FormControlLabel,Radio,
} from "@mui/material";
import {
  EditSharp,
  AddSharp,
  FileDownload,
  RefreshSharp,
  ShoppingBag,
  PreviewSharp,
  PaymentSharp,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { useState, useEffect, useMemo, useContext } from "react";
import PurchaseModelHistory from "./PurchaseModelHistory";
import NewOrder from "../Products/NewOrder";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "../../axiosInstances";
import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import PaymentsModel from "../PaymentsModel";
import { format } from "date-fns";
import PurchaseReceiptModel from "./PurchaseReceiptModel";
import PurchaseProformaModel from "./PurchaseProformaModel";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function PurchaseHistoryComp(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [update, setUpdate] = useState(false);
  const location = useLocation();
  //const [SupplierCategoriesList, setSupplierCategoriesList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [lineItemsList, setLineItemsList] = useState([]);

  const [startDate, setStartDate] = useState(format((new Date()).setDate((new Date()).getDate()-30), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format((new Date()), "yyyy-MM-dd"));
  const [supplier, setSupplier] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [ledgerType, setLedgerType] = useState("Vendor Ledger");
  const [loadTable, setLoadTable] = useState(false);
  const {
    globalUser,
    globalProviders,
    globalCustomers,
    globalRoleSecurity,
    setPageHeader,
    globalToken,
    allItems,
  } = useContext(ShoppingCartContext);



  const orderColumns = useMemo(
    () => [
      {
        header: "Order No",
        accessorKey: "OrderId",
        size: "100",
      },
      {
        header: "Supplier",
        accessorKey: "Supplier",
        size: "60",
      },
    
      {
        header: "Supplier Name",
        accessorKey: "SupplierName",
      },
         {
        header: "Date",
        accessorKey: "VoucherDate",
        size: "80",
      },
 {
        header: "Sub Total",
        accessorKey: "Amount",
        size: "150",
      },
      {
        header: "VAT Amount",
        accessorKey: "VATAmount",
        size: "150",
      },
     
      {
        header: "Total Amount",
        accessorKey: "TotalAmount",
        size: "150",
      },
      {
        header: "Paid Amount",
        accessorKey: "PaidAmount",
        size: "150",
      },

      /* 
      {
        header: "Status",
        accessorKey: "Status",
        size: "80",
      }, */
    ],
    []
  );
  const lineItemColumns = useMemo(
    () => [
      {
        header: "Order Id",
        accessorKey: "Reference",
        size: "80",
      },
      
      {
        header: "Order Date",
        accessorKey: "VoucherDate",
        size: "100",
      },
      {
        header: "Item",
        accessorKey: "Item",
        size: "100",
      },
      {
        header: "Item Name",
        accessorKey: "Name",
      },

      {
        header: "Quantity",
        accessorKey: "Quantity",
        size: "90",
      },
      {
        header: "UOM",
        accessorKey: "UOM",
        size: "70",
      },
      {
        header: "Unit Price",
        accessorKey: "UnitPrice",
        size: "90",
      },
      {
        header: "VAT",
        accessorKey: "TaxAmuont",
        size: "90",
      },
      {
        header: "Total Price",
        accessorKey: "TotalPrice",
        size: "100",
      },
      
    ],
    []
  );

 
  const loadVouchersList = () => {

  setLoadTable(false);
    axios
      .get(
        "/Vouchers/getReportBySupplierTypeAndDate?Supplier=" +
        (location.state.Type==="Purchase"?
        (globalProviders.filter(gp=>gp.Name===supplier).length>0 ? globalProviders.filter(gp=>gp.Name===supplier)[0].Id:''):
        (globalCustomers.filter(gp=>gp.Name===supplier).length>0 ? globalCustomers.filter(gp=>gp.Name===supplier)[0].Id:''))
           +
          "&Type="+ location.state.Type + "&StartDate=" + startDate +
          "&EndDate=" + endDate +
          "&Item=" + 
          (allItems.filter(it=>it.Name===selectedItem).length>0 ? allItems.filter(it=>it.Name===selectedItem)[0].Item:''),
          {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setOrdersList(response.data.data.Orders);
          setLineItemsList(response.data.data.LineItems);
       
         
        } else {
          alert("log out and log in again");
        }
      })
      .then(() => {
        console.log("after loading");
        setLoadTable(true);
      });
  };


  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    var jsonData = XLSX.utils.sheet_to_json(worksheet);

    var fdata = new FormData();
    fdata.append("JsonData", JSON.stringify(jsonData));
    await axios.post("/Vouchers/Excel", fdata, {
      headers: { "Content-Type": "application/json", token: globalToken },
    });
  };
  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(ordersList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "vouchers");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "vouchersdata.xlsx");
  }

  useEffect(() => {
  /*   if (!globalUser) {
      navigate("/");
      return;
    }
    setSelectedPurchaseType(location.state.Status);
    setPageHeader("Pur. " + location.state.Status);
    loadEmployeeList();
    loadBankList();*/
    loadVouchersList(); 
  }, [update, supplier,selectedItem]);

  return (
    <div width="100%">
     
        <Grid container mt={1}>
          <Grid item xs={3} sx={{backgroundColor: theme.palette.primary.main}}>
          <Stack flexDirection="column" m={1} >
                   <TextField
                          size="small"
                          label="Start Date"
                          type="date"
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value)
                          }}
                          sx={{ width: "95%", marginBottom: 2,color:theme.palette.primary.contrastText}}
                          
                         InputLabelProps={{
                            shrink: true,
                            style:{color:theme.palette.primary.contrastText}
                          }}
                        />
                         <TextField
                          size="small"
                          label="End Date"
                          type="date"
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value)
                          }}
                          sx={{ width: "95%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                            style:{color:theme.palette.primary.contrastText}
                          }}
                        />
                         <Autocomplete
                            label="Supplier"
                                            id="Supplier"
                                            variant="standard"
                                            value={supplier}
                                            onChange={(e) => {
                                             setSupplier(e.target.textContent)
                                          
                                                }
                                              }
                                            options={
                                              location.state.Type==="Purchase"?
                                            (!(globalProviders === null) &&
                                              globalProviders.length > 0
                                                && globalProviders.map((pro) => {
                                                      return pro.Name;
                                                    })):
                                                    !(globalCustomers === null) &&
                                                    globalCustomers.length > 0
                                                && globalCustomers.map((pro) => {
                                                      return pro.Name;
                                                    })
                                            }
                                            sx={{ width: "95%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={t("Supplier")}
                                                InputLabelProps={{
                                                  style:{color:theme.palette.primary.contrastText}
                                                }}

                                                //error={!from ? true : false}
                                              />
                            )}
                           />         
           <Autocomplete
                            label="item"
                                            id="item"
                                            variant="standard"
                                            value={selectedItem}
                                            onChange={(e) => {
                                             setSelectedItem(e.target.textContent)
                                          
                                                }
                                              }
                                            options={
                                              !(allItems === undefined) &&
                                              !(allItems === null) &&
                                              allItems.length > 0
                                                && allItems.map((it) => {
                                                      return it.Name;
                                                    })
                                            }
                                          
                                            sx={{ width: "95%",mt:2}}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={t("Item")}
                                                InputLabelProps={{
                                                  style:{color:theme.palette.primary.contrastText}
                                                }}

                                                //error={!from ? true : false}
                                              />
                            )}
                           />  
                  <Tooltip title="refresh">
                    <Button
                      size="small"
                      variant="standard"
                      sx={{ width: "95%" ,mt:2,background:theme.palette.primary.light,color:theme.palette.primary.contrastText}}
                      endIcon={<RefreshSharp />}
                      onClick={() => setUpdate(!update)}
                      
                    >
                      Refresh
                    </Button>
                  </Tooltip>
          </Stack>
          </Grid>
          <Grid item xs={9} className="hideInMobile">
            <MaterialReactTable
              sx={{ backgroundColor: "#00f" }}
              columns={
             ledgerType==="Item Ledger"?lineItemColumns: orderColumns
              }
              data={ledgerType==="Item Ledger"? lineItemsList: ordersList}
              initialState={{
                density: "compact",
                pagination: {
                  pageIndex: 0,
                  pageSize: 20,
                },
              /*   grouping:ledgerType==="Item Ledger" ?  ['Item'] : [''] */
              }}
              enableGlobalFilter
              enableColumnResizing
              enableRowNumbers
             
              muiTopToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              renderTopToolbarCustomActions={() => (
                <Box flexDirection="column"  sx={{ width: "100%"}}>
                  
  <RadioGroup
    sx={{display: 'flex',flexDirection:'row'}}
    size="small"
    name="controlled-radio-buttons-group"
    value={ledgerType}
    onChange={(e)=>(setLedgerType(e.target.value))}
  >
    <FormControlLabel  value="Vendor Ledger" control={<Radio size="small" />} label="Vendor Ledger" />
    <FormControlLabel  value="Item Ledger" control={<Radio size="small" />} label="Item Ledger" />
  
  </RadioGroup>
                  <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <FileDownload style={{ width: "20px", height: "20px" }} />
                    }
                    type="file"
                    onClick={handleDownloadFile}
                  >
                    Download
                  </Button>
                  |
                  <Tooltip title="refresh">
                    <Button
                      size="small"
                      variant="standard"
                      sx={{ borderRadius: "30px" }}
                      endIcon={<RefreshSharp />}
                      onClick={() => setUpdate(!update)}
                    >
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
             
              )}
              enableRowActions
              renderRowActionMenuItems={({ row }) => [
            

                <Tooltip title="Preview">
                  <MenuItem>
                    <InvoicePreview
                      icon={
                        <div>
                          <PreviewSharp
                            style={{
                              width: "13px",
                              height: "13px",
                              margin: 1,
                              marginRight: 4,
                            }}
                          />
                          Preview
                        </div>
                      }
                      Suppliers={[
                        { ...row.original, Id: row.original.Supplier },
                      ]}
                      LineItems={lineItemsList.filter(
                        (li) => li.Reference === row.original.OrderId
                      )}
                      DocumentType={"Purchase " + row.original.Status}
                      Type="Invoice"
                    />
                  </MenuItem>
                </Tooltip>,
              ]}
            ></MaterialReactTable>
          </Grid>
          <Grid item xs={12} mt={1} className="hideInFull">
          <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>

              <Button
                size="small"
                variant="standard"
                endIcon={
                  <FileDownload style={{ width: "20px", height: "20px" }} />
                }
                type="file"
                onClick={handleDownloadFile}
              >
                Download
              </Button>
              |
              <Tooltip title="refresh">
                <Button
                  size="small"
                  variant="standard"
                  endIcon={<RefreshSharp />}
                  onClick={() => setUpdate(!update)}
                >
                  Refresh
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          {!(ordersList === undefined) &&
            ordersList.length > 0 &&
            ordersList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5.5} m={1} className="hideInFull">
                   <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={'Supplier: '+item.Name} //.substring(0, 20)}
                      subheader={'Total Amount: '+item.TotalAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                    />

                    <CardContent>
                    <Typography variant="body2" align="left">
                        Status: {item.Status}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Store: {item.StoreName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Date: {item.VoucherDate}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Amount: {item.Amount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                      </Typography>
                      <Typography variant="body2" align="left">
                        VAT Amount: {item.VATAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Total Amount: {item.TotalAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      <Stack direction="row">
                    
                        <Tooltip title="Preview">
                          <IconButton>
                            <InvoicePreview
                              icon={
                                <PreviewSharp
                                  sx={{
                                    color: theme.palette.primary.contrastText,
                                    width: "13px",
                                    height: "13px",
                                  }}
                                />
                              }
                              Suppliers={[{ ...item, Id: item.Supplier }]}
                              LineItems={lineItemsList.filter(
                                (li) => li.Reference === item.OrderId
                              )}
                              DocumentType={"Purchase " + item.Status}
                              Type="Invoice"
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
     
    </div>
  );
}

export default PurchaseHistoryComp;
