import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Badge,
  CardActions,
  IconButton,
  Avatar,
  Tooltip,
  Box,
  AppBar,
  Toolbar,
  Stack,
  Drawer,
  Button,
  Rating,
  List,
  ListItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ProductDetails from "../Products/ProductDetails";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { Close } from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";

function LandingPurchaseComp() {
  const theme=useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { globalUser,globalProviders, addtoCart, 
    removeFromCart,selectedItems, setSelectedItems,
     allItems,setPageHeader } =
    useContext(ShoppingCartContext);
  const [productsList, setProductsList] = useState(allItems);
  const [filteredProductsList, setFilterProductsList] = useState(allItems);
  const [providerOrProductCategoriesList, setProviderOrProductCategoriesList] =
    useState([]);

  const [selectedCategory, setSelectedCategory] = useState();
  const [supplier, setSupplier] = useState();

  const [searchText, setSearchText] = useState("");
  const [openItemCategoryDrawer, setOpenItemCategoryDrawer] = useState(false);
  const [openSupplierDrawer, setOpenSupplierDrawer] = useState(true);
  const [filteredSupplier, setFilteredSupplier] = useState(globalProviders);
  const [searchSupplier, setSearchSupplier] = useState("");
  let history = useNavigate();

  const toggleItemCateogyDrawer = () => {
    setOpenItemCategoryDrawer(!openItemCategoryDrawer);
  };
  const toggleSupplierDrawer = () => {
    setOpenSupplierDrawer(!openSupplierDrawer);
  };
  useEffect(() => {
    if(!globalUser){
      navigate('/');
      return;
    }
    try{
      setSupplier("")
   setPageHeader('Purchase Landing')   
  setFilteredSupplier(globalProviders)
      
    }catch(err){

    }
  }, []);
  return (
    <Stack >
      <AppBar position="static"  >
        <Toolbar>
          <Grid container>
            <Grid item xs={6}>                    
            
              <TextField
              size="small"
              variant="outlined"
              //label={t("search")}
              value={searchText}
              sx={{width:'95%',borderRadius:'25px',border:1}}
              onChange={(e) => {
                setSearchText(e.target.value.toLowerCase());
                var searchTem = e.target.value.toLowerCase();
                setProductsList(
                  filteredProductsList.filter((item) => {
                    return (
                      item.Name.toLowerCase().match(
                        new RegExp(searchTem, "g")
                      ) ||
                     /* (item.BrandName != null &&
                        item.BrandName.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        )) ||
                       (item.Category != null &&
                        item.Category.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        )) ||
                      (item.Abbreviation != null &&
                        item.Abbreviation.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        )) || */
                      (item.Description != null &&
                        item.Description.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        ))||
                        (item.SupplierName != null &&
                          item.SupplierName.toLowerCase().match(
                            new RegExp(searchTem, "g")
                          ))
                    );
                  })
                );
              }}
InputProps={{
  startAdornment:(
    <InputAdornment position="start">
        <Button
              variant="standard"  
                endIcon={<MenuIcon />}
                onClick={() => toggleItemCateogyDrawer()}
              />
      <SearchIcon sx={{color: theme.palette.primary.main}}/>      
    </InputAdornment>    
    ),
    endAdornment:(
      searchText && searchText.length>0 &&
    <InputAdornment position="end">       
      <Close sx={{color: theme.palette.primary.contrastText}} onClick={()=>{
        setSearchText("")
        setProductsList(filteredProductsList)
        }}/>      
    </InputAdornment>
     
    ),
 
     
    style:{color:'white'}
}}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography color={theme.palette.primary.contrastText} variant="h5">
                {!(selectedCategory == null) && selectedCategory.length > 0 ? (
                  <span>
                    {" "}
                    {selectedCategory}
                    {" ("} {productsList.length} {" Products)"}
                    <Tooltip title={t("clear selection")}>
                      <IconButton
                      variant="standard"
                      style={{border:1}}
                        onClick={() => {
                          setSearchText("");
                          setProductsList(filteredProductsList);
                          setSelectedCategory("");
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                ) : globalUser &&
                  globalUser.length > 0 &&
                  globalUser.Type === "Retailer" ? (
                  t("all providers") + " (" + productsList.length + " Products)"
                ) : (
                  t("all categories") +
                  " (" +
                  productsList.length +
                  " Products)"
                )}
              </Typography>
              <Typography variant="h6" color={theme.palette.primary.contrastText} ml={8}>
                {!(searchText == null) && searchText.length > 0 && (
                  <span> Searched with : {searchText}</span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={openItemCategoryDrawer}
        onClose={() => setOpenItemCategoryDrawer(false)}
      >
        <Button
          variant="standard"
          endIcon={<ChevronLeftIcon />}
          onClick={() => toggleItemCateogyDrawer()}
        >
          Categories
        </Button>

        <nav>
          <ul>
            {providerOrProductCategoriesList &&
              providerOrProductCategoriesList.map((item) => {
                return (
                  <li key={item.Id+item.Supplier} style={{fontSize:"10px"}}>
                    <Button
                      startIcon={
                        <Avatar
                          src={
                            item.Image != null && item.Image.length > 0
                              ? item.Image.split(",")[0]
                              : "/Images/ProductCategoriesPics/default.png"
                          }
                        />
                      }
                      variant="standard"
                      value={item.Id}
                      onClick={(e) => {
                        setSelectedCategory(item.Name);
                        setProductsList(
                          filteredProductsList.filter((tt) => {
                            return globalUser.Type === "Retailer"
                              ? tt.Supplier === e.target.value
                              : tt.Category === e.target.value;
                          })
                        );
                      }}
                    >
                      {item.Name}
                    </Button>{" "}
                  </li>
                );
              })}
          </ul>
        </nav>
       {/*  <List sx={{ bgcolor: "#1976d2", color: "#fff", fontSize:"5px" }}>
          {providerOrProductCategoriesList &&
            providerOrProductCategoriesList.map((item) => {
              return (
                <ListItem>
                  <FormControlLabel
                    control={
                      <Checkbox 
                      checked={false} 
                      onChange={(event) => {}} />
                    }
                    label= {item.Name}
                  />

                 <Avatar
                    src={
                      item.Image != null && item.Image.length > 0
                        ? item.Image.split(",")[0]
                        : "/Images/ProductCategory/default.png"
                    }
                  /> 
                </ListItem>
              );
            })}
        </List> */}
      </Drawer>
          <Grid container>
          <Grid item xs={12} md={3}>
            <Grid container>
            <Grid item xs={12} >
              {"Supplier : " + supplier} {supplier + (supplier && supplier.length>0) && <Close sx={{color: "green",height:20,marginLeft:1,marginBottom:-1}} onClick={()=>{
         setSupplier("");
         setSearchSupplier("");
          }}/>}
<TextField
              size="small"
              variant="outlined"
              label={supplier?supplier:'search supplier'}
              value={searchSupplier}
              sx={{width:'95%',borderRadius:'25px',marginTop:1,border:0,color: "green"}}
              onChange={(e) => {
                setSearchSupplier(e.target.value.toLowerCase());
                var searchTem = e.target.value.toLowerCase();
                setFilteredSupplier(
                  globalProviders.filter((item) => {
                    return (
                      item.Name.toLowerCase().match(
                        new RegExp(searchTem, "g")
                      ) /*||
                      (item.BrandName != null &&
                        item.BrandName.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        )) ||
                       (item.Category != null &&
                        item.Category.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        )) ||
                      (item.Abbreviation != null &&
                        item.Abbreviation.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        )) || 
                      (item.Description != null &&
                        item.Description.toLowerCase().match(
                          new RegExp(searchTem, "g")
                        ))||
                        (item.SupplierName != null &&
                          item.SupplierName.toLowerCase().match(
                            new RegExp(searchTem, "g")
                          ))*/
                    );
                  })
                );
              }}
InputProps={{
  startAdornment:(
    <InputAdornment position="start"> 
      <Button
              variant="standard" 
                endIcon={<MenuIcon />}
                onClick={() => toggleSupplierDrawer()}
              />      
      <SearchIcon sx={{color: theme.palette.primary.main}}/>      
    </InputAdornment>
     
    ),
       endAdornment:(
        searchSupplier && searchSupplier.length>0 &&
      <InputAdornment position="end">       
        <Close sx={{color: theme.palette.primary.main}} onClick={()=>{
          setSearchSupplier("")
          setFilteredSupplier(globalProviders)
          }}/>      
      </InputAdornment>
       
      ),
    
     
    style:{color:'green'}
}}
/>
</Grid>
<Grid item display={{xs:openSupplierDrawer?'block':'none',md:'block'}} xs={12}>
<nav>
<ul style={{textAlign:'left'}}>
  {filteredSupplier &&
    filteredSupplier.map((item) => {
      return (
        <li key={item.Id} style={{fontSize:"10px"}}>
          <Button
            startIcon={
              <Avatar
                src={
                  item.Logo != null && item.Logo.length > 0
                    ? item.Logo.split(",")[0]
                    : "/Images/ProductCategoriesPics/default.png"
                }
              />
            }
            variant="standard"
            value={item.Id}
            onClick={(e) => {
             setSupplier(item.Name);
             setSearchSupplier(item.Name)
             toggleSupplierDrawer();
            }}
          >
            {item.Name}
          </Button>{" "}
        </li>
      );
    })}
</ul>
</nav>
           </Grid>
           </Grid>
           </Grid>
            <Grid item xs={12} md={9}>
            <Grid container direction='row'  m={1} spacing={2} width="100%" >
            {filteredSupplier && filteredSupplier.length>0 && productsList.length > 0 &&
              productsList.map((product, index) => {
                return (
                  <Grid item display={{xs:openSupplierDrawer?'none':'block',md:'block'}} key={product.Item+product.Supplier} xs={11} md={4} lg={3}>
                     <Badge 
                     badgeContent={
                      selectedItems && selectedItems.length && selectedItems.filter((item) => ((parseInt(item.Item) === parseInt(product.Item)) && ("Purchase"===item.Type) && (item.SupplierName===supplier)))
                     .length >0 ?
                      selectedItems.filter((item) => ((parseInt(item.Item) === parseInt(product.Item)) && ("Purchase"===item.Type) && (item.SupplierName===supplier)))[0].Quantity:0} 
                     color="secondary" 
                     invisible={selectedItems.filter((item) => ((parseInt(item.Item) === parseInt(product.Item)) && ("Purchase"===item.Type) && (item.SupplierName===supplier)))
                     .length <= 0?true:false}
                     >
                    <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                      <CardHeader
                        avatar={ 
                          <Avatar
                            src={
                              product.Picture != null &&
                              product.Picture.length > 0
                                ? product.Picture.split(",")[0]
                                : "/Images/ProductsPics/default.png"
                            }
                          />
                        }
                        className="CardHeader"
                        title={product.Name.substring(0, 20)}
                        subheader={product.CategoryName}
                      />

                      <CardMedia
                        component="img"
                        height="100px"
                        sx={{
                          padding: "0.2em 0.2em 0 0.2em",
                          objectFit: "fill",
                        }}
                        image={
                          product.Picture != null && product.Picture.length > 0
                            ? product.Picture
                            : "/Images/Products/default.png"
                        }
                        alt={product.Description}
                      />
                      <CardContent>
                     {/*  <Typography variant="body2" align="left">
                          Supplier.: {product.SupplierName}
                        </Typography> */}
                        <Typography variant="body2" align="left">
                          Desc.: {product.Description}
                        </Typography>
                        {!(product.Price === "0.00") ? (
                          <Typography variant="body2" align="left">
                            Price :{product.Price}
                            
                            {selectedItems.filter((item) => ((parseInt(item.Item) === parseInt(product.Item)) && ("Purchase"===item.Type) && (item.SupplierName===supplier)))
                              .length > 0 ? (
                                <div>
                          <Button
                              variant="contained"
                              sx={{backgroundColor:'red',color:'white'}}
                               size="small"
                              mt= {1}
                                onClick={() => {
                                  removeFromCart({
                                    Id: 0,
                                    Type: "Purchase",
                                    Item: product.Item,
                                    Name: product.Name,
                                    Quantity: 1,
                                    UnitPrice: product.Price,
                                    TotalPrice: product.Price * 1,
                                    Supplier: globalProviders.filter(asup=>asup.Name===supplier)[0].Id,
                                  });
                                }}
                              >
                                {" "}
                                {t("remove")}
                              </Button>|                                 
                              <Button
                              variant="contained"
                              sx={{color:'white'}}
                               size="small"
                              mt= {1}
                                onClick={() => {
                                  selectedItems.filter((item) => ((parseInt(item.Item) === parseInt(product.Item)) && ("Purchase"===item.Type) && (item.SupplierName===supplier)))[0].Quantity+=1;
                                  setSelectedItems(selectedItems.map(it=>it))
                                }}
                              >
                                {" "}
                                {t("+")}
                              </Button>|
                              <Button
                              variant="contained"
                              sx={{color:'white'}}
                               size="small"
                              mt= {1}
                                onClick={() => {
                                  selectedItems.filter((item) => ((parseInt(item.Item) === parseInt(product.Item)) && ("Purchase"===item.Type) && (item.SupplierName===supplier)))[0].Quantity-=1;
                                  setSelectedItems(selectedItems.map(it=>it))
                                }}
                              >
                                {" "}
                                {t("-")}
                              </Button>
                              </div>
 
                            ) : (
                              <Button
                               variant="contained"
                               size="small"
                                fullWidth
                                onClick={() => {
                              
                                 (supplier && supplier!==null && supplier.length>0) ?
                                  addtoCart({
                                    OrderId: 0,
                                    Type: "Purchase",
                                    Item: product.Item,
                                    Name: product.Name,
                                    Quantity:(product.DefaultOrderQty===0?1:product.DefaultOrderQty),
                                    UnitPrice: product.Price,
                                    TotalPrice: product.Price * 1,
                                    VATInclusive:product.VATInclusive,
                                    Supplier: globalProviders.filter(asup=>asup.Name===supplier)[0].Id,
                                    SupplierName: supplier,
                                    Image: product.Picture,
                                    TaxAmount: product.VATInclusive
                                      ? product.Price * 1 * 0.15
                                      : 0,
                                  }):alert('Select the supplier first');
                                }}
                              >
                                {t("add cart")}
                              </Button>
                            )}
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Typography variant="body2" align="left">
                          <Button
                          variant="contained"
                          size="small"
                            fullWidth
                           sx={{
                          marginTop:1
                            }} 
                          >
                            <ProductDetails
                                     icon={
                                
                                <div>  {t("product details")}</div>
                                
                              }
                              
                              Products={productsList.filter(pro=>pro.Item===product.Item)}
                            />
                          </Button>
                        </Typography>
                        <Tooltip title={t("favorite item")}>
                          <Rating aria-label="add to favorites">Rating:</Rating>
                        </Tooltip>
                      </CardContent>
                     
                    </Card>
                    </Badge>
                  </Grid>
                );
              })}
                          </Grid>            
            </Grid>
           
          </Grid>
       
    </Stack>
  );
}

export default LandingPurchaseComp;
