import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
       IconButton,
       Button,
    Box,
    Tooltip,
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    FileUpload,
    RefreshSharp
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo, useContext } from "react";
  import SuppliersModel from "../Purchase/SuppliersModel";
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
  import { useTranslation } from "react-i18next";


import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

  function SuppliersComp(props) {
    const navigate = useNavigate();
    const theme=useTheme();
    const { t, i18n } = useTranslation();
    const [update, setUpdate] = useState(false);
     ;
    const [SuppliersList, setSuppliersList] = useState([]);
    const { setPageHeader,globalUser,globalToken,globalRoleSecurity }=useContext(ShoppingCartContext)

 
    const columns = useMemo(
      () => [
       
        {
          header: "Id",
          accessorKey: "Id",
          size:120
                  },
        {
          header: "Tin No.",
          accessorKey: "TinNumber",
          size:120 
        },
      /*   {
          header: "Type",
          accessorKey: "Type", 
        }, */
        {
          header: "Name",
          accessorKey: "Name", 
        },
        {
          header: "Description",
          accessorKey: "Description", 
        },
        {
          header: "Category",
          accessorKey: "Category", 
          size:120
        },
       /*  {
          header: "Logo",
          accessorKey: "Logo", 
        }, */
        {
          header: "Amount",
          accessorKey: "Amount", 
        },
       /*  {
          header: "Commission",
          accessorKey: "Commission", 
        },
        {
          header: "Route",
          accessorKey: "Route", 
        },
        {
          header: "Region",
          accessorKey: "Region", 
        },
        {
          header: "Zone",
          accessorKey: "Zone", 
        },
        {
          header: "Wereda",
          accessorKey: "Wereda", 
        },
        {
          header: "House No.",
          accessorKey: "HouseNo", 
        },
        {
          header: "Surrounding",
          accessorKey: "Surrounding", 
        },
        {
          header: "Telephone",
          accessorKey: "Telephone", 
        }, */
        {
          header: "Mobile",
          accessorKey: "Mobile", 
          size:120
        },
       /*  {
          header: "Email",
          accessorKey: "Email", 
        },
        {
          header: "Web",
          accessorKey: "Web", 
        },
        {
          header: "User Name",
          accessorKey: "UserName", 
        },
        {
          header: "Password",
          accessorKey: "Password", 
        },
        {
          header: "Lat",
          accessorKey: "Lat", 
        }, */
        {
          header: "Remark",
          accessorKey: "Remark", 
        },
      ],
  
      []
    );
  

  
    const loadCustomersList =  () => {
      
      setSuppliersList([]);
     axios.get("/Suppliers/getByParentAndType?Parent=" + globalUser.Parent + "&Type=Customer", {
      headers: {
        token: globalToken,
      },
    }).then(response=>{
          if (response.data.data){
           setSuppliersList(response.data.data);
          }else{
            alert('log out and log in again')
          }
        })
        
      };

  
    const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/Suppliers/Excel", fdata, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
        },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(SuppliersList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "supports");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "supportsdata.xlsx");
    }
    
  useEffect(() => {

      if(!globalUser){
        navigate('/');
        return;
      }
      loadCustomersList();
      setPageHeader('Customers')
    }, [update]);
    return (
      <Grid container>
         

          <Grid item className="hideInMobile" m={1}>
      
            <MaterialReactTable
            title="Abdulmejid table"
              columns={columns}
              data={SuppliersList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
             enableGrouping
           
              enableColumnOrdering
              enableColumnResizing
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
               {globalRoleSecurity.filter(sec=>(sec.Functionality==="Customers" && sec.Security>1)).length>0 &&
                <><Button size="small" variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <SuppliersModel
                      icon={<div>Add</div>}
                      data={{Id:0, Name:""}}
                      Type="Customer"
                      Title="ADD NEW USER"
                     setParentUpdate={setUpdate}
                     ParentUpdate={update}
                    />
                </Button>|
                </>
              }
                <Button type="file" onClick={handleDownloadFile}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                        Download
                      </Button>|
                     
                        <Button onClick={() => setUpdate(!update)}      size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                          Refresh
                        </Button>
                    
             
              </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("modify")}>                  
                  <IconButton > 
                   <SuppliersModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  Type="Customer"
                  Title="EDIT SUPPLIER"
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />   
                  </IconButton>
                  </Tooltip>

                </Box>
              )}
            ></MaterialReactTable> 
        </Grid>
        <Grid item xs={12} className="hideInFull" mt={2}>
        <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main}}>
            <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                <SuppliersModel
                  icon={<div>Add</div>}
                  data=''
                  Type="Customer"
                  Title="ADD NEW USER"
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />
            </Button>|
            <Button type="file" onClick={handleDownloadFile}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                    Download
                  </Button>|
                 
                    <Button onClick={() => setUpdate(!update)}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                      Refresh
                    </Button>
                
         
          </Box>
      </Grid>
          {!(SuppliersList === undefined) &&
            SuppliersList.length > 0 &&
            SuppliersList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                   <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={item.Name} //.substring(0, 20)}
                      subheader={item.Type}
                    />

                    <CardContent>
                      <Typography variant="body2" align="left">
                       Tin No.: {item.TinNumber}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Category: {item.Category}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Description: {item.Description}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                            <SuppliersModel
                              icon={
                                <EditSharp
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                              data={item}
                              Type="Customer"
                              Title="EDIT STOCK"
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
     
    );
  }
  
  export default SuppliersComp;
  