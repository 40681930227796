import { createContext, useState } from 'react'



export const ShoppingCartContext = createContext()
export function ShoppingCartProvider({ children }) {
  const [globalRoleSecurity, setGlobalRoleSecurity] = useState([]);
  const [globalSetting, setGlobalSetting] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [globalSelectedSuppliers, setGlobalSelectedSuppliers] = useState([]);
  const [globalSelectedCustomers, setGlobalSelectedCustomers] = useState([]);
  const [globalProviders, setGlobalProviders] = useState([]);
  const [globalUser, setGlobalUser] = useState(null);
  const [globalCustomers, setGlobalCustomers] = useState([]);
  const [globalStores, setGlobalStores] = useState([]);
  const [pageHeader, setPageHeader] = useState('');
  const [globalToken, setGlobalToken] = useState('');
  const [selectedSalesType,setSelectedSalesType]=useState('')
  const [selectedPurchaseType,setSelectedPurchaseType]=useState('')
  const [selectedLanguage,setSelectedLanguage]=useState('en')
  const addtoCart = (item) => {
    if (
      selectedItems.length > 0 &&
      selectedItems.filter((itemInItems) => ((itemInItems.Item === item.Item) && (itemInItems.Supplier === item.Supplier))).length > 0
    ) {
      selectedItems[
        selectedItems.findIndex((itemInItems) => itemInItems.Item === item.Item)
      ] = item
      //setSelectedItems(newItems);
    } else {
      setSelectedItems((prevItems) => [...prevItems, item])
    }

   
    if (item.Type==="Sales" &&
      !(
        globalSelectedCustomers.length > 0 &&
        globalSelectedCustomers.filter((sup) => parseInt(sup.Id) === parseInt(item.Supplier)).length > 0
      )
    ) {
    var temp=globalCustomers.filter(gs=>parseInt(gs.Id)===parseInt(item.Supplier)).length>0?globalCustomers.filter(gs=>parseInt(gs.Id)===parseInt(item.Supplier))[0]:'';
      setGlobalSelectedCustomers(gsup=>[...gsup,temp]);
    
    }else if (item.Type==="Purchase" &&
      !(
        globalSelectedSuppliers.length > 0 &&
        globalSelectedSuppliers.filter((sup) => parseInt(sup.Id) === parseInt(item.Supplier)).length > 0
      )
    ) {
      var temp=globalProviders.filter(gs=>parseInt(gs.Id)===parseInt(item.Supplier)).length>0?globalProviders.filter(gs=>parseInt(gs.Id)===parseInt(item.Supplier))[0]:'';
      setGlobalSelectedSuppliers(gsup=>[...gsup,temp]);
    
    }
 
  }
  const removeFromCart = (item) => {
    
    const newItems = selectedItems.filter((cart) => !((parseInt(cart.Item) === parseInt(item.Item)) && (parseInt(cart.Supplier) === parseInt(item.Supplier))))
    setSelectedItems(newItems)
   

    if (
      newItems.filter((e) => (parseInt(e.Supplier) === parseInt(item.Supplier)))
        .length <= 0
    ) {

      if(item.Type==="Sales"){
        setGlobalSelectedCustomers(
        globalSelectedCustomers.filter((sup) => (parseInt(sup.Id) !== parseInt(item.Supplier))),
        )
      }else{
        setGlobalSelectedSuppliers(
          globalSelectedSuppliers.filter((sup) => (parseInt(sup.Id) !== parseInt(item.Supplier))),
        )
      }
     
    }
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        selectedItems,
        setSelectedItems,

        addtoCart,
        removeFromCart,
        globalSelectedSuppliers,
        setGlobalSelectedSuppliers,

        globalSelectedCustomers,
        setGlobalSelectedCustomers,

        globalUser,
        setGlobalUser,

        allItems,
        setAllItems,

        pageHeader, 
        setPageHeader,

        globalCustomers, 
        setGlobalCustomers,
        globalProviders, 
        setGlobalProviders,
        globalRoleSecurity,
        setGlobalRoleSecurity,
        globalStores, 
        setGlobalStores,
        globalToken,
        setGlobalToken,
        globalSetting, 
        setGlobalSetting,
        selectedSalesType,
        setSelectedSalesType,
        selectedPurchaseType,
        setSelectedPurchaseType,
        selectedLanguage,
        setSelectedLanguage
      }}
    >
      {children}{' '}
    </ShoppingCartContext.Provider>
  )
}
