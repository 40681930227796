import * as React from "react";
import { Button,RadioGroup,FormControlLabel,Radio,colors, Grid, TextField,Avatar,} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";
import axios from "../../axiosInstances";
import { useState, useEffect,useContext } from "react";
import { useNavigate  } from 'react-router-dom'
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import { CloseSharp, SaveSharp } from "@mui/icons-material";

import { Formik } from 'formik';
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Name is required!!!'),
    UserName: Yup.string()
    .required('Username is required!!!'),
    Password: Yup.string()
    .required('Password is required!!!'),
    ConfirmPassword: Yup.string()
    .required('Confirm Password is required!!!')
    .oneOf([Yup.ref('Password'), null], 'Passwords must match')

});

const User_RegEx = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PW_RegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
export default function UserRegisterComp(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { globalUser,setGlobalUser,globalToken} =useContext(ShoppingCartContext)

  let history=useNavigate ();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [defaultTheme, setDefaultTheme] = useState(globalUser.Theme);
  const [themeExpand, setThemeExpand] = useState(false);

  const [username, setUserName] = useState(globalUser.UserName);

  const [password, setpassword] = useState(globalUser.password);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleUserSubmit = (values) => {
 
   /* if(username===undefined || username===null || username.length<=0){
      alert('write the username first')
      return;
    }
    if(password===undefined || password===null || password.length<=0){
      alert('write the password first')
      return;
    } 
    if(confirmPassword===undefined || confirmPassword===null || confirmPassword.length<=0){
      alert('write the confirm password first')
      return;
    } 
    if(confirmPassword!==password){
      alert('The password and confirm password are different.')
      return;
    } */
 
    try {
      var fd = new FormData();
      fd.append("Id", globalUser.Id);
      /* fd.append("TinNumber", globalUser.TinNumber);
      fd.append("Type", 'Employee');
      fd.append("Name", globalUser.Name);
      fd.append("Description", globalUser.Description);
      fd.append("Category", globalUser.Category);
       fd.append("Logo", globalUser.Picture);
      
      fd.append("Amount", globalUser.Amount);
      fd.append("Commision", globalUser.Commision);
      fd.append("Remark", defaultTheme);
      fd.append("Route", globalUser.Route);
      fd.append("Region", globalUser.Region)
      fd.append("Zone", globalUser.Zone);
      fd.append("Wereda", globalUser.Wereda);
      fd.append("HouseNo", globalUser.HouseNo);
      fd.append("Surrounding", globalUser.Surrounding);
      fd.append("Telephone", globalUser.Telephone);
      fd.append("Mobile", globalUser.Mobile);
      fd.append("Email", globalUser.Email);
      fd.append("Web", globalUser.Web); */
      fd.append("UserName", values.UserName);
      fd.append("Password", values.Password);
      fd.append("Theme", defaultTheme);
      /* fd.append("Role", globalUser.Role);
      fd.append("Lat", globalUser.Lat);
      fd.append("Longt", globalUser.Longt);
   
        fd.append("Parent", globalUser.Parent);
     
     fd.append("Logo",globalUser.Logo)
      fd.append("Store", globalUser.Store); */
     
     axios.post("/employees/updateUser", fd, {
      headers: {"Content-Type": "application/json" , 
      token: globalToken,
   },
      });
    } catch (err) {
    } finally {
      setGlobalUser({...globalUser,
        Theme: defaultTheme})
      setOpen(false);
    }
  }
  return (
    <div>
      <label onClick={handleClickOpen}>{props.icon?props.icon:props.menuTtitle}</label>
      <Formik
          initialValues={props.data}
          onSubmit={values => {handleUserSubmit(values)}}
           validationSchema={validationSchema}
           enableReinitialize            
          >
            {({values,errors,touched,handleChange,isValid,handleSubmit}) => (
              
      <Dialog       
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{backgroundColor:theme.palette.primary.main,color:theme.palette.primary.contrastText,height:25,marginBottom:2}}> {props.Title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           <Grid container>
           
           <Grid item xs={6}>
           <Grid container>
            
              <Grid item xs={12}>
               Name of User: {globalUser.Name}
              </Grid>
              <Grid item xs={12}>
              <TextField
                    size="small"
                          label="Username"
                          value={values.UserName}
                          onChange={//(e) => {
                            handleChange('UserName')// setName(e.target.value);
                          }//}
                          sx={{ width: "95%", marginBottom: 2, marginTop: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.UserName && <span style={{color:'red'}}> {errors.UserName}</span>}
              </Grid>
              <Grid item xs={12}>
             <TextField
                          size="small"
                          variant="standard"
                          type="password"
                          label="Password"
                          value={values.Password}
                          onChange={//(e) => {
                            handleChange('Password')// setName(e.target.value);
                          }//}
                          sx={{ width: "95%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.Password && <span style={{color:'red'}}> {errors.Password}</span>}
              </Grid>
              <Grid item xs={12}>
 <TextField
                      type="password"
                      variant="standard"
                          size="small"
                          label="Confirm Password"
                          value={values.ConfirmPassword}
                          onChange={//(e) => {
                            handleChange('ConfirmPassword')// setName(e.target.value);
                          }//}
                          sx={{ width: "95%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.ConfirmPassword && <span style={{color:'red'}}> {errors.ConfirmPassword}</span>}
              </Grid>
              </Grid>

             </Grid>
          
              
              
             
             </Grid>
              
          </DialogContentText>
        </DialogContent>
       <DialogActions sx={{backgroundColor:theme.palette.primary.main,color:theme.palette.primary.contrastText}}>
       <Button variant="standard" endIcon={<SaveSharp />} onClick={handleSubmit} autoFocus>
            Update
          </Button>|
          <Button variant="standard" endIcon={<CloseSharp />} onClick={()=>handleClose()} autoFocus>
            Close
          </Button>
        </DialogActions> 
      </Dialog>
            )}
      </Formik>
    </div>
  );
}
