import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  PropaneSharp,
  Preview,
  PreviewSharp,
  RequestQuoteSharp,
  ApprovalSharp,
  DeliveryDiningSharp,
  ReceiptSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";

import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function ItemTransferModel(props) {
  const theme=useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { globalUser, allItems,globalToken,globalRoleSecurity } = useContext(ShoppingCartContext);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [selectedVoucher, setSelectedVoucher] = useState("");
  const [transferlines, setTransferlines] = useState(props.ItemTransferLines);
  const [transferOrder, setTransferOrder] = useState(props.TransferOrder?props.TransferOrder:{Id:0,OrderDate:orderDate});
  const [lineItems, setLineItems] = useState(props.LineItems ? props.LineItems : allItems);
  const [EmployeesList, setEmployeesList] = useState(props.EmployeesList);
  const [StoresList, setStoresList] = useState(props.StoresList);
  const [toStore, setToStore] = useState(transferOrder?StoresList && StoresList.filter(st=>parseInt(st.Id)===parseInt(transferOrder.From)).length>0 ? 
  StoresList.filter(st=>parseInt(st.Id)===parseInt(transferOrder.From))[0].Name:'': 
  StoresList.filter(st=>parseInt(st.Id)===parseInt(globalUser.Store)).length>0 ? StoresList.filter(st=>parseInt(st.Id)===parseInt(globalUser.Store))[0].Name:'');
  const [fromStore, setFromStore] = useState(transferOrder?StoresList && StoresList.filter(st=>parseInt(st.Id)===parseInt(transferOrder.To)).length>0 ?
  StoresList.filter(st=>parseInt(st.Id)===parseInt(transferOrder.To))[0].Name:'':StoresList && StoresList.filter(st=>parseInt(st.Id)===parseInt(globalUser.Store)).length>0 ? StoresList.filter(st=>parseInt(st.Id)===parseInt(globalUser.Store))[0].Name:'');

  const [issueTo, setIssuedTo] = useState(props.data ? props.data.Customer : "");
  const [remark, setRemark] = useState(props.data ? props.data.Remark : "");

  const [productsList] = useState(allItems);


  const handleSubmit = (status, lItems) => {
  
   
   
    if (fromStore === undefined || fromStore === null || fromStore.length<=0) {
      alert("First select store from");
      return;
    }
    if (toStore=== undefined || toStore === null || toStore.length<=0) {
      alert("First select store to");
      return;
    }
    if (fromStore===toStore) {
      alert("The source and destination stores must be difference");
      return;
    }
    if (
      lItems.filter(li=>li.Order===transferOrder.Id) === undefined ||
      lItems.filter(li=>li.Order===transferOrder.Id).filter((li) => li.Name.length > 0).length <= 0
    ) {
      alert("First select the items" );
      return;
    }
 

    lItems.filter(li=>li.Order===transferOrder.Id).map((li) => {
      
      if (isNaN(li.Quantity) || li.Quantity <= 0) {
        alert("First insert the proper quantity of product: " + li.Name);
         return;
      }
    });
    if (
      window.confirm(
        "are you sure you want to save the transfer " + status +"?"
      )
    ) {
    try {
        
            var fd = new FormData();
            fd.append("Id", transferOrder.Id);
            fd.append("Order", 0);
            fd.append("From", StoresList.filter(st=>st.Name===fromStore)[0].Id);
            fd.append("To", StoresList.filter(st=>st.Name===toStore)[0].Id);
            fd.append("RequestedBy", transferOrder.RequestedBy?transferOrder.RequestedBy:globalUser.Id);
            fd.append("RequestedDate",transferOrder.RequestedDate? transferOrder.RequestedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("ApprovedBy", transferOrder.ApprovedBy?transferOrder.ApprovedBy:globalUser.Id);
            fd.append("ApprovedDate",transferOrder.ApprovedDate? transferOrder.ApprovedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("IssuedBy", transferOrder.IssuedBy?transferOrder.IssuedBy:globalUser.Id);
            fd.append("IssuedDate",transferOrder.IssuedDate? transferOrder.IssuedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("ReceivedBy", transferOrder.ReceivedBy?transferOrder.ReceivedBy:globalUser.Id);
            fd.append("ReceivedDate",transferOrder.ReceivedDate? transferOrder.ReceivedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("Status", status);
            fd.append("Parent", globalUser.Parent);            
            fd.append("Remark", transferOrder.Remark);
            fd.append("TransferLines",JSON.stringify(lItems));
 
            axios
              .post("/itemtransfers", fd, {
                headers: { "Content-Type": "application/json" , 
                    token: globalToken,
                 },
              })
                      
      
    } catch (err) {
    } finally {
      //setTransferlines([]);
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }

  };
  const handleDelete = (id) => {
    if (
      window.confirm(
        "are you sure you want to delete?"
      )
    ) {
    try {
      axios.delete("/jobs?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleLineItemDelete = (id) => {
    if (
      window.confirm(
        "are you sure you want to delete?"
      )
    ) {
    try {
      axios.delete("/lineitems?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
    }
  }
  };
  

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
                <DialogContentText mt={1}>
                  <DialogTitle
                    style={{
                      backgroundColor: theme.palette.primary.main, color:theme.palette.primary.contrastText,
                      height: "15px",
                    }}
                  
                  >
                     {"From: " + fromStore + " -->   To: " + toStore}
                  </DialogTitle>
                  <div>
                    <Grid container>
                    <Grid
                        item
                        xs={12}
                        pl={'35%'}
                        sx={{
                         backgroundColor:theme.palette.primary.dark,
                         color:theme.palette.primary.contrastText,
                         textDecoration:'underline',
                         fontWeight:'bold',
                        
                       }}
                      >
                        {"Transfer " + (transferOrder?transferOrder.Status:'Request')}
                      </Grid>
                      <Grid item xs={6}>
                        <span>Doc. No.: {transferOrder.Id}</span><br></br>
                        <span>Status: {transferOrder.Status}</span>
                      </Grid>
                  
                      
                      <Grid item xs={6}>
                        Requested By: {transferOrder.IssuedToName ? transferOrder.RequestedByName? transferOrder.RequestedByName: globalUser.Name:globalUser.Name}<br></br>
                        Approved By: {transferOrder.ApprovedByName}<br></br>
                        </Grid>
                        <Grid item xs={6} mt={1}>
                        <Autocomplete
                          label="From Store"
                          id="fromStore"
                          size="small"
                          variant="standard"
                          value={fromStore}
                          onChange={(e) => {
                            setFromStore(e.target.textContent);
                            
                           
                          }}
                          options={
                            !(StoresList === undefined) &&
                            !(StoresList === null) &&
                            StoresList.length > 0 &&
                            StoresList.map((pro) => {
                              return pro.Name;
                            })
                          }
                          sx={{ width: "95%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("From Store")}

                              //error={!from ? true : false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        Issued By: {transferOrder.IssuedByName}<br></br>
                        Received By: {transferOrder.ReceivedByName}<br></br>
                      </Grid>
                      
                     
                      <Grid item xs={6} mt={1}>
                        <Autocomplete
                          label="To Store"
                          id="toStore"
                          size="small"
                          variant="standard"
                          value={toStore}
                          onChange={(e) => {
                            setToStore(e.target.textContent);
                          
                          }}
                          options={
                            !(StoresList === undefined) &&
                            !(StoresList === null) &&
                            StoresList.length > 0 &&
                            StoresList.map((pro) => {
                              return pro.Name;
                            })
                          }
                          sx={{ width: "95%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("To Store")}

                              //error={!from ? true : false}
                            />
                          )}
                        />
                      </Grid>
                     

                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          variant="standard"
                          value={remark}
                          label="Remark"
                          minRows={2}
                          sx={{ width: "95%" }}
                          onChange={(e) => {
                            transferOrder.Remark = e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                         >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>
                             
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  textAlign: "center",
                                }}
                              >
                                Remark
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                <IconButton
                                  size="small"
                                  //id={transferOrder.Id}
                                  onClick={(e) => {
                                    if (
                                      transferlines &&
                                      transferlines.filter(
                                        (itt) => itt.Item === ""
                                      ).length <= 0
                                    ) {
                                      let newLineItem = {};
                                      newLineItem.Item = "";
                                      newLineItem.Name = "";
                                      newLineItem.Order = transferOrder.Id;
                                      //newLineItem.Job=transferOrder.Id;
                                      //newLineItem.IssuedTo = transferOrder.IssuedTo;
                                      newLineItem.Quantity = 0;
                                      //newLineItem.IssuedToName = transferOrder.IssuedToName;
                                      setTransferlines((xx) => [
                                        ...xx,
                                        newLineItem,
                                      ]);
                                    } else {
                                      alert("First complete the data");
                                    }
                                    console.log(transferlines);
                                  }}
                                >
                                  <Add sx={{color:theme.palette.primary.contrastText}} />
                                </IconButton>
                              </Grid>

                              {transferlines &&
                                transferlines !== null &&
                                transferlines.length > 0 &&
                                /*  transferlines.map(jl=>{
              var issuedQuantity=issuedQuantity + jl.Quantity;
            }) && */
                                transferlines
                                  .filter(
                                    (it) =>
                                      parseInt(it.Order) === parseInt(transferOrder.Id)
                                  )
                                  .map((item, index) => {
                                    /*         
                  var issuedQuantity=item.Quantity; */
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Autocomplete
                                            label="Items"
                                            id="Items"
                                            size="small"
                                            variant="standard"
                                            value={item.Name}
                                            onChange={(e) => {
                                              if (
                                                transferlines &&
                                                transferlines.length > 0 &&
                                                transferlines.filter(
                                                  (li) =>
                                                    li.Name === e.target.textContent
                                                ).length > 0
                                              ) {
                                                item.Item = "";
                                                item.Name = "";
                                                item.Quantity = 0;
                                                setTransferlines(
                                                  transferlines.map((litem) => {
                                                    return litem;
                                                  })
                                                );
                                                //alert('The selected item is already registered')
                                                return;
                                              } else {
                                                let selectedProduct =
                                                  productsList.filter(
                                                    (selItem) =>
                                                      selItem.Name ===
                                                      e.target.textContent
                                                  );

                                                if (
                                                  selectedProduct &&
                                                  selectedProduct.length > 0
                                                ) {
                                                  item.Quantity =
                                                    selectedProduct[0].Quantity;

                                                  item.Item =
                                                    selectedProduct[0].Item;
                                                  item.Name =
                                                    selectedProduct[0].Name;
                                                  item.Order = transferOrder.Id;
                                                  //item.IssuedTo=transferOrder.IssuedTo;
                                                  //item.IssuedToName=transferOrder.IssuedToName;
                                                  //item.Job=transferOrder.Id;
                                                  setTransferlines((ji) => [
                                                    ...ji,
                                                    item,
                                                  ]);
                                                  setTransferlines(
                                                    transferlines.map(
                                                      (litem) => {
                                                        return litem;
                                                      }
                                                    )
                                                  );
                                                } else {
                                                  item.Quantity = 0;
                                                  //item.IssuedTo=transferOrder.IssuedTo;
                                                  //item.IssuedToName=transferOrder.IssuedToName;
                                                  item.Item = "";
                                                  item.Name = "";

                                                  setTransferlines(
                                                    transferlines.map(
                                                      (litem) => {
                                                        return litem;
                                                      }
                                                    )
                                                  );
                                                }
                                              }
                                            }}
                                            options={
                                              !(lineItems === undefined) &&
                                              !(lineItems === null) &&
                                              lineItems.length > 0
                                                ? lineItems.map((it) => {
                                                    return it.Name;
                                                  })
                                                : [""]
                                            }
                                            sx={{ width: "95%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={item.Name}

                                                //error={!from ? true : false}
                                              />
                                            )}
                                          />
                                        </Grid>
                                     
                                        <Grid item xs={2}>
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            defaultValue={
                                              item.Quantity /* (lineItems && lineItems.filter(li=>li.Item===item.Item).length>0?lineItems.filter(li=>li.Item===item.Item)[0].Quantity:0)- issuedQuantity */
                                            }
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                              item.Quantity = e.target.value;
                                              setTransferlines(
                                                transferlines.map((litem) => {
                                                  return litem;
                                                })
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>

                                        <Grid item xs={4}>
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            value={item.Remark}
                                            sx={{ width: "95%" }}
                                            onChange={(e) => {
                                              item.Remark = e.target.value;
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Tooltip title="remove">
                                            <Button
                                              endIcon={<Delete width="10px" />}
                                              onClick={() => {
                                                if (
                                                  transferlines.filter(
                                                    (litem) =>
                                                      litem.Item ===
                                                        item.Item &&
                                                      parseInt(
                                                        litem.IssuedTo
                                                      ) ===
                                                        parseInt(item.IssuedTo)
                                                  ).Id
                                                ) {
                                                  handleLineItemDelete(
                                                    transferlines.filter(
                                                      (litem) =>
                                                        litem.Item ===
                                                          item.Item &&
                                                        parseInt(
                                                          litem.IssuedTo
                                                        ) ===
                                                          parseInt(
                                                            item.IssuedTo
                                                          )
                                                    )[0].Id
                                                  );
                                                }

                                                /*    setTransferlines(
                  transferlines.filter(
                    (litem) => !(litem.Item === item.Item && parseInt(litem.Supplier)===parseInt(item.Supplier))
                  )
                ); */
                                                if (
                                                  transferlines /*. filter(
                                  (litem) =>                                                      
                                      parseInt(
                                        litem.IssuedTo
                                      ) ===
                                        parseInt(
                                          transferOrder.IssuedTo
                                        )                                                      
                                ) */.length <= 0
                                                ) {
                                                  //setAssignedEmployees(EmployeesList.filter(ssp=>parseInt(ssp.Id)!==parseInt(sup.Id)));
                                                  /* setOpen(false);
                                 let newLineItem = {};
                  newLineItem.Item = "";
                  newLineItem.Name = "";
                  newLineItem.Supplier = sup.Id;
                  newLineItem.UnitCost = 0;
                  newLineItem.Quantity = 0;
                  newLineItem.UnitPrice = 0;
                  newLineItem.TotalPrice = 0;
                  setTransferlines((xx) => [...xx, newLineItem]); */
                                                }
                                              }}
                                            ></Button>
                                          </Tooltip>
                                        </Grid>
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {transferlines &&
                              transferlines !== null &&
                              transferlines.length > 0 &&
                              transferlines
                                /* .filter(
                (it) =>
                  parseInt(it.IssuedToName) === parseInt(transferOrder.IssuedToName)
              ) */
                                .map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <Autocomplete
                                                label="Items"
                                                id="Items"
                                                size="small"
                                                variant="standard"
                                                value={item.Name}
                                                onChange={(e) => {
                                                  if (
                                                    transferlines.filter(
                                                      (li) =>
                                                        li.Name ===
                                                        e.target.textContent
                                                    ).length > 0
                                                  ) {
                                                    item.Item = "";
                                                    item.Name = "";
                                                    item.Quantity = 0;
                                                    //item.IssuedTo = transferOrder.AssignTo;

                                                    setTransferlines(
                                                      transferlines.map(
                                                        (litem) => {
                                                          return litem;
                                                        }
                                                      )
                                                    );

                                                    return;
                                                  } else {
                                                    let selectedProduct =
                                                      productsList.filter(
                                                        (selItem) =>
                                                          selItem.Name ===
                                                          e.target.textContent
                                                      );
                                                    if (
                                                      selectedProduct &&
                                                      selectedProduct.length > 0
                                                    ) {
                                                      item.Quantity =
                                                        selectedProduct[0].DefaultOrderQty;

                                                      item.Item =
                                                        selectedProduct[0].Item;
                                                      item.Name =
                                                        selectedProduct[0].Name;
                                                      //item.IssuedTo = transferOrder.AssignTo;

                                                      setTransferlines(
                                                        transferlines.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    } else {
                                                      item.Quantity = 0;

                                                      item.Item = "";
                                                      item.Name = "";
                                                      // item.IssuedTo = transferOrder.AssignTo;
                                                      setTransferlines(
                                                        transferlines.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    }
                                                  }
                                                }}
                                                options={
                                                  !(
                                                    productsList === undefined
                                                  ) &&
                                                  !(productsList === null) &&
                                                  productsList.length > 0
                                                    ? productsList.map((it) => {
                                                        return it.Name;
                                                      })
                                                    : [""]
                                                }
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label={item.Name}

                                                    //error={!from ? true : false}
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.Quantity}
                                                maxRows={4}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.Quantity =
                                                    e.target.value;
                                                  setTransferlines(
                                                    transferlines.map(
                                                      (litem) => {
                                                        return litem;
                                                      }
                                                    )
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>

                                            <Grid item xs={4}>
                                              Remark :
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                              {item.Remark}
                                            </Grid>
                                            <Grid item xs={12}>
                                              <hr></hr>
                                              Remove: {"   "}
                                              <Tooltip title="remove">
                                                <Button
                                                  endIcon={
                                                    <Delete width="10px" />
                                                  }
                                                  onClick={() => {
                                                    handleLineItemDelete(
                                                      transferlines.filter(
                                                        (litem) =>
                                                          litem.Item ===
                                                          item.Item
                                                      )[0].Id
                                                    );

                                                    if (
                                                      transferlines.filter(
                                                        (litem) =>
                                                          !(
                                                            litem.Item ===
                                                            item.Item
                                                          )
                                                      ).length <= 0
                                                    ) {
                                                      let newLineItem = {};
                                                      newLineItem.Item = "";
                                                      newLineItem.Name = "";
                                                      //newLineItem.IssuedTo = transferOrder.AssignTo;
                                                      //newLineItem.IssuedToName = transferOrder.IssuedToName;
                                                      newLineItem.Quantity = 0;

                                                      setTransferlines((xx) => [
                                                        ...xx,
                                                        newLineItem,
                                                      ]);
                                                    }
                                                  }}
                                                ></Button>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </DialogContentText>
         
        
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button variant="standard" style={{ color: "white" }} endIcon={<PreviewSharp />}>
            <InvoicePreview
              icon={
                <div style={{ fontSize: "10px"}}>Preview</div>
              }
              data={[transferOrder]}
              LineItems={transferlines}
              Type="IssueOrder"
              DocumentType={"Transfer " }
            />
          </Button>
          |
          {transferOrder.Status!=='Direct'?<>
          {((transferOrder && transferOrder.Status==='Request') ||(transferOrder===undefined ||transferOrder===null)) &&
          globalRoleSecurity.filter(gs=>(gs.Functionality==='Transfer Request'  && gs.Security>1)).length>0 &&
          <>
          <Button
          variant="standard"
            style={{ fontSize: "10px"}}
            endIcon={<RequestQuoteSharp />} 
            autoFocus
            onClick={() =>
              handleSubmit(
                "Request",
                transferlines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Request
          </Button>
          |</>
          }
           {transferOrder && (transferOrder.Status==='Request' || transferOrder.Status==='Approved') &&
           globalRoleSecurity.filter(gs=>(gs.Functionality==='Transfer Approval'  && gs.Security>1)).length>0 &&
           <>
          <Button
          variant="standard"
            style={{ fontSize: "10px"}}
            endIcon={<ApprovalSharp />}
            autoFocus
            onClick={() =>
              handleSubmit(
                "Approved",
                transferlines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Approve
          </Button>
          |
          </>
}
{transferOrder && globalRoleSecurity.filter(gs=>((gs.Functionality===('Transfer '+transferOrder.Type))  && gs.Security>2)).length>0 &&
<>
          <Button
          variant="standard"
            style={{ fontSize: "10px"}}
            /* endIcon={<Send />} */
            autoFocus
            onClick={() => handleDelete(props.order.Id)}
          >
            Delete
          </Button>
          |
          </>
}
</>:
<>
{globalRoleSecurity.filter(gs=>(gs.Functionality==='Transfer Direct'  && gs.Security>1)).length>0 &&
           <>
          <Button
          variant="standard"
            style={{ fontSize: "10px"}}
            endIcon={<ApprovalSharp />}
            autoFocus
            onClick={() =>
              handleSubmit(
                "DI",
                transferlines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Direct Issue
          </Button>
          |
          <Button
          variant="standard"
            style={{ fontSize: "10px"}}
            endIcon={<ApprovalSharp />}
            autoFocus
            onClick={() =>
              handleSubmit(
                "DIR",
                transferlines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Direct Issue and Receive
          </Button>
          |
          </>
}
</>
}

          <Button
          variant="standard"
            style={{ fontSize: "10px"}}
            /* endIcon={<Close />} */
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
