import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  PropaneSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import ConsumptionModel from './ConsumptionModel';

export default function JobsModel(props) {
  const theme=useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState();
  const {globalUser,globalToken} = useContext(ShoppingCartContext);




  const [joblines, setJoblines] = useState(props.Jobslines);
  const [EmployeesList] = useState(props.EmployeesList);
  const [customer, setCustomer] = useState(props.order?props.order.Customer:globalUser.Id);
  const [assignedEmployees, setAssignedEmployees] = useState(props.EmployeesList && props.EmployeesList.length>0?[props.EmployeesList[0]]:[]);
  const [jobOrders,setJobOrders]=useState(props.JobOrders)
 
  const [selectedVoucher, setSelectedVoucher] = useState(
    !(jobOrders === undefined) && !(jobOrders === null) &&
    jobOrders.length > 0 &&
    jobOrders[0].Id
  );
  
  const [productsList] = useState(props.ProductsList);
 /*  const [remark,setRemark]=useState(props.Order?props.Order.Remark:'')
  const [storeList, setStoreList] = useState([]);
  const [store, setStore] = useState(0); */
 
  function splitPictures() {
    try {
      fileArray = [];
      props.order.Attachment.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }
 


  const handleSubmit = (status, lItems) => {
    if (
      lItems === undefined ||
      lItems.filter((li) => li.Name.length > 0).length <= 0
    ) {
      alert("First select the items: ");
      return;
    }

    let testResult = false;
    lItems.map((li) => {
      if (isNaN(li.OrderQuantity) || li.OrderQuantity <= 0) {
        alert("First insert the proper quantity of product: " + li.Name);
        testResult = true;
        return;
      }

   
    });

    if (testResult) {
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    try {

      jobOrders && jobOrders.length>0 && jobOrders.map(ord=>{
        alert('in of in')

        var fd = new FormData();
        fd.append("Id", ord.Id);
        fd.append("Order", props.data?props.data.OrderId:ord.Order?ord.Order:0);
        fd.append("OrderDate", ord?ord.OrderDate:format(new Date(),"yyyy-MM-dd"));
        fd.append("RequestedBy", ord?ord.RequestedBy:globalUser.Id);
        fd.append("ApprovedBy", status==="Approved"?globalUser.Id:0);
       
        fd.append("ApprovedDate", ord?ord.ApprovedDate:status==="Approved"?format(new Date(),"yyyy-MM-dd"):'');
        fd.append("AssignedTo", ord.AssignedTo);
        fd.append("StartDate", ord?ord.OrderDate:format(new Date(),"yyyy-MM-dd"));
        fd.append("EndDate", '');

        fd.append("Status", status);
        fd.append("Company", globalUser.Parent);
        fd.append("Remark", ord.Remark);
        alert('in of in11')
        fd.append("Joblines",JSON.stringify(lItems.filter(
              (it) =>(it.Name.length > 0 )))
        );
        alert('in of 123654')
        axios
          .post("/jobs", fd, {
            headers: { "Content-Type": "multipart/form-data" ,
                token: globalToken,
            },
          })
          .then((res) => {
            if (!(props.order && !(props.order === null))) {
                       }
          });
        })
    } catch (err) {
    } finally {
      //setJoblines([]);
      //setCustomer('')
      //props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = (id) => {
    try {
      axios.delete("/jobs?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleLineItemDelete = (id) => {
    try {
      axios.delete("/joblines?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
    }
  };

  
 
  useEffect(() => {
   if (jobOrders===undefined || jobOrders.length<=0){
    setJobOrders([{}])
   }
    
   try {
      if (!(props.Jobslines === undefined) && !(props.Jobslines === null)) {
        setJoblines(props.Jobslines);
      } else {
        setJoblines([]);
      }
    } catch {
    } 
  }, []);

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
            {!(jobOrders === undefined) &&
              !(jobOrders === null) &&
              jobOrders.map((Ord, index) => {
              Ord.Id=Ord.Id? Ord.Id : 0
              Ord.Order=Ord.Order? Ord.Order : props.data
               Ord.OrderDate=Ord.OrderDate? Ord.OrderDate : format(new Date(), "yyyy-MM-dd")
               Ord.RequestedBy=Ord.RequestedBy? Ord.RequestedBy : globalUser.Id
               Ord.RequestedByName=Ord.RequestedByName? Ord.RequestedByName : globalUser.Name
               Ord.Remark=Ord.Remark? Ord.Remark : ""
                //if(sup && (sup!==null) && sup.Name && sup.Name.length>0){
               return (
                  <>
                  <DialogTitle style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText, height:'15px'}} onClick={() =>
                          Ord.Id === selectedVoucher
                            ? setSelectedVoucher(
                              jobOrders[
                                  index + 1 >= jobOrders.length
                                    ? 0
                                    : index + 1
                                ].Id
                              )
                            : setSelectedVoucher(Ord.Id)
                        }>
                 Job Order {index + 1 +  ":                       Assigned To:"} {" "}
                        {Ord.AssignedToName}
                    </DialogTitle>
                    <Grid container mt={1} key={Ord.Id}>
                    

                      <Grid
                        item
                        xs={6}
                        style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }}
                      >
                        <span style={{}}>
                          Doc. No.: {Ord.Id }
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }}
                      >
                        <span style={{}}>
                          Status: {Ord.Status?Ord.Status:'Draft'}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }}
                      >
                        
                        <Autocomplete
                                            label="Assigned To"
                                            id="AssignedTo"
                                            size="small"
                                            variant="standard"
                                            value={Ord.AssignedToName}
                                            onSelect={(e) => {
                                              Ord.AssignedToName=e.target.value
                                              Ord.AssignedTo=(EmployeesList && EmployeesList.filter(emp=>emp.Name=== e.target.value).length>0?EmployeesList.filter(emp=>emp.Name=== e.target.value)[0].Id:0);
                                              setJobOrders(jobOrders.map(od=>{return od}))
                                                if(!(e.target.value==='') && !(e.target.value===null) && (e.target.value.length>0) && assignedEmployees.filter(sf=>sf.Name===e.target.value).length<=0){
                                                  
                                                  // setAssignedEmployees(sp=>[...sp,EmployeesList.filter(ss=>ss.Name===e.target.value)[0]]);
                                                 //setSelectedVoucher(jobOrders.filter(ss=>ss.Id===e.target.value).length>0?EmployeesList.filter(ss=>ss.Name===e.target.value)[0].Id:selectedVoucher);
                                                 
                                                }
                                               }
                                                }
                                            options={
                                              !(EmployeesList === undefined) &&
                                              !(EmployeesList === null) &&
                                              EmployeesList.length > 0
                                                && EmployeesList.map((pro) => {
                                                      return pro.Name;
                                                    })
                                            }
                                            sx={{ width: "95%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={t("Assigned To")}

                                                //error={!from ? true : false}
                                              />
                                            )}
                                          />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }}
                      >
                        Customer: {Ord.CustomerName}<br></br>
                        Ordered By:{" "}
                        {Ord.OrderedByName ? Ord.OrderedByName : globalUser.Name}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        mt={1}
                        style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }}
                      >
                        <TextField
                          size="small"
                          label="Order Date"
                          type="date"
                          value={Ord.OrderDate?Ord.OrderDate:format(new Date(),"yyyy-MM-dd")}
                          onChange={(e) => {
                            Ord.OrderDate=e.target.value;
                            setJobOrders(jobOrders.map(od=>{return od}))
                          }}
                          sx={{ width: "70%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      {/*   <Grid item xs={6} style={{display:selectedVoucher===sup.Id?'block':'none'}}>
                <span>Billto: {customer}</span>
              </Grid> */}
                      <Grid
                        item
                        xs={6}
                        style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }}
                      >
                        Approved By:{" "}
                        {Ord.ApprovedByName}<br></br>
                        Order No:{" "}
                        {props.data?props.data.Id:Ord.Order?Ord.Order:0} 
                      </Grid>
                      <Grid item xs={12}  style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }}>
                      <TextField
                                            size="small"
                                            variant="standard"
                                            value={Ord.Remark}
                                            label="Remark"
                                            minRows={2}
                                            sx={{ width: "95%" }}
                                            onChange={(e) => {
                                              Ord.Remark=e.target.value;
                                              setJobOrders(jobOrders.map(od=>{return od}))
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                      </Grid>
                     
                      {/* <Grid item xs={6} mt={1} style={{display:selectedVoucher===sup.Id?'block':'none'}}>
                <Autocomplete
                  label="Store"
                  id="store"
                  size="small"
                  variant="outlined"
                  value={
                    storeList.filter((st) => st.Id === store).length > 0
                      ? storeList.filter((st) => st.Id === store)[0].Name
                      : ""
                  }
                  onSelect={(e) => {
                    setStore(
                      storeList.filter((cust) => cust.Name === e.target.value)
                        .length > 0
                        ? storeList.filter(
                            (cust) => cust.Name === e.target.value
                          )[0].Id
                        : ""
                    );
                  }}
                  options={
                    !(storeList === undefined) &&
                    !(storeList === null) &&
                    storeList.length > 0
                      ? storeList.map((item) => {
                          return item.Name;
                        })
                      : [""]
                  }
                  sx={{ width: "100%", height: "10px", marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Store"}
                    />
                  )}
                /> 
              </Grid>*/}

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                          style={{
                            display:
                              selectedVoucher === Ord.Id ? "block" : "none",
                          }}
                        >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>
                             
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  textAlign:'center'
                                }}
                              >
                                Remark
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                <IconButton
                                  size="small"
                                  id={Ord.Id}
                                  onClick={(e) => {
                                    if (
                                      joblines &&
                                      joblines.filter(
                                        (itt) =>((itt.Item === "") &&
                                          (parseInt(itt.Job) ===parseInt(Ord.Id)))
                                      ).length <= 0
                                    ) {
                                      let newLineItem = {};
                                      newLineItem.Item = "";
                                      newLineItem.Name = "";
                                      newLineItem.Job=Ord.Id;
                                      newLineItem.AssignedTo = Ord.AssignedTo;
                                      newLineItem.OrderQuantity = 0;
                                      newLineItem.AssignedToName = Ord.AssignedToName;


                                      setJoblines((xx) => [...xx,newLineItem]);
                                    } else {
                                      alert("First complete the data");
                                    }
                                    console.log(joblines)
                                  }}
                                >
                                  <Add sx={{color:theme.palette.primary.contrastText}} />
                                </IconButton>
                              </Grid>

                              {joblines && joblines!==null && joblines.length > 0 &&
                                joblines
                                  .filter(
                                    (it) =>(parseInt(it.AssignTo) === parseInt(Ord.AssignedTo) || (it.AssignTo===undefined && parseInt(it.Job) === parseInt(Ord.Id)))
                                  ).map((item, index) => {
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Autocomplete
                                            label="Items"
                                            id="Items"
                                            size="small"
                                            variant="standard"
                                            value={item.Name}
                                            onSelect={(e) => {
                                          
                                              if (joblines && joblines.length>0 &&
                                                joblines.filter(
                                                  (li) =>
                                                    (li.Name === e.target.value )
                                                ).length > 0
                                              ) {
                                                item.Item = "";
                                                item.Name = "";
                                                item.OrderQuantity = 0;
                                                setJoblines(
                                                  joblines.map((litem) => {
                                                    return litem;
                                                  })
                                                );
                                                //alert('The selected item is already registered')
                                                return;
                                              } else {
                                                let selectedProduct =
                                                  productsList.filter(
                                                    (selItem) =>
                                                      selItem.Name ===
                                                      e.target.value
                                                  );

                                                if (
                                                  selectedProduct &&
                                                  selectedProduct.length > 0
                                                ) {
                                                  let producedQty=0;
                                                  joblines.filter(jl=>jl.Item===selectedProduct[0].Item).map(itt=>{
                                                    producedQty=parseFloat(producedQty) + parseFloat(itt.Quantity)
                                                  });
                                                  item.OrderQuantity =
                                                    selectedProduct[0]
                                                      .Quantity!==0
                                                      ? (parseFloat(selectedProduct[0]
                                                          .Quantity)-parseFloat(producedQty))
                                                      : 1;
                                                 
                                                  item.Item =
                                                    selectedProduct[0].Item;
                                                  item.Name =
                                                    selectedProduct[0].Name;
                                                 item.AssignedTo=Ord.AssignedTo;
                                                 item.AssignedToName=Ord.AssignedToName;
                                                 item.Job=Ord.Id;
                                                    setJoblines(ji=>[...ji,item])
                                                    setJoblines(
                                                    joblines.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                } else {
                                                  item.OrderQuantity = 0;
                                                  item.AssignedTo=Ord.AssignedTo;
                                                  item.AssignedToName=Ord.AssignedToName;
                                                  item.Item = "";
                                                  item.Name = "";

                                                  setJoblines(
                                                    joblines.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                }
                                              }
                                            }}
                                            options={
                                              !(productsList === undefined) &&
                                              !(productsList === null) &&
                                              productsList.length > 0
                                                ? productsList.map((it) => {
                                                      return it.Name;
                                                    })
                                                : [""]
                                            }
                                            sx={{ width: "95%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={item.Name}

                                                //error={!from ? true : false}
                                              />
                                            )}
                                          />
                                        </Grid>

                                        <Grid item xs={2}>
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            value={item.OrderQuantity}
                                            maxRows={4}
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                              item.OrderQuantity = e.target.value;
                                              setJoblines(
                                                joblines.map((litem) => {
                                                  return litem;
                                                })
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>

                                        <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            value={item.Remark}
                                            sx={{ width: "95%" }}
                                            onChange={(e) => {
                                              item.Remark=e.target.value
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                         
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Tooltip title="remove">
                                            <Button
                                              endIcon={<Delete width="10px" />}
                                              onClick={() => {
                                                if(joblines.filter((litem) =>
                                                    ((litem.Item ===
                                                      item.Item) &&
                                                    (parseInt(
                                                      litem.AssignedTo
                                                    ) ===
                                                      parseInt(item.AssignedTo)))
                                                ).Id){
                                                
                                                handleLineItemDelete(
                                                  joblines.filter(
                                                    (litem) =>
                                                      litem.Item ===
                                                        item.Item &&
                                                      parseInt(
                                                        litem.AssignedTo
                                                      ) ===
                                                        parseInt(item.AssignedTo)
                                                  )[0].Id
                                                )  
                                              };
                                             
                                             
                                                /*    setJoblines(
                                    joblines.filter(
                                      (litem) => !(litem.Item === item.Item && parseInt(litem.Supplier)===parseInt(item.Supplier))
                                    )
                                  ); */
                                                if (
                                                  joblines.filter(
                                                    (litem) =>                                                      
                                                        parseInt(
                                                          litem.AssignedTo
                                                        ) ===
                                                          parseInt(
                                                            Ord.AssignedTo
                                                          )                                                      
                                                  ).length <= 0
                                                ) {
                                                  //setAssignedEmployees(EmployeesList.filter(ssp=>parseInt(ssp.Id)!==parseInt(sup.Id)));
                                                  /* setOpen(false);
                                                   let newLineItem = {};
                                    newLineItem.Item = "";
                                    newLineItem.Name = "";
                                    newLineItem.Supplier = sup.Id;
                                    newLineItem.UnitCost = 0;
                                    newLineItem.Quantity = 0;
                                    newLineItem.UnitPrice = 0;
                                    newLineItem.TotalPrice = 0;
                                    setJoblines((xx) => [...xx, newLineItem]); */
                                                }
                                              }}
                                            ></Button>
                                          </Tooltip>
                                        </Grid>
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                            


                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {joblines && joblines!==null && joblines.length > 0 &&
                              joblines.map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <Autocomplete
                                                label="Items"
                                                id="Items"
                                                size="small"
                                                variant="standard"
                                                value={item.Name}
                                                onSelect={(e) => {
                                                  if (
                                                    joblines.filter(
                                                      (li) =>
                                                        li.Name ===
                                                        e.target.value
                                                    ).length > 0
                                                  ) {
                                                    item.Item = "";
                                                    item.Name = "";
                                                    item.OrderQuantity = 0;
                                                    item.AssignedTo = Ord.AssignTo;
                                                  
                                                    setJoblines(
                                                      joblines.map((litem) => {
                                                        return litem;
                                                      })
                                                    );

                                                    return;
                                                  } else {
                                                    let selectedProduct =
                                                      productsList.filter(
                                                        (selItem) =>
                                                          selItem.Name ===
                                                          e.target.value
                                                      );
                                                    if (
                                                      selectedProduct &&
                                                      selectedProduct.length > 0
                                                    ) {
                                                      item.OrderQuantity =
                                                        selectedProduct[0]
                                                          .DefaultOrderQty!==0
                                                          ? selectedProduct[0]
                                                              .DefaultOrderQty
                                                          : 1;
                                                     
                                                      item.Item =
                                                        selectedProduct[0].Item;
                                                      item.Name =
                                                        selectedProduct[0].Name;
                                                      item.AssignedTo = Ord.AssignTo;
                                                      
                                                      setJoblines(
                                                        joblines.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    } else {
                                                      item.OrderQuantity = 0;
                                                  
                                                      item.Item = "";
                                                      item.Name = "";
                                                      item.AssignedTo = Ord.AssignTo;
                                                      setJoblines(
                                                        joblines.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    }
                                                  }
                                                }}
                                                options={
                                                  !(
                                                    productsList === undefined
                                                  ) &&
                                                  !(productsList === null) &&
                                                  productsList.length > 0
                                                    ? productsList.map((it) => {
                                                        return it.Name;
                                                      })
                                                    : [""]
                                                }
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label={item.Name}

                                                    //error={!from ? true : false}
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.OrderQuantity}
                                                maxRows={4}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.OrderQuantity =
                                                    e.target.value;
                                                  setJoblines(
                                                    joblines.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                           
                                            <Grid item xs={4}>
                                              Remark :
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                             {item.Remark}
                                            </Grid>
                                            <Grid item xs={12}>
                                              <hr></hr>
                                              Remove: {"   "}
                                              <Tooltip title="remove">
                                                <Button
                                                  endIcon={
                                                    <Delete width="10px" />
                                                  }
                                                  onClick={() => {
                                                    handleLineItemDelete(
                                                      joblines.filter(
                                                        (litem) =>
                                                          litem.Item ===
                                                          item.Item
                                                      )[0].Id
                                                    );
                                               
                                                    if (
                                                      joblines.filter(
                                                        (litem) =>
                                                          !(
                                                            litem.Item ===
                                                            item.Item
                                                          )
                                                      ).length <= 0
                                                    ) {
                                                      let newLineItem = {};
                                                      newLineItem.Item = "";
                                                      newLineItem.Name = "";
                                                      newLineItem.AssignedTo = Ord.AssignTo;
                                                      newLineItem.AssignedToName = Ord.AssignedToName;
                                                      newLineItem.OrderQuantity = 0;
                                                     
                                                      setJoblines((xx) => [
                                                        ...xx,
                                                        newLineItem,
                                                      ]);
                                                    }
                                                  }}
                                                ></Button>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                          
                          </Grid>
                        </Grid>
                       
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button variant="standard">
                
                <ConsumptionModel
                  icon={<div  style={{ fontSize: "10px" }}>Output</div>}
                  data={jobOrders}
                  LineItems={props.Jobslines}
                  Type="JobOrder"
                />

              </Button>|
          <Button variant="standard">
                
                <ConsumptionModel
                  icon={<div  style={{ fontSize: "10px" }}>Consumption</div>}
                  data={jobOrders}
                  LineItems={props.Jobslines}
                  Type="JobOrder"
                />

              </Button>|
              <Button variant="standard">
                
                <InvoicePreview
                  icon={<div  style={{ fontSize: "10px" }}>Preview</div>}
                  data={jobOrders}
                  LineItems={props.Jobslines}
                  Type="JobOrder"
                />
              </Button>|
             
              <Button
              variant="standard"
                style={{ fontSize: "10px"}}
                /*  endIcon={<Send />} */
                autoFocus
                onClick={() =>
                  handleSubmit(
                    "Request",
                    joblines.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Request
              </Button>
              |
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                /*  endIcon={<Send />} */
                autoFocus
                onClick={() =>
                  handleSubmit(
                    "Approved",
                    joblines.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Approved
              </Button>
              | <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                /*  endIcon={<Send />} */
                autoFocus
                onClick={() =>
                  handleSubmit(
                    "Completed",
                    joblines.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Completed
              </Button>
              |
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                /* endIcon={<Send />} */
                autoFocus
                onClick={() => handleDelete(props.order.OrderId)}
              >
                Delete
              </Button>
              |
           
          <Button
          variant="standard"
            style={{ fontSize: "10px" }}
            /* endIcon={<Close />} */
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
