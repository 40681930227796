import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  Stack,
  IconButton,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import {
  EditSharp,
  AddSharp,
  FileDownload,
  FileUpload,
  RefreshSharp,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { useState, useEffect, useMemo,useContext } from "react";
import ItemsModel from "./ItemsModel";
import * as XLSX from "xlsx";
import axios from "../../axiosInstances";
import ItemModel from "./ItemsModel";
import { useTranslation } from "react-i18next";

import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

function ItemsComp(props) {
  const navigate = useNavigate();
  const theme=useTheme();
  const [update, setUpdate] = useState(false);
  const [itemCategoriesList, setitemCategoriesList] = useState([]);
  const [itemsList, setitemsList] = useState([]);
  const { setPageHeader,globalUser,globalToken }=useContext(ShoppingCartContext)


  const { t, i18n } = useTranslation();

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
        size: "150"
      },
      {
        header: t("Name"),
        accessorKey: "Name",
      },
     /*  {
        header: "Abbriviation",
        accessorKey: "Abbriviation",
      }, */
      {
        header: "Description",
        accessorKey: "Description",
      },
      {
        header: "Category",
        accessorKey: "CategoryName",
      },
      {
        header: "UOM",
        accessorKey: "UOM",
      },
     /*  {
        header: "Picture",
        accessorKey: "Picture",
      }, */
      {
        header: "VAT Inclusive",
        accessorKey: "VATInclusive",
        size: "150"
      },
      {
        header: "Remark",
        accessorKey: "Remark",
      },
    ],
    []
  );

  const loadItemsList = () => {
    axios
      .get("/items/getAllItemsPerParent?Parent="+globalUser.Parent , {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
    
        if (response.data.data) {
          setitemsList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
/*   const loadItemCategoriesList = () => {
    axios
      .get("/itemCategories", {
        headers: {
          accessorToken: sessionStorage.getItem("accessorToken"),
        },
      })
      .then((response) => {
        if (response.data.data) {
          setitemCategoriesList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  }; */

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    var jsonData = XLSX.utils.sheet_to_json(worksheet);

    var fdata = new FormData();
    fdata.append("JsonData", JSON.stringify(jsonData));
    await axios.post("/items/Excel", fdata, {
      headers: { "Content-Type": "application/json" ,
          token: globalToken,
       },
    });
  };
  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(itemsList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "items");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "itemsdata.xlsx");
  }

  useEffect(() => {
    if(!globalUser){
      navigate('/');
      return;
    }
    setPageHeader('Products')
    loadItemsList();
  }, [update]);
  return (
    <Stack direction="column" spacing={2}>
      {!itemsList ? (
        <div>Loading...</div>
      ) : (
        <Grid container>
         

          <Grid item className="hideInMobile" m={1}>
            <MaterialReactTable
              columns={columns}
              data={itemsList}
              initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
              enableColumnFilters
             // enableRowSelection //enable some features
              //enableColumnOrdering
              enableColumnResizing
              enableRowNumbers
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
              
                    <Button      size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                      <ItemModel
                        icon={
                          <div>Add</div>
                        }
                        data=""
                        Title="ADD NEW ITEM"
                        setParentUpdate={setUpdate}
                        ParentUpdate={update}
                      />
                    </Button>|
                
                    <Button      size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                      <label htmlFor="file">
                      Upload
                      </label>
                      <input
                        id="file"
                        name="file"
                        hidden
                        type="file"
                        onChange={handleUploadFile}
                      />
                    </Button>|
                
                    <Button size="small"
                    variant="standard" type="file" onClick={handleDownloadFile}  sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                     Download
                    </Button>|
                    <Button onClick={() => setUpdate(!update)}      size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                    Refresh
                    </Button>
              
                </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  <Tooltip title={t("modify")}>
                    <IconButton>
                      <ItemsModel
                        icon={
                          <EditSharp
                            style={{ width: "20px", height: "20px" }}
                          />
                        }
                        data={table.row.original}
                        Title="EDIT ITEM"
                        setParentUpdate={setUpdate}
                        ParentUpdate={update}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            ></MaterialReactTable>
          </Grid>
          <Grid item xs={12} className="hideInFull" mt={1}>
          <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
              
              <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                <ItemModel
                  icon={
                    <div>Add</div>
                  }
                  data=""
                  Title="ADD NEW ITEM"
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />
              </Button>|
          
              <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                <label htmlFor="file">
                Upload
                </label>
                <input
                  id="file"
                  name="file"
                  hidden
                  type="file"
                  onChange={handleUploadFile}
                />
              </Button>|
          
              <Button size="small"
                    variant="standard" type="file" onClick={handleDownloadFile} sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
               Download
              </Button>|
              <Button onClick={() => setUpdate(!update)}      size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
              Refresh
              </Button>
        
          </Box>
          </Grid>
          {!(itemsList === undefined) &&
            itemsList.length > 0 &&
            itemsList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5}  m={2} className="hideInFull">
                   <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={'Name: '+item.Name} //.substring(0, 20)}
                    />

                    <CardContent>
                    <Typography variant="body2" align="left">
                        Category: {item.CategoryName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Inventory: {item.Quantity}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Default Order Qty: {item.DefaultOrderQty}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Unit Sales Price: {item.Price}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                            <ItemsModel
                              icon={
                                <EditSharp
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                              data={item}
                              Title="EDIT STOCK"
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      )}
    </Stack>
  );
}

export default ItemsComp;
