import { Button, Grid, Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
//import ReactPDF from '@react-pdf/renderer';
import axios from "../../axiosInstances";
import { useEffect, useState, useContext } from "react";
import { format } from "date-fns";

import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import { ArrowDropDown, AssessmentOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

export default function ReportInterface() {
  const theme = useTheme();
  const [totalAmount, setTotalAmount] = useState(0);

  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [item, setItem] = useState("");
  const [itemVisible, setItemVisbile] = useState(false);
  //const [itemCategory,setItemCategory]=useState('')

  const {
    globalUser,
    globalRoleSecurity,
    setPageHeader,
    globalToken,
    allItems,
  } = useContext(ShoppingCartContext);

  const [tableHeader, setTableHeader] = useState("");
  const [expandReport, setExpandReport] = useState({
    State: false,
    Module: "Purchase",
    Type: "General",
    Filter: true,
  });

  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      lineHeight: 1.5,
      padding: 15,
      paddingBottom: 50,
      height:'800',
    },
    header: {
      alignContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: 14,
      margin: 2,
    },
    orderHeader: {
      alignContent: "center",
      alignItems: "center",
      fontWeight: "bold",
      fontSize: 12,
      margin: 2,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      margin: 2,
    },
    tableFilter: {
      flexDirection: "row",
      width: "100%",
    },
    tableRow: {
      margin: 0,
      flexDirection: "row",
      width: "100%",
      borderTopWidth: 1,
    },
    tableRowDetail: {
      margin: 0,
      flexDirection: "column",
      textAlign: "center",
      width: "100%",
      paddingLeft: 6,
      borderWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },

    tableCell: {
      margin:0,
      paddingLeft: 5,
      fontSize: 8.25,
      width: "16%",
      height: 15,
      borderLeftWidth:1,
      borderRightWidth:1
    },
    tableCellWOBorder: {
      margin:0,
      paddingLeft: 5,
      fontSize: 8.25,
      width: "16%",
      height: 15,
    },
    tableCellTotals: {
      margin:0,
      paddingLeft: 5,
      fontSize: 8.25,
      flex:1,
      height: 15,
      borderBottomWidth:1
    },
    tableCellDetail: {
      margin: 0,
      fontSize: 8.25,
      width: "16%",
      height: 15,
      borderLeft: 1,
    },
    tableCellDetailTotals: {
      margin: 0,
      fontSize: 8.25,
      width: "16%",
      height: 15,
      border: 1,
      textAlign: "right",
      paddingRight: 3,
    },
    pageNumber: {
      position:'absolute',
      marginTop: 800,
      paddingLeft: 10,
      width: "800",
      color: "gray",
      borderTop: 1,
    },
    logo: {
      width: "100%",
      height: 60,
      marginLeft: "auto",
      marginRight: "auto",
    },
  });
  // Create Document Component
  const MyGeneralDocument = (props) => (
    <Document rotate={90}>
      <Page size="A4" style={styles.page}>
        {!(props.DataList === undefined) &&
        !(props.DataList === null) &&
        props.DataList.length > 0 ? (
          <>
            <View style={styles.header}>
              <Image style={styles.logo} src={globalUser.CompanyHeader} />
            </View>
            <View style={[styles.header, { backgroundColor: "lightgray" }]}>
              <Text>{tableHeader}</Text>{" "}
            </View>
            <View style={styles.tableFilter}>
              <Text style={styles.tableCellWOBorder}>Filter</Text>
              <Text style={styles.tableCellWOBorder}>Start Date: {startDate}</Text>
              <Text style={styles.tableCellWOBorder}>End Date: {endDate}</Text>
              <Text style={styles.tableCellWOBorder}> </Text>
              <Text style={styles.tableCellWOBorder}> </Text>
              <Text style={styles.tableCellWOBorder}>
                Print Date :{format(new Date(), "yyyy-MM-dd")}{" "}
              </Text>
            </View>
            <View style={styles.table}>
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "lightgray",
                    fontWeight: "bold",
                    fontSize: "20",
                  },
                ]}
              >
                <Text style={[styles.tableCell, { marginLeft: 5,borderLeftWidth:0 }]}>
                  Order Id
                </Text>
                <Text style={styles.tableCell}>Order Date</Text>
                <Text style={styles.tableCell}>Supplier</Text>
                <Text style={styles.tableCell}>Amount</Text>
                <Text style={styles.tableCell}>VAT Amount</Text>
                <Text style={[styles.tableCell,{borderRightWidth:0}]}>Total Amount</Text>
              </View>
              {props.DataList.map((ord, index) => {
                return (
                  <View style={styles.tableRow}>
                    <Text style={[styles.tableCell, { marginLeft: 5,borderLeftWidth:0  }]}>
                      {ord.OrderId}
                    </Text>
                    <Text style={styles.tableCell}>{ord.VoucherDate}</Text>
                    <Text style={styles.tableCell}>{ord.SupplierName}</Text>
                    <Text style={styles.tableCell}>
                      {ord.Amount
                        ? ord.Amount?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text style={styles.tableCell}>
                      {ord.VATAmount
                        ? ord.VATAmount?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text style={[styles.tableCell,{borderRightWidth:0 }]}>
                      {ord.TotalAmount
                        ? ord.TotalAmount?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                  </View>
                );
              })}
              <View style={[styles.tableRow, { backgroundColor: "lightgray" }]}>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text
                  style={[styles.tableCellWOBorder,{fontSize: 10, fontWeight: "bold"} ]}>
                  Total
                </Text>

                <Text
                  style={[styles.tableCellWOBorder,
                    { fontSize: 10,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }]
                  }
                >
                  {totalAmount?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>
            </View>
          </>
        ) : (
          <View style={styles.header}>
            <Text>
              {tableHeader.length > 0
                ? "There is no data for " +
                  tableHeader +
                  " between " +
                  startDate +
                  " and " +
                  endDate
                : "Select the order type in the left"}
            </Text>{" "}
          </View>
        )}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            "Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: " +
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
  const MyDetailDocument = (props) => (
    <Document rotate={90}>
      <Page size="A4" style={styles.page}>
        {!(props.Orders === undefined) &&
        !(props.Orders === null) &&
        props.Orders.length > 0 ? (
          <>
            <View style={styles.header}>
              <Image style={styles.logo} src={globalUser.CompanyHeader} />
            </View>
            <View style={[styles.header, { backgroundColor: "lightgray" }]}>
              <Text>{tableHeader}</Text>{" "}
            </View>
            <View style={styles.tableFilter}>
              <Text style={styles.tableCellWOBorder}>Filter</Text>
              <Text style={styles.tableCellWOBorder}>Start Date: {startDate}</Text>
              <Text style={styles.tableCellWOBorder}>End Date: {endDate}</Text>
              <Text style={styles.tableCellWOBorder}> </Text>
              <Text style={styles.tableCellWOBorder}> </Text>
              <Text style={styles.tableCellWOBorder}>
                {" "}
                Print Date :{format(new Date(), "yyyy-MM-dd")}{" "}
              </Text>
            </View>
            <View style={styles.table}>
              {props.Orders.map((ord, index) => {
                return (
                  <>
                    <View
                      style={[
                        styles.tableRow,
                        { backgroundColor: "lightgray" },
                      ]}
                    >
                      <Text style={styles.orderHeader}>
                        {"Order Id: " +
                          ord.OrderId +
                          "  Order Date: " +
                          ord.VoucherDate +
                          "   Supplier: " +
                          ord.SupplierName}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableRow,
                        {
                          backgroundColor: "lightgray",
                          fontWeight: "bold",
                          fontSize: "20",
                        },
                      ]}
                    >
                      <Text
                        style={[styles.tableCellDetail, { paddingLeft: 3 }]}
                      >
                        Item
                      </Text>
                      <Text style={styles.tableCell}>Item Name</Text>
                      <Text style={styles.tableCell}>UOM</Text>
                      <Text style={styles.tableCell}>Quantity</Text>
                      <Text style={styles.tableCell}>Unit Price</Text>
                      <Text style={styles.tableCellWOBorder}>Total Price</Text>
                    </View>
                    {props.LineItems.filter(
                      (it) => parseInt(it.Reference) === parseInt(ord.OrderId)
                    ).map((lItem, index) => {
                      return (
                        <View style={styles.tableRow}>
                          <Text
                            style={[styles.tableCellDetail, { paddingLeft: 3 }]}
                          >
                            {lItem.Item}
                          </Text>
                          <Text style={styles.tableCell}>{lItem.Name}</Text>
                          <Text style={styles.tableCell}>{lItem.UOM}</Text>
                          <Text style={styles.tableCell}>
                            {lItem.Quantity
                              ? lItem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})
                              : "0.00"}
                          </Text>
                          <Text style={styles.tableCell}>
                            {lItem.UnitPrice
                              ? lItem.UnitPrice?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})
                              : "0.00"}
                          </Text>
                          <Text style={styles.tableCellWOBorder}>
                            {lItem.TotalPrice
                              ? lItem.TotalPrice?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})
                              : "0.00"}
                          </Text>
                        </View>
                      );
                    })}
                    <View
                      style={[
                        styles.tableRow,
                        { fontWeight: "bold", fontSize: "20" },
                      ]}
                    >
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellDetailTotals}>
                        Sub Total
                      </Text>
                      <Text style={[styles.tableCellTotals]}>
                        {ord.Amount}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableRow,
                        { fontWeight: "bold", fontSize: "20", border: 0 },
                      ]}
                    >
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellDetailTotals}>
                        VAT Amount
                      </Text>
                      <Text style={styles.tableCellTotals}>
                        {ord.VATAmount}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableRow,
                        { fontWeight: "bold", fontSize: "20", border: 0 },
                      ]}
                    >
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellWOBorder}></Text>
                      <Text style={styles.tableCellDetailTotals}>
                        Total Amount
                      </Text>
                      <Text style={styles.tableCellTotals}>
                        {ord.TotalAmount}
                      </Text>
                    </View>
                  </>
                );
              })}
              <View style={[styles.tableRow, { backgroundColor: "lightgray" }]}>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text style={styles.tableCellWOBorder}> </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10, fontWeight: "bold" },
                  ]}
                >
                  Grand Total
                </Text>

                <Text
                  style={[
                    styles.tableCellTotals,
                    {
                      fontSize: 10,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    },
                  ]}
                >
                  {totalAmount?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>
            </View>
          </>
        ) : (
          <View style={styles.header}>
            <Text>
              {tableHeader.length > 0
                ? "There is no data for " +
                  tableHeader +
                  " between " +
                  startDate +
                  " and " +
                  endDate
                : "Select the order type in the left"}
            </Text>{" "}
          </View>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            "Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: " +
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
  const MyLineItemsDocument = () => (
    <Document rotate={90}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>Text</Text>
        </View>
        <View style={styles.header}>
          {globalUser.CompanyHeader && (
            <Image style={styles.logo} src={globalUser.CompanyHeader} />
          )}
        </View>
        <View style={[styles.header, { backgroundColor: "gray" }]}>
          <Text>{tableHeader}</Text>{" "}
        </View>
        <View style={styles.tableFilter}>
          <Text style={styles.tableCell}>Filter</Text>
          <Text style={styles.tableCell}>Start Date: {startDate}</Text>
          <Text style={styles.tableCell}>End Date: {endDate}</Text>

          <Text style={styles.tableCell}>Item </Text>
          <Text style={styles.tableCell}>
            {allItems.filter((it) => parseInt(it.Item) === parseInt(item))
              .length > 0
              ? allItems.filter((it) => parseInt(it.Item) === parseInt(item))[0]
                  .Name
              : ""}{" "}
          </Text>
          <Text style={styles.tableCell}>
            Print Date :{format(new Date(), "yyyy-MM-dd")}{" "}
          </Text>
        </View>
        <View style={styles.table}>
          <View
            style={[
              styles.tableRow,
              {
                backgroundColor: theme.palette.primary.main,
                fontWeight: "bold",
                fontSize: "20",
                margin: 6,
              },
            ]}
          >
            <Text style={styles.tableCell}>Item Name</Text>
            <Text style={styles.tableCell}>Quantity</Text>
            <Text style={styles.tableCell}>Unit Price</Text>
            <Text style={styles.tableCell}>Total Price</Text>
          </View>
          {dataList.map((lItem, index) => {
            return (
              <View style={[styles.tableRow, { margin: 6 }]}>
                <Text style={styles.tableCell}>{lItem.Name}</Text>
                <Text style={styles.tableCell}>
                  {lItem.Quantity
                    ? lItem.Quantity?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </Text>
                <Text style={styles.tableCell}>
                  {lItem.UnitPrice
                    ? lItem.UnitPrice?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </Text>
                <Text style={styles.tableCell}>
                  {lItem.TotalPrice
                    ? lItem.TotalPrice?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </Text>
              </View>
            );
          })}

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              "Page: " + `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
  const MyAdministratorDocument = (props) => (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {!(props.DataList === undefined) &&
        !(props.DataList === null) &&
        props.DataList.length > 0 ? (
          <View style={{flex:1}}>
            <View style={styles.header}>
              <Image style={styles.logo} src={globalUser.CompanyHeader} />
            </View>
            <View style={[styles.header, { backgroundColor: "gray",color:'white' }]}>
              <Text>{tableHeader}</Text>{" "}
            </View>
            <View style={styles.table}>
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "gray",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "20",
                  },
                ]}
              >
                <Text style={styles.tableCell}>Id</Text>
                <Text style={[styles.tableCell,{width:'35%'}]}>Name</Text>
                <Text style={[styles.tableCell,{width:'25%'}]}>User Name</Text>
                <Text style={[styles.tableCell,{width:'25%'}]}>Balance</Text>
              </View>
              {props.DataList.map((item, index) => {
                return (
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>{item.Id}</Text>
                    <Text style={[styles.tableCell,{width:'35%'}]}>{item.Name}</Text>
                    <Text style={[styles.tableCell,{width:'25%'}]}>{item.UserName}</Text>
                    <Text style={[styles.tableCell,{width:'25%'}]}>{item.Balance}</Text>
                  </View>
                );
              })}

              
            </View>
            
            <Text
                fixed
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  "Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: " +
                  `${pageNumber} / ${totalPages}`
                }
              />
            

          </View>
        ) : (
          <View style={styles.header}>
            <Text>
              {tableHeader.length > 0
                ? "There is no data for " +
                  tableHeader +
                  " between " +
                  startDate +
                  " and " +
                  endDate
                : "Select the order type in the left"}
            </Text>{" "}
          </View>
        )}
      </Page>
    </Document>
  );
  const MyAdministratorDetailDocument = (props) => (
    <Document rotate={90}>
      <Page size="A4" style={styles.page}>
        {!(props.DataList === undefined) &&
        !(props.DataList === null) &&
        props.DataList.length > 0 ? (
          <>
            <View style={styles.header}>
              <Image style={styles.logo} src={globalUser.CompanyHeader} />
            </View>

            <View style={styles.table}>
              <View
                style={[
                  styles.tableRow,
                  {
                    backgroundColor: "gray",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "20",
                  },
                ]}
              >
                {" "}
                <Text style={styles.tableCell}>Id</Text>
                <Text style={styles.tableCell}>Id</Text>
                <Text style={styles.tableCell}>Name</Text>
                <Text style={styles.tableCell}>User Name</Text>
              </View>
              {props.DataList.map((item, index) => {
                return (
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>{item.Id}</Text>
                    <Text style={styles.tableCell}>{item.Name}</Text>
                    <Text style={styles.tableCell}>{item.UserName}</Text>
                  </View>
                );
              })}
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: "gray", color: "white" },
                ]}
              >
                <Text style={styles.tableCell}> </Text>
                <Text style={styles.tableCell}> </Text>
                <Text style={styles.tableCell}> </Text>
                <Text style={styles.tableCell}> </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10, fontWeight: "bold" },
                  ]}
                >
                  Total
                </Text>

                <Text
                  style={[
                    styles.tableCell,
                    {
                      fontSize: 10,
                      fontWeight: "bold",
                      textDecoration: "underline",
                    },
                  ]}
                >
                  {totalAmount?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>

              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  "Designed By ARMA IT Solution PLC. Mobile: 251-913-4040-58   Email: ArmaItSolution@gmail.com         Page: " +
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </View>
          </>
        ) : (
          <View style={styles.header}>
            <Text>
              {tableHeader.length > 0
                ? "There is no data for " +
                  tableHeader +
                  " between " +
                  startDate +
                  " and " +
                  endDate
                : "Select the order type in the left"}
            </Text>{" "}
          </View>
        )}
      </Page>
    </Document>
  );

  const [dataList, setDataList] = useState([]);
  const [lineItemsList, setLineItemsList] = useState([]);
  const [reportType, setReportType] = useState("General");
  const loadVouchersList = (type, status) => {
    var tempTotal = 0;
    setDataList([]);
    axios
      .get(
        "/Vouchers/getReportByDateAndType?Parent=" +
          globalUser.Parent +
          "&StartDate=" +
          startDate +
          "&EndDate=" +
          endDate +
          "&Status=" +
          status +
          "&Type=" +
          type,
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
          response.data.data.map((ord, index) => {
            tempTotal =
              parseFloat(tempTotal) +
              parseFloat(ord.TotalAmount.toLocaleString().replaceAll(",", ""));
          });
          setTotalAmount(tempTotal);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadLineItemsList = (type, status) => {
    setDataList([]);
    axios
      .get(
        "/lineitems/getReportByDate?Parent=" +
          globalUser.Parent +
          "&StartDate=" +
          startDate +
          "&EndDate=" +
          endDate +
          "&Status=" +
          status +
          "&Type=" +
          type,
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setLineItemsList(response.data.data);
          //setLineItemsList(response.data.data.LineItems);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadDetailVouchersList = (type, status) => {
    setDataList([]);
    var tempTotal = 0;
    axios
      .get(
        "/Vouchers/getDetailReportByDateAndType?Parent=" +
          globalUser.Parent +
          "&StartDate=" +
          startDate +
          "&EndDate=" +
          endDate +
          "&Status=" +
          status +
          "&Type=" +
          type,
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data.Orders);
          setLineItemsList(response.data.data.LineItems);
          console.log(response.data.data.LineItems);
          response.data.data.Orders.map((ord, index) => {
            tempTotal =
              parseFloat(tempTotal) +
              parseFloat(ord.TotalAmount.toLocaleString().replaceAll(",", ""));
          });
          setTotalAmount(tempTotal);
        } else {
          alert("log out and log in again");
        }
      });
  };

  const loadDetailItemHistory = (item) => {
    setDataList([]);
    axios
      .get(
        "/items/getItemHistory?Item=" +
          item +
          "&StartDate=" +
          startDate +
          "&EndDate=" +
          endDate +
          "&Parent=" +
          globalUser.Parent,
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
        }
      });
  };
  const loadCustomersList = (type) => {
    setDataList([]);
    axios
      .get(
        "/suppliers/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=" +
          type,
        {
          headers: {
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
        }
      });
  };
  const loadEmployeesList = () => {
    setDataList([]);
    axios
      .get("/employees/getByParent?Parent=" + globalUser.Parent, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
        }
      });
  };
  const loadItemsList = () => {
    setDataList([]);
    axios
      .get("/items", {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
        }
      });
  };
  const loadItemCategoriesList = () => {
    setDataList([]);
    axios
      .get("/itemCategories", {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
        }
      });
  };
  const loadStoresList = () => {
    setDataList([]);
    axios
      .get("/stores/getByParent?Parent="+globalUser.Parent, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
        }
      });
  };
  const loadStockBalancesList = () => {
    setDataList([]);
    axios
      .get("/stockbalances/getByParent?Parent="+globalUser.Parent, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setDataList(response.data.data);
        }
      });
  };
  useEffect(() => {
    // loadVouchersList();
    setPageHeader("Reports");
  }, []);
  return (
    <Grid
      container
      mt={1}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      <Grid item xs={2} border={1}>
        <Button
          variant="contained"
          fullWidth
          disabled={true}
          style={{
            fontWeight: "bold",
            color: theme.palette.primary.contrastText,
          }}
          startIcon={<AssessmentOutlined />}
        >
          Reports
        </Button>
        {globalRoleSecurity.filter(
          (sec) =>
            sec.Functionality === "Purchase Reports" && !(sec.Security === 0)
        ).length > 0 && (
          <Grid container p={0.5}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: !expandReport.State,
                    Module: "Purchase",
                    Type: "",
                    Filter: false,
                  });
                }}
              >
                Purchase
              </Button>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Purchase" && expandReport.State
                    ? "block"
                    : "none",
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Purchase",
                    Type: expandReport.Type === "General" ? "" : "General",
                    Filter: true,
                  });
                }}
              >
                General Reports
              </Button>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={4}
              style={{
                display:
                  expandReport.Module === "Purchase" &&
                  expandReport.Type === "General" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
             {/*    <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Draft Orders List");
                      loadVouchersList("Purchase", "Draft");
                      setReportType("General");
                    }}
                  >
                    Purchase Draft
                  </Button>
                </Grid> */}
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Requests List");
                      loadVouchersList("Purchase", "Request");
                      setReportType("General");
                    }}
                  >
                    Purchase Requests
                  </Button>
                </grid>

                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Orders List");
                      loadVouchersList("Purchase", "Order");
                      setReportType("General");
                    }}
                  >
                    Purchase Orders
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Received Orders List");
                      loadVouchersList("Purchase", "Received");
                      setReportType("General");
                    }}
                  >
                    Purchase Received
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Return Orders List");
                      loadVouchersList("Purchase", "Return");
                      setReportType("General");
                    }}
                  >
                    Purchase Return
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Cleared List");
                      loadVouchersList("Purchase", "Cleared");
                      setReportType("General");
                    }}
                  >
                    Purchase Cleared
                  </Button>
                </grid>

                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Payment List");
                      loadVouchersList("Purchase", "Payment");
                      setReportType("General");
                    }}
                  >
                    Purchase Payment
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Items List");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Purchased Items
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Suppliers History");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Suppliers History
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Suppliers Balance");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Suppliers Balance
                  </Button>
                </grid>
              </Grid>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Purchase" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Purchase",
                    Type: expandReport.Type === "Detail" ? "" : "Detail",
                    Filter: true,
                  });
                }}
              >
                Detail Reports
              </Button>
            </Grid>
            <Grid
              item
              p={1}
              pl={4}
              style={{
                display:
                  expandReport.Module === "Purchase" &&
                  expandReport.Type === "Detail" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
              {/*   <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Purchase Draft Orders List");
                      loadDetailVouchersList("Purchase", "Draft");
                      setReportType("Detail");
                    }}
                  >
                    Detail Draft
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Purchase Requests List");
                      loadDetailVouchersList("Purchase", "Request");
                      setReportType("Detail");
                    }}
                  >
                    Detail Requests
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Purchase Orders List");
                      loadDetailVouchersList("Purchase", "Order");
                      setReportType("Detail");
                    }}
                  >
                    Detail Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Purchase Received Orders List");
                      loadDetailVouchersList("Purchase", "Received");
                      setReportType("Detail");
                    }}
                  >
                    Detail Received
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Purchase Return Orders List");
                      loadDetailVouchersList("Purchase", "Return");
                      setReportType("Detail");
                    }}
                  >
                    Detail Return
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Purchase Cleared List");
                      loadDetailVouchersList("Purchase", "Cleared");
                      setReportType("Detail");
                    }}
                  >
                    Detail Cleared
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Purchase Payment List");
                      loadDetailVouchersList("Purchase", "Payment");
                      setReportType("Detail");
                    }}
                  >
                    Detail Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Purchase" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Purchase",
                    Type: expandReport.Type === "Summary" ? "" : "Summary",
                  });
                }}
              >
                Summary Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Purchase" &&
                  expandReport.Type === "Summary" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
         {/*        <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Draft Orders List");
                      loadVouchersList("Sales", "Draft");
                    }}
                  >
                    Summary Draft
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Requests List");
                      loadVouchersList("Sales", "Request");
                    }}
                  >
                    Summary Requests
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Orders List");
                      loadVouchersList("Sales", "Order");
                    }}
                  >
                    Summary Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Shipped Orders List");
                      loadVouchersList("Sales", "Shipped");
                    }}
                  >
                    Summary Delivery
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Line Items List");
                      loadLineItemsList("Sales", "Shipped");
                      setReportType("LineItems");
                    }}
                  >
                    Summary Delivery LineItems
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Payment List");
                      loadVouchersList("Sales", "Payment");
                    }}
                  >
                    Summary Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {globalRoleSecurity.filter(
          (sec) =>
            sec.Functionality === "Sales Reports" && !(sec.Security === 0)
        ).length > 0 && (
          <Grid container p={0.5}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: !expandReport.State,
                    Module: "Sales",
                    Type: "",
                    Filter: true,
                  });
                }}
              >
                Sales
              </Button>
            </Grid>
            <Grid
              p={0.5}
              pl={3}
              item
              xs={12}
              style={{
                display:
                  expandReport.Module === "Sales" && expandReport.State
                    ? "block"
                    : "none",
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Sales",
                    Type: expandReport.Type === "General" ? "" : "General",
                    Filter: true,
                  });
                }}
              >
                General Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Sales" &&
                  expandReport.Type === "General" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <ul>
            {/*     <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Draft Orders List");
                      loadVouchersList("Sales", "Draft");
                      setReportType("General");
                    }}
                  >
                    Sales Drafts
                  </Button>
                </grid> */}
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Requests List");
                      loadVouchersList("Sales", "Request");
                      setReportType("General");
                    }}
                  >
                    Sales Requests
                  </Button>
                </grid>

                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Orders List");
                      loadVouchersList("Sales", "Order");
                      setReportType("General");
                    }}
                  >
                    Sales Orders
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Received Orders List");
                      loadVouchersList("Sales", "Received");
                      setReportType("General");
                    }}
                  >
                    Sales Received
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Payment List");
                      loadVouchersList("Sales", "Payment");
                      setReportType("General");
                    }}
                  >
                    Sales Payment
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Purchase Items List");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Soled Items
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Customers History");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Customers History
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Customers Balance");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Customers Balance
                  </Button>
                </grid>
              </ul>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Sales" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Sales",
                    Type: expandReport.Type === "Detail" ? "" : "Detail",
                    Filter: true,
                  });
                }}
              >
                Detail Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Sales" &&
                  expandReport.Type === "Detail" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
          {/*       <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Sales Draft Orders List");
                      loadDetailVouchersList("Sales", "Draft");
                      setReportType("Detail");
                    }}
                  >
                    Detail Drafts
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Sales Requests List");
                      loadDetailVouchersList("Sales", "Request");
                      setReportType("Detail");
                    }}
                  >
                    Detail Requests
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Sales Orders List");
                      loadDetailVouchersList("Sales", "Order");
                      setReportType("Detail");
                    }}
                  >
                    Detail Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Sales Received Orders List");
                      loadDetailVouchersList("Sales", "Received");
                      setReportType("Detail");
                    }}
                  >
                    Detail Received
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Sales Payment List");
                      loadDetailVouchersList("Sales", "Payment");
                      setReportType("Detail");
                    }}
                  >
                    Detail Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Sales" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Sales",
                    Type: expandReport.Type === "Summary" ? "" : "Summary",
                    Filter: true,
                  });
                }}
              >
                Summary Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Sales" &&
                  expandReport.Type === "Summary" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
               {/*  <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Draft Orders List");
                      loadVouchersList("Sales", "Draft");
                    }}
                  >
                    Summary Draft
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Requests List");
                      loadVouchersList("Sales", "Request");
                    }}
                  >
                    Summary Requests
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Orders List");
                      loadVouchersList("Sales", "Order");
                    }}
                  >
                    Summary Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Shipped Orders List");
                      loadVouchersList("Sales", "Shipped");
                    }}
                  >
                    Summary Delivery
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Line Items List");
                      loadLineItemsList("Sales", "Shipped");
                      setReportType("LineItems");
                    }}
                  >
                    Summary Delivery LineItems
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Payment List");
                      loadVouchersList("Sales", "Payment");
                    }}
                  >
                    Summary Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {globalRoleSecurity.filter(
          (sec) =>
            sec.Functionality === "Inventory Reports" && !(sec.Security === 0)
        ).length > 0 && (
          <Grid container p={0.5}>
            <Grid item pl={2} p={0.5} xs={12}>
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: !expandReport.State,
                    Module: "Inventory",
                    Type: "",
                    Filter: true,
                  });
                }}
              >
                Inventory
              </Button>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Inventory" && expandReport.State
                    ? "block"
                    : "none",
                textAlign: "left",
              }}
            >
              <Button
                m={2}
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Inventory",
                    Type: expandReport.Type === "General" ? "" : "General",
                    Filter: true,
                  });
                }}
              >
                General Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Inventory" &&
                  expandReport.Type === "General" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <ul>
       {/*          <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Item Request List");
                      loadVouchersList("Inventory", "Draft");
                      setReportType("General");
                    }}
                  >
                    Item Request List
                  </Button>
                </grid> */}
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Item Requests Approval List");
                      loadVouchersList("Inventory", "Request");
                      setReportType("General");
                    }}
                  >
                    Item Requests Approval List
                  </Button>
                </grid>

                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Item Issues List");
                      loadVouchersList("Inventory", "Order");
                      setReportType("General");
                    }}
                  >
                    Item Issues List
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Item Received List");
                      loadVouchersList("Inventory", "Received");
                      setReportType("General");
                    }}
                  >
                    Item Received List
                  </Button>
                </grid>

              {/*   <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Transfer Request List");
                      loadVouchersList("Inventory", "Draft");
                      setReportType("General");
                    }}
                  >
                    Transfer Request List
                  </Button>
                </grid> */}
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Transfer Requests Approval List");
                      loadVouchersList("Inventory", "Request");
                      setReportType("General");
                    }}
                  >
                    Transfer Requests Approval List
                  </Button>
                </grid>

                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Transfer Issues List");
                      loadVouchersList("Inventory", "Order");
                      setReportType("General");
                    }}
                  >
                    Transfer Issues List
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Transfer Received List");
                      loadVouchersList("Inventory", "Received");
                      setReportType("General");
                    }}
                  >
                    Transfer Received List
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Customers History");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Items History
                  </Button>
                </grid>
                <grid item xs={12} style={{ textalign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Customers Balance");
                      loadVouchersList("Purchase", "Items");
                      setReportType("General");
                    }}
                  >
                    Items Balance
                  </Button>
                </grid>
              </ul>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Inventory" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Inventory",
                    Type: expandReport.Type === "Detail" ? "" : "Detail",
                    Filter: true,
                  });
                }}
              >
                Detail Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Inventory" &&
                  expandReport.Type === "Detail" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
              {/*   <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Inventory Draft Orders List");
                      loadDetailVouchersList("Inventory", "Draft");
                      setReportType("Detail");
                    }}
                  >
                    Detail Inventory Draft Orders
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Inventory Requests List");
                      loadDetailVouchersList("Inventory", "Request");
                      setReportType("Detail");
                    }}
                  >
                    Detail Inventory Requests
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Inventory Orders List");
                      loadDetailVouchersList("Inventory", "Order");
                      setReportType("Detail");
                    }}
                  >
                    Detail Inventory Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Inventory Received Orders List");
                      loadDetailVouchersList("Inventory", "Received");
                      setReportType("Detail");
                    }}
                  >
                    Detail Inventory Received Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Inventory Payment List");
                      loadDetailVouchersList("Inventory", "Payment");
                      setReportType("Detail");
                      setItemVisbile(false);
                    }}
                  >
                    Detail Inventory Payment
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Detail Item History");
                      loadDetailItemHistory(item);
                      setReportType("LineItems");
                      setItemVisbile(true);
                    }}
                  >
                    Detail Item History
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Inventory" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Inventory",
                    Type: expandReport.Type === "Summary" ? "" : "Summary",
                    Filter: true,
                  });
                }}
              >
                Summary Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Inventory" &&
                  expandReport.Type === "Summary" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
              {/*   <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Draft Orders List");
                      loadVouchersList("Sales", "Draft");
                    }}
                  >
                    Sales Draft Orders
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Requests List");
                      loadVouchersList("Sales", "Request");
                    }}
                  >
                    Sales Requests
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Orders List");
                      loadVouchersList("Sales", "Order");
                    }}
                  >
                    Sales Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Shipped Orders List");
                      loadVouchersList("Sales", "Shipped");
                    }}
                  >
                    Sales Delivery Orders
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Line Items List");
                      loadLineItemsList("Sales", "Shipped");
                      setReportType("LineItems");
                    }}
                  >
                    Sales Delivery LineItems
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Sales Payment List");
                      loadVouchersList("Sales", "Payment");
                    }}
                  >
                    Sales Payment
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Items List");
                    }}
                  >
                    Items
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {globalRoleSecurity.filter(
          (sec) =>
            sec.Functionality === "Administrator Report" &&
            !(sec.Security === 0)
        ).length > 0 && (
          <Grid container p={0.5}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: !expandReport.State,
                    Module: "Administrator",
                    Type: "",
                  });
                }}
              >
                Administrator
              </Button>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Administrator" && expandReport.State
                    ? "block"
                    : "none",
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Administrator",
                    Type: expandReport.Type === "General" ? "" : "General",
                    Filter: false,
                  });
                }}
              >
                General Reports
              </Button>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={4}
              style={{
                display:
                  expandReport.Module === "Administrator" &&
                  expandReport.Type === "General" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Employees List");
                      loadEmployeesList();
                      setReportType("General");
                    }}
                  >
                    Employees List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Customers List");
                      loadCustomersList("Customer");
                      setReportType("General");
                    }}
                  >
                    Customers List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Suppliers List");
                      loadCustomersList("Supplier");
                      setReportType("General");
                    }}
                  >
                    Suppliers List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Items List");
                      loadItemsList();
                      setReportType("General");
                    }}
                  >
                    Items List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Item Categories List");
                      loadItemCategoriesList();
                      setReportType("General");
                    }}
                  >
                    Item Categories List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Stores List");
                      loadStoresList();
                      setReportType("General");
                    }}
                  >
                    Stores List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Stock Balances List");
                      loadStockBalancesList();
                      setReportType("General");
                    }}
                  >
                    Stock Balances List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Setting List");
                      loadItemCategoriesList();
                      setReportType("General");
                    }}
                  >
                    Setting List
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <Button
                    variant="standard"
                    onClick={() => {
                      setTableHeader("Security List");
                      loadItemCategoriesList();
                      setReportType("General");
                    }}
                  >
                    Security List
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Administrator" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Administrator",
                    Type: expandReport.Type === "Detail" ? "" : "Detail",
                    Filter: false,
                  });
                }}
              >
                Detail Reports
              </Button>
            </Grid>
            <Grid
              item
              p={1}
              pl={4}
              style={{
                display:
                  expandReport.Module === "Administrator" &&
                  expandReport.Type === "Detail" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
                
              </Grid>
            </Grid>
            <Grid
              item
              p={0.5}
              pl={3}
              xs={12}
              style={{
                display:
                  expandReport.Module === "Administrator" && expandReport.State
                    ? "block"
                    : "none",
                m: 4,
                textAlign: "left",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={() => {
                  setExpandReport({
                    State: expandReport.State,
                    Module: "Administrator",
                    Type: expandReport.Type === "Summary" ? "" : "Summary",
                    Filter: false,
                  });
                }}
              >
                Summary Reports
              </Button>
            </Grid>
            <Grid
              item
              pl={4}
              style={{
                display:
                  expandReport.Module === "Administrator" &&
                  expandReport.Type === "Summary" &&
                  expandReport.State
                    ? "block"
                    : "none",
              }}
            >
              <Grid container>
            
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={10}>
        <Grid container>
          <Grid
            item
            xs={2}
            ml={2}
            style={{ display: expandReport.Filter ? "block" : "none" }}
          >
            <TextField
              size="small"
              type="date"
              variant="standard"
              label="Start Date"
              defaultValue={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              sx={{ width: "95%", marginBottom: 1 }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: theme.palette.primary.contrastText,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: expandReport.Filter ? "block" : "none" }}
          >
            <TextField
              size="small"
              type="date"
              variant="standard"
              label="End Date"
              defaultValue={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              sx={{ width: "95%", marginBottom: 1 }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: theme.palette.primary.contrastText,
                },
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ display: itemVisible ? "block" : "none" }}>
            <Autocomplete
              label="Items"
              id="Items"
              size="small"
              variant="standard"
              value={
                allItems.filter((it) => parseInt(it.Item) === parseInt(item))
                  .length > 0
                  ? allItems.filter(
                      (it) => parseInt(it.Item) === parseInt(item)
                    )[0].Name
                  : ""
              }
              onSelect={(e) => {
                setItem(
                  allItems.filter((it) => it.Name === e.target.value).length > 0
                    ? allItems.filter((it) => it.Name === e.target.value)[0]
                        .Item
                    : ""
                );
              }}
              options={
                !(allItems === undefined) &&
                !(allItems === null) &&
                allItems.length > 0
                  ? allItems.map((it) => {
                      return it.Name;
                    })
                  : [""]
              }
              sx={{ width: "98%", marginBlock: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Selected Item"}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  //error={!from ? true : false}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: itemVisible ? "block" : "none" }}
          ></Grid>

          <Grid item xs={12}>
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              {expandReport.Module !== "Administrator" ? (
                reportType === "General" ? (
                  <MyGeneralDocument DataList={dataList} />
                ) : reportType === "LineItems" ? (
                  <MyLineItemsDocument />
                ) : (
                  <MyDetailDocument
                    Orders={dataList}
                    LineItems={lineItemsList}
                  />
                )
              ) : reportType === "General" ? (
                <MyAdministratorDocument DataList={dataList} />
              ) : (
                <MyAdministratorDetailDocument DataList={dataList} />
              )}
            </PDFViewer>
            {/* <Apps DataList={dataList} style={{width: '100%', height:'800px'}}/> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
