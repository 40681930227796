import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  IconButton,
  Button,
  Box,
  Tooltip,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  EditSharp,
  AddSharp,
  FileDownload,
  FileUpload,
  RefreshSharp,
  PreviewSharp,
  ViewDaySharp,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { useState, useEffect, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import ItemJournalModel from "./ItemJournalModel";
import ItemJournalModelHistory from "./ItemJournalModelHistory";

import * as XLSX from "xlsx";
import axios from "../../axiosInstances";
import {format} from 'date-fns'
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import ItemJournalIssueModel from "./ItemJournalIssueModel";
//import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

function ItemJournalComp(props) {
  const navigate = useNavigate();
  const theme=useTheme();
  const [update, setUpdate] = useState(false);
  const location = useLocation();
  //const [SupplierCategoriesList, setSupplierCategoriesList] = useState([]);
  const [itemjournalList, setItemjournalList] = useState([]);
  const [itemJournalLineList, setItemJournalLineList] = useState([]);
  const [issuedItemjournalList, setIssuedItemjournalList] = useState([]);
  const [issuedItemJournalLineList, setIssuedItemJournalLineList] = useState([]);
  const { globalUser, setPageHeader,globalToken,globalSetting,globalRoleSecurity } = useContext(ShoppingCartContext);

  
  const [employeeList, setEmployeeList] = useState([]);
  const [storesList,setStoresList] = useState([]);
  

  //const { t, i18n } = useTranslation();
  const loadStoresList = () => {
    axios
      .get(
        "/stores/getByParent?Parent=" +
          globalUser.Parent, {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setStoresList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      
      {
        header: "Store",
        accessorKey: "StoreName",
      },
      {
            header: "Issued By",
            accessorKey: "IssuedByName",
          },
      {
            header: "Issued To",
            accessorKey: "IssuedToName",
          },
      {
        header: "Date",
        accessorKey: "OrderDate",
      },
      {
        header: "Status",
        accessorKey: "Status", 
      }, 
         
    ],
    []
  );

  const loaditemjournalsList = () => {
    axios
      .get(
        "/itemjournal/getByParentAndStatus?Parent=" +
          globalUser.Parent +"&Status=" + location.state.Status +
          "&Store=" +
          ((location.state.Store===undefined || location.state.Store===null || isNaN(location.state.Store))?0:location.state.Store)
, {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setItemjournalList(response.data.data.Orders);
          setItemJournalLineList(response.data.data.ItemJournalLines);

          setIssuedItemjournalList(response.data.data.IssuedOrders);
          
          setIssuedItemJournalLineList(response.data.data.IssuedItemJournalLines);
          
        
        
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadEmployeeList = () => {
    axios
      .get(
        "/employees/getByParent?Parent=" +
          globalUser.Parent 
          , {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setEmployeeList(response.data.data);
      
        } else {
          alert("log out and log in again");
        }
      });
  };
  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    var jsonData = XLSX.utils.sheet_to_json(worksheet);

    var fdata = new FormData();
    fdata.append("JsonData", JSON.stringify(jsonData));
    await axios.post("/itemjournals/Excel", fdata, {
      headers: { "Content-Type": "application/json", 
          token: globalToken,
       },
    });
  };
  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(itemjournalList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "itemjournals");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "itemjournalsdata.xlsx");
  }

  useEffect(() => {
    if(!globalUser){
      navigate('/');
      return;
    }
    loadStoresList();
    loaditemjournalsList();
    setPageHeader("Store " + location.state.Status);
    loadEmployeeList();
  }, [update,location.state.Status]);



  return (
    <div width="100%">
      {storesList !== undefined &&
      storesList !== null &&
      storesList.length &&
      employeeList !== undefined &&
      employeeList !== null &&
      employeeList.length > 0 ? (
        <Grid container>
          <Grid item className="hideInMobile" m={1}>
            <MaterialReactTable
              columns={columns}
              data={itemjournalList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
              // enableColumnOrdering
              
              enableColumnResizing
              enableRowNumbers
         
            
              renderDetailPanel={({ row }) => (
                <Grid
                  container
                  ml={2}
                  mr={4}
                  sx={{ backgroundColor: "smockwhite" }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "lightgreen",
                      fontWeight: "bold",
                    }}
                  >
                  
                      <IconButton>
                            <ItemJournalModel
                              icon={
                                <EditSharp
                                   style={{ width: "10px", height: "10px",margin:1 }}
                                />
                              }
                              StoresList={storesList}
                              EmployeesList={employeeList}
                              IssueOrder={row.original}
                              ItemJournalLines={itemJournalLineList.filter(
                                (li) => li.Order === row.original.Id
                              )}
                             ParentUpdate={update}
                             setParentUpdate={setUpdate}
                            />
                 </IconButton>
                   Line Items
                  </Grid>
                  {itemJournalLineList &&
                    itemJournalLineList.filter(
                      (li) => li.Order === row.original.Id
                    ).map((litem, index) => {
                      var issuedQty=0;
                      issuedItemJournalLineList && issuedItemJournalLineList.filter(iss=>(parseInt(iss.ParentOrder)===parseInt(litem.Order))).map(issLItem=>{
                        issuedQty=parseFloat(issuedQty)+ parseFloat(issLItem.Quantity);
                      })
                      return (
                        <>
                          {index === 0 ? (
                            <>
                              <Grid
                                item
                                xs={4}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Item
                              </Grid>
                              <Grid
                                item
                                xs={2}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Issued Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Quantity
                              </Grid>
                             
                              
                              <Grid
                                item
                                xs={4}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Remark
                              </Grid>
                           

                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Name}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {issuedQty?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                              
                            
                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Remark}
                              </Grid>
                              
                            </>
                          ) : (
                            <>
                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Name}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {issuedQty?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                             
                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Remark}
                              </Grid>
                             

                            </>
                          )}
                        </>
                      );
                    })} 
            {issuedItemjournalList && issuedItemjournalList.filter(iss=>parseInt(iss.Order)===parseInt(row.original.Id)).map((IssOrd,index)=>{
         return(
          <>
  <Grid
  item
  xs={12}
  style={{
    backgroundColor: "lightgreen",
    fontWeight: "bold",
  }}
>

<Tooltip title="Issue Edit"> 
    <IconButton>
          <ItemJournalModelHistory
            icon={
              <EditSharp
                 style={{ width: "15px", height: "15px",margin:1 }}
              />
            }
            StoresList={storesList}
            EmployeesList={employeeList}
            IssueOrder={IssOrd}
            ItemJournalLines={issuedItemJournalLineList.filter(
              (li) => li.Order === IssOrd.Id
            )}
            ParentUpdate={update}
           setParentUpdate={setUpdate}
          />
</IconButton>
</Tooltip>
|<Tooltip title="Issue Preview">              
                    <IconButton endIcon={<PreviewSharp />}>
                        <InvoicePreview
                          icon={
                            <PreviewSharp
                            style={{ width: "15px", height: "15px",margin:1 }}
                            />
                          }
                          data={[IssOrd]}
                          LineItems={issuedItemJournalLineList.filter(ijl=>parseInt(ijl.Order)===parseInt(IssOrd.Id))}
                          Type="IssueOrder"
                          DocumentType={"Store "+ IssOrd.Status}
                        />
                    </IconButton>
                   </Tooltip>|
 {"  Issued Order "+(index+1)+": |         Issued By : " + IssOrd.IssuedByName  + "     |           Issued To: " + IssOrd.IssuedToName +  "      |           Issued Date: " + IssOrd.IssuedDate } 
</Grid>
{issuedItemJournalLineList &&
  issuedItemJournalLineList.filter(
    (li) => parseInt(li.Order) === parseInt(IssOrd.Id)
  ).map((litem, index) => {
    
    return (
      <>
        {index === 0 ? (
          <>
            <Grid
              item
              xs={4}
              sx={{ border: 1 }}
              pl={12}
              style={{
                backgroundColor: "lightgray",
                color: "white",
              }}
            >
              Item
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ border: 1 }}
              pl={12}
              style={{
                backgroundColor: "lightgray",
                color: "white",
              }}
            >
              Quantity
            </Grid>
           
            
            <Grid
              item
              xs={4}
              sx={{ border: 1 }}
              pl={12}
              style={{
                backgroundColor: "lightgray",
                color: "white",
              }}
            >
              Remark
            </Grid>
         

            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
              {litem.Name}
            </Grid>
            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
              {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
            </Grid>
            
          
            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
              {litem.Remark}
            </Grid>
            
          </>
        ) : (
          <>
            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
              {litem.Name}
            </Grid>
            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
              {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
            </Grid>
           
            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
              {litem.Remark}
            </Grid>
           

          </>
        )}
      </>
    );
  })}
  </>
         ) 
})}

                
                </Grid>
              )}
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  {location.state.Status==='Request' && globalSetting.filter(gs=>(gs.Description==='Issue Request Only From Job Order'  && gs.Value==='0')).length>0 &&
                   globalRoleSecurity.filter(gs=>(gs.Functionality==='Issue Request'  && gs.Security>0)).length>0 &&
                <>
                   <Button
                         size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <AddSharp style={{ width: "20px", height: "20px" }} />
                    }
                  >
                      <ItemJournalModel
                        icon={<div>Add</div>}
                        StoresList={storesList}
                        EmployeesList={employeeList}
                        IssueOrder={{Id:0,Status:'Request',IssuedBy:globalUser.Id,IssuedByName:"",RequestedByName:globalUser.Name,IssuedToName:"",OrderDate:format(new Date(),"yyyy-MM-dd")}}
                        ItemJournalLines={[{}]}
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                   
                  </Button>|
                  </>
              }
                  {globalRoleSecurity.filter(gs=>(gs.Functionality==="Issue Direct"  && gs.Security>2)).length>0 &&
<>
                   <Button
                         size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <AddSharp style={{ width: "20px", height: "20px" }} />
                    }
                  >
                      <ItemJournalModel
                        icon={<div>Direct Issue</div>}
                        StoresList={storesList}
                        EmployeesList={employeeList}
                        IssueOrder={{Id:0,Status:'Direct',IssuedBy:globalUser.Id,IssuedByName:"",RequestedByName:globalUser.Name,IssuedToName:"",OrderDate:format(new Date(),"yyyy-MM-dd")}}
                        ItemJournalLines={[{}]}
                        ParentUpdate={update}
                        setParentUpdate={setUpdate}
                      />
                   
                  </Button>|
                  </>
              }
              

                  <Button
                         size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <FileDownload style={{ width: "20px", height: "20px" }} />
                    }
                    type="file"
                    onClick={handleDownloadFile}
                  >
                    Download
                  </Button>
                  |
                  <Tooltip title="refresh">
                    <Button
                           size="small"
                    variant="standard"
                      sx={{ borderRadius: "30px" }}
                      endIcon={<RefreshSharp />}
                      onClick={() => setUpdate(!update)}
                    >
                      Refresh
                    </Button>
                  </Tooltip>
                 
                </Box>
              )}
              enableRowActions              
              renderRowActionMenuItems={(table) => ([
                 
                  <Tooltip title="Issue Order">  
                    <MenuItem>
                    {(table.row.original.Status==='Request') && (globalRoleSecurity.filter(gs=>((gs.Functionality==="Issue Request") && (gs.Security>1))).length>0) ||
                    (table.row.original.Status==='Approved') && (globalRoleSecurity.filter(gs=>((gs.Functionality==="Issue Approval") && (gs.Security>1))).length>0)?
                      <ItemJournalModel
                          icon={<div>
                            <EditSharp   
                             style={{ width: "13px", height: "13px",margin:1,marginRight:4}}                           
                            />{"Issue "+location.state.Status} </div>
                          }
                          StoresList={storesList}
                          IssueOrder={table.row.original}
                          EmployeesList={employeeList}
                          ItemJournalLines={itemJournalLineList.filter(
                            (li) => li.Order === table.row.original.Id
                          )}
                          IssuedItemJournalLines={issuedItemJournalLineList.filter(iss=>(parseInt(iss.ParentOrder)===parseInt(table.row.original.Id)))}
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />:
                  
                        <ItemJournalModelHistory
                        icon={<div>
                          <EditSharp 
                           style={{ width: "13px", height: "13px",margin:1,marginRight:4}}                             
                          />{"Issue "+location.state.Status} </div>
                        }
                        StoresList={storesList}
                        IssueOrder={table.row.original}
                        EmployeesList={employeeList}
                        ItemJournalLines={itemJournalLineList.filter(
                          (li) => li.Order === table.row.original.Id
                        )}
                        ParentUpdate={update}
                          setParentUpdate={setUpdate}
                       
                      />
                        }
                          
                    </MenuItem>
                   </Tooltip>
                   ,
                 
                    ((table.row.original.Status==='Approved') && (globalRoleSecurity.filter(gs=>((gs.Functionality==="Issued") && (gs.Security>0))).length>0)) &&
                    <Tooltip title="Issue Order">              
                    <MenuItem>
                     
                        <ItemJournalIssueModel
                          icon={<div>
                            <EditSharp
                             style={{ width: "13px", height: "13px",margin:1,marginRight:4}}                              
                            />Issue</div>
                          }
                          StoresList={storesList}
                          IssueOrder={table.row.original}
                          ItemJournalLines={itemJournalLineList.filter(
                            (li) => parseInt(li.Order) === parseInt(table.row.original.Id)
                          )}
                          IssuedItemJournalLines={issuedItemJournalLineList.filter(iss=>(parseInt(iss.ParentOrder)===parseInt(table.row.original.Id)))}
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                          
                    </MenuItem>
                   </Tooltip>,
                   <Tooltip title="Issue Preview">              
                    <MenuItem>
                        <InvoicePreview
                          icon={<div>
                            <PreviewSharp
                             style={{ width: "13px", height: "13px",margin:1,marginRight:4}}
                            />Issue Preview</div>
                          }
                          data={[table.row.original]}
                          LineItems={itemJournalLineList.filter(ijl=>parseInt(ijl.Order)===parseInt(table.row.original.Id))}
                          Type="IssueOrder"
                          DocumentType={"Store "+ location.state.Status}
                        />
                    </MenuItem>
                   </Tooltip>, 
                        ]
              )}
            ></MaterialReactTable>
          </Grid>
          <Grid item xs={12} mt={1} className="hideInFull">
          <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>
 
            {location.state.Status==='Request' && globalSetting.filter(gs=>(gs.Description==='Issue Request Only From Job Order'  && gs.Value==='0')).length>0 &&
                   globalRoleSecurity.filter(gs=>(gs.Functionality==='Issue Request'  && gs.Security>0)).length>0 &&
                <>
              <Button
                
                     size="small"
                    variant="standard"
                sx={{ borderRadius: "30px" }}
                endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}
              >
               
               
                        <ItemJournalModel
                          icon={
                            <EditSharp                              
                            />
                          }
                          StoresList={storesList}
                          IssueOrder={{Id:0,Status:'Request',IssuedBy:globalUser.Id,IssuedByName:"",RequestedByName:globalUser.Name,IssuedToName:"",OrderDate:format(new Date(),"yyyy-MM-dd")}}
                          EmployeesList={employeeList}
                          ItemJournalLines={[]}
                          ParentUpdate={update}
                          setParentUpdate={setUpdate}
                        />
                   
               </Button>|
               </>
}
              <Button
                
                     size="small"
                    variant="standard"
                sx={{ borderRadius: "30px" }}
                endIcon={
                  <FileDownload style={{ width: "20px", height: "20px" }} />
                }
                type="file"
                onClick={handleDownloadFile}
              >
                Download
              </Button>
              |
              <Tooltip title="refresh">
                <Button
                  
                       size="small"
                    variant="standard"
                  sx={{ borderRadius: "30px" }}
                  endIcon={<RefreshSharp />}
                  onClick={() => setUpdate(!update)}
                >
                  Refresh
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          {!(itemjournalList === undefined) &&
            itemjournalList.length > 0 &&
            itemjournalList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                 <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={item.IssuedToName} //.substring(0, 20)}
                      subheader={item.StoreName}
                    />

                    <CardContent>
                    <Typography variant="body2" align="left">
                        Issued By: {item.IssuedByName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Date: {item.OrderDate}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Status: {item.Status}
                      </Typography>
                      
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Stack direction='row'>
                     <Tooltip title="Issue Order">  
                   <IconButton>
                   {(item.Status==='Request') && (globalRoleSecurity.filter(gs=>((gs.Functionality==="Issue Request") && (gs.Security>1))).length>0) ||
                   (item.Status==='Approved') && (globalRoleSecurity.filter(gs=>((gs.Functionality==="Issue Approval") && (gs.Security>1))).length>0)?
                     <ItemJournalModel
                         icon={<div>
                           <EditSharp   
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4}}                           
                           />|</div>
                         }
                         StoresList={storesList}
                         IssueOrder={item}
                         EmployeesList={employeeList}
                         ItemJournalLines={itemJournalLineList.filter(
                           (li) => li.Order === item.Id
                         )}
                         IssuedItemJournalLines={issuedItemJournalLineList.filter(iss=>(parseInt(iss.ParentOrder)===parseInt(item.Id)))}
                         ParentUpdate={update}
                         setParentUpdate={setUpdate}
                       />:
                 
                       <ItemJournalModelHistory
                       icon={<div>
                         <EditSharp 
                          style={{ width: "13px", height: "13px",margin:1,marginRight:4}}                             
                         />|</div>
                       }
                       StoresList={storesList}
                       IssueOrder={item}
                       EmployeesList={employeeList}
                       ItemJournalLines={itemJournalLineList.filter(
                         (li) => li.Order === item.Id
                       )}
                       ParentUpdate={update}
                         setParentUpdate={setUpdate}
                      
                     />
                       }
                         
                   </IconButton>
                  </Tooltip>
                  {((item.Status==='Approved') && (globalRoleSecurity.filter(gs=>((gs.Functionality==="Issued") && (gs.Security>0))).length>0)) &&
                   <Tooltip title="Issue Order">              
                   <IconButton>
                    
                       <ItemJournalIssueModel
                         icon={<div>
                           <EditSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4}}                              
                           />|</div>
                         }
                         StoresList={storesList}
                         IssueOrder={item}
                         ItemJournalLines={itemJournalLineList.filter(
                           (li) => parseInt(li.Order) === parseInt(item.Id)
                         )}
                         IssuedItemJournalLines={issuedItemJournalLineList.filter(iss=>(parseInt(iss.ParentOrder)===parseInt(item.Id)))}
                         ParentUpdate={update}
                         setParentUpdate={setUpdate}
                       />
                         
                   </IconButton>
                  </Tooltip>
            }
                  <Tooltip title="Issue Preview">              
                   <IconButton>
                       <InvoicePreview
                         icon={<div>
                           <PreviewSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4}}
                           />|</div>
                         }
                         data={[item]}
                         LineItems={itemJournalLineList.filter(ijl=>parseInt(ijl.Order)===parseInt(item.Id))}
                         Type="IssueOrder"
                         DocumentType={"Store "+ location.state.Status}
                       />
                   </IconButton>
                  </Tooltip>
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <div>There is not employee in this company</div>
      )}
    </div>
  );
}

export default ItemJournalComp;
