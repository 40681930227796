import { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Grid,
 Stack,
  Autocomplete,
  Avatar,
} from "@mui/material";
import {CloseSharp,SaveSharp,PreviewSharp,Delete} from "@mui/icons-material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
//import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'

import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";
import { Formik } from 'formik';
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Name is required!!!'),
    Type: Yup.string()
    .required('Type is required!!!'),
    TinNumber: Yup.string()
    .required('TinNumber is required!!!')

});
export default function SuppliersModel(props) {
  const theme=useTheme();
  const { t, i18n } = useTranslation();
  const { globalUser,setGlobalProviders,globalToken,globalRoleSecurity } = useContext(ShoppingCartContext);
  const [open, setOpen] = useState(false);
  const [isAddresssDisplayed, setIsAddresssDisplayed] = useState(false);
  var fileObj = [];
  var fileArray = [];

  const [id, setId] = useState(props.data.Id);
  const [tinNumber, setTinNumber] = useState(props.data.TinNumber);
  const [type, setType] = useState(props.Type);
  const [name, setName] = useState(props.data.Name);
  const [description, setDescription] = useState(props.data.Description);
  const [category, setCategory] = useState(props.data.Category);
  const [logoFiles, setLogoFiles] = useState(splitPictures());
  const [amount, setAmount] = useState(props.data.Amount);
  const [commision, setCommission] = useState(props.data.Commision);
  const [remark, setRemark] = useState(props.data.Remark);
  const [route, setRoute] = useState(props.data.Route);
  const [routeObject, setRouteObject] = useState(props.data.Route);
  const [region, setRegion] = useState(props.data.Region);
  const [zone, setZone] = useState(props.data.Zone);
  const [wereda, setWereda] = useState(props.data.Wereda);
  const [houseNo, setHouseNo] = useState(props.data.HouseNo);
  const [surrounding, setSurrounding] = useState(props.data.Surrounding);
  const [telephone, setTelephone] = useState(props.data.Telephone);
  const [mobile, setMobile] = useState(props.data.Mobile);
  const [email, setEmail] = useState(props.data.Email);
  const [web, setWeb] = useState(props.data.Web);
  const [userName, setUserName] = useState(props.data.UserName);
  const [password, setPassword] = useState(props.data.Password);
  const [lat, setLat] = useState(props.data.Lat);
  const [longt, setLongt] = useState(props.data.Longt);
  const [parent, setParent] = useState(props.data.Parent);
  const [parentCategoriesList, setParentCategoriesList] = useState([]);
  const [SupplierCategoriesList, setSupplierCategoriesList] = useState([]);
  const [salesRoutesList, setSalesRoutesList] = useState([]);
  const [isRouteCollapsed, setIsRouteCollapsed] = useState(false);
  function splitPictures() {
    try {
      fileArray = [];
      props.data.Logo?.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }

/*      const handleCustomerSupplierRelation =  () => {
     
      try{
      var fd = new FormData();
      fd.append("Customer", id);
      fd.append("Supplier", globalUser.Parent);
      fd.append("StartDate", format(new Date(),'yyyy-MM-dd'));
      fd.append("Approved", 1);
      fd.append("ApprovedBy", globalUser.Id);
      fd.append("Remark", "By supplier company user: " + globalUser.Name);
    
      axios.post("/customerSupplierRelationship", fd ,{
        headers: { 
          'Accept': 'application/json',
          "Content-Type": "application/json" },
      })
    }catch(err){

    }finally{
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
      }
       }; */
  const loadRoutesList = () => {
    axios
      .get(
        "/SalesRoutes/getBySupplier?Supplier=" +
          (globalUser
            ? globalUser.Parent === null || globalUser.Parent === 0
              ? globalUser.Id
              : globalUser.Parent
            : 0)
            , {
              headers: {
                token: globalToken,
              },
            }
      )
      .then((response) => {
        if (response.data.data) {
          setSalesRoutesList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };

  const loadRoutesById = () => {
    axios
      .get("/SalesRoutes/getById?Id=" + props.data.Route, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setRouteObject(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  useEffect(() => {
    loadRoutesList();
    
  }, [props.data]);
  useEffect(() => {
    loadRoutesById();
  }, []);
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setLogoFiles(fileArray);
  }

  const handleSupplierSubmit = (values) => {
 
   /* if(name===undefined || name===null || name.length<=0){
      alert('write the name first')
      return;
    }
    if(tinNumber===undefined || tinNumber===null || tinNumber.length<=0){
      alert('write the tin number first')
      return;
    }
 
     if(userName===undefined || userName===null || userName.length<=0){
      alert('write the username first')
      return;
    }
    if(password===undefined || password===null || password.length<=0){
      alert('write the password first')
      return;
    } */
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("TinNumber", values.TinNumber);
      fd.append("Type", type);
      fd.append("Name", values.Name);
      fd.append("Description", description);
      fd.append("Category", category);
      if (Array.from(document.getElementById("Logo").files).length > 0) {
          Array.from(document.getElementById("Logo").files).map((file) => {
          fd.append("Logo", file);
        })
      } else {
              fd.append("OriginalLogo", logoFiles);
      }
      fd.append("Amount", amount);
      fd.append("Commision", commision);
      fd.append("Remark", remark);
      fd.append("Route", route);
      fd.append("Region", region);
      fd.append("Zone", zone);
      fd.append("Wereda", wereda);
      fd.append("HouseNo", houseNo);
      fd.append("Surrounding", surrounding);
      fd.append("Telephone", telephone);
      fd.append("Mobile", mobile);
      fd.append("Email", email);
      fd.append("Web", web);
      fd.append("UserName", userName);
      fd.append("Password", password);
      fd.append("Lat", lat);
      fd.append("Longt", longt);
      fd.append("Parent", globalUser.Parent);
      fd.append("Suppliers", [globalUser.Parent]);
      fd.append("StartDate", format(new Date(),'yyyy-MM-dd'));
      fd.append("Approved", 1);
      fd.append("ApprovedBy", globalUser.Id);
      fd.append("AssignedTo", '');
      fd.append("BirthDate", '');
      fd.append("Gender", '');
      fd.append("Race", '');
      fd.append("CustSuppRelationRemark", "By supplier company user: " + globalUser.Name);
      axios.post("/suppliers", fd, {
        headers: { "Content-Type": "multipart/form-data" ,
            token: globalToken,
         },
      })/* .then(response=>{
        if (response.data.data){
          setGlobalProviders(response.data.data);
        }else{
          alert('log out and log in again')
        }
      })   */    
    
    } catch (err) {
    } finally{
      try{
 props.setParentUpdate(!props.ParentUpdate);
      }catch(err){

      }
     
      setOpen(false);
    }
  }
  }
  const handleDelete=async()=>{
  
    try {
     await axios.get("/vouchers/getByParentAndCustomerOrSupplier?Supplier="+id, {
        headers: {
          token: globalToken,
        },
      }).then(response=>{
        if (response.data.data.length<=0){
          deleteNoTransaction()
      }else{
        alert('Can not delete this '+ type +'.It has transaction history');
        setOpen(false)
        return;
      }
    })
    } catch (err) {
   
  }
}
  const deleteNoTransaction = () => {
    if (
      window.confirm(
        "Are you sure you want to delete?"
      )
    ) {
    try {
      axios.delete("/suppliers/delete?Id=" + id, {
        headers: {
          token: globalToken,
        },
      })
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Formik
          initialValues={props.data}
          onSubmit={values => {handleSupplierSubmit(values)}}
           validationSchema={validationSchema}
           enableReinitialize
            
          >
            {({values,errors,handleChange,handleSubmit}) => (
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>
          <Stack style={{display: 'flex',flexDirection:'row'}}>
          <label >Parent Company: </label>
          {/* <Avatar src="" style={{alignItems:'end',alignContent:'end'}}/> */} 
          <label hidden>{globalUser.Parent}</label>
          <label>{globalUser.ParentName}</label>
          
          </Stack>
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <h5 style={{ fontWeight: "bold" }}>{props.Title}</h5> */}
            <hr></hr>

            <Grid container>
            <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label={t("Name")}
                  value={values.Name}
                  onChange={
                    handleChange('Name')
                  }
                  sx={{ width: "95%",  }}
                  InputLabelProps={{
                    
                    shrink: true,
                  }}
                  
                />
                 {errors.Name && <span style={{color:'red'}}> {errors.Name}</span>}
                  </Grid>
           
            <Grid item xs={12} md={6}>
               Type: {type}
                  </Grid>
                  <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  size="small"
                  label={t("Tin Number")}
                  value={values.TinNumber}
                  onChange={
                    handleChange('TinNumber')
                  }
                  sx={{ width: "95%",  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                 {errors.TinNumber && <span style={{color:'red'}}> {errors.TinNumber}</span>}
                  </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label={t("description")}
                  defaultValue={description}
                  multiline
                            onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  sx={{ width: "95%",  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid>
            {/*<Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  selectOnFocus
                  autoHighlight
                  defaultValue={category}
                  id="registeredFor"
                  onSelect={(e) => {
                    setCategory(e.target.value);
                  }}
                  options={[
                    "All Categories",
                    "Specific Categories",
                    "For Sepecific Retailers",
                    "For Sepecific Distributors",
                    "For Sepecific Manufacturers",
                  ].map((item) => {
                    return item;
                  })}
                  sx={{
                    width: "95%",
                    ,
                    borderRadius: "25%",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Select Registered For")}
                      variant="standard"
                      //error={!fromAirport ? true : false}
                    />
                  )}
                />
  </Grid>
             <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Amount"
                  type="number"
                  defaultValue={amount}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Commission"
                  type="number"
                  defaultValue={commision}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setCommission(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Remark"
                  defaultValue={remark}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid> 
            <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="UserName"
                  defaultValue={userName}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid>
            <Grid item xs={12} md={6}>
            <TextField
                  size="small"
                  type="password"
                  variant="standard"
                  label="Password"
                  defaultValue={password}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
          
              </Grid>
          */}
           <Grid item xs={12} >
           <Stack style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,cursor:'pointer'}} onClick={()=>setIsAddresssDisplayed(!isAddresssDisplayed)}>Customer Address and Logo</Stack>
             <div  style={{display:isAddresssDisplayed?'block':'none'}}>
             <Grid container>
            
            <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  selectOnFocus
                  id="route"
                  autoHighlight
                  defaultValue={
                    routeObject &&
                    !(routeObject === null) &&
                    routeObject.length > 0
                      ? routeObject[0]
                      : ""
                  }
                  getOptionLabel={(option) => option.Name}
                  onChange={(e, newValue) => {
                    setRouteObject([]);
                    setRouteObject((preValue) => [...preValue, newValue]);
                    setRoute(newValue.Id)
                  }}
                  options={salesRoutesList}
                  sx={{
                    width: "95%",
                    borderRadius: "25%",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Route Name")}
                      variant="standard"
                      //error={!fromAirport ? true : false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Region"
                  defaultValue={region}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setRegion(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Zone"
                  defaultValue={zone}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setZone(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Wereda"
                  defaultValue={wereda}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setWereda(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="HouseNo"
                  defaultValue={houseNo}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setHouseNo(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Surrounding"
                  defaultValue={surrounding}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setSurrounding(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="telephone"
                  defaultValue={telephone}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setTelephone(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Mobile"
                  defaultValue={mobile}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Email"
                  defaultValue={email}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Web"
                  defaultValue={web}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setWeb(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Lat"
                  defaultValue={lat}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setLat(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Longt"
                  defaultValue={longt}
                  sx={{ width: "95%",  }}
                  onChange={(e) => {
                    setLongt(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,cursor:'pointer'}} >
                <label htmlFor="Logo">
                  Company Logo or Perosnal Picture {logoFiles && <Button endIcon={<CloseSharp style={{color:'red'}} />} onClick={()=>{setLogoFiles('')}}/>}
                  <input
                    type="file"
                    id="Logo"
                    onChange={uploadMultipleFiles}
                    hidden
                  />
                  <div className="form-group multi-preview">
                    {(logoFiles || []).map((address, index) => (
                      <img
                        key={index}
                        src={address}
                        alt="..."
                        style={{ width: "95%", height: "95" }}
                      />
                    ))}
                  </div>
                </label>
              </Grid>
              </Grid>
              </div>
           </Grid>
            
            </Grid>

            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        {/*   <Button
            color="inherit"
            className="collapse-button"
            onClick={() => {
              setIsRouteCollapsed(!isRouteCollapsed);
            }}
          >
            Route
          </Button>| */}
          <Button variant="standard" autoFocus endIcon={<PreviewSharp />}>
        <InvoicePreview 
                    icon={<div>Preview</div>}
                        Suppliers={[props.data]}
                        Type="Supplier"
                        DocumentType={type +" Information"}
                        />
          </Button>|
          {globalRoleSecurity.filter(sec=>(sec.Functionality==="Suppliers" && sec.Security>1)).length>0 &&
<>
          <Button variant="standard" autoFocus onClick={(e)=>{handleSupplierSubmit(values)}} endIcon={<SaveSharp  />}>
            Save
          </Button>|</>
}
{globalRoleSecurity.filter(sec=>(sec.Functionality==="Suppliers" && sec.Security>2)).length>0 &&
<>
          <Button variant="standard" autoFocus onClick={()=>handleDelete()} endIcon={<Delete />}>
            Delete
          </Button>|</>
}
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      {/*   {routeObject && !(routeObject === null) && routeObject.length > 0 ? (
          <DialogActions
            className={`collapse-content ${
              !isRouteCollapsed ? "expanded" : "collapsed"
            }`}
            aria-expanded={isRouteCollapsed}
          >
            <Grid container  m={2}>
              <Grid item xs={3}>
                Route Name:
              </Grid>
              <Grid item xs={3}>
                {routeObject[0].Name}
              </Grid>

              <Grid item xs={3}>
                Delivery Person:
              </Grid>
              <Grid item xs={3}>
                {routeObject[0].DeliveryPersonName}
              </Grid>
              <Grid item xs={3}>
                Sales Representative:
              </Grid>
              <Grid item xs={3}>
                {routeObject[0].SalesRepresentativeName}
              </Grid>
              <Grid item xs={3}>
                Supervisor:
              </Grid>
              <Grid item xs={3}>
                {routeObject[0].SupervisorName}
              </Grid>
              <Grid item xs={3}>
                Path Information:
              </Grid>
              <Grid item xs={3}>
                {routeObject[0].PathDetail}
              </Grid>
              <Grid item xs={3}>
                Remark:
              </Grid>
              <Grid item xs={3}>
                {routeObject[0].Remark}
              </Grid>
            </Grid>
          </DialogActions>
        ) : (
          ""
        )} */}
      </Dialog>
            )}
            </Formik>
    </div>
  );
}
