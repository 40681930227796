import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel } from "@mui/material";
import {CloseSharp, DeleteSharp, Preview, PreviewSharp, SaveSharp} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function FinanceSetupCompModel(props) {
const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalProviders,globalCustomers,globalToken }=useContext(ShoppingCartContext)
 
  const [chartofAccounts,setChartofAccounts]=useState(props.ChartOfAccounts)
  const [itemCategories,setItemCategories]=useState(props.ItemCategories)
  const [id, setId] = useState(props.data?props.data.Id:0);
  const [itemCategory, setItemCategory] = useState(props.data?props.data.ItemCategoryName:'');
  const [inventoryAccount, setInvetoryAccount] = useState(props.data?props.data.InventoryAccountName:'');
  const [cogs, setCogs] = useState(props.data?props.data.COGSAccountName:'');
  const [sales, setSales] = useState(props.data?props.data.SalesAccountName:'');
   const [remark, setRemark] = useState(props.data?props.data.Remark:'');

  const handleSubmit = () => {
    if(itemCategory===null || itemCategory<=0){
      alert('Item Category field is required');
      return;
    }
    if(inventoryAccount===null || inventoryAccount<=0){
      alert('Inventory Account field is required');
      return;
    }
    if(cogs===null || cogs<=0){
      alert('COGS Account field is required');
      return;
    }
    if(sales===null || sales<=0){
      alert('Sales Account field is required');
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to pay?"
      )
    ) {
    try {
      var fd = new FormData();
      
      fd.append("Id", id);
      fd.append("ItemCategory",itemCategories.filter(ca=>ca.Name===itemCategory)[0].Id );
     
      fd.append("InventoryAccount",chartofAccounts.filter(ca=>ca.AccountDescription===inventoryAccount)[0].Account );
   
      fd.append("COGS",chartofAccounts.filter(ca=>ca.AccountDescription===cogs)[0].Account );
      alert('post')
        fd.append("Sales",chartofAccounts.filter(ca=>ca.AccountDescription===sales)[0].Account );
        fd.append("Parent",globalUser.Parent);
        fd.append("Remark", remark);
      
      axios.post("/inventoryPostingSetups", fd, {
        headers: {  "Content-Type": "application/json" , 
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      props.OrderData && props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    try {
      axios.delete("/inventoryPostingSetups?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>Product Posting Setup </DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={itemCategory}
                        id="itemCategory"
                        onSelect={(e) => {
                          setItemCategory(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={itemCategories && itemCategories.length>0 && itemCategories.map((item) => {
                             return item.Name;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Item Category"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={inventoryAccount}
                        id="inventoryAccount"
                        onSelect={(e) => {
                          setInvetoryAccount(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Inventory Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={cogs}
                        id="cogsAccount"
                        onSelect={(e) => {
                          setCogs(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="COGS Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={sales}
                        id="salesAccount"
                        onSelect={(e) => {
                          setSales(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sales Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={12}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Remark")}
                    defaultValue={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>

              </Grid>
           
          </DialogContentText>
        </DialogContent>
         <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                        {   
                          "Id":id,
                          "ItemCategory": itemCategory,
                        "InventoryAccount": inventoryAccount,
                          "COGS": cogs,
                          "Sales": sales,
                          "Remark": remark,
                         
                        }
                        }
                        Type="Posting"
                        DocumentType={ "Inventory Posting Setup"}
                        />
                    </Button>|
          <Button variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveSharp />}>
            Save
          </Button>|
          <Button variant="standard" autoFocus onClick={handleDelete} endIcon={<DeleteSharp />}>
            Delete
          </Button>|
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
