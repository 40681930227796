import { useState, useContext, useRef, useEffect } from 'react'
import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'

import axios from '../../axiosInstances'
import { ShoppingCartContext } from '../Context/ShoppingCartContext'
import { CloseSharp, SaveAsSharp } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

export default function SecurityModel(props) {
  const theme = useTheme()
  const LogoInputRef = useRef(null)
  const { t, i18n } = useTranslation()
  const {
    globalUser,
    globalToken,
    setGlobalRoleSecurity,
    globalRoleSecurity,
    globalSetting,
  } = useContext(ShoppingCartContext)
  const [open, setOpen] = useState(false)

  /* const [id, setId] = useState(props.data.Id);
  const [role, setRole] = useState(props.data.Role); 
  const [store, setStore] = useState(props.data.Store); 
  const [department, setDepartment] = useState(props.data.Department); 
  const [functionality, setFunctionality] =useState(props.data.Functionality);
  const [read, setRead] = useState(props.data.Read===null?0:props.data.Read);
  const [modify, setModify] = useState(props.data.Modify===null?0:props.data.Modify);
  const [create, setCreate] = useState(props.data.Create===null?0:props.data.Create);
  const [remark, setRemark] = useState(props.data.Remark);
  const [rolesList,setRolesList]=useState(props.Roles);

  const [SecurityList]=useState(props.SecurityList);
  const [functionalitiesList,setFunctionalitiesList]=useState(SecurityList.filter(sec=>(sec.Department===props.data.Department &&  sec.Role===role)).map(sec=> {return sec.Functionality}));
  const loadRoleSecurity = () => {
    axios
      .get(
        "/security/getByParentAndRole?Parent=" +
          globalUser.Parent +"&Role="+globalUser.Role, {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setGlobalRoleSecurity(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  }; */
  const handleSubmit = () => {
    window.confirm('Are you sure you want to save?')
    {
      try {
        {
          globalSetting &&
            globalSetting.length > 0 &&
            globalSetting.map((gs) => {
              try {
                var fd = new FormData()
                fd.append('Id', gs.Id)
                fd.append('Type', gs.Type)
                fd.append('Description', gs.Description)
                fd.append('Value', gs.Value)
                fd.append('Parent', globalUser.Parent)
                fd.append('Remark', props.Type)

                axios.post('/settings', fd, {
                  headers: {
                    'Content-Type': 'application/json',
                    token: globalToken,
                  },
                })
              } catch (err) {
              } finally {
              }
            })
        }
      } catch (err) {
      } finally {
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    console.log(globalSetting)
  }, [])

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            height: '20px',
          }}
        >
          {props.Type + ' Setting'}{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <hr></hr>
            <table>
              {globalSetting &&
                globalSetting.length > 0 &&
                globalSetting
                  .filter((gss) => gss.Remark === props.Type)
                  .map((gs) => {
                    return (
                      <tr>
                        <td>{gs.Description}</td>
                        <td>
                          {' '}
                          <TextField
                            size="small"
                            variant="standard"
                            defaultValue={gs.Value}
                            onChange={(e) => {
                              gs.Value = e.target.value
                            }}
                            sx={{ width: '95%', marginBottom: 2 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
            </table>

            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          {globalRoleSecurity.filter(
            (sec) => sec.Functionality === 'Security' && sec.Security > 1,
          ).length > 0 && (
            <>
              <Button
                variant="standard"
                autoFocus
                onClick={() => handleSubmit()}
                endIcon={<SaveAsSharp />}
              >
                Save
              </Button>
              |
            </>
          )}

          <Button
            variant="standard"
            onClick={() => setOpen(false)}
            autoFocus
            endIcon={<CloseSharp />}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
