import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel } from "@mui/material";
import {CloseSharp, DeleteSharp, Preview, PreviewSharp, SaveSharp} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function FinanceSetupCompModel(props) {
const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalProviders,globalCustomers,globalToken }=useContext(ShoppingCartContext)
 
  const [chartofAccounts,setChartofAccounts]=useState(props.ChartOfAccounts)
  const [supplierGroups,setSupplierGroups]=useState(props.SupplierGroups)
  const [id] = useState(props.data?props.data.Id:0);
  const [supplierGroup, setSupplierGroup] = useState(props.data?props.data.SupplierGroup:'');
  const [accountPayable, setAccountPayable] = useState(props.data?props.data.AccountPayableName:'');
   const [accountReceivable, setAccountReceivable] = useState(props.data?props.data.AccountReceivableName:'');
   const [vatAccount, setVatAccount] = useState(props.data?props.data.VATAccountName:'');

   const [remark, setRemark] = useState(props.data?props.data.Remark:'');

  const handleSubmit = () => {
    if(supplierGroup===null || supplierGroup<=0){
      alert('Supplier Group field is required');
      return;
    }
    if(accountPayable===null || accountPayable<=0){
      alert('Account Payable field is required');
      return;
    }
    if(accountReceivable===null || accountReceivable<=0){
      alert('Account Receivable field is required');
      return;
    }
    if(vatAccount===null || vatAccount<=0){
      alert('VAT Account field is required');
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to pay?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("SupplierGroup", supplierGroup);
      fd.append("AccountPayable", chartofAccounts.filter(ca=>ca.AccountDescription===accountPayable)[0].Account );
      fd.append("AccountReceivable",chartofAccounts.filter(ca=>ca.AccountDescription===accountReceivable)[0].Account );
      fd.append("VATAccount",chartofAccounts.filter(ca=>ca.AccountDescription===vatAccount)[0].Account );

      fd.append("Parent",globalUser.Parent);
      fd.append("Remark", remark);
    
      axios.post("/businesspostingsetups", fd, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      props.OrderData && props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    try {
      axios.delete("/inventoryPostingSetups?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>Business Posting Setup </DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={supplierGroup}
                        id="supplierGroup"
                        onSelect={(e) => {
                          setSupplierGroup(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={supplierGroups && supplierGroups.length>0 && supplierGroups.map((item) => {
                             return item.Description;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Business Group"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={accountPayable}
                        id="accountPayable"
                        onSelect={(e) => {
                          setAccountPayable(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                       /*  inputValue={accountPayableDescription}
                        onInputChange={(event, newInputValue) => {
                          setAccountPayableDescription(newInputValue);
                        }} */
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Account Payable"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={accountReceivable}
                        id="accounrReceivable"
                        onSelect={(e) => {
                          setAccountReceivable(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Account Receivable"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={vatAccount}
                        id="vatAccount"
                        onSelect={(e) => {
                          setVatAccount(e.target.value);
                      }}
                     options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="VAT Account"
                            variant="standard"
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={12}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Remark")}
                    defaultValue={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>

              </Grid>
           
          </DialogContentText>
        </DialogContent>
        <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}>
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                        {   
                          "Id":id,
                          "SupplierGroup": supplierGroup,
                        "AccountPayable": accountPayable,
                          "AccountReceivable": accountReceivable,
                                                  "Remark": remark,
                         
                        }
                        }
                        Type="Posting"
                        DocumentType={ "Business Posting Setup"}
                        />
                    </Button>|
          <Button  variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveSharp />}>
            Save
          </Button>|
          <Button  variant="standard" autoFocus onClick={handleDelete} endIcon={<DeleteSharp />}>
            Delete
          </Button>|
          <Button  variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
