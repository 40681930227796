import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  PropaneSharp,
  ReportSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function JobsModel(props) {
  const theme=useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState();
  const {globalUser,globalToken,allItems} = useContext(ShoppingCartContext);


  
  const [bomList,setBomList] = useState([]);
 /*  const [remark,setRemark]=useState(props.Order?props.Order.Remark:'')*/
  const [modifiedDate, setModifiedDate] = useState(format(new Date(),"yyyy-MM-dd"));
  const [product, setProduct] = useState(props.Product); 
  const [standardQuantity, setStandardQuantity] = useState(1); 
 
  function splitPictures() {
    try {
      fileArray = [];
      props.order.Attachment.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }
 


  const handleSubmit = (status, lItems) => {
 
    if (!product || !product.Id ||product.Id==='undefined'){
      alert("First select the product to be produced ");
      return;
    }
    if (!standardQuantity || standardQuantity.length<=0 || standardQuantity<=0){
      alert("First select the product standard quantity ");
      return;
    }
    if (
      lItems === undefined ||
      lItems.filter((li) => li.ItemName.length > 0).length <= 0
    ) {
      alert("First select the BOM items: ");
      return;
    }

    let testResult = false;
    lItems.map((li) => {
      if (isNaN(li.Quantity) || li.Quantity <= 0) {
        alert("First insert the proper quantity of BOM item: " + li.ItemName);
        testResult = true;
        return;
      }

   
    });

    if (testResult) {
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    try {

      lItems && lItems.length>0 && lItems.map(bom=>{

        var fd = new FormData();
        fd.append("Id", bom.Id);
        fd.append("Product", product.Id);
        fd.append("StandardQuantity", standardQuantity);
        fd.append("Item",bom.Item);
        fd.append("Quantity", bom.Quantity);
        fd.append("ModifiedDate", format(new Date(),"yyyy-MM-dd"));
        fd.append("Status", status);
        fd.append("Parent", globalUser.Parent);
        fd.append("Remark", bom.Remark);
       axios
          .post("/bomsettings", fd, {
            headers: { "Content-Type": "application/json" , 
                token: globalToken,
            },
          })
         
        }) 
    } catch (err) {
    } finally {
      //setBomList([]);
      //setCustomer('')
      //props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = (id) => {
    try {
      axios.delete("/jobs?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleLineItemDelete = (id) => {
    try {
      axios.delete("/bomList?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
    }
  };

  const loadBOMItem =  (prod) => {
    
   axios.get("/bomsettings/getByParentAndProduct?Parent=" + globalUser.Parent + "&Product="+ prod, {
    headers: {
      token: globalToken,
    },
  }).then(response=>{
        if (response.data.data){
          setBomList(response.data.data);
         setStandardQuantity(response.data.data.length>0? response.data.data[0].StandardQuantity:0)
   
        }else{
          alert('log out and log in again')
        }
      })
      
    };
 
  useEffect(() => {
    loadBOMItem(props.Product)
    
  }, []);

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
                  <DialogTitle style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}} >
                    BOM Setting
                    </DialogTitle>
                    <Grid container mt={1}>
                   
                    
                  
                     <Grid item xs={6}>
                     <Autocomplete
                                            label="Products"
                                            id="Items"
                                            size="small"
                                            variant="standard"
                                            getOptionLabel={option=>option.Name}
                                            value={product}
                                            onChange={(e,newvalue) => {
                                          setProduct(newvalue)
                                       
                                          loadBOMItem(newvalue.Id)
                                            }}
                                            options={
                                              !(allItems === undefined) &&
                                              !(allItems === null) &&
                                              allItems.length > 0
                                                ? allItems.map((it) => {
                                                      return {Id:it.Item,Name:it.Name};
                                                    })
                                                : [""]
                                            }
                                            sx={{ width: "95%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label='Products'
                                                //error={!from ? true : false}
                                              />
                                            )}
                                          />
                     </Grid>
                     <Grid item xs={6}>
                     <TextField
                     label="Standard Quantity"
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            value={standardQuantity}
                                            maxRows={4}
                                            sx={{ width: "95%" }}
                                            onChange={(e) => {
                                              setStandardQuantity(e.target.value);
                                              
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                     </Grid>
                    
                      <Grid
                        item
                        xs={6}
                        mt={1}
                        
                      >
                        <TextField
                          size="small"
                          label="Modified Date"
                          type="date"
                          value={modifiedDate}
                         onChange={(e) => {
                            setModifiedDate( e.target.value);
                          }} 
                          sx={{ width: "70%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    
                      <Grid
                        item
                        xs={6}
                       
                      >
                        Designed By:{globalUser.Name} <br></br>
                        <span style={{}}>
                          Status: {'Draft'}
                        </span>
                        {/* {Ord.ApprovedByName}<br></br>
                        Order No:{" "}
                        {props.data?props.data.Id:Ord.Order?Ord.Order:0}  */}
                      </Grid>
                   
                     
                    

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container>
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>
                             
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  textAlign:'center'
                                }}
                              >
                                Remark
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    if (
                                      bomList &&
                                      bomList.filter(
                                        (itt) =>((itt.Item === ""))
                                      ).length <= 0
                                    ) {
                                      let newLineItem = {};
                                      newLineItem.StandardQuantity = 0;
                                      newLineItem.Item = '';
                                      newLineItem.Quantity = 0;
                                      newLineItem.Product=product.Id;


                                      setBomList((xx) => [...xx,newLineItem]);
                                    } else {
                                      alert("First complete the data");
                                    }
                                  }}
                                >
                                  <Add sx={{color:theme.palette.primary.contrastText}} />
                                </IconButton>
                              </Grid>

                              {bomList && bomList!==null && bomList.length > 0 &&
                                bomList
                                  .map((item, index) => {
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Autocomplete
                                            label="Items"
                                            id="Items"
                                            size="small"
                                            variant="standard"
                                            value={item.ItemName}
                                            onSelect={(e) => {
                                          
                                              if (bomList && bomList.length>0 &&
                                                bomList.filter(
                                                  (li) =>
                                                    (li.ItemName === e.target.value )
                                                ).length > 0
                                              ) {
                                                item.Item = "";
                                                item.ItemName = "";
                                                item.Product='';
                                                item.Quantity = 0;
                                                setBomList(
                                                  bomList.map((litem) => {
                                                    return litem;
                                                  })
                                                );
                                                //alert('The selected item is already registered')
                                                return;
                                              } else {
                                                let selectedProduct =
                                                allItems.filter(
                                                    (selItem) =>
                                                      selItem.Name ===
                                                      e.target.value
                                                  );

                                                if (
                                                  selectedProduct &&
                                                  selectedProduct.length > 0
                                                ) {
                                                  let producedQty=0;
                                                  bomList.filter(jl=>jl.Item===selectedProduct[0].Item).map(itt=>{
                                                    producedQty=parseFloat(producedQty) + parseFloat(itt.Quantity)
                                                  });
                                                  item.Quantity =
                                                    selectedProduct[0]
                                                      .Quantity!==0
                                                      ? (parseFloat(selectedProduct[0]
                                                          .Quantity)-parseFloat(producedQty))
                                                      : 1;
                                                 
                                                  item.Item =
                                                    selectedProduct[0].Item;
                                                  item.ItemName =
                                                    selectedProduct[0].Name;
                                                    item.Product=product.Id
                                                    setBomList(ji=>[...ji,item])
                                                    setBomList(
                                                    bomList.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                } else {
                                                  item.Item = "";
                                      item.ItemName = "";
                                      item.Product=product.Id;
                                      item.Quantity='';

                                                  setBomList(
                                                    bomList.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                }
                                              }
                                            }}
                                            options={
                                              !(allItems === undefined) &&
                                              !(allItems === null) &&
                                              allItems.length > 0
                                                ? allItems.map((it) => {
                                                      return it.Name;
                                                    })
                                                : [""]
                                            }
                                            sx={{ width: "95%" }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={item.Name}

                                                //error={!from ? true : false}
                                              />
                                            )}
                                          />
                                        </Grid>

                                        <Grid item xs={2}>
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            value={item.Quantity}
                                            maxRows={4}
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                              item.Quantity = e.target.value;
                                              setBomList(
                                                bomList.map((litem) => {
                                                  return litem;
                                                })
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>

                                        <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            variant="standard"
                                            value={item.Remark}
                                            sx={{ width: "95%" }}
                                            onChange={(e) => {
                                              item.Remark=e.target.value
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                         
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Tooltip title="remove">
                                            <Button
                                              endIcon={<Delete width="10px" />}
                                              onClick={() => {
                                                if(bomList.filter((litem) =>
                                                    ((litem.Item ===
                                                      item.Item) )
                                                ).Id){
                                                
                                                handleLineItemDelete(
                                                  bomList.filter(
                                                    (litem) =>
                                                      litem.Item ===
                                                        item.Item &&
                                                      parseInt(
                                                        litem.AssignedTo
                                                      ) ===
                                                        parseInt(item.AssignedTo)
                                                  )[0].Id
                                                )  
                                              };
                                             
                                             
                                              }}
                                            ></Button>
                                          </Tooltip>
                                        </Grid>
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                            


                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {bomList && bomList!==null && bomList.length > 0 &&
                              bomList.map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <Autocomplete
                                                label="Items"
                                                id="Items"
                                                size="small"
                                                variant="standard"
                                                value={item.ItemName}
                                                onSelect={(e) => {
                                                  if (
                                                    bomList.filter(
                                                      (li) =>
                                                        li.ItemName ===
                                                        e.target.value
                                                    ).length > 0
                                                  ) {
                                                    item.Item = "";
                                                    item.ItemName = "";
                                                    item.Quantity = 0;
                                                  
                                                    setBomList(
                                                      bomList.map((litem) => {
                                                        return litem;
                                                      })
                                                    );

                                                    return;
                                                  } else {
                                                    let selectedProduct =
                                                    allItems.filter(
                                                        (selItem) =>
                                                          selItem.Name ===
                                                          e.target.value
                                                      );
                                                    if (
                                                      selectedProduct &&
                                                      selectedProduct.length > 0
                                                    ) {
                                                      item.Quantity =
                                                        selectedProduct[0]
                                                          .DefaultOrderQty!==0
                                                          ? selectedProduct[0]
                                                              .DefaultOrderQty
                                                          : 1;
                                                     
                                                      item.Item =
                                                        selectedProduct[0].Item;
                                                      item.ItemName =
                                                        selectedProduct[0].Name;
                                                      
                                                      setBomList(
                                                        bomList.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    } else {
                                                      item.Quantity = 0;
                                                  
                                                      item.Item = "";
                                                      item.ItemName = "";
                                                      setBomList(
                                                        bomList.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                    }
                                                  }
                                                }}
                                                options={
                                                  !(
                                                    allItems === undefined
                                                  ) &&
                                                  !(allItems === null) &&
                                                  allItems.length > 0
                                                    ? allItems.map((it) => {
                                                        return it.Name;
                                                      })
                                                    : [""]
                                                }
                                                sx={{ width: "100%" }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label={item.ItemName}

                                                    //error={!from ? true : false}
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.Quantity}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.Quantity =
                                                    e.target.value;
                                                  setBomList(
                                                    bomList.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                           
                                            <Grid item xs={4}>
                                              Remark :
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                             {item.Remark}
                                            </Grid>
                                            <Grid item xs={12}>
                                              <hr></hr>
                                              Remove: {"   "}
                                              <Tooltip title="remove">
                                                <Button
                                                  endIcon={
                                                    <Delete width="10px" />
                                                  }
                                                  onClick={() => {
                                                    handleLineItemDelete(
                                                      bomList.filter(
                                                        (litem) =>
                                                          litem.Item ===
                                                          item.Item
                                                      )[0].Id
                                                    );
                                               
                                                    if (
                                                      bomList.filter(
                                                        (litem) =>
                                                          !(
                                                            litem.Item ===
                                                            item.Item
                                                          )
                                                      ).length <= 0
                                                    ) {
                                                      let newLineItem = {};
                                                      newLineItem.Item = "";
                                                      newLineItem.ItemName = "";
                                                    newLineItem.Quantity = 0;
                                                     
                                                      setBomList((xx) => [
                                                        ...xx,
                                                        newLineItem,
                                                      ]);
                                                    }
                                                  }}
                                                ></Button>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                          
                          </Grid>
                        </Grid>
                       
                      </Grid>
                    </Grid>
                  
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
 
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                /*  endIcon={<Send />} */
                autoFocus
                onClick={() =>
                  handleSubmit(
                    "Draft",
                    bomList.filter((itt) => itt.ItemName && itt.ItemName.length > 0)
                  )
                }
              >
                Draft
              </Button>
              | <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                /*  endIcon={<Send />} */
                autoFocus
                onClick={() =>
                  handleSubmit(
                    "Approved",
                    bomList.filter((itt) => itt.ItemName && itt.ItemName.length > 0)
                  )
                }
              >
                Approved
              </Button>
              |
              <Button
              variant="standard"
                style={{ fontSize: "10px" }}
                /* endIcon={<Send />} */
                autoFocus
                onClick={() => handleDelete(0)}
              >
                Delete
              </Button>
              |
           
          <Button
          variant="standard"
            style={{ fontSize: "10px" }}
            /* endIcon={<Close />} */
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
