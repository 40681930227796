import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  PropaneSharp,
  Preview,
  PreviewSharp,
  RequestQuoteSharp,
  ApprovalSharp,
  DeliveryDiningSharp,
  ReceiptSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";

import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";


export default function ItemTransferModelHistory(props) {
  const theme=useTheme();
  const { globalUser, allItems,globalToken,globalRoleSecurity } = useContext(ShoppingCartContext);
  const [open, setOpen] = useState(false);

  const [transferlines, setTransferlines] = useState(props.ItemTransferLines);
  const [transferOrder, setTransferOrder] = useState(props.TransferOrder);
 
 


  const handleSubmit = (status, lItems) => {
   
 
    if (
      lItems.filter(li=>li.Order===transferOrder.Id) === undefined ||
      lItems.filter(li=>li.Order===transferOrder.Id).filter((li) => li.Name.length > 0).length <= 0
    ) {
      alert("First select the items");
      return;
    }
 

    lItems.filter(li=>li.Order===transferOrder.Id).map((li) => {
      if (isNaN(li.Quantity) || li.Quantity <= 0) {
        alert("First insert the proper quantity for product: " + li.Name);
         return;
      }
    });
    if (transferOrder.From === undefined || transferOrder.From === null || transferOrder.From===0) {
      alert("First select store from");
      return;
    }
    if (transferOrder.To === undefined || transferOrder.To === null || transferOrder.To===0) {
      alert("First select store to");
      return;
    }
    if (transferOrder.From==transferOrder.To) {
      alert("The source and destination stores must be differet");
      return;
    }
    if (
      window.confirm(
        "are you sure you want to save?"
      )
    ) {
    try {
        
            var fd = new FormData();
            fd.append("Id", transferOrder.Id);
            fd.append("Order",transferOrder.Order);
            fd.append("From", transferOrder.From);
            fd.append("To", transferOrder.To);
            fd.append("RequestedBy", transferOrder.RequestedBy?transferOrder.RequestedBy:globalUser.Id);
            fd.append("RequestedDate",transferOrder.RequestedDate? transferOrder.RequestedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("ApprovedBy", transferOrder.ApprovedBy?transferOrder.ApprovedBy:globalUser.Id);
            fd.append("ApprovedDate",transferOrder.ApprovedDate? transferOrder.ApprovedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("IssuedBy", transferOrder.IssuedBy?transferOrder.IssuedBy:globalUser.Id);
            fd.append("IssuedDate",transferOrder.IssuedDate? transferOrder.IssuedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("ReceivedBy", transferOrder.ReceivedBy?transferOrder.ReceivedBy:globalUser.Id);
            fd.append("ReceivedDate",transferOrder.ReceivedDate? transferOrder.ReceivedDate : format(new Date(), "yyyy-MM-dd"));
            fd.append("Status", status);
            fd.append("Parent", globalUser.Parent);            
            fd.append("Remark", transferOrder.Remark);
            fd.append("TransferLines",JSON.stringify(transferlines));

            axios
              .post("/itemtransfers", fd, {
                headers: { "Content-Type": "application/json" , 
                    token: globalToken,
                 },
              })
                       
      
    } catch (err) {
      alert('error')
    } finally {
    
     props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }

  };



  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
       
                <DialogContentText mt={1}>
                  <DialogTitle
                    style={{
                      backgroundColor: theme.palette.primary.main,             color:theme.palette.primary.contrastText,
                      height: "15px",
                    }}
                    
                  >
                     {"From: " + transferOrder.FromStoreName + " -->   To: " + transferOrder.ToStoreName}
                  </DialogTitle>
                 
                    <Grid container>
                    <Grid
                        item
                        xs={12}
                        pl={'35%'}
                        sx={{
                         backgroundColor:theme.palette.primary.main,
                         textDecoration:'underline',
                         fontWeight:'bold',
                        
                       }}
                      >
                        {"Transfer " + transferOrder.Status}
                      </Grid>
                      <Grid item xs={6}>
                        <span>Doc. No.: {transferOrder.Id}</span><br></br>
                        <span>Status: {transferOrder.Status}</span><br></br>
                        Issue Date: {transferOrder.RequestedDate}
                      </Grid>
                      
                      <Grid item xs={6}>
                        Requested By: {transferOrder.IssuedToName ? transferOrder.RequestedByName? transferOrder.RequestedByName: globalUser.Name:globalUser.Name}<br></br>
                        Approved By: {transferOrder.ApprovedByName}
                        </Grid>
                        <Grid item xs={6} mt={1}>
                        From Store: {transferOrder.FromStoreName}<br></br>
                        To Store:{transferOrder.ToStoreName}
                      </Grid>
                      <Grid item xs={6}>
                        Issued By: {transferOrder.IssuedByName}<br></br>
                        Received By: {transferOrder.ReceivedByName}
                      </Grid>
                     

                      <Grid item xs={12}>
                      Remark:{transferOrder.Remark }
                      
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                          
                        >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>
                             
                              <Grid
                                item
                                xs={3}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  textAlign: "center",
                                }}
                              >
                                Remark
                              </Grid>
                          

                              {transferlines &&
                                transferlines !== null &&
                                transferlines.length > 0 &&
                             transferlines
                                  .filter(
                                    (it) =>
                                      parseInt(it.Order) === parseInt(transferOrder.Id)
                                  )
                                  .map((item, index) => {
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={4}>
                                        {item.Name}
                                        
                                        </Grid>
                                     
                                        <Grid item xs={3}>
                                          {item.Quantity}
                                         
                                        </Grid>

                                        <Grid item xs={4}>
                                        {item.Remark}
                                         
                                        </Grid>
                                      
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {transferlines &&
                              transferlines !== null &&
                              transferlines.length > 0 &&
                              transferlines.map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                            {item.Name}
                                            
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {item.Quantity}
                                            
                                            </Grid>

                                            <Grid item xs={4}>
                                              Remark :
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                              {item.Remark}
                                            </Grid>
                                           
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
               
                </DialogContentText>
             
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button variant="standard" endIcon={<PreviewSharp />}>
            <InvoicePreview
              icon={
                <div style={{ fontSize: "10px" }}>Preview</div>
              }
              data={[transferOrder]}
              LineItems={transferlines}
              Type="TransferOrder"
              DocumentType="Transfer Issue"
            />
          </Button>
         
          |
        {props.TransferOrder.Status==='Issued' && globalRoleSecurity.filter(gs=>((gs.Functionality===('Transfer Issue'))  && gs.Security>1)).length>0 &&
          <>
          <Button
          variant="standard"
            style={{ fontSize: "10px" }}
           endIcon={<DeliveryDiningSharp />} 
            autoFocus
            onClick={() =>
              handleSubmit(
                "Approved",
                transferlines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Issue Return
          </Button>
          |
          </>
}
{props.TransferOrder.Status==='Received' && globalRoleSecurity.filter(gs=>((gs.Functionality===('Transfer Receipt'))  && gs.Security>1)).length>0 &&
          <>
          <Button
          variant="standard"
            style={{ fontSize: "10px" }}
           endIcon={<DeliveryDiningSharp />} 
            autoFocus
            onClick={() =>
              handleSubmit(
                "Issued",
                transferlines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Receipt Return
          </Button>
          |
          </>
}

          <Button
          variant="standard"
            style={{ fontSize: "10px" }}
            /* endIcon={<Close />} */
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
