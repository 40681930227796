import React,{useMemo,useEffect,useState,useContext} from 'react'
import {Grid,Box,Button,IconButton,TextField} from "@mui/material"
import {AddSharp,EditSharp, RefreshSharp, SaveSharp} from "@mui/icons-material"
import MaterialReactTable from "material-react-table";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import { useTheme } from "@mui/material/styles"; 
import axios from "../../axiosInstances";
import { useNavigate } from "react-router-dom";
import NewVisit from "./NewVisit"
import { format } from "date-fns";
import { th } from 'date-fns/locale';
export default function TriageComp() {
    const navigate = useNavigate();
    const theme = useTheme();
    const [update, setUpdate] = useState(false);
    const mainColumn = useMemo(
      () => [
        {
          header: "Id",
          accessorKey: "Id",
        },
        {
          header: "Name",
          accessorKey: "Name",
        },
        {
          header: "Mobile",
          accessorKey: "Mobile",
          size:130
        },
      
        {
          header: "Assigned To",
          accessorKey: "AssignedToName",
        },
       
      ],
      []
    );
    const [visitDate, setVisitDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [activePatientsList, setActivePatientsList] = useState([]);
    const { setPageHeader,globalUser,globalToken,globalRoleSecurity }=useContext(ShoppingCartContext)
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [rowSelection, setRowSelection] = useState({});
    const [triageList, setTriageList] = useState([]);
    const [triagHistoryGroups, setTriagHistoryGroups] = useState([]);
    const [triagHistory, setTriagHistory] = useState([]);
    
    const [physicians, setPhysicians] = useState([]);
    const [subSpecialities, setSubSpecialities] = useState([]);
    const [displayState,setDisplayState]=useState(true)
    const handleSubmitVitalSign=()=>{
      if (
        window.confirm(
          "Are you sure you want to pay?"
        )
      ) {
    try {
      triageList && triageList.filter(tr=>parseInt(tr.Customer)===parseInt(selectedPatient.Id)).map(item=>{
        var fd = new FormData();
        fd.append("Id", 0);
        fd.append("Customer", selectedPatient.Id);
        fd.append("VisitId", selectedPatient.VisitId);
        fd.append("OrderedBy", globalUser.Id);
        fd.append("OrderDate", format(new Date(),"yyyy-MM-dd"));
        fd.append("Type", 'Service');
        fd.append("Item", item.Id);
        fd.append("Name", item.Name);
        fd.append("Dosage", 1);
        fd.append("Freq", 1);
        fd.append("OrderQty", 1);
        fd.append("Description", '');
        fd.append("RemainingQty", 0);
        fd.append("Outsource", 0);
        fd.append("Status", '');
        fd.append("ExaminationDate", format(new Date(),"yyyy-MM-dd"));
        fd.append("Result", item.Result);
        fd.append("Paid", 0);
        fd.append("Route", '');
        fd.append("Remark", '');
     axios
        .post("/hosorders", fd, {
          headers: {
            "Content-Type": "application/json",
            token: globalToken,
          },
        }).then(()=>setUpdate(!update))
      })
    } catch (err) {
    } 
  }
    }
    const getSelectedPatient = () => {
      Object.getOwnPropertyNames(rowSelection).map((item) => {
        if (item === "length") {
          setSelectedPatient(null);
        } else {
        
          setSelectedPatient(
            activePatientsList.filter(
              (ap) => parseInt(ap.Id) === parseInt(item)
            )[0]
          );
        }
      });
    };
    const loadTriageList =  (patients) => {
     axios.get("/items/getByParentAndCategoryName?Parent=" + globalUser.Parent + "&CategoryName="+'Vital Sign', {
      headers: {
        token: globalToken,
      },
    }).then(response=>{
          if (response.data.data){          
                       setTriageList(()=>(tril=>[...tril.flat(),
           (patients  && patients.filter(pt=>(triageList.filter(tril=>((parseInt(tril.Customer)===parseInt(pt.Id)).length<=0)))).map(pt=>{
            return(
          response.data.data.map(tr=>{
           return ({...tr,Result:(triagHistory.filter(th=>((parseInt(th.Customer)===parseInt(pt.Id)) && (parseInt(th.VisitId)===2) && (parseInt(th.Item)===parseInt(tr.Id)))).length>0 ? 
            triagHistory.filter(th=>((parseInt(th.Customer)===parseInt(pt.Id)) && (parseInt(th.VisitId)===2) && (parseInt(th.Item)===parseInt(tr.Id))))[0].Result:'')
            ,Customer:pt.Id})
           }))          
          
         })).flat()
        ])
           )

          }else{
            alert('log out and log in againllll')
          }
        })
        
      };
      
      const loadTriageHistory =  (patients) => {
        axios.get("/hosorders/getByCustomerAndItemType?Parent=" +
        globalUser.Parent +
        "&Type="+'Vital Sign', {
         headers: {
           token: globalToken,
         },
       }).then(response=>{
             if (response.data.data){
             setTriagHistoryGroups(response.data.data.VisitGroups)
             setTriagHistory(response.data.data.Histories)
            
             }else{
               alert('log out and log in againllll')
             }
           })
           
         };
const loadActivePatientsList = () => {
          axios
            .get(
              "/hosvisits/getPatientByVisitDateAndAssignedTo?Parent=" +
                globalUser.Parent +
                "&VisitDate=" +
                visitDate +
                "&AssignedTo=",
              {
                headers: {
                  "Content-Type": "application/json" ,
                  token: globalToken,
                },
              }
            )
            .then((response) => {
              if (response.data.data) {
                setActivePatientsList(response.data.data.ActivePatients);
                loadTriageList(response.data.data.ActivePatients);
               loadTriageHistory(response.data.data.ActivePatients)
              } else {
                alert("log out and log in again");
              }
            });
        };
        const loadPhysicians = () => {
          axios
            .get(
              "/employees/getByParentAndType?Parent=" +
                globalUser.Parent +
                "&Type=Physician",
              {
                headers: {
                  "Content-Type": "application/json" ,
                  token: globalToken,
                },
              }
            )
            .then((response) => {
              if (response.data.data) {
                setPhysicians(response.data.data);
              } else {
                alert("log out and log in again");
              }
            });
        };
        useEffect(() => {

            if(!globalUser){
              navigate('/');
              return;
            }
            loadActivePatientsList();
            loadPhysicians(); 
            setPageHeader("Triage")
            var intervalId= setInterval(() => {
              loadActivePatientsList();
            }, 20000);
            return ()=>clearInterval(intervalId);
          }, [update,visitDate]);
          useEffect(() => {
            if (Object.getOwnPropertyNames(rowSelection).length > 0) {
              getSelectedPatient();
            }
          }, [rowSelection]);
  return (
    <Grid container spacing={2} mt={1}>+
         <Grid item xs={8}>
            <MaterialReactTable
              columns={mainColumn}
              data={activePatientsList}
              initialState={{
                density: "compact",
                pagination: {
                  pageIndex: 0,
                  pageSize: 12,
                },
              }}
              enableGlobalFilter
              enableColumnResizing
              enableRowSelection
              enableMultiRowSelection={false}
              getRowId={(originalRow) => originalRow.Id}
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              enableRowNumbers
              muiTopToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              renderTopToolbarCustomActions={() => 
              <Box>
             <TextField
                size="small"
                label="Visit Date"
                type="date"
                value={visitDate}
                onChange={(e) => {
                  setVisitDate(e.target.value);
                }}
                sx={{
                  width: "15",
                  height: "5",
                  marginBottom: 1,
                  marginLeft: 1,
                  color: theme.palette.primary.contrastText,
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.palette.primary.contrastText,
                    height: "5",
                  },
                  shrink: true,
                }}
              />|
              <Button variant='standard' endIcon={<RefreshSharp />} onClick={()=>setUpdate(!update)}>
                Refresh
              </Button>
                        | Active Patients
              </Box>
              }
              enableRowActions
              renderRowActions={({ row }) =>(
                <Box>
                <IconButton  >
                <NewVisit
                         icon={<EditSharp style={{ width: "15px", height: "15px" }} />}
                          data={row.original}
                          SubSpecialities={subSpecialities}
                          Type="Customer"
                          Physicians={physicians}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />
                </IconButton>|
              
             
              </Box>
              ) }
            ></MaterialReactTable>
            </Grid>
            <Grid item xs={4}>
            <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sx={{backgroundColor:theme.palette.primary.main,color:theme.palette.primary.contrastText}}>
            {selectedPatient &&
            <>
              {"  Patient Name: "+  selectedPatient.Name + "   |   "}
              {displayState && <><Button variant="standard" endIcon={<SaveSharp />} onClick={()=>handleSubmitVitalSign()}
                  >Save</Button>|</>}
              <Button variant="standard" onClick={()=>setDisplayState(!displayState)}>{displayState?"History":"Vital Sign"}</Button>
            
            </>
            }
            </Grid>
                          {displayState && selectedPatient && triageList && triageList.filter(tr=>(parseInt(tr.Customer)===parseInt(selectedPatient.Id))).map(trl=>{
return (<Grid item xs={12}>
 <TextField
                size="small"
                label={trl.Name}
                value={trl.Result}
               multiline
                sx={{
                  width: "95%",
                  marginBottom: 1,
                  marginLeft: 1,
                }}
                onChange={(e)=>{
                  trl.Result=e.target.value
                  setTriageList(triageList.map(tr=>tr))
                }}
                InputLabelProps={{
                  sx: {
                    height: "5",
                  },
                  shrink: true,
                }}
              />
                          </Grid>)
                          })
                    }
                    {!displayState &&
                    <table>
                      <thead>
                        <th>Test Type</th>
                        {selectedPatient && triagHistoryGroups && triagHistoryGroups.filter(thg=>parseInt(thg.Customer)===parseInt(selectedPatient.Id)).map(thg=>{
                          return (
                            <th>{thg.OrderDate}</th>
                          )
                        })}
                      </thead>
                   
                        {selectedPatient && 
                                triageList && triageList.filter(tl=>parseInt(tl.Customer)===parseInt(selectedPatient.Id)).map(tl=>{
                                  return(
                                    <tr>
                                      <td>{tl.Name}</td>
                       {triagHistoryGroups && triagHistoryGroups.filter(thg=>parseInt(thg.Customer)===parseInt(selectedPatient.Id)).map((thg,index)=>{
                          return(                    
                          triagHistory && triagHistory.filter(th=>((parseInt(tl.Id)===parseInt(th.Item)) && (parseInt(th.Customer)===parseInt(selectedPatient.Id)) && (th.VisitId===thg.VisitId))).map(th=>{
                            return(
                             <td>{th.Result}</td>
                            )
                          }) 
                          
                          )
                        })
                      } 
                      </tr>
                          )                    
                          
                        })
                      }
                     
                    </table>

                    }
                        </Grid>
            </Grid>
    </Grid>
  )
}
