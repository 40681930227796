import { useState, useEffect,useContext } from "react";
import { Button,Stack, IconButton,Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel, Avatar } from "@mui/material";
import {CloseSharp, DeleteSharp, Preview, PreviewSharp, SaveSharp,Clear} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'
import axios from "../../axiosInstances";
import FiexedAssetJournalModel from "./FiexedAssetJournalModel";
import { useTheme } from "@mui/material/styles";

export default function FinanceSetupCompModel(props) {
  const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  var fileObj = [];
  var fileArray = [];
  const { globalUser,globalProviders,globalCustomers,globalToken }=useContext(ShoppingCartContext)
 
  const [chartofAccounts,setChartofAccounts]=useState(props.ChartOfAccounts)
  const [categories,setCategories]=useState(props.Categories)
  const [employeesList, setEmployeesList] = useState(props.EmployeesList);
  const [id] = useState(props.data?props.data.Id:0);
  const [name, setName] = useState(props.data?props.data.Name:'');
  const [description, setDescription] = useState(props.data?props.data.Description:'');
    
   const [employee, setEmployee] = useState(props.data?employeesList.filter(emp=>parseInt(emp.Id)===parseInt(props.data.Employee)).length>0?employeesList.filter(emp=>emp.Id===props.data.Employee)[0].Name:'':'');
   const [vendor, setVendor] = useState(props.data?globalProviders.filter(gp=>parseInt(gp.Id)=== parseInt(props.data.Vendor)).length>0?globalProviders.filter(gp=>gp.Id=== props.data.Vendor)[0].Name:'':'');
  const [acqisitionAmount, setAcqisitionAmount] = useState(props.data?props.data.AcqisitionAmount:'');

   const [purchaseDate, setPurchaseDate] = useState(props.data?props.data.PurchaseDate:'');
   const [depreciationStartDate, setDepreciationStartDate] = useState(props.data?props.data.DepreciationStartDate:'');
   const [depreciationType, setDepreciationType] = useState(props.data?props.data.DepreciationType:'');
   const [bookValue, setBookValue] = useState(props.data?props.data.BookValue:'');
   const [category, setCategory] = useState(props.data?props.data.Category:'');
   const [Photofiles, setPhotofiles] = useState(splitPictures());
   const [disposalDate, setDisposalDate] = useState(props.data?props.data.DisposalDate:'');
   const [customer, setCustomer] = useState(props.data?globalCustomers.filter(gp=>parseInt(gp.Id)=== parseInt(props.data.Customer)).length>0?globalCustomers.filter(gp=>gp.Id=== props.data.Customer)[0].Name:'':'');
   const [disposalAmount, setDisposalAmount] = useState(props.data?props.data.DisposalAmount:'');
   const [salvageValue, setSalvageValue] = useState(props.data?props.data.SalvageValue:'');
   const [remark, setRemark] = useState(props.data?props.data.Remark:'');
   function splitPictures() {
   
    try {
      fileArray = [];
      props.data.Picture.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }


}
   function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setPhotofiles(fileArray);
  }
  const handleSubmit = () => {
    if(category===null || category<=0){
      alert('Category field is required');
      return;
    }
    if(name===null || name<=0){
      alert('Name field is required');
      return;
    }
    if(depreciationType===null || depreciationType<=0){
      alert('Depreciation Type field is required');
      return;
    }
    
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Name", name);
      fd.append("Description", description);
          fd.append("Employee",employeesList.filter(emp=>emp.Name===employee).length>0?employeesList.filter(emp=>emp.Name===employee)[0].Id:0);
        fd.append("Vendor",globalProviders.filter(emp=>emp.Name===vendor).length>0?globalProviders.filter(emp=>emp.Name===vendor)[0].Id:0);
      fd.append("AcqisitionAmount",acqisitionAmount);
          fd.append("PurchaseDate", purchaseDate);
      fd.append("DepreciationStartDate", depreciationStartDate);
      fd.append("DepreciationType", depreciationType);
      fd.append("BookValue", bookValue);
      fd.append("Category", category);
      
      Array.from(document.getElementById("fixedAssetPicture").files).map((file) => {
           fd.append("Picture", file);
      });
      fd.append("DisposalDate", disposalDate);
      fd.append("Customer", globalCustomers.filter(emp=>emp.Name===customer).length>0?globalCustomers.filter(emp=>emp.Name===customer)[0].Id:0);
      fd.append("DisposalAmount", disposalAmount);
      fd.append("SalvageValue", salvageValue);
      fd.append("Parent",globalUser.Parent);
      fd.append("Remark", remark);

      axios.post("/fixedassets", fd, {
        headers: { "Content-Type": "multipart/form-data",
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      props.OrderData && props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    try {
      axios.delete("/fixedassets?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>
        <Stack direction='row' spacing={2}> <Avatar src={props.data?props.data.Picture:''} /> <span> {props.data?props.data.Name:''}</span></Stack> 
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container>
        
 

  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Name")}
                    defaultValue={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Description")}
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    type='float'
                    label={t("Acquisition Cost")}
                    defaultValue={acqisitionAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                    onChange={(e) => {
                      setAcqisitionAmount(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={vendor}
                        id="vendor"
                        onSelect={(e) => {
                          setVendor(e.target.value);
                          
                        }}
                        options={globalProviders && globalProviders.length>0 && globalProviders.map((item) => {
                             return item.Name;
                        })}
                        sx={{
                          width: "95%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Vendor"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
                  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={employee}
                        id="employee"
                        onSelect={(e) => {
                          setEmployee(e.target.value);
                          
                        }}
                        options={employeesList && employeesList.length>0 && employeesList.map((item) => {
                             return item.Name;
                        })}
                        sx={{
                          width: "95%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Employee"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    type='date'
                    variant="standard"
                    label={t("Purchase Date")}
                    defaultValue={purchaseDate}
                    onChange={(e) => {
                      setPurchaseDate(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    type='date'
                    variant="standard"
                    label={t("Depreciation Start Date")}
                    defaultValue={depreciationStartDate}
                    onChange={(e) => {
                      setDepreciationStartDate(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                  disabled={true}
                    size="small"
                    type='float'
                    variant="standard"
                    label={t("Book Value")}
                    defaultValue={bookValue?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                    onChange={(e) => {
                      setBookValue(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={category}
                        id="category"
                        onSelect={(e) => {
                          setCategory(e.target.value);
                          
                        }}
                        options={categories && categories.length>0 && categories.map((item) => {
                             return item.Description;
                        })}
                        sx={{
                          width: "95%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="FA Category"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
  <TextField
                    size="small"
                    type='date'
                    variant="standard"
                    label={t("Disposal Date")}
                    defaultValue={disposalDate}
                    onChange={(e) => {
                      setDisposalDate(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
  </Grid>
  <Grid item xs={12} md={6}>
  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={customer}
                        id="Customer"
                        onSelect={(e) => {
                          setCustomer(e.target.value);
                          
                        }}
                        options={globalCustomers && globalCustomers.length>0 && globalCustomers.map((item) => {
                             return item.Name;
                        })}
                        sx={{
                          width: "95%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Disposed to Customer"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
  <TextField
                    size="small"
                    variant="standard"
                    type='float'
                    label={t("Disposal Amount")}
                    defaultValue={disposalAmount?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                    onChange={(e) => {
                      setDisposalAmount(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
  </Grid>
  <Grid item xs={12} md={6}>
  <TextField
                    size="small"
                    variant="standard"
                    type='float'
                    label={t("Salvage Amount")}
                    defaultValue={salvageValue?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                    onChange={(e) => {
                      setSalvageValue(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
  </Grid>
  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Remark")}
                    defaultValue={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                <IconButton onClick={()=>{
                  setPhotofiles([])
               
                }}>
                {<Clear style={{ width: "20px", height: "20px" }} />}
                </IconButton>
                  <label htmlFor="fixedAssetPicture">Product Picture
                  <input
                    type="file"
                    id="fixedAssetPicture"
                    multiple
                    onChange={uploadMultipleFiles}
                    hidden
                  />
                  
                  </label>
                  <div className="form-group multi-preview">
                    {(Photofiles || []).map((address) => (
                      <img
                        src={address}
                        alt="..."
                        style={{ width: "95%", height: "95" }}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
           
          </DialogContentText>
        </DialogContent>
         <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                        {   
                        "Id":id,
                       "Name": name,
                        "Description": description,
                          "PurchaseDate": purchaseDate,
                          "Remark": remark,
                         
                        }
                        }
                        Type="FixedAsset"
                        DocumentType={ "Fixed Asset"}
                        />
                    </Button>|
                    <Button variant="standard" autoFocus endIcon={<SaveSharp />}>
                    <FiexedAssetJournalModel
                  icon={
                    <div>Depreciation
                    </div>
                  }
                  Journals={[{Id:0,Type:'Depreciation'}]}
                  ReferenceTo={props.data}
                  Type="New"
                 
                /> 
          </Button>|
          <Button variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveSharp />}>
            Save
          </Button>|
          <Button variant="standard" autoFocus onClick={handleDelete} endIcon={<DeleteSharp />}>
            Delete
          </Button>|
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
