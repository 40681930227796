import { useState, useEffect, useRef, useContext } from "react";
import axios from "../../axiosInstances";
import {
  CheckCircle,
  Close,
  InfoRounded,
  FaceRetouchingNaturalTwoTone,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Checkbox,
  Avatar,
  Grid,
  Autocomplete,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
const User_RegEx = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PW_RegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
function Register() {
  var fileObj = [];
  var fileArray = [];
  const { t } = useTranslation();
  const userRef = useRef();
  const errRef = useRef();
const {globalToken}=useContext(ShoppingCartContext)
  const [user, setUser] = useState();
  const [validUserName, setValidUserName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [password, setpassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setpasswordFocus] = useState(false);
  const [tinNumber, setTinNumber] = useState(0);
  const [type, setType] = useState("Customer");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState("");
  const [amount, seAmount] = useState(0);
  const [commission, setCommission] = useState(0);
  const [remark, setRemark] = useState("");
  const [route, setRoute] = useState(0);
  const [region, setRegion] = useState("");
  const [zone, setZone] = useState("");
  const [wereda, setWereda] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [surrounding, setSurrounding] = useState("");
  const [telephone, setTelephone] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [web, setWeb] = useState("");
  const [role, setRole] = useState("user");
  const [lat, setLat] = useState(0);
  const [longt, setLongt] = useState(0);
  const [parent, setParent] = useState(0);

  const [Expanded, setExpanded] = useState(false);

  const [matchPassword, setmatchPassword] = useState("");
  const [validMatchPassword, setValidmatchPassword] = useState(false);
  const [matchPasswordFocus, setmatchPasswordFocus] = useState(false);

  const [errMsg, seterrMsg] = useState("");
  const [success, setsuccess] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoryChecked, setCategoryChecked] = useState([]);
  const [manufacturersList, setManufacturersList] = useState([]);
  const [distributorsList, setDistributorsList] = useState([]);
  const [retailersList, setRetailersList] = useState([]);
  const [category, setCategory] = useState();
  const [registeredFor, setRegisteredFor] = useState();
  const [registeredForChecked, setRegisteredForChecked] = useState([]);

  const getListOfCategories = () => {
    try {
      axios.get("/itemCategories", {
        headers: {
          token: globalToken,
        },
      }).then((result) => {
        setCategoriesList(result.data.data);
      });
    } catch (err) {}
  };

  const getSuppliersList = (type) => {
    axios
      .get("/Suppliers/getByType?Type=" + type, {
        headers: {
          token: globalToken,
        },
      })
      .then((result) => {
        if (result.data.data) {
          switch (type) {
            case "Manufacturer":
              setManufacturersList(result.data.data);
              break;
            case "Distributor":
              setDistributorsList(result.data.data);
              break;
            case "Retailer":
              setRetailersList(result.data.data);
              break;
          }
        } else {
          alert("log out and log in again");
        }
      });
  };
  useEffect(() => {
    getListOfCategories();
    getSuppliersList("Manufacturer");
    getSuppliersList("Distributor");
    //getSuppliersList('retailer');
    userRef.current.focus();
  }, []);

  useEffect(() => {
    const result = User_RegEx.test(user);
    console.log(result);
    console.log(user);
    setValidUserName(result);
  }, [user]);

  useEffect(() => {
    const result = PW_RegEx.test(password);
    console.log(result);
    console.log(password);
    setValidPassword(result);
    const match = password === matchPassword;
    setValidmatchPassword(match);
  }, [password, matchPassword]);

  useEffect(() => {
    seterrMsg("");
  }, [user, password, matchPassword]);
  const handleSummit = async (e) => {
    e.preventDefault();
    //if button enabled with JS hack
    const v1 = User_RegEx.test(user);
    const v2 = PW_RegEx.test(password);
    if (!v1 || !v2) {
      seterrMsg("Invalid Entry");
      return;
    }
    console.log(user, password);
    var formdata = new FormData();
    //add three variable to form
    formdata.append("Name", document.getElementById("name").value);
    formdata.append("UserName", user);
    formdata.append("Password", password);
    formdata.append("TinNumber", tinNumber);
    formdata.append("Type", type);
    formdata.append("Description", description);
    formdata.append("Category", category);
    Array.from(document.getElementById("logo").files).map((file) => {
      formdata.append("Logo", file);
    });
    formdata.append("Amount", amount);
    formdata.append("Commission", commission);
    formdata.append("Remark", remark);
    formdata.append("Route", route);
    formdata.append("Region", region);
    formdata.append("Zone", zone);
    formdata.append("Wereda", wereda);
    formdata.append("HouseNo", houseNo);
    formdata.append("Surrounding", surrounding);
    formdata.append("Telephone", telephone);
    formdata.append("Mobile", mobile);
    formdata.append("Email", email);
    formdata.append("Web", web);
    formdata.append("Role", role);
    formdata.append("Lat", lat);
    formdata.append("Longt", longt);
    formdata.append("Parent", parent);
    formdata.append("BirthDate", '');
    formdata.append("Gender", '');
    formdata.append("Race", '');
    const response = await axios.get(
      "/Suppliers/getByUserName?UserName=" + user, {
        headers: {
          token: globalToken,
        },
      }
    );

    if (response.data.data.length === 0) {
      categoryChecked.map((item) => {
        switch (category) {
          case "All Category":
            formdata.append("ItemCategory", item);
            break;
          case "Specific Category":
            formdata.append("ItemCategory", item);
            break;
          case "Specific Manufacturers":
            formdata.append("ItemCategory", item);
            break;
          case "Specific Distributors":
            formdata.append("Provider", item);
            break;
          case "Specific Retailers":
            formdata.append("Provider", item);
            break;
            default:
            break;
        }
      });

      axios
        .post("/Suppliers", formdata, {
          headers: { "Content-Type": "application/json" , 
              token: globalToken,
          },
        })
        .then((res) => {
          setsuccess(true);
        });
    } else {
      seterrMsg("The username is already occupied");
    }
  };
  const handleItemCategoryToggle = (categoryId) => {
    
    const currentIndex = categoryChecked.indexOf(categoryId);
    const newChecked = [...categoryChecked];

    if (currentIndex === -1) {
      newChecked.push(categoryId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCategoryChecked(newChecked);
    console.log(categoryChecked);
  };
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setLogo(fileArray);
  }
  return (
    <>
      {success ? (
        <div>
          <h1>Success</h1>
          <p>
            <a href="">Sign In</a>
          </p>
        </div>
      ) : (
        <div style={{width:'100%'}}>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "uffscreen"}
            aria-live="asserive"
          >
            {errMsg}
          </p>
        
        
                <Grid container spacing={4} m={4}  width="95%">
                  <Grid item xs={12}>
                    <h1>Register</h1>
                  </Grid>

                  <Grid item xs={6}>
                   
                      <TextField
                       variant="standard"
                       label="Name"
                        type="text"
                        id="name"
                        onChange={(e) => setmatchPassword(e.target.value)}
                        required
                        sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
                      />
                       </Grid>
            
            <Grid item xs={6}>
                    {/*  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue=""
                        id="category"
                        onSelect={(e) => {
                          setCategory(e.target.value);
                          setCategoryChecked([]);
                        }}
                        options={[
                          "Customer",
                          "Retailer",
                          "Distributor",
                          "Manufacturer",
                        ]}
                        sx={{
                          width: "35%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderTextField={(params) => (
                          <TextField
                            {...params}
                            label={t("Select Registration Type")}
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
                  
                       <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue=""
                        id="registeredFor"
                        onSelect={(e) => {
                          setRegisteredFor(e.target.value);
                          setRegisteredForChecked([]);
                        }}
                        options={[
                          "All Categories",
                          "Specific Categories",
                          "For Sepecific Retailers",
                          "For Sepecific Distributors",
                          "For Sepecific Manufacturers",
                        ]}
                        sx={{
                          width: "35%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderTextField={(params) => (
                          <TextField
                            {...params}
                            label={t("Select Registered For")}
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      />*/}

                      <label htmlFor="username">
                        <span className={validUserName ? "valid" : "hide"}>
                          <FaceRetouchingNaturalTwoTone icon={CheckCircle} />
                        </span>
                        <span
                          className={
                            validUserName || !user ? "hide" : "invalid"
                          }
                        >
                          <FaceRetouchingNaturalTwoTone icon={Close} />
                        </span>
                      </label>

                      <TextField
                      variant="standard"
                      label="User Name"
                        type="text"
                        id="username"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        required
                        aria-invalid={validUserName ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setUserFocus(true)}
                        onBlur={() => setUserFocus(false)}
                        sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
                      />
                        </Grid>
            
            <Grid item xs={6}>
                      <p
                        id="uidnote"
                        className={
                          userFocus && user && !validUserName
                            ? "instructions"
                            : "offscreen"
                        }
                      >
                        <FaceRetouchingNaturalTwoTone icon={<InfoRounded />} />
                        4 to 24 characters. <br />
                        Must begin with a letter. <br />
                        Letters, numbers, underscores, hyphens allowed
                      </p>

                      <label htmlFor="password">
                        <span className={validPassword ? "valid" : "hide"}>
                          <FaceRetouchingNaturalTwoTone icon={CheckCircle} />
                        </span>
                        <span
                          className={
                            validPassword || !password ? "hide" : "invalid"
                          }
                        >
                          <FaceRetouchingNaturalTwoTone icon={Close} />
                        </span>
                      </label>

                      <TextField
                      label="Password"
                      variant="standard"
                        type="password"
                        id="password"
                        onChange={(e) => setpassword(e.target.value)}
                        required
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="pwdnote"
                        onFocus={() => setpasswordFocus(true)}
                        onBlur={() => setpasswordFocus(false)}
                        sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
                      />
                      <p
                        id="pwdnote"
                        className={
                          passwordFocus && !validPassword
                            ? "instructions"
                            : "offscreen"
                        }
                      >
                        <FaceRetouchingNaturalTwoTone icon={<InfoRounded />} />
                        8 to 24 characters. <br />
                        Must include uppercase and lowercase letters, a number
                        and a special character. <br />
                        Allowed special characters:
                        <span aria-label="exclamation mark">!</span>
                        <span aria-label="at symbol">@</span>
                        <span aria-label="hashtag">#</span>
                        <span aria-label="dollar sign">$</span>
                        <span aria-label="oercent">%</span>
                      </p>
                      </Grid>
            
            <Grid item xs={6}>
                      <label htmlFor="confirmpassword">
                      
                        <span
                          className={
                            validMatchPassword && matchPassword
                              ? "valid"
                              : "hide"
                          }
                        >
                          <FaceRetouchingNaturalTwoTone icon={CheckCircle} />
                        </span>
                        <span
                          className={
                            validMatchPassword || !matchPassword
                              ? "hide"
                              : "invalid"
                          }
                        >
                          <FaceRetouchingNaturalTwoTone icon={Close} />
                        </span>
                      </label>

                      <TextField
                      variant="standard"
                      label="Confirm Password"
                        type="password"
                        id="confirmpassword"
                        onChange={(e) => setmatchPassword(e.target.value)}
                        required
                        aria-invalid={validMatchPassword ? "false" : "true"}
                        aria-describedby="confirmnote"
                        onFocus={() => setmatchPasswordFocus(true)}
                        onBlur={() => setmatchPasswordFocus(false)}
                        sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
                      />
                      <p
                        id="confirmnote"
                        className={
                          matchPasswordFocus && !validMatchPassword
                            ? "instructions"
                            : "offscreen"
                        }
                      >
                        <FaceRetouchingNaturalTwoTone icon={<InfoRounded />} />
                        Must match the first password TextField field.
                      </p>
                  
                  </Grid>

                  <Grid item xs={6}>
                    <div>
                      {category === "Distributor" ? (
                        <span>Manufacturers List</span>
                      ) : category === "Retailer" ? (
                        <span>Distributors List</span>
                      ) : category === "Specific Category" ? (
                        <span>Product Category List</span>
                      ) : (
                        ""
                      )}

                      <List id="itemCategory" dense sx={{ width: "100%" }}>
                        {(category === "Specific Category"
                          ? categoriesList
                          : category === "Distributor"
                          ? manufacturersList
                          : category === "Retailer"
                          ? distributorsList
                          : []
                        ).map((value) => {
                          const labelId = `checkbox-list-secondary-label-${value.Name}`;
                          return (
                            <ListItem
                              required
                              key={value.Id}
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  onChange={() =>
                                    handleItemCategoryToggle(value.Id)
                                  }
                                  categoryChecked={
                                    categoryChecked.indexOf(value.Id) !== -1
                                  }
                                  TextFieldProps={{ "aria-labelledby": labelId }}
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemAvatar>
                                  <Avatar
                                    alt={value.Name}
                                    src={
                                      value.Image
                                        ? value.Image
                                        : "/Images/ProductCategory/default.png"
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  id={value.Id}
                                  primary={value.Name}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </Grid>
                 
                
            
            <Grid item xs={6}>
              
              <TextField
                 size="small"
                 variant="standard"
                 label={t("Tin No:")}
                 defaultValue={tinNumber}
                 onChange={(e) => {
                   setTinNumber(e.target.value);
                 }}
                 sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
              />
              </Grid>
            
            <Grid item xs={6}>
              <TextField
                    size="small"
                    variant="standard"
                    label={t("Description")}
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
              />
                </Grid>
            
            <Grid item xs={6}>
              <label htmlFor="logo">Company Logo Or Perosonal logo</label>
              <TextField
                hidden
                type="file"
                name="logo"
                id="logo"
                multiple
                className="form-control"
                onChange={uploadMultipleFiles}
              />
              <br />
              <div className="form-group multi-preview">
                {(fileArray || []).map((address) => (
                  <img
                    src={address}
                    alt="..."
                    style={{ width: "100%", height: "100" }}
                  />
                ))}
              </div>
              </Grid>
            
            <Grid item xs={6}>
              <TextField
                   size="small"
                   variant="standard"
                   label={t("Region")}
                   defaultValue={region}
                   onChange={(e) => {
                     setRegion(e.target.value);
                   }}
                   sx={{ width: "95%", marginBottom: 2 }}
                   InputLabelProps={{
                     shrink: true,
                   }}
              />
               </Grid>
            
            <Grid item xs={6}>
              <TextField
                   size="small"
                   variant="standard"
                   label={t("Zone")}
                   defaultValue={zone}
                   onChange={(e) => {
                     setZone(e.target.value);
                   }}
                   sx={{ width: "95%", marginBottom: 2 }}
                   InputLabelProps={{
                     shrink: true,
                   }}
              />
               </Grid>
            
            <Grid item xs={6}>
              <TextField
                 size="small"
                 variant="standard"
                 label={t("Wereda")}
                 defaultValue={wereda}
                 onChange={(e) => {
                   setWereda(e.target.value);
                 }}
                 sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
              />
               </Grid>
            
            <Grid item xs={6}>
              <TextField
                    size="small"
                    variant="standard"
                    label={t("houseNo")}
                    defaultValue={houseNo}
                    onChange={(e) => {
                      setHouseNo(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
              />
               </Grid>
            
            <Grid item xs={6}>
              <TextField
                 size="small"
                 variant="standard"
                 label={t("Surrounding")}
                 defaultValue={surrounding}
                 onChange={(e) => {
                   setSurrounding(e.target.value);
                 }}
                 sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
              />
              </Grid>
            
            <Grid item xs={6}>
              <TextField
                   size="small"
                   variant="standard"
                   label={t("Telephone")}
                   defaultValue={telephone}
                   onChange={(e) => {
                     setTelephone(e.target.value);
                   }}
                   sx={{ width: "95%", marginBottom: 2 }}
                   InputLabelProps={{
                     shrink: true,
                   }}
              />
               </Grid>
            
            <Grid item xs={6}>
              <TextField
                   size="small"
                   variant="standard"
                   label={t("Mobile")}
                   defaultValue={mobile}
                   onChange={(e) => {
                     setMobile(e.target.value);
                   }}
                   sx={{ width: "95%", marginBottom: 2 }}
                   InputLabelProps={{
                     shrink: true,
                   }}
              />
                </Grid>
            
            <Grid item xs={6}>
              <TextField
                 size="small"
                 variant="standard"
                 label={t("Email")}
                 defaultValue={email}
                 onChange={(e) => {
                   setEmail(e.target.value);
                 }}
                 sx={{ width: "95%", marginBottom: 2 }}
                 InputLabelProps={{
                   shrink: true,
                 }}
              />
               </Grid>
            
            <Grid item xs={6}>
              <TextField
                  size="small"
                  variant="standard"
                  label={t("Web")}
                  defaultValue={web}
                  onChange={(e) => {
                    setWeb(e.target.value);
                  }}
                  sx={{ width: "95%", marginBottom: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
               </Grid>
            
            <Grid item xs={6}>
              <TextField
                  size="small"
                  variant="standard"
                  label={t("Lat")}
                  defaultValue={lat}
                  onChange={(e) => {
                    setLat(e.target.value);
                  }}
                  sx={{ width: "95%", marginBottom: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
                </Grid>
            
            <Grid item xs={6}>
              <TextField
                  size="small"
                  variant="standard"
                  label={t("Longt")}
                  defaultValue={longt}
                  onChange={(e) => {
                    setLongt(e.target.value);
                  }}
                  sx={{ width: "95%", marginBottom: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
              </Grid>
              <Grid item xs={6} >
                    <button
                      type="submit"
                      disabled={
                        !validUserName || !validPassword || !validMatchPassword
                          ? true
                          : false
                      }
                    >
                      Sign Up
                    </button>
                  </Grid>
            </Grid>
          <p>
            Already registered?
            <br />
            <span className="line">
              <a href="#">Sign In</a>
            </span>
          </p>
        </div>
      )}
    </>
  );
}
export default Register;
