import { Grid,TextField,Stack } from '@mui/material'
import React, { useState,useContext} from 'react'
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
function DischargePatient(props) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { globalUser,globalToken, globalRoleSecurity } =  useContext(ShoppingCartContext);
  const [dischargeReason,setDischargeReason]=useState('');
  return (
    <div width="500">
    <label onClick={() => setOpen(true)}>{props.icon}</label>
    <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              bgcolor={theme.palette.primary.main}
              color={theme.palette.primary.contrastText}
            >
              <Stack style={{ display: "flex", flexDirection: "row" }}>
                <label>Company: </label>               
                <label hidden>{globalUser.Parent}</label>
                <label>{globalUser.ParentName}</label>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
    <Grid container>
      <Grid item xs={12}>
        Patient Name:{props.Name}
      </Grid>
      <Grid item xs={12}>
      <TextField
                size="small"
                label="Discharge Reason"
                multiline
                minRows={4}
                value={dischargeReason}
                onChange={(e) => {
                  setDischargeReason(e.target.value);
                }}
                sx={{
                  width:'95%'
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.palette.primary.contrastText,
                    height: "5",
                  },
                  shrink: true,
                }}
              />
      </Grid>
    </Grid>
    </DialogContentText>
    </DialogContent>
    </Dialog>
    </div>
  )
}

export default DischargePatient