import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  PaymentSharp,
  BookOnline,
  RequestPageSharp,
  DraftsSharp,
  DirectionsSharp,
  Preview,
  AddSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";
import PaymentsModel from "../PaymentsModel";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function NewOrder(props) {
  const theme = useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState(
    props.data ? props.data.VoucherDate : format(new Date(), "yyyy-MM-dd")
  );
  const {
    addtoCart,
    setSelectedItems,
    removeFromCart,
    globalSelectedSuppliers,
    setGlobalSelectedSuppliers,
    globalUser,
    selectedItems,
    allItems,
    globalProviders,
    globalRoleSecurity,
    globalStores,
    globalToken,
  } = useContext(ShoppingCartContext);

  const [lineItems, setLineItems] = useState([]);

  const [customer, setCustomer] = useState(
    props.data ? props.data.Customer : globalUser.Id
  );

  const [selectedVoucher, setSelectedVoucher] = useState(
    !(globalSelectedSuppliers === undefined) &&
      globalSelectedSuppliers.length > 0 &&
      globalSelectedSuppliers[0].Id
  );
  const [suppliers, setSuppliers] = useState([]);
  const [Photofiles, setPhotofiles] = useState(splitPictures());
  const [productsList] = useState(props.ProductsList);
  const [storeList, setStoreList] = useState(globalStores);
  const [store, setStore] = useState(
    props.data
      ? storeList &&
          storeList !== null &&
          storeList.filter(
            (st) => parseInt(st.Id) === parseInt(props.data.Store)
          ).length > 0 &&
          storeList.filter(
            (st) => parseInt(st.Id) === parseInt(props.data.Store)
          )[0].Name
      : storeList.filter((st) => parseInt(st.Id) === parseInt(globalUser.Store))
          .length > 0 &&
          storeList.filter(
            (st) => parseInt(st.Id) === parseInt(globalUser.Store)
          )[0].Name
  );
  function splitPictures() {
    try {
      fileArray = [];
      props.data.Attachment.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }

  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setPhotofiles(fileArray);
  }

  const handleSubmit = (id, status, type, vDate, lItems) => {
   
    suppliers &&
      suppliers.map((sup, index) => {
        if (status !== "Draft" || status !== "Request") {
          if (sup.Id === undefined || sup.Id === null || sup.Id === 0) {
            alert(
              "First select the supplier for PO :" +
                (parseInt(index) + parseInt(1))
            );

            return;
          }
        }

        if (
          sup.Store === undefined ||
          sup.Store === null ||
          sup.Store.length === 0 ||
          sup.Store === false
        ) {
          alert(
            "First select the store for PO :" + (parseInt(index) + parseInt(1))
          );

          return;
        }
        if (
          lItems === undefined ||
          lItems.filter(
            (li) =>
              li.Name.length > 0 && parseInt(li.Supplier) === parseInt(sup.Id)
          ).length <= 0
        ) {
          alert(
            "First select the items in PO :" + (parseInt(index) + parseInt(1))
          );

          return;
        }
        var testLoopError = false;
        lItems.map((li) => {
          if (isNaN(li.Quantity) || li.Quantity <= 0) {
            alert(
              "First insert the proper quantity of product: " +
                li.Name +
                " in  PO :" +
                (parseInt(index) + parseInt(1))
            );
            testLoopError = true;
            return;
          }
          if (
            status !== "Draft" &&
            status !== "Request" &&
            status !== "Approved"
          ) {
            if (isNaN(li.UnitPrice) || li.UnitPrice <= 0) {
              alert(
                "First insert the proper unit price of product: " +
                  li.Name +
                  " in  PO :" +
                  (parseInt(index) + parseInt(1))
              );
              testLoopError = true;
              return;
            }
          }
        });

        if (testLoopError === true) {
          return;
        }

        if (
          window.confirm(
            "Are you sure you want to save the " +
              props.Type +
              " " +
              status +
              "?"
          )
        ) {
          try {
            var fd = new FormData();
            fd.append("OrderId", id);
            fd.append("Supplier", sup.Id);
            fd.append("VoucherDate", vDate);
            status === "Draft" || status === "Request" || status === "Direct"
              ? fd.append("RequestedBy", globalUser.Id)
              : fd.append("RequestedBy", sup.RequestedBy);
            status === "Draft" || status === "Request" || status === "Direct"
              ? fd.append("RequestedDate", vDate)
              : fd.append("RequestedDate", sup.RequestedDate);
            status === "Approved" || status === "Direct"
              ? fd.append("ApprovedBy", globalUser.Id)
              : fd.append("ApprovedBy", sup.ApprovedBy);
            status === "Approved" || status === "Direct"
              ? fd.append("ApprovedDate", vDate)
              : fd.append("ApprovedDate", sup.ApprovedDate);
            fd.append("Amount", sup.Amount.toLocaleString().replace(",", ""));
            fd.append(
              "VATAmount",
              sup.VATAmount.toLocaleString().replace(",", "")
            );
            fd.append(
              "TotalAmount",
              sup.TotalAmount.toLocaleString().replace(",", "")
            );
            fd.append("Customer", globalUser.Parent);
            fd.append("Status", status);
            fd.append("BillTo", sup.Id);
            fd.append("Store", sup.Store);
            fd.append("User", globalUser.Id);
            Array.from(document.getElementById("Attachment").files).map(
              (file) => {
                fd.append("Attachment", file);
              }
            );

            fd.append(
              "LineItems",
              JSON.stringify(
                lineItems.filter(
                  (it) =>
                    it.Name.length > 0 &&
                    parseInt(it.Supplier) === parseInt(sup.Id)
                )
              )
            );
            fd.append("Type", type);
            axios.post("/vouchers", fd, {
              headers: {
                "Content-Type": "multipart/form-data",
                token: globalToken,
              },
            });
          } catch (err) {
          } finally {
            setGlobalSelectedSuppliers([]);
            setSelectedItems([]);
            setLineItems([]);
            try{
if (props.data && props.data !== null) {
              props.setParentUpdate(!props.ParentUpdate);
            }
            }catch(err){

            }
            
            setOpen(false);
          }
        }
      });
  };
  const deleteNoTransaction = (id) => {
    
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        axios.delete("/vouchers?Id=" + id, {
          headers: {
            token: globalToken,
          },
        }); //add id
      } catch (err) {
      } finally {
        props.setParentUpdate(!props.ParentUpdate);
        setOpen(false);
      }
    }
  };
  const handleDelete = (id) => {
  alert(id)
      try {
        axios.get("/vouchers/getBySoureceNo?SourceNo=" + id, {
          headers: {
            token: globalToken,
          },
        }) .then((response) => {
          if (response.data.data.length<=0) {
            deleteNoTransaction(id);         
          }else{
            alert('Can not delete this order. It has other related orders')
          }
        });
      } catch (err) {
      } 
    
  };
  const handleLineItemDelete = (id) => {
    try {
      axios
        .delete("/lineItems?Id=" + id, {
          headers: {
            token: globalToken,
          },
        })
        .then(() => setLineItems(lineItems.filter((li) => li.Id !== id))); //add id
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    setSuppliers(
      props.data
        ? [{ ...props.data, Id: props.data.Supplier }]
        : globalSelectedSuppliers && globalSelectedSuppliers.length > 0
        ? globalSelectedSuppliers
        : globalProviders && globalProviders.length > 0
        ? [
            {
              ...globalProviders[0],
              VoucherDate: orderDate,
              RequestedByName: globalUser.Name,
              RequestedDate: orderDate,
            },
          ]
        : [{ Id: 0, Supplier: 0 }]
    );
    setSelectedVoucher(
      props.data
        ? props.data.Supplier
        : globalSelectedSuppliers && globalSelectedSuppliers.length > 0
        ? globalSelectedSuppliers[0].Id
        : globalProviders && globalProviders.length > 0
        ? globalProviders[0].Id
        : 0
    );
  }, []);
  useEffect(() => {
    try {
      if (!(props.LineItems === undefined) && !(props.LineItems === null) && (props.LineItems.length>0)) {
        console.log(props.data)
        setLineItems(props.LineItems);
        console.log('selected lineitem during loadin prps.')
        console.log(props.LineItems)
      } else {
        setLineItems(selectedItems.filter((itt) => itt.Type === "Purchase"));
        console.log('selected lineitem during selected')
        console.log(props.LineItems)
      }

    } catch {}
  }, [selectedItems, globalSelectedSuppliers]);

 
  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
            {!(suppliers === undefined) &&
              !(suppliers === null) &&
              suppliers.map((sup, index) => {
                if (sup && !(sup === null) && sup.Name && sup.Name.length > 0) {
                  sup.VATAmount = 0;
                  sup.Amount = 0;
                  lineItems &&
                    lineItems
                      .filter(
                        (li) => parseInt(li.Supplier) === parseInt(sup.Id)
                      )
                      .map((it) => {
                        sup.VATAmount = sup.VATAmount + it.TaxAmount;
                        sup.Amount = sup.Amount + it.TotalPrice;
                      });
                  sup.TotalAmount = sup.Amount + sup.VATAmount;
                  return (
                    <>
                      <DialogTitle
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          height: "20px",
                        }}
                        onClick={() =>
                          sup.Id === selectedVoucher
                            ? setSelectedVoucher(
                                suppliers[
                                  index + 1 >= suppliers.length ? 0 : index + 1
                                ].Id
                              )
                            : setSelectedVoucher(sup.Id)
                        }
                      >
                        PO: {index + 1 + "              "} Supplier:
                        {" " +
                          sup.Name +
                          "          Total Amount: " +
                          (sup.Amount + sup.VATAmount).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                      </DialogTitle>
                      <Grid container key={sup.Id}>
                        <Grid
                          item
                          xs={12}
                          pl={"35%"}
                          sx={{
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.contrastText,
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Purchase " +
                            (props.data ? props.data.Status : props.Status)}
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === sup.Id ? "block" : "none",
                          }}
                        >
                          <span style={{}}>
                            Doc. No.: {props.data ? props.data.OrderId : ""}
                            <br></br>
                            Customer: {globalUser.ParentName}
                            <br></br>
                            Requested By:{" "}
                            {props.data
                              ? props.data.RequestedByName
                              : globalUser.Name}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === sup.Id ? "block" : "none",
                          }}
                        >
                          <span style={{}}>
                            Status:{" "}
                            {props.data ? props.data.Status : props.Status}
                            <br></br>
                            User:
                            {props.data ? props.data.UserName : globalUser.Name}
                            <br></br>
                            Requested Date:{" "}
                            {props.data
                              ? props.data.RequestedDate
                              : format(new Date(), "yyyy-MM-dd")}
                          </span>
                        </Grid>
                        {(props.Status === "Direct" ||
                          props.Status === "Order" ||
                          (props.data &&
                            props.data.Status !== "Draft" &&
                            props.data.Status !== "Request")) && (
                          <Grid
                            item
                            xs={6}
                            style={{
                              display:
                                selectedVoucher === sup.Id ? "block" : "none",
                            }}
                          >
                            <Autocomplete
                              label="Supplier"
                              id="Supplier"
                              size="small"
                              variant="standard"
                              value={sup.Name}
                              onChange={(e) => {
                                suppliers[index] =
                                  globalProviders.filter(
                                    (ss) => ss.Name === e.target.textContent
                                  ).length > 0 &&
                                  globalProviders.filter(
                                    (ss) => ss.Name === e.target.textContent
                                  )[0];
                                setLineItems(
                                  lineItems
                                    .filter((lil) => lil.Supplier === sup.Id)
                                    .map((li) => {
                                      li.Supplier = suppliers[index].Id;
                                      return li;
                                    })
                                );
                                /*  console.log('selected supplier')
                                              console.log(globalProviders.filter(ss=>ss.Name===e.target.textContent))
                                            
                                                if(!(e.target.textContent==='') && !(e.target.textContent===null) && (e.target.textContent.length>0) ){
                                                 if(suppliers.length>1){
                                                  suppliers.map(supInt=>{
                                                    console.log('inside deleting')
                                                    if( (e.target.textContent!==supInt.Name) && lineItems.filter(itt=>(parseInt(itt.Supplier)===parseInt(supInt.Id)).length<=0)){
                                                      console.log('last inside deleting')
                                                      setSuppliers(suppliers.filter(ssp=>(parseInt(ssp.Id)!==parseInt(supInt.Id) )))
                                                     } 
                                                  })
                                                }
                                                console.log('selected suppliers')
                                                console.log('test:'+e.target.textContent)
                                               console.log(suppliers)
                                               console.log(globalProviders.filter(ss=>ss.Name===e.target.textContent)[0])
                                                  setSuppliers(sp=>[...sp,(globalProviders.filter(ss=>ss.Name===e.target.value)[0])]); */

                                setSelectedVoucher(suppliers[index].Id);
                                setSuppliers(
                                  suppliers.map((sp) => {
                                    return sp;
                                  })
                                );
                              }}
                              options={
                                !(globalProviders === undefined) &&
                                !(globalProviders === null) &&
                                globalProviders.length > 0 &&
                                globalProviders.map((pro) => {
                                  return pro.Name;
                                })
                              }
                              sx={{ width: "95%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("Supplier")}

                                  //error={!from ? true : false}
                                />
                              )}
                            />
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={6}
                          mt={1}
                          style={{
                            display:
                              selectedVoucher === sup.Id ? "block" : "none",
                          }}
                        >
                          {(props.data &&
                            globalRoleSecurity.filter(
                              (sec) =>
                                sec.Functionality === "Purchase " + props.Status
                            ).length > 0 &&
                            isNaN(
                              parseInt(
                                globalRoleSecurity.filter(
                                  (sec) =>
                                    sec.Functionality ===
                                    "Purchase " + props.Status
                                )[0].Store
                              )
                            )) ||
                          !props.data ? (
                            <Autocomplete
                              label="Store"
                              id="store"
                              size="small"
                              variant="outlined"
                              value={store}
                              onChange={(e) => {
                                setStore(e.target.textContent);
                                sup.Store =
                                  storeList &&
                                  storeList.length > 0 &&
                                  storeList.filter(
                                    (st) => st.Name === e.target.textContent
                                  ).length > 0 &&
                                  storeList.filter(
                                    (st) => st.Name === e.target.textContent
                                  )[0].Id;
                              }}
                              options={
                                !(storeList === undefined) &&
                                !(storeList === null) &&
                                storeList.length > 0
                                  ? storeList.map((item) => {
                                      return item.Name;
                                    })
                                  : [""]
                              }
                              sx={{ width: "95%" }}
                              renderInput={(params) => (
                                <TextField {...params} label={"Store"} />
                              )}
                            />
                          ) : (
                            <>
                              Store:{" "}
                              {props.data &&
                                storeList.filter(
                                  (st) =>
                                    parseInt(st.Id) ===
                                    parseInt(props.data.Store)
                                ).length > 0 &&
                                storeList.filter(
                                  (st) =>
                                    parseInt(st.Id) ===
                                    parseInt(props.data.Store)
                                )[0].Name}
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          mt={1}
                          style={{
                            display:
                              selectedVoucher === sup.Id ? "block" : "none",
                          }}
                        >
                          <TextField
                            size="small"
                            label="Order Date"
                            type="date"
                            value={orderDate}
                            onChange={(e) => {
                              setOrderDate(e.target.value);
                              sup.VoucherDate = e.target.value;
                            }}
                            sx={{ width: "70%", marginBottom: 2 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            direction="row"
                            container
                            style={{
                              display:
                                selectedVoucher === sup.Id ? "block" : "none",
                            }}
                          >
                            <hr></hr>
                            <Grid item xs={12} className="hideInMobile">
                              <Grid container>
                                <Grid
                                  item
                                  xs={
                                    (props.data === undefined ||
                                      props.data === null ||
                                      props.data.Status === "Draft" ||
                                      props.data.Status === "Request") &&
                                    !(
                                      props.Status &&
                                      (props.Status === "Direct" ||
                                        props.Status === "Order")
                                    )
                                      ? 2
                                      : 1
                                  }
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  No.
                                </Grid>
                                <Grid
                                  item
                                  xs={
                                    (props.data === undefined ||
                                      props.data === null ||
                                      (props.data &&
                                        (props.data.Status === "Draft" ||
                                          props.data.Status === "Request"))) &&
                                    !(
                                      props.Status &&
                                      (props.Status === "Direct" ||
                                        props.Status === "Order")
                                    )
                                      ? 5
                                      : 4
                                  }
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  Item Name
                                </Grid>

                                <Grid
                                  item
                                  xs={
                                    (props.data === undefined ||
                                      props.data === null ||
                                      (props.data &&
                                        (props.data.Status === "Draft" ||
                                          props.data.Status === "Request"))) &&
                                    !(
                                      props.Status &&
                                      (props.Status === "Direct" ||
                                        props.Status === "Order")
                                    )
                                      ? 3
                                      : 2
                                  }
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Quantity
                                </Grid>
                                {(props.data === undefined ||
                                  props.data === null ||
                                  (props.data &&
                                    (props.data.Status === "Draft" ||
                                      props.data.Status === "Request"))) &&
                                !(
                                  props.Status &&
                                  (props.Status === "Direct" ||
                                    props.Status === "Order")
                                ) ? (
                                  ""
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        paddingLeft: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color:
                                          theme.palette.primary.contrastText,
                                        color:
                                          theme.palette.primary.contrastText,
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                    >
                                      Unit Price
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        paddingLeft: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color:
                                          theme.palette.primary.contrastText,
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                    >
                                      Total
                                    </Grid>
                                  </>
                                )}
                                <Grid
                                  item
                                  xs={
                                    (props.data === undefined ||
                                      props.data === null ||
                                      (props.data &&
                                        (props.data.Status === "Draft" ||
                                          props.data.Status === "Request"))) &&
                                    !(
                                      props.Status &&
                                      (props.Status === "Direct" ||
                                        props.Status === "Order")
                                    ) &&
                                    !(
                                      props.Status &&
                                      (props.Status === "Direct" ||
                                        props.Status === "Order")
                                    )
                                      ? 2
                                      : 1
                                  }
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    variant="standard"
                                    id={sup.Id}
                                    onClick={(e) => {
                                      if (
                                        lineItems &&
                                        lineItems.filter(
                                          (itt) =>
                                            itt.Item === "" &&
                                            parseInt(itt.Supplier) ===
                                              parseInt(sup.Id)
                                        ).length <= 0
                                      ) {
                                        let newLineItem = {};
                                        newLineItem.Item = "";
                                        newLineItem.Name = " ";
                                        newLineItem.UnitCost = 0;
                                        newLineItem.Quantity = 0;
                                        newLineItem.UnitPrice = 0;
                                        newLineItem.TotalPrice = 0;
                                        newLineItem.Supplier = sup.Id;
                                        newLineItem.Type = "Purchase";
                                        newLineItem.SupplierName = sup.Name;

                                        setLineItems((xx) => [
                                          ...xx,
                                          newLineItem,
                                        ]);
                                      } else {
                                        alert("First complete the data");
                                      }
                                    }}
                                  >
                                    <Add
                                      sx={{
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                    />
                                  </IconButton>
                                </Grid>

                                {lineItems &&
                                  lineItems !== null &&
                                  lineItems.length > 0 &&
                                  lineItems
                                    .filter(
                                      (it) =>
                                        parseInt(it.Supplier) ===
                                        parseInt(sup.Id)
                                    )
                                    .map((item, index) => {
                                      console.log('selected item')
                                      console.log(item)
                                      return (
                                        <>
                                          <Grid item xs={12}>
                                            <hr></hr>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={
                                              (props.data === undefined ||
                                                props.data === null ||
                                                (props.data &&
                                                  (props.data.Status ===
                                                    "Draft" ||
                                                    props.data.Status ===
                                                      "Request"))) &&
                                              !(
                                                props.Status &&
                                                (props.Status === "Direct" ||
                                                  props.Status === "Order")
                                              )
                                                ? 2
                                                : 1
                                            }
                                            key={index}
                                          >
                                            {index + 1}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={
                                              (props.data === undefined ||
                                                props.data === null ||
                                                (props.data &&
                                                  (props.data.Status ===
                                                    "Draft" ||
                                                    props.data.Status ===
                                                      "Request"))) &&
                                              !(
                                                props.Status &&
                                                (props.Status === "Direct" ||
                                                  props.Status === "Order")
                                              )
                                                ? 5
                                                : 4
                                            }
                                          >
                                            <Autocomplete
                                              label="Items"
                                              id="Items"
                                              size="small"
                                              variant="standard"
                                              value={item.Name}
                                              onChange={(e) => {
                                                if (
                                                  lineItems.filter(
                                                    (li) =>
                                                      li.Name ===
                                                        e.target.textContent &&
                                                      parseInt(li.Supplier) ===
                                                        parseInt(sup.Id)
                                                  ).length > 0
                                                ) {
                                                  alert('exist')
                                                  item.Item = "";
                                                  item.Name = "";
                                                  item.Quantity = 0;
                                                  item.UnitPrice = 0;
                                                  item.UnitCost = 0;
                                                  item.TotalPrice = 0;
                                                  item.Supplier = sup.Id;
                                                  item.Type = "Purchase";
                                                  setLineItems(
                                                    lineItems.map((litem) => {
                                                      return litem;
                                                    })
                                                  );
                                                  //alert('The selected item is already registered')
                                                  return;
                                                } else {
                                                  let selectedProduct =
                                                    allItems.filter(
                                                      (selItem) =>
                                                        selItem.Name ===
                                                        e.target.textContent
                                                    );

                                                  if (
                                                    selectedProduct &&
                                                    selectedProduct.length > 0
                                                  ) {
                                                  
                                                    item.Supplier=sup.Id;
                                                    item.Quantity =
                                                      selectedProduct[0]
                                                        .DefaultOrderQty !== 0
                                                        ? selectedProduct[0]
                                                            .DefaultOrderQty
                                                        : 1;
                                                    item.UnitCost =
                                                      selectedProduct[0]
                                                        .Price !== 0
                                                        ? selectedProduct[0]
                                                            .Price
                                                        : 1;
                                                    item.UOM =
                                                      selectedProduct[0].UOM
                                                        ? selectedProduct[0].UOM
                                                        : "PCS";
                                                    item.VATInclusive =
                                                      selectedProduct[0].VATInclusive;

                                                    item.Item =
                                                      selectedProduct[0].Item;
                                                    item.Name =
                                                      selectedProduct[0].Name;
                                                    item.TotalPrice =
                                                      item.UnitPrice *
                                                      item.Quantity;
                                                    item.TaxAmount =
                                                      selectedProduct[0]
                                                        .VATInclusive
                                                        ? item.UnitPrice *
                                                          item.Quantity *
                                                          0.15
                                                        : 0;
                                                    sup.VATAmount = 0;
                                                    sup.Amount = 0;
                                                    lineItems &&
                                                      lineItems
                                                        .filter(
                                                          (li) =>
                                                            parseInt(
                                                              li.Supplier
                                                            ) ===
                                                            parseInt(sup.Id)
                                                        )
                                                        .map((it) => {
                                                          sup.VATAmount =
                                                            sup.VATAmount +
                                                            it.TaxAmount;
                                                          sup.Amount =
                                                            sup.Amount +
                                                            it.TotalPrice;
                                                        });
                                                    addtoCart(item);

                                                    setLineItems(
                                                      lineItems.map((litem) => {
                                                        return litem;
                                                      })
                                                    );
                                                  } else {
                                                    item.Quantity = 0;
                                                    item.UnitPrice = 0;
                                                    item.UnitCost = 0;
                                                    item.TotalPrice = 0;
                                                    item.Supplier = sup.Id;
                                                    item.Type = "Purchase";
                                                    item.Item = "";
                                                    item.Name = "";

                                                    setLineItems(
                                                      lineItems.map((litem) => {
                                                        return litem;
                                                      })
                                                    );
                                                  }
                                                }
                                              }}
                                              options={
                                                !(allItems === undefined) &&
                                                !(allItems === null) &&
                                                allItems.length > 0
                                                  ? allItems.map((it) => {
                                                      return it.Name;
                                                    })
                                                  : [""]
                                              }
                                              sx={{ width: "95%" }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={item.Name}

                                                  //error={!from ? true : false}
                                                />
                                              )}
                                            />
                                          </Grid>

                                          <Grid
                                            item
                                            xs={
                                              (props.data === undefined ||
                                                props.data === null ||
                                                (props.data &&
                                                  (props.data.Status ===
                                                    "Draft" ||
                                                    props.data.Status ===
                                                      "Request"))) &&
                                              !(
                                                props.Status &&
                                                (props.Status === "Direct" ||
                                                  props.Status === "Order")
                                              )
                                                ? 3
                                                : 2
                                            }
                                          >
                                            <TextField
                                              size="small"
                                              variant="standard"
                                              type="float"
                                              value={item.Quantity}
                                              sx={{ width: "70px" }}
                                              onChange={(e) => {
                                                item.Quantity = e.target.value;
                                                item.TotalPrice =
                                                  item.Quantity *
                                                  item.UnitPrice;
                                                item.TaxAmount =
                                                  item.VATInclusive
                                                    ? item.TotalPrice * 0.15
                                                    : 0;
                                                sup.VATAmount = 0;
                                                sup.Amount = 0;
                                                lineItems &&
                                                  lineItems
                                                    .filter(
                                                      (li) =>
                                                        parseInt(
                                                          li.Supplier
                                                        ) === parseInt(sup.Id)
                                                    )
                                                    .map((it) => {
                                                      sup.VATAmount =
                                                        sup.VATAmount +
                                                        it.TaxAmount;
                                                      sup.Amount =
                                                        sup.Amount +
                                                        it.TotalPrice;
                                                    });
                                                setLineItems(
                                                  lineItems.map((litem) => {
                                                    return litem;
                                                  })
                                                );
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>
                                          {(props.data === undefined ||
                                            props.data === null ||
                                            (props.data &&
                                              (props.data.Status === "Draft" ||
                                                props.data.Status ===
                                                  "Request"))) &&
                                          !(
                                            props.Status &&
                                            (props.Status === "Direct" ||
                                              props.Status === "Order")
                                          ) ? (
                                            ""
                                          ) : (
                                            <>
                                              <Grid item xs={2}>
                                                <TextField
                                                  size="small"
                                                  variant="standard"
                                                  type="float"
                                                  value={item.UnitPrice}
                                                  sx={{ width: "70px" }}
                                                  onChange={(e) => {
                                                    item.UnitPrice =
                                                      e.target.value;
                                                    item.TotalPrice =
                                                      item.Quantity *
                                                      item.UnitPrice;
                                                    item.TaxAmount =
                                                      item.VATInclusive
                                                        ? item.TotalPrice * 0.15
                                                        : 0;

                                                    //Unit Price Change
                                                    sup.VATAmount = 0;
                                                    sup.Amount = 0;
                                                    lineItems &&
                                                      lineItems
                                                        .filter(
                                                          (li) =>
                                                            parseInt(
                                                              li.Supplier
                                                            ) ===
                                                            parseInt(sup.Id)
                                                        )
                                                        .map((it) => {
                                                          sup.VATAmount =
                                                            sup.VATAmount +
                                                            it.TaxAmount;
                                                          sup.Amount =
                                                            sup.Amount +
                                                            it.TotalPrice;
                                                        });
                                                    setLineItems(
                                                      lineItems.map((litem) => {
                                                        return litem;
                                                      })
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={2}>
                                                {item.TotalPrice.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </Grid>
                                            </>
                                          )}
                                          <Grid
                                            item
                                            xs={
                                              (props.data === undefined ||
                                                props.data === null ||
                                                (props.data &&
                                                  (props.data.Status ===
                                                    "Draft" ||
                                                    props.data.Status ===
                                                      "Request"))) &&
                                              !(
                                                props.Status &&
                                                (props.Status === "Direct" ||
                                                  props.Status === "Order")
                                              )
                                                ? 2
                                                : 1
                                            }
                                          >
                                            <Tooltip title="remove">
                                              <Button
                                                variant="standard"
                                                endIcon={
                                                  <Delete
                                                    sx={{
                                                      color:
                                                        theme.palette.primary
                                                          .main,
                                                    }}
                                                  />
                                                }
                                                onClick={() => {
                                                  if (
                                                    lineItems.filter(
                                                      (litem) =>
                                                        parseInt(litem.Item) ===
                                                          parseInt(item.Item) &&
                                                        parseInt(
                                                          litem.Supplier
                                                        ) ===
                                                          parseInt(
                                                            item.Supplier
                                                          )
                                                    ).length > 0
                                                  ) {
                                                    if (
                                                      window.confirm(
                                                        "Are you sure you want to delete "
                                                      )
                                                    ) {
                                                      handleLineItemDelete(
                                                        lineItems.filter(
                                                          (litem) =>
                                                            litem.Item ===
                                                              item.Item &&
                                                            parseInt(
                                                              litem.Supplier
                                                            ) ===
                                                              parseInt(
                                                                item.Supplier
                                                              )
                                                        )[0].Id
                                                      );
                                                    }
                                                    removeFromCart(item);
                                                    if (
                                                      lineItems.filter(
                                                        (litem) =>
                                                          parseInt(
                                                            litem.Supplier
                                                          ) === parseInt(sup.Id)
                                                      ).length <= 0
                                                    ) {
                                                      setSuppliers(
                                                        suppliers.filter(
                                                          (ssp) =>
                                                            parseInt(ssp.Id) !==
                                                            parseInt(sup.Id)
                                                        )
                                                      );
                                                    }
                                                  }
                                                }}
                                              ></Button>
                                            </Tooltip>
                                          </Grid>
                                        </>
                                      );
                                    })}
                                <Grid item xs={12}>
                                  <hr></hr>
                                </Grid>
                                <Grid item xs={5}></Grid>
                                {(props.data === undefined ||
                                  props.data === null ||
                                  props.data.Status === "Draft" ||
                                  props.data.Status === "Request") &&
                                !(
                                  props.Status &&
                                  (props.Status === "Direct" ||
                                    props.Status === "Order")
                                ) ? (
                                  ""
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                    >
                                      {" "}
                                      Sub Total:
                                    </Grid>

                                    <Grid
                                      item
                                      xs={3}
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        textDecoration: "underline",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                    >
                                      {sup.Amount?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Grid>

                                    <Grid item xs={5}></Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                    >
                                      {" "}
                                      VAT Amount:
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color:
                                          theme.palette.primary.contrastText,
                                        textDecorationLine: "underline",
                                      }}
                                    >
                                      {(sup.VATAmount && sup.VATAmount !== null
                                        ? sup.VATAmount
                                        : 0
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </Grid>
                                    <Grid item xs={5}></Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                    >
                                      {" "}
                                      Total Amount:
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        textDecoration: "underline",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                    >
                                      {" "}
                                      {(
                                        sup.Amount + sup.VATAmount
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}{" "}
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item className="hideInFull" m={1} xs={12}>
                              {lineItems &&
                                lineItems !== null &&
                                lineItems.length > 0 &&
                                lineItems
                                  .filter(
                                    (it) =>
                                      parseInt(it.Supplier) === parseInt(sup.Id)
                                  )
                                  .map((item, index) => {
                                    return (
                                      <>
                                        <Card
                                          sx={{
                                            borderRadius: 10,
                                            boxShadow: 3,
                                            width: "100%",
                                          }}
                                        >
                                          <CardContent>
                                            <Grid container>
                                              <Grid item xs={4}>
                                                No.:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                {index + 1}
                                              </Grid>
                                              <Grid item xs={4}>
                                                Product:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <Autocomplete
                                                  label="Items"
                                                  id="Items"
                                                  size="small"
                                                  variant="standard"
                                                  value={item.Name}
                                                  onChange={(e) => {
                                                    if (
                                                      lineItems.filter(
                                                        (li) =>
                                                          li.Name ===
                                                            e.target.textContent &&
                                                          parseInt(
                                                            li.Supplier
                                                          ) === parseInt(sup.Id)
                                                      ).length > 0
                                                    ) {
                                                      item.Item = "";
                                                      item.Name = "";
                                                      item.Quantity = 0;
                                                      item.UnitPrice = 0;
                                                      item.UnitCost = 0;
                                                      item.TotalPrice = 0;
                                                      item.Supplier = sup.Id;
                                                      item.Type = "Purchase";
                                                      setLineItems(
                                                        lineItems.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );
                                                      //alert('The selected item is already registered')
                                                      return;
                                                    } else {
                                                      let selectedProduct =
                                                        allItems.filter(
                                                          (selItem) =>
                                                            selItem.Name ===
                                                            e.target.textContent
                                                        );

                                                      if (
                                                        selectedProduct &&
                                                        selectedProduct.length >
                                                          0
                                                      ) {
                                                        item.Supplier = sup.Id;
                                                        item.Quantity =
                                                          selectedProduct[0]
                                                            .DefaultOrderQty !==
                                                          0
                                                            ? selectedProduct[0]
                                                                .DefaultOrderQty
                                                            : 1;
                                                        item.UnitCost =
                                                          selectedProduct[0]
                                                            .Price !== 0
                                                            ? selectedProduct[0]
                                                                .Price
                                                            : 1;
                                                        item.UOM =
                                                          selectedProduct[0].UOM
                                                            ? selectedProduct[0]
                                                                .UOM
                                                            : "PCS";
                                                        item.VATInclusive =
                                                          selectedProduct[0].VATInclusive;

                                                        item.Item =
                                                          selectedProduct[0].Item;
                                                        item.Name =
                                                          selectedProduct[0].Name;
                                                        item.TotalPrice =
                                                          item.UnitPrice *
                                                          item.Quantity;
                                                        item.TaxAmount =
                                                          selectedProduct[0]
                                                            .VATInclusive
                                                            ? item.UnitPrice *
                                                              item.Quantity *
                                                              0.15
                                                            : 0;
                                                        sup.VATAmount = 0;
                                                        sup.Amount = 0;
                                                        lineItems &&
                                                          lineItems
                                                            .filter(
                                                              (li) =>
                                                                parseInt(
                                                                  li.Supplier
                                                                ) ===
                                                                parseInt(sup.Id)
                                                            )
                                                            .map((it) => {
                                                              sup.VATAmount =
                                                                sup.VATAmount +
                                                                it.TaxAmount;
                                                              sup.Amount =
                                                                sup.Amount +
                                                                it.TotalPrice;
                                                            });
                                                        addtoCart(item);

                                                        setLineItems(
                                                          lineItems.map(
                                                            (litem) => {
                                                              return litem;
                                                            }
                                                          )
                                                        );
                                                      } else {
                                                        item.Quantity = 0;
                                                        item.UnitPrice = 0;
                                                        item.UnitCost = 0;
                                                        item.TotalPrice = 0;
                                                        item.Supplier = sup.Id;
                                                        item.Type = "Purchase";
                                                        item.Item = "";
                                                        item.Name = "";

                                                        setLineItems(
                                                          lineItems.map(
                                                            (litem) => {
                                                              return litem;
                                                            }
                                                          )
                                                        );
                                                      }
                                                    }
                                                  }}
                                                  options={
                                                    !(allItems === undefined) &&
                                                    !(allItems === null) &&
                                                    allItems.length > 0
                                                      ? allItems.map((it) => {
                                                          return it.Name;
                                                        })
                                                      : [""]
                                                  }
                                                  sx={{ width: "95%" }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label={item.Name}

                                                      //error={!from ? true : false}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                              <Grid item xs={4}>
                                                Quantity:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <TextField
                                                  size="small"
                                                  variant="standard"
                                                  type="float"
                                                  value={item.Quantity}
                                                  sx={{ width: "70px" }}
                                                  onChange={(e) => {
                                                    item.Quantity =
                                                      e.target.textContent;
                                                    item.TotalPrice =
                                                      item.Quantity *
                                                      item.UnitPrice;
                                                    item.TaxAmount =
                                                      item.VATInclusive
                                                        ? item.TotalPrice * 0.15
                                                        : 0;
                                                    sup.VATAmount = 0;
                                                    sup.Amount = 0;
                                                    lineItems &&
                                                      lineItems
                                                        .filter(
                                                          (li) =>
                                                            parseInt(
                                                              li.Supplier
                                                            ) ===
                                                            parseInt(sup.Id)
                                                        )
                                                        .map((it) => {
                                                          sup.VATAmount =
                                                            sup.VATAmount +
                                                            it.TaxAmount;
                                                          sup.Amount =
                                                            sup.Amount +
                                                            it.TotalPrice;
                                                        });
                                                    setLineItems(
                                                      lineItems.map((litem) => {
                                                        return litem;
                                                      })
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </Grid>

                                              <Grid item xs={4}>
                                                Unit Price:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <TextField
                                                  size="small"
                                                  variant="standard"
                                                  type="float"
                                                  value={item.UnitPrice}
                                                  sx={{ width: "70px" }}
                                                  onChange={(e) => {
                                                    item.UnitPrice =
                                                      e.target.textContent;
                                                    item.TotalPrice =
                                                      item.Quantity *
                                                      item.UnitPrice;
                                                    item.TaxAmount =
                                                      item.VATInclusive
                                                        ? item.TotalPrice * 0.15
                                                        : 0;

                                                    //Unit Price Change
                                                    sup.VATAmount = 0;
                                                    sup.Amount = 0;
                                                    lineItems &&
                                                      lineItems
                                                        .filter(
                                                          (li) =>
                                                            parseInt(
                                                              li.Supplier
                                                            ) ===
                                                            parseInt(sup.Id)
                                                        )
                                                        .map((it) => {
                                                          sup.VATAmount =
                                                            sup.VATAmount +
                                                            it.TaxAmount;
                                                          sup.Amount =
                                                            sup.Amount +
                                                            it.TotalPrice;
                                                        });
                                                    setLineItems(
                                                      lineItems.map((litem) => {
                                                        return litem;
                                                      })
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={4}>
                                                Total Price:
                                              </Grid>
                                              <Grid item xs={8} pl={8}>
                                                {item.TotalPrice.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}
                                              </Grid>
                                              <Grid item xs={12}>
                                                <hr></hr>
                                                Remove: {"   "}
                                                <Tooltip title="remove">
                                                  <Button
                                                    variant="standard"
                                                    endIcon={
                                                      <Delete
                                                        sx={{
                                                          color:
                                                            theme.palette
                                                              .primary.main,
                                                        }}
                                                      />
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        window.confirm(
                                                          "Are you sure you want to delete "
                                                        )
                                                      ) {
                                                        handleLineItemDelete(
                                                          lineItems.filter(
                                                            (litem) =>
                                                              parseInt(
                                                                litem.Item
                                                              ) ===
                                                              parseInt(
                                                                item.Item
                                                              )
                                                          )[0].Id
                                                        );
                                                        removeFromCart(item);
                                                        if (
                                                          lineItems.filter(
                                                            (litem) =>
                                                              !(
                                                                litem.Item ===
                                                                item.Item
                                                              )
                                                          ).length <= 0
                                                        ) {
                                                          let newLineItem = {};
                                                          newLineItem.Item = "";
                                                          newLineItem.Name = "";
                                                          newLineItem.Supplier =
                                                            sup.Id;
                                                          newLineItem.Type =
                                                            "Purchase";
                                                          newLineItem.UnitCost = 0;
                                                          newLineItem.Quantity = 0;
                                                          newLineItem.UnitPrice = 0;
                                                          newLineItem.TotalPrice = 0;
                                                          setLineItems((xx) => [
                                                            ...xx,
                                                            newLineItem,
                                                          ]);
                                                        }
                                                      }
                                                    }}
                                                  ></Button>
                                                </Tooltip>
                                                <Tooltip title="remove">
                                                  <Button
                                                    variant="standard"
                                                    endIcon={
                                                      <AddSharp
                                                        sx={{
                                                          color:
                                                            theme.palette
                                                              .primary.main,
                                                        }}
                                                      />
                                                    }
                                                    onClick={(e) => {
                                                      if (
                                                        lineItems &&
                                                        lineItems.filter(
                                                          (itt) =>
                                                            itt.Item === "" &&
                                                            parseInt(
                                                              itt.Supplier
                                                            ) ===
                                                              parseInt(sup.Id)
                                                        ).length <= 0
                                                      ) {
                                                        let newLineItem = {};
                                                        newLineItem.Item = "";
                                                        newLineItem.Name = " ";
                                                        newLineItem.UnitCost = 0;
                                                        newLineItem.Quantity = 0;
                                                        newLineItem.UnitPrice = 0;
                                                        newLineItem.TotalPrice = 0;
                                                        newLineItem.Supplier =
                                                          sup.Id;
                                                        newLineItem.Type =
                                                          "Purchase";
                                                        newLineItem.SupplierName =
                                                          sup.Name;

                                                        setLineItems((xx) => [
                                                          ...xx,
                                                          newLineItem,
                                                        ]);
                                                      } else {
                                                        alert(
                                                          "First complete the data"
                                                        );
                                                      }
                                                    }}
                                                  ></Button>
                                                </Tooltip>
                                              </Grid>
                                            </Grid>
                                          </CardContent>
                                        </Card>
                                        <hr></hr>
                                      </>
                                    );
                                  })}
                              {props.data === undefined ||
                              props.data === null ||
                              props.data.Status === "Draft" ||
                              props.data.Status === "Request" ? (
                                ""
                              ) : (
                                <Table>
                                  <tr>
                                    <td />
                                    <td hidden />
                                    <td />
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                      colSpan={2}
                                    >
                                      {" "}
                                      Sub Total:
                                    </td>

                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        textDecoration: "underline",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                    >
                                      {sup.Amount?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                    ></td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                      hidden
                                    >
                                      {" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                    >
                                      {" "}
                                    </td>

                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      }}
                                      colSpan={2}
                                    >
                                      {" "}
                                      VAT Amount:
                                    </td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color:
                                          theme.palette.primary.contrastText,
                                        textDecorationLine: "underline",
                                      }}
                                    >
                                      {(sup.VATAmount && sup.VATAmount !== null
                                        ? sup.VATAmount
                                        : 0
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                    ></td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                      hidden
                                    >
                                      {" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                    >
                                      {" "}
                                    </td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                      }}
                                    >
                                      {" "}
                                    </td>

                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                      colSpan={2}
                                    >
                                      {" "}
                                      Total Amount:
                                    </td>
                                    <td
                                      style={{
                                        border: "solid 1",
                                        padding: "5px",
                                        textDecoration: "underline",
                                        backgroundColor:
                                          theme.palette.primary.main,
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                    >
                                      {" "}
                                      {(
                                        sup.Amount + sup.VATAmount
                                      )?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        minFractionDigits: 2,
                                      })}{" "}
                                    </td>
                                  </tr>
                                </Table>
                              )}
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            border={1}
                            style={{
                              display:
                                selectedVoucher === sup.Id ? "block" : "none",
                            }}
                          >
                            <label htmlFor="Attachment">
                              Uplaod necessary document here
                              <input
                                id="Attachment"
                                name="Attachment"
                                type="file"
                                multiple
                                hidden
                                onChange={uploadMultipleFiles}
                              />
                            </label>
                            {Photofiles && Photofiles.length > 0 && (
                              <Button
                                variant="standard"
                                style={{ color: "red" }}
                                ml={4}
                                endIcon={<Close />}
                                onClick={() => {
                                  setPhotofiles([]);
                                }}
                              />
                            )}
                            <div className="form-group multi-preview">
                              {(Photofiles || []).map((address) => (
                                <img
                                  src={address}
                                  alt="..."
                                  style={{ width: "100%", height: "100" }}
                                />
                              ))}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                }
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            color: theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button
            variant="standard"
            style={{ fontSize: "10px" }}
            endIcon={<Preview />}
          >
            {suppliers && (
              <InvoicePreview
                icon={<div>Preview</div>}
                Suppliers={suppliers}
                LineItems={lineItems}
                data=""
                DocumentType={
                  "Purchase " +
                  (props.data ? props.data.Status : props.Status === "Direct")
                }
                Type="Invoice"
              />
            )}
          </Button>
          |
          {props.Status !== "Direct" && (
            <>
              {props.data &&
                (props.data.Status === "Order" ||
                  props.data.Status === "Received") &&
                globalRoleSecurity.filter(
                  (sec) =>
                    sec.Functionality === "Purchase Payment" && sec.Security > 2
                ).length > 0 && (
                  <>
                    <Button
                      variant="standard"
                      style={{ fontSize: "10px" }}
                      endIcon={<PaymentSharp />}
                      autoFocus
                    >
                      <PaymentsModel
                        icon={<div>Payment</div>}
                        data=""
                        BankList={props.BankList}
                        OrderData={props.data}
                        Type="Purchase"
                      />
                    </Button>
                    |
                  </>
                )}
              {props.data &&
                props.data.Status === "Request" &&
                globalRoleSecurity.filter(
                  (sec) =>
                    sec.Functionality === "Purchase Request Approval" &&
                    sec.Security > 1
                ).length > 0 && (
                  <>
                    <Button
                      variant="standard"
                      style={{ fontSize: "10px" }}
                      endIcon={<BookOnline />}
                      autoFocus
                      onClick={() =>
                        handleSubmit(
                          props.data ? props.data.OrderId : 0,
                          "Approved",
                          "Normal",
                          orderDate,
                          lineItems.filter(
                            (itt) => itt.Name && itt.Name.length > 0
                          )
                        )
                      }
                    >
                      Approve
                    </Button>
                    |
                  </>
                )}

              {((props.data &&
                (props.data.Status === "Draft" ||
                  props.data.Status === "Request")) ||
                props.data === undefined ||
                props.data === null) &&
                globalRoleSecurity.filter(
                  (sec) => sec.Functionality === "Purchase Request"
                )[0].Security > 1 && (
                  <>
                    <Button
                      variant="standard"
                      style={{ fontSize: "10px" }}
                      endIcon={<RequestPageSharp />}
                      autoFocus
                      onClick={() =>
                        handleSubmit(
                          props.data ? props.data.OrderId : 0,
                          "Request",
                          "Normal",
                          orderDate,
                          lineItems.filter(
                            (itt) => itt.Name && itt.Name.length > 0
                          )
                        )
                      }
                    >
                      Request
                    </Button>
                    |
                    <Button
                      variant="standard"
                      style={{ fontSize: "10px" }}
                      endIcon={<DraftsSharp />}
                      autoFocus
                      onClick={() =>
                        handleSubmit(
                          props.data ? props.data.OrderId : 0,
                          "Draft",
                          "Normal",
                          orderDate,
                          lineItems.filter(
                            (itt) => itt.Name && itt.Name.length > 0
                          )
                        )
                      }
                    >
                      Draft
                    </Button>
                    |
                  </>
                )}
              {props.data &&
                globalRoleSecurity.filter(
                  (sec) => sec.Functionality === "Purchase " + props.data.Status
                )[0].Security > 2 && (
                  <>
                    <Button
                      variant="standard"
                      style={{ fontSize: "10px" }}
                      endIcon={<Delete />}
                      autoFocus
                      onClick={() => handleDelete(props.data.OrderId)}
                    >
                      Delete
                    </Button>
                    |
                  </>
                )}
            </>
          )}
          {props.Status === "Direct" &&
            globalRoleSecurity.filter(
              (sec) =>
                sec.Functionality === "Purchase Direct" && sec.Security > 1
            ).length > 0 && (
              <>
                <Button
                  variant="standard"
                  style={{ fontSize: "10px" }}
                  endIcon={<DirectionsSharp />}
                  autoFocus
                  onClick={() =>
                    handleSubmit(
                      props.data ? props.data.OrderId : 0,
                      "Direct",
                      "DO",
                      orderDate,
                      lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                    )
                  }
                >
                  Direct Order
                </Button>
                |
                </>)
          }
          {(props.Status === "Direct" || props.Status === "Direct Receipt") &&
          globalRoleSecurity.filter(
            (sec) =>
              sec.Functionality === "Purchase Direct" && sec.Security > 1
          ).length > 0 && (
            <>
                <Button
                  variant="standard"
                  style={{ fontSize: "10px" }}
                  endIcon={<DirectionsSharp />}
                  autoFocus
                  onClick={() =>
                    handleSubmit(
                      props.data ? props.data.OrderId : 0,
                      "Direct",
                      "DOR",
                      orderDate,
                      lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                    )
                  }
                >
                  Direct Receipt
                </Button>
                |
                </>)
}
{(props.Status === "Direct" || props.Status === "Direct Receipt & Payment") &&
          globalRoleSecurity.filter(
            (sec) =>
              sec.Functionality === "Purchase Direct" && sec.Security > 1
          ).length > 0 && (
            <>
                <Button
                  variant="standard"
                  style={{ fontSize: "10px" }}
                  endIcon={<DirectionsSharp />}
                  autoFocus
                  onClick={() =>
                    handleSubmit(
                      props.data ? props.data.OrderId : 0,
                      "Direct",
                      "DORP",
                      orderDate,
                      lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                    )
                  }
                >
                  Direct Receipt & Payment
                </Button>
                |
              </>
            )}
          <Button
            variant="standard"
            style={{ fontSize: "10px" }}
            endIcon={<Close />}
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
