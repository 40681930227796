import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { BarChart,Bar,CartesianGrid,XAxis,YAxis,Legend,Tooltip } from "recharts";
import { useTheme } from "@mui/material/styles";

export default function HomeComp(props) {
  const theme=useTheme();
    const [open, setOpen] = useState(false);
    return(
        <div width="500">
        <label onClick={() => setOpen(true)}>{props.icon}</label>
        <Dialog
          
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
        
          <DialogTitle  style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText, height:'20px'}}>{props.Title} </DialogTitle> 
          <DialogContent>
            <DialogContentText>
             <BarChart width={500} height={370} data={props.MainData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Item" />
          <YAxis />
          <Tooltip content="Abdulmm" />
          <Bar dataKey={props.MainDataType} fill="#8884d8" />
        </BarChart>
        </DialogContentText>
        </DialogContent>
        </Dialog>
        </div>
    )
}