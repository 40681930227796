import { Exposure } from "@mui/icons-material";
import { GoogleMap, useLoadScript} from "@react-google-maps/api"
function GMaps(){
    const {isLoaded}=useLoadScript({
        googleMapsApiKey: "AIzaSyCZrlwQG7sPwqkh9L6s68VoNGpNMQYZtTU"
    })
    if (!isLoaded) return <div>Loading....</div>;
   return (
    <GoogleMap zoom={10} center={{lat: 44,lng: -80}} mapContainerClassName="mapContainer"></GoogleMap>
   ) 
}
export default GMaps