import { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Grid,
  Stack,
  Autocomplete,
  Checkbox,
  Avatar,

} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";

//import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import axios from "../../axiosInstances";
import {
  AlternateEmail,
  CloseSharp,
  DeleteSharp,
  Login,
  Preview,
  SaveSharp,
  SendAndArchive,
  SendSharp,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  Name: Yup.string().required("Name is required!!!"),
  UserName: Yup.string().required("Username is required!!!"),
  Password: Yup.string().required("Password is required!!!"),
});

export default function UsersModel(props) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const {
    globalUser,
    globalStores,
    globalToken,
    globalRoleSecurity,
  } = useContext(ShoppingCartContext);
  const [open, setOpen] = useState(false);
  const [isAddresssDisplayed, setIsAddresssDisplayed] = useState(false);
  var fileObj = [];
  var fileArray = [];
  const [forCustomer, setForCustomer] = useState(
    props.data ? props.data.ForCustomer : false
  );
  const [customer, setCustomer] = useState(
    props.data ? props.data.ParentName : globalUser.Parent
  );


  const [id, setId] = useState(props.data.Id);
  const [employeesList, setEmployeesList] = useState([]);
  const [allMessages, setAllMessages] = useState([] );
  const [receiver, setReceiver] = useState(0);
  const [receiverName, setReceiverName] = useState("");
 const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  useEffect(() => {
    getAllEmployeesPerCompany()
  }, []);


  const handleUserSubmit = () => {
    /*   if(name===undefined || name===null || name.length<=0){
      alert('write the name first')
      return;
    }
 
   if(userName===undefined || userName===null || userName.length<=0){
      alert('write the username first')
      return;
    }
    if(password===undefined || password===null || password.length<=0){
      alert('write the password first')
      return;
    } */
    try {
      var fd = new FormData();
      fd.append("Id", 0);
      fd.append("SendingDate", format(new Date(), "yyyy-MM-dd"))
      fd.append("ReceivingDate", format(new Date(), "yyyy-MM-dd"))
      fd.append("Sender", globalUser.Id);
      fd.append("Receiver", receiver);
      fd.append("Voucher", 0);
      fd.append("Subject", subject);
      fd.append("Message", message);
      fd.append("Status", status);
      
      fd.append("Remark", remark);
  
      axios.post("/messageBoards", fd, {
        headers: {"Content-Type": "application/json" , 
        token: globalToken,
      }});
    } catch (err) {
    } finally {
        setMessage("")
      getAllMessages(receiver)
    }
  };
  const getAllEmployeesPerCompany=async()=>{

    try {
        await axios
          .get(
            "/employees/getByParent?Parent=" +
              globalUser.Parent,
            {
              headers: {
                token: globalToken,
              },
            }
          )
          .then(response=>{
     
            if (response.data.data){
             setEmployeesList(response.data.data);
            }else{
              alert('log out and log in again')
            }
          })
        
      } catch (err) {}
    };
    const getAllMessages=async(receiver)=>{
        setAllMessages([])
        try {
            await axios
              .get(
                "/messageBoards/getBySenderandReceiver?Sender=" +
                  globalUser.Id+ "&Receiver=" + receiver,
                {
                  headers: {
                    token: globalToken,
                  },
                }
              )
              .then(response=>{
         
                if (response.data.data){
                 setAllMessages(response.data.data);
                }else{
                  alert('log out and log in again')
                }
              })
            
          } catch (err) {}
        };
  const handleDelete = async () => {
    try {
      await axios
        .get(
          "/vouchers/getByParentAndUser?Parent=" +
            globalUser.Parent +
            "&User=" +
            id,
          {
            headers: {
              token: globalToken,
            },
          }
        )
        .then((response) => {
          if (response.data.data.length <= 0) {
            deleteUserNoTransaction();
          } else {
            alert("Can not delete this user.It has transaction history");
            setOpen(false);
            return;
          }
        });
    } catch (err) {}
  };
  const deleteUserNoTransaction = async () => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        axios.delete("/messageBoards/delete?Id=" + id, {
          headers: {
            token: globalToken,
          },
        });
      } catch (err) {
      } finally {
        props.setParentUpdate(!props.ParentUpdate);
        setOpen(false);
      }
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
  
     
          <Dialog
            
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              style={{ display: "flex" }}
              bgcolor={theme.palette.primary.main}
              color={theme.palette.primary.contrastText}
            >
              <label>Parent Company: </label>
              <label hidden>{globalUser.Parent}</label>
              <label>{globalUser.ParentName}</label>
            
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* <h5 style={{ fontWeight: "bold" }}>{props.Title}</h5> */}
                <hr></hr>

                <Grid container spacing={2} direction="row">
                <Grid item xs={12} md={6}>
                <ul>
            {employeesList &&
              employeesList.map((item) => {
                return (
                  <li key={item.Id+item.Name} style={{fontSize:"10px"}}>
                    <Button
                      startIcon={
                        <Avatar
                          src={
                            item.Image != null && item.Image.length > 0
                              ? item.Image.split(",")[0]
                              : "/Images/ProductCategoriesPics/default.png"
                          }
                        />
                      }
                     variant="standard"
                      value={item.Id}
                      onClick={(e) => {
                        getAllMessages( e.target.value);
                        setReceiver(e.target.value)
                        setReceiverName(item.Name)
                      }}
                    >
                      {item.Name}
                    </Button>{" "}
                  </li>
                );
              })}
          </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {receiverName}
                   {allMessages && <ul>
                        {allMessages.map(mm=>(
                        <li style={{background: (mm.Sender===globalUser.Id?"red":"yellow")}}>{mm.Message }</li>
                        ))}
                   
                      </ul>}
                    
                    <TextField
                      size="small"
                      variant="standard"
                      placeholder="Write the message here"
                      multiline
                      label={t("message")}
                      value={message}
                      onChange={(e)=>setMessage(e.target.value)}
                      sx={{ width: "95%", marginBottom: 2 }}
                      InputLabelProps={{
                        shrink: true,
                        
                      }}
                      slotProps={{
                        input:{
                            endAdornment:<Button variant="standard" endIcon={<SendSharp />} onClick={()=>handleUserSubmit()}/>
                        }
                      }}
                      
                    />
                   
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label={t("Name")}
                      value={values.Name}
                      onChange={handleChange("Name")}
                      sx={{ width: "95%", marginBottom: 2 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.Name && (
                      <span style={{ color: "red" }}> {errors.Name}</span>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label={t("description")}
                      multiline
                      value={values.Description}
                      onChange={
                        //(e) => {
                        handleChange("Description") // setName(e.target.value);
                      }
                      sx={{ width: "95%", marginBottom: 2 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                 <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  variant="standard"
                  label="Remark"
                  defaultValue={remark}
                  sx={{ width: "95%", marginBottom: 2 }}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </Grid> 
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                       required
                      label="Username"
                      sx={{ width: "95%", marginBottom: 2 }}
                      value={values.UserName}
                      onChange={
                        //(e) => {
                        handleChange("UserName") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.UserName && (
                      <span style={{ color: "red" }}> {errors.UserName}</span>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      type="password"
                      required
                      variant="standard"
                      label="Password"
                      sx={{ width: "95%", marginBottom: 2 }}
                      value={values.Password}
                      onChange={
                        //(e) => {
                        handleChange("Password") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.Password && (
                      <span style={{ color: "red" }}> {errors.Password}</span>
                    )}
                  </Grid>

                  {globalUser.ParentType === "Company" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <span>
                          {" "}
                          <b>{"For Customer:   "}</b>
                          <Checkbox
                            value={values.ForCustomer}
                            onChange={
                              //(e) => {
                              handleChange("ForCustomer") // setName(e.target.value);
                            }
                          />
                        </span>
                      </Grid>

                      {forCustomer === true && (
                        <Grid item xs={6} mt={1}>
                          <Autocomplete
                            label="Customers"
                            id="customer"
                            size="small"
                            variant="standard"
                            value={values.Customer}
                            onChange={
                              //(e) => {
                              handleChange("Customer") // setName(e.target.value);
                            }
                            options={
                              !(globalCustomers === undefined) &&
                              !(globalCustomers === null) &&
                              globalCustomers.length > 0 &&
                              globalCustomers.map((cust) => {
                                return cust.Name;
                              })
                            }
                            sx={{ width: "95%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Customers")}

                                //error={!from ? true : false}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={6} mt={1}>
                    <Autocomplete
                      label="Issued To"
                      id="StoreName"
                      size="small"
                      variant="standard"
                      defaultValue={values.StoreName}
                      onSelect={
                        handleChange("StoreName")
                      }
                      onChange={
                        handleChange("StoreName")
                      }
                      options={
                        !(storesList === undefined) &&
                        !(storesList === null) &&
                        storesList.length > 0 &&
                        storesList.map((pro) => {
                          return pro.Name;
                        })
                      }
                      sx={{ width: "95%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Working Store")}

                          //error={!from ? true : false}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Autocomplete
                      id="role"
                      size="small"
                      variant="standard"
                      defaultValue={values.Role}
                      onSelect={
                        //(e) => {
                        handleChange("Role") // setName(e.target.value);
                      }
                      options={
                        !(rolesList === undefined) &&
                        !(rolesList === null) &&
                        rolesList.length > 0 &&
                        rolesList.map((rl) => {
                          return rl.Description;
                        })
                      }
                      sx={{ width: "95%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Security Role")}

                          //error={!from ? true : false}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label="Mobile"
                      sx={{ width: "95%" }}
                      value={values.Mobile}
                      onChange={
                        //(e) => {
                        handleChange("Mobile") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      variant="standard"
                      label="Email"
                      type="email"
                      sx={{ width: "95%" }}
                      value={values.Email}
                      onChange={
                        //(e) => {
                        handleChange("Email") // setName(e.target.value);
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        cursor: "pointer",
                        marginTop: 2,
                      }}
                      onClick={() =>
                        setIsAddresssDisplayed(!isAddresssDisplayed)
                      }
                      pl={2}
                    >
                      User Additional Information
                    </Stack>
                    <div
                      style={{
                        display: isAddresssDisplayed ? "block" : "none",
                        width: "100%",
                      }}
                    >
                      <Grid container mt={2}>
                        <Grid item xs={6} md={6}>
                          <Autocomplete
                            disablePortal
                            selectOnFocus
                            id="route"
                            autoHighlight
                            defaultValue={
                              routeObject &&
                              !(routeObject === null) &&
                              routeObject.length > 0
                                ? routeObject[0]
                                : ""
                            }
                            getOptionLabel={(option) => option.Name}
                            onChange={(e, newValue) => {
                              setRouteObject([]);
                              setRouteObject((preValue) => [
                                ...preValue,
                                newValue,
                              ]);
                              setRoute(newValue.Id);
                            }}
                            options={salesRoutesList}
                            sx={{
                              width: "95%",
                              borderRadius: "25%",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Route Name")}
                                variant="standard"
                                //error={!fromAirport ? true : false}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Region"
                            sx={{ width: "95%" }}
                            value={values.Region}
                            onChange={
                              //(e) => {
                              handleChange("Region") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Zone"
                            sx={{ width: "95%" }}
                            value={values.Zone}
                            onChange={
                              //(e) => {
                              handleChange("Zone") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Wereda"
                            sx={{ width: "95%" }}
                            value={values.Wereda}
                            onChange={
                              //(e) => {
                              handleChange("Wereda") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="HouseNo"
                            sx={{ width: "95%" }}
                            value={values.HouseNo}
                            onChange={
                              //(e) => {
                              handleChange("HouseNo") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Surrounding"
                            sx={{ width: "95%" }}
                            value={values.Surrounding}
                            onChange={
                              //(e) => {
                              handleChange("Surrounding") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="telephone"
                            sx={{ width: "95%" }}
                            value={values.Telephone}
                            onChange={
                              //(e) => {
                              handleChange("Telephone") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Web"
                            sx={{ width: "95%" }}
                            value={values.Web}
                            onChange={
                              //(e) => {
                              handleChange("Web") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Lat"
                            sx={{ width: "95%" }}
                            value={values.Lat}
                            onChange={
                              //(e) => {
                              handleChange("Lat") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            size="small"
                            variant="standard"
                            label="Longt"
                            sx={{ width: "95%" }}
                            value={values.Longt}
                            onChange={
                              //(e) => {
                              handleChange("Longt") // setName(e.target.value);
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} style={{ cursor: "pointer" }} p={1}>
                          <label htmlFor="employeePicture">
                            User Perosnal Photo{" "}
                            {logoFiles && (
                              <Button
                                endIcon={<CloseSharp />}
                                onClick={() => {
                                  setLogoFiles("");
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="employeePicture"
                              onChange={uploadMultipleFiles}
                              hidden
                            />
                            <div className="form-group multi-preview">
                              {(logoFiles || []).map((address, index) => (
                                <img
                                  key={index}
                                  src={address}
                                  alt="..."
                                  style={{ width: "100%", height: "100" }}
                                />
                              ))}
                            </div>
                          </label>
                        </Grid>
                        <Grid
                          item
                          pl={2}
                          xs={6}
                          style={{ cursor: "pointer" }}
                          p={1}
                        >
                          <label htmlFor="userSigniture">
                            User Signature
                            {signature && (
                              <Button
                                endIcon={<CloseSharp />}
                                onClick={() => {
                                  setSignature("");
                                }}
                              />
                            )}
                            <input
                              type="file"
                              id="userSigniture"
                              multiple
                              onChange={(e) =>
                                setSignature(
                                  URL.createObjectURL(e.target.files[0])
                                )
                              }
                              hidden
                            />
                            <div className="form-group multi-preview">
                              <img
                                src={signature}
                                alt="..."
                                style={{ width: "100%", height: "100" }}
                              />
                            </div>
                          </label>
                        </Grid>
                      </Grid>
                    </div>
                            </Grid>*/}
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                height: "20px",
              }}
            >
            
              <Button
                variant="standard"
                endIcon={<CloseSharp />}
                onClick={() => setOpen(false)}
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
   
    </div>
  );
}
