import { useState, useEffect, useMemo,useContext } from "react";
import axios from "../../axiosInstances";
import MaterialReactTable from "material-react-table";
import { Button, IconButton, Box,Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,Tooltip } from "@mui/material";
import { EditSharp, FileDownload, RefreshSharp, AddSharp } from "@mui/icons-material";

import UsersModel from "./UsersModel";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
import * as XLSX from "xlsx";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

function UsersComp() {
  const navigate = useNavigate();
  const theme=useTheme();
  const [usersList, setUsersList] = useState([]);
  const {setPageHeader,globalUser,globalToken }=useContext(ShoppingCartContext)
  const [update,setUpdate]=useState();
  const [rolesList, setRolesList] = useState([]);
  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(usersList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "usersdata.xlsx");
  }
  const loadRolesList =  () => {
    axios.get("/settings/getByParentAndType?Parent="+ globalUser.Parent +"&Type=Role", {
      headers: {
        token: globalToken,
      },
    }).then(response=>{
     
         if (response.data.data){
          setRolesList(response.data.data);
         }else{
           alert('log out and log in again')
         }
       })
       
     };
  const loadUsersList = async () => {
    setUsersList([]);
    axios.get("/employees/getByParentAndType?Parent=" + globalUser.Parent + "&Type=Employee", {
      headers: {
        token: globalToken,
      },
    }).then(response=>{
      if (response.data.data){
          setUsersList(response.data.data);
         }else{
           alert('log out and log in again')
         }
       })
  };
  useEffect(() => {
    if(!globalUser){
      navigate('/');
      return;
    }
    setPageHeader("Users")
    loadUsersList();
    loadRolesList();
  }, [update]);

  //simple column definitions pointing to flat data
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "Name", 
      },
      {
        header: "User Name",
        accessorKey: "UserName", 
      },
      {
        header: "Company Name",
        accessorKey: "ParentName", 
      },
      {
        header: "Role",
        accessorKey: "Role", 
        size:100
      },
      {
        header: "Active",
        accessorKey: "Active", 
        size:80
      },
      {
        header: "Default Store",
        accessorKey: "StoreName", 
      },
    ],
    []
  );

  return (
    <Grid container>
         {rolesList && rolesList!==null && rolesList.length>0 &&
<>
          <Grid item className="hideInMobile" m={1}>

        <MaterialReactTable
          columns={columns}
          data={usersList}
          initialState={{ density: 'compact' }}
          //enableRowSelection //enable some features
          enableColumnOrdering
          enableColumnResizing
          muiTopToolbarProps= {{
            sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
          }}
          muiBottomToolbarProps={{
            sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
          }}
          renderTopToolbarCustomActions={() => (
         <Box>
            <Button      size="small"
                    variant="standard" 
            sx={{borderRadius:'25px'}} 
            endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                <UsersModel
                  icon={<div>Add</div>}
                  data={{Name:"",Username:""}}
                  Roles={rolesList}
                  Title="ADD NEW USER"
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />
            </Button>|
            <Button type="file" onClick={handleDownloadFile}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                   Download
                  </Button>|
                 <Button onClick={() => setUpdate(!update)}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                   Refresh
                 </Button>
          </Box>
         )}
          enableRowActions
     
          renderRowActions={(table) => (
            <Box>
         
              <IconButton >
                <UsersModel
                  icon={<EditSharp style={{ width: "20px", height: "20px" }} />}
                  data={table.row.original}
                  Roles={rolesList}
                  Title="EDIT USER"
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                  
                />

              </IconButton>
            </Box>
          )}
     
        />
      </Grid>
      <Grid item xs={12} className="hideInFull" mt={1}>
      <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>

            <Button       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                <UsersModel
                  icon={<div>Add</div>}
                  data=''
                  Roles={rolesList}
                                    Title="ADD NEW USER"
                                    setParentUpdate={setUpdate}
                                    ParentUpdate={update}
                />
            </Button>|
            <Button type="file" onClick={handleDownloadFile}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />}>
                    Download
                  </Button>|
                 
                    <Button onClick={() => setUpdate(!update)}       size="small"
                    variant="standard" sx={{borderRadius:'25px'}} endIcon={<RefreshSharp style={{ width: "20px", height: "20px" }} />}>
                      Refresh
                    </Button>
                
         
          </Box>
      </Grid>
          {!(usersList === undefined) &&
            usersList.length > 0 &&
            usersList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                   <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={item.Name} //.substring(0, 20)}
                      subheader={item.Type}
                    />

                    <CardContent>
                      <Typography variant="body2" align="left">
                       Tin No.: {item.TinNumber}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Category: {item.Category}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Description: {item.Description}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                            <UsersModel
                              icon={
                                <EditSharp
                                  style={{ width: "20px", height: "20px" }}
                                />
                              }
                              data={item}
                              Roles={rolesList}
                              Type="Supplier"
                              Title="EDIT STOCK"
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
            </>}
        </Grid>
        
  );
}

export default UsersComp;
