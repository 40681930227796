import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel } from "@mui/material";
import {CloseSharp, DeleteSharp, Preview, PreviewSharp, SaveSharp} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function FixedAssetSetupsModel(props) {
const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalProviders,globalCustomers,globalToken }=useContext(ShoppingCartContext)
 
  const [chartofAccounts,setChartofAccounts]=useState(props.ChartOfAccounts)
  const [categories,setCategories]=useState(props.Categories)
  const [id, setId] = useState(props.data?props.data.Id:0);
  const [category, setcategory] = useState(props.data?props.data.Category:'');
  const [depreciationType, setDepreciationType] = useState(props.data?props.data.DefDepreciationType:'');
  const [fAAccount, setFAAccount] = useState(props.data?props.data.FAAccountName:'');
  const [accumulativeDepreciationAccount, setAccumulativeDepreciationAccount] = useState(props.data?props.data.AccumulativeDepreciationAccountName:'');
  const [gainAndLossAccount, setGainAndLossAccount] = useState(props.data?props.data.GainAndLossAccountName:'');
  const [maintenanceAccount, setMaintenanceAccount] = useState(props.data?props.data.MaintenanceAccountName:'');
  const [depreciationAccount, setDepreciationAccount] = useState(props.data?props.data.DepreciationAccountName:'');
  const [appreciationAccount, setAppreciationAccount] = useState(props.data?props.data.AppreciationAccountName:'');
  const [writeDownAccount, setWriteDownAccount] = useState(props.data?props.data.WriteDownAccountName:'');
  const [salesAccount, setSalesAccount] = useState(props.data?props.data.SalesAccountName:'');
   const [remark, setRemark] = useState(props.data?props.data.Remark:'');

  const handleSubmit = () => {
    if(category===null || category<=0){
      alert('Category field is required');
      return;
    }
    if(fAAccount===null || fAAccount<=0){
      alert('Acquisition Account field is required');
      return;
    }
    if(accumulativeDepreciationAccount===null || accumulativeDepreciationAccount<=0){
      alert('Accumulative Depreciation Account field is required');
      return;
    }
    if(depreciationAccount===null || depreciationAccount<=0){
      alert('Depreciation Account field is required');
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
    try {
      var fd = new FormData();
      
      fd.append("Id", id);
      fd.append("Category",category );
      fd.append("DepreciationType",depreciationType );
      fd.append("FAAcount",chartofAccounts.filter(ca=>ca.AccountDescription===fAAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===fAAccount)[0].Account:'' );
      fd.append("AccumulativeDepreciationAccount",chartofAccounts.filter(ca=>ca.AccountDescription===accumulativeDepreciationAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===accumulativeDepreciationAccount)[0].Account:'' );
      fd.append("GainAndLossAccount",chartofAccounts.filter(ca=>ca.AccountDescription===gainAndLossAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===gainAndLossAccount)[0].Account:'' );
      fd.append("MaintenanceAccount",chartofAccounts.filter(ca=>ca.AccountDescription===maintenanceAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===maintenanceAccount)[0].Account:'' );
      fd.append("DepreciationAccount",chartofAccounts.filter(ca=>ca.AccountDescription===depreciationAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===depreciationAccount)[0].Account:'' );

      fd.append("AppreciationAccount",chartofAccounts.filter(ca=>ca.AccountDescription===appreciationAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===appreciationAccount)[0].Account:'' );
     
      fd.append("WriteDownAccount",chartofAccounts.filter(ca=>ca.AccountDescription===writeDownAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===writeDownAccount)[0].Account:'' );
      fd.append("SalesAccount",chartofAccounts.filter(ca=>ca.AccountDescription===salesAccount).length>0 ? chartofAccounts.filter(ca=>ca.AccountDescription===salesAccount)[0].Account:'' );
  
      fd.append("Parent",globalUser.Parent);
        fd.append("Remark", remark);
    
      axios.post("/fixedassetpostingsetups", fd, {
        headers: {  "Content-Type": "application/json" , 
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      props.OrderData && props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    try {
      axios.delete("/fixedassetpostingsetups?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>FA Posting Setup </DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={category}
                        id="Category"
                        onSelect={(e) => {
                          setcategory(e.target.value);
                        }}
                        options={categories && categories.length>0 && categories.map((item) => {
                             return item.Description;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={fAAccount}
                        id="FAAccount"
                        onSelect={(e) => {
                          setFAAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Acquisition Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={accumulativeDepreciationAccount}
                        id="accumulativeDepreciationAccount"
                        onSelect={(e) => {
                          setAccumulativeDepreciationAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Accum. Depr. Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={gainAndLossAccount}
                        id="gainAndLossAccount"
                        onSelect={(e) => {
                          setGainAndLossAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gain And Loss Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={maintenanceAccount}
                        id="maintenanceAccount"
                        onSelect={(e) => {
                          setMaintenanceAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Maintenance Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={depreciationAccount}
                        id="depreciationAccount"
                        onSelect={(e) => {
                          setDepreciationAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Depreciation Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={appreciationAccount}
                        id="appreciationAccount"
                        onSelect={(e) => {
                          setAppreciationAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Appreciation Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={writeDownAccount}
                        id="writeDownAccount"
                        onSelect={(e) => {
                          setWriteDownAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="WriteDown Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={salesAccount}
                        id="salesAccount"
                        onSelect={(e) => {
                          setSalesAccount(e.target.value);
                        }}
                        options={chartofAccounts && chartofAccounts.length>0 && chartofAccounts.map((item) => {
                             return item.AccountDescription;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sales Account"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={12}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Remark")}
                    defaultValue={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>

              </Grid>
           
          </DialogContentText>
        </DialogContent>
         <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        <Button
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                        {   
                          "Id":id,
                          "Category": category,
                          "FAAccount": fAAccount,
                          "DepreciationAccount": depreciationAccount,
                          "Remark": remark,
                         
                        }
                        }
                        Type="Posting"
                        DocumentType={ "Inventory Posting Setup"}
                        />
                    </Button>|
          <Button variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveSharp />}>
            Save
          </Button>|
          <Button variant="standard" autoFocus onClick={handleDelete} endIcon={<DeleteSharp />}>
            Delete
          </Button>|
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
