import * as React from "react";
import { Button,RadioGroup,FormControlLabel,Radio,colors, Grid, TextField,Avatar,} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";
import axios from "../../axiosInstances";
import { useState, useEffect,useContext } from "react";
import { useNavigate  } from 'react-router-dom'
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import { CloseSharp, SaveSharp } from "@mui/icons-material";
import MessagesModel from "./MessagesModel"
import { useTranslation } from "react-i18next";

import { Formik } from 'formik';
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .required('Name is required!!!'),
    UserName: Yup.string()
    .required('Username is required!!!'),
    Password: Yup.string()
    .required('Password is required!!!'),
    ConfirmPassword: Yup.string()
    .required('Confirm Password is required!!!')
    .oneOf([Yup.ref('Password'), null], 'Passwords must match')

});

const User_RegEx = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PW_RegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
export default function UserRegisterComp(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { globalUser,setGlobalUser,globalToken} =useContext(ShoppingCartContext)

  let history=useNavigate ();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [defaultTheme, setDefaultTheme] = useState(globalUser.Theme);
  const [themeExpand, setThemeExpand] = useState(false);

  const [username, setUserName] = useState(globalUser.UserName);

  const [password, setpassword] = useState(globalUser.password);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleUserSubmit = (values) => {
 
   /* if(username===undefined || username===null || username.length<=0){
      alert('write the username first')
      return;
    }
    if(password===undefined || password===null || password.length<=0){
      alert('write the password first')
      return;
    } 
    if(confirmPassword===undefined || confirmPassword===null || confirmPassword.length<=0){
      alert('write the confirm password first')
      return;
    } 
    if(confirmPassword!==password){
      alert('The password and confirm password are different.')
      return;
    } */
    if (window.confirm("Are you sure you want to update your profile?")) {

    try {
      var fd = new FormData();
      fd.append("Id", globalUser.Id);
      /* fd.append("TinNumber", globalUser.TinNumber);
      fd.append("Type", 'Employee');
      fd.append("Name", globalUser.Name);
      fd.append("Description", globalUser.Description);
      fd.append("Category", globalUser.Category);
       fd.append("Logo", globalUser.Picture);
      
      fd.append("Amount", globalUser.Amount);
      fd.append("Commision", globalUser.Commision);
      fd.append("Remark", defaultTheme);
      fd.append("Route", globalUser.Route);
      fd.append("Region", globalUser.Region)
      fd.append("Zone", globalUser.Zone);
      fd.append("Wereda", globalUser.Wereda);
      fd.append("HouseNo", globalUser.HouseNo);
      fd.append("Surrounding", globalUser.Surrounding);
      fd.append("Telephone", globalUser.Telephone);
      fd.append("Mobile", globalUser.Mobile);
      fd.append("Email", globalUser.Email);
      fd.append("Web", globalUser.Web); 
      fd.append("UserName", globalUser.UserName);
      fd.append("Password", globalUser.Password);*/
      fd.append("Theme", defaultTheme);
      /* fd.append("Role", globalUser.Role);
      fd.append("Lat", globalUser.Lat);
      fd.append("Longt", globalUser.Longt);
   
        fd.append("Parent", globalUser.Parent);
     
     fd.append("Logo",globalUser.Logo)
      fd.append("Store", globalUser.Store); */
      axios.post("/employees/updateUserProfile", fd, {
      headers: {"Content-Type": "application/json" , 
      token: globalToken,
   },
      });
    } catch (err) {
    } finally {
      setGlobalUser({...globalUser,
        Theme: defaultTheme})
      setOpen(false);
    }
  }
  }
  return (
    <div>
      <label onClick={handleClickOpen}>{props.icon?props.icon:props.menuTtitle}</label>
      <Formik
          initialValues={props.data}
          onSubmit={values => {handleUserSubmit(values)}}
           validationSchema={validationSchema}
           enableReinitialize
            
          >
            {({values,errors,touched,handleChange,isValid,handleSubmit}) => (
              
      <Dialog       
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{backgroundColor:theme.palette.primary.main,color:theme.palette.primary.contrastText,height:25,marginBottom:2}}> {props.Title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           <Grid container>
           
           <Grid item xs={6}>
           <Grid container>
            
           <Grid item xs={6}>
               Name of User: {globalUser.Name}
              </Grid>
              <Grid item xs={12}>
              </Grid>
            
              </Grid>

             </Grid>
             
             <Grid item xs={6}>
                   <Button variant="contained" fullWidth sx={{ margin:1,
                      backgroundColor:defaultTheme==="THEME1"?colors.orange[500]:
                      defaultTheme==="THEME2"?colors.blue[900]:
                      defaultTheme==="THEME3"?colors.teal[300]:
                      defaultTheme==="THEME4"?colors.grey[500]:
                      defaultTheme==="THEME5"?colors.lightBlue[500]:
                      defaultTheme==="THEME6"?colors.lime[500]:
                      defaultTheme==="THEME7"?"#454545":
                      defaultTheme==="THEME8"?colors.purple[200]:
                      defaultTheme==="THEME9"?colors.common.white:
                      defaultTheme==="THEME10"?"#D4B996":"#0971f1",

                      color:defaultTheme==="THEME1"?colors.common.white:
                      defaultTheme==="THEME2"?colors.common.white:
                      defaultTheme==="THEME3"?colors.common.white:
                      defaultTheme==="THEME4"?colors.yellow[500]:
                      defaultTheme==="THEME5"?colors.common.white:
                      defaultTheme==="THEME6"?colors.blue[500]:
                      defaultTheme==="THEME7"?colors.common.white:
                      defaultTheme==="THEME8"?colors.common.white:
                      defaultTheme==="THEME9"?colors.lightGreen[500]:
                      defaultTheme==="THEME10"?colors.common.white:colors.common.white,
                    }}
                    onClick={()=>setThemeExpand(!themeExpand)}
                    >Select Your Personal Theme</Button>
              </Grid>
              <Grid item xs={6}>
             <Avatar src={globalUser.Logo} sx={{
              height:'180px',
              width:'180px',
              marginLeft:4
             }}/>
             </Grid>
              <Grid item xs={6}>
                     <RadioGroup
                    sx={{display:(themeExpand?'block':'none'),marginLeft:5}}
                    
  size="small"
    name="controlled-radio-buttons-group"
    value={defaultTheme}
    onChange={(e)=>{
      setDefaultTheme(e.target.value);
     
    }}
    
  >
    <FormControlLabel sx={{width:'100%',backgroundColor:"#0971f1",color:colors.common.white}}  value="THEME0" control={<Radio size="small" />} label="THEME0" />
    <FormControlLabel sx={{width:'100%',backgroundColor:colors.orange[500],color:colors.common.white}} value="THEME1" control={<Radio size="small" />} label="THEME1" />
    <FormControlLabel sx={{width:'100%',backgroundColor:colors.blue[900],color:colors.common.white}} value="THEME2" control={<Radio size="small" />} label="THEME2" />
     <FormControlLabel sx={{width:'100%',backgroundColor:colors.teal[300],color:colors.common.white}} value="THEME3" control={<Radio size="small" />} label="THEME3" />
     <FormControlLabel sx={{width:'100%',backgroundColor:colors.grey[500],color:colors.yellow[500]}} value="THEME4" control={<Radio size="small" />} label="THEME4" />
    <FormControlLabel sx={{width:'100%',backgroundColor:colors.lightBlue[500],color:colors.common.white}} value="THEME5" control={<Radio size="small" />} label="THEME5" />
    <FormControlLabel sx={{width:'100%',backgroundColor:colors.lime[500],color:colors.blue[500]}} value="THEME6" control={<Radio size="small" />} label="THEME6" />
     <FormControlLabel sx={{width:'100%',backgroundColor:"#454545",color:colors.common.white}} value="THEME7" control={<Radio size="small" />} label="THEME7" />
     <FormControlLabel sx={{width:'100%',backgroundColor:colors.purple[200],color:colors.common.white}} value="THEME8" control={<Radio size="small" />} label="THEME8" />
     <FormControlLabel sx={{width:'100%',backgroundColor:colors.common.white,color:colors.lightGreen[500]}} value="THEME9" control={<Radio size="small" />} label="THEME9" />
     <FormControlLabel sx={{width:'100%',backgroundColor:"#D4B996",color:colors.common.white}} value="THEME10" control={<Radio size="small" />} label="THEME10" />
  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                 
                </Grid>
                <Grid item xs={12}>
                  <hr></hr>
                <p>
                    Already registered?
                    
                    <Button  ml={8} onClick={()=>{
                      history("/");
                      setOpen(false)
                    }}>LOG IN
               </Button>
               <MessagesModel  Title= {t("messages")} data={globalUser} icon={<div> {t("messages")}</div>} />

                  </p>
                </Grid>
              
              
             
             </Grid>
              
          </DialogContentText>
        </DialogContent>
       <DialogActions sx={{backgroundColor:theme.palette.primary.main,color:theme.palette.primary.contrastText}}>
       <Button variant="standard" endIcon={<SaveSharp />} onClick={()=>handleUserSubmit(values)} autoFocus>
      
            Update
          </Button>|
          <Button variant="standard" endIcon={<CloseSharp />} onClick={()=>handleClose()} autoFocus>
            Close
          </Button>
        </DialogActions> 
      </Dialog>
            )}
      </Formik>
    </div>
  );
}
