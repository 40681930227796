import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  ContentPasteOffSharp,
  Troubleshoot,
  PropaneSharp,
  PreviewSharp,
  RequestPageSharp,
  ApprovalSharp,
  DeliveryDiningSharp,
  DeleteForeverSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function ItemJournalIssueModel(props) {
  const theme=useTheme();
  const [open, setOpen] = useState(false);
  const { globalUser, allItems,globalToken,globalRoleSecurity } = useContext(ShoppingCartContext);
  const { t, i18n } = useTranslation();
   const [IssuedItemJournalLines, setIssuedItemJournalLines] = useState(props.IssuedItemJournalLines);
  const [itemjournallines, setItemJournalLines] = useState(props.ItemJournalLines);
  
 const [remark,setRemark]=useState('')
 const [orderDate,setOrderDate]=useState(format(new Date(),"yyy-MM-dd"))
  
useEffect(()=>{
setItemJournalLines(itemjournallines.map(ijl=>{
  var issuedQty=0;
  IssuedItemJournalLines && IssuedItemJournalLines.filter(iss=>parseInt(iss.Item)===parseInt(ijl.Item)).map(IssLitem=>{
  issuedQty=(parseFloat(issuedQty)+parseFloat(IssLitem.Quantity));
  })
  ijl.IssuedQuantity=issuedQty;
  ijl.ReceivingQuantity=(parseFloat(ijl.Quantity)-parseFloat(issuedQty));
  return ijl;
}))
},[])
 
  const handleSubmit = (status, lItems) => {
  
   
   
    var testValues=false;
      lItems.map((li) => {
        if (isNaN(li.ReceivingQuantity) || li.ReceivingQuantity < 0) {
          alert("First insert the proper quantity of product: " + li.Name);
          testValues=true;
          return "";
        }
      });
      if (
        testValues===true
      ) {
        return "";
      }
    if (
      window.confirm(
        "are you sure you want to Issue?"
      )
    ) {
    try {
      var fd = new FormData();
            fd.append("Id", 0);
            fd.append("Order",props.IssueOrder.Id);
            fd.append("OrderDate",props.IssueOrder.OrderDate)
           fd.append("RequestedBy", props.IssueOrder.RequestedBy);
           fd.append("ApprovedBy", props.IssueOrder.ApprovedBy);
           fd.append("ApprovedDate", props.IssueOrder.ApprovedDate);
       
            fd.append("IssuedBy",globalUser.Id)
            fd.append("IssuedDate",orderDate)
            fd.append("IssuedTo", props.IssueOrder.IssueTo);
            fd.append("Store", props.IssueOrder.Store);
            fd.append("Parent", globalUser.Parent);
            fd.append("Status", status);
            fd.append("Remark", remark);
            fd.append(
              "ItemJournalLines",
              JSON.stringify(
                itemjournallines.map(litem=>{
                 litem.Id=0;
                 litem.Quantity=litem.ReceivingQuantity
                  return litem;
                })
              )
            );
 
            axios
              .post("/itemjournal", fd, {
                headers: { "Content-Type": "application/json" , 
                    token: globalToken,
                 },
              })
                       
      
    } catch (err) {
    } finally {
      setItemJournalLines([]);
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
 
  };
  const handleDelete = (id) => {
    try {
      axios.delete("/jobs?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  };
  const handleLineItemDelete = (id) => {
    try {
      axios.delete("/lineitems?Id=" + id, {
        headers: {
          token: globalToken,
        },
      }); //add id
    } catch (err) {
    } finally {
    }
  };

 return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
                <DialogContentText mt={1}>
                  <DialogTitle
                    style={{
                      backgroundColor: theme.palette.primary.main,             color:theme.palette.primary.contrastText,
                      height: "15px",
                    }}
                   
                  >
                    {"Issue "+ props.IssueOrder.Status}  {/* : {props.IssueOrder.IssuedToName} */}
                  </DialogTitle>
                  <div
                   
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <span>Doc. No.: {props.IssueOrder.Id}</span><br></br>
                       
                      </Grid>
                      <Grid item xs={6}>
                      Status: {props.IssueOrder.Status}
                      </Grid>
                      <Grid item xs={6}>
                        Requested By: {props.IssueOrder.RequestedByName}
                      </Grid>
                      <Grid item xs={6}>
                      Issue To: {props.IssueOrder.IssuedToName}
                       
                      </Grid>
                      <Grid item xs={6}>
                        Approved By: {props.IssueOrder.ApprovedByName}
                      </Grid>
                      <Grid item xs={6} mt={1}>
                        Store: {props.IssueOrder.StoreName}
                      </Grid>
                      <Grid item xs={6} mt={1}>
                        <TextField
                          size="small"
                          label="Issue Date"
                          type="date"
                          value={
                            orderDate
                          }
                          onChange={(e) => {
                            setOrderDate(e.target.value);
                            props.IssueOrder.OrderDate=e.target.value;
                          
                          }}
                          sx={{ width: "70%", marginBottom: 2 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          variant="standard"
                          value={remark}
                          label="Remark"
                          minRows={2}
                          sx={{ width: "95%" }}
                          onChange={(e) => {
                           setRemark(e.target.value);
                          props.IssueOrder.Remark=e.target.value;
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                         
                        >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Order Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Issued Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>

                              <Grid
                                item
                                xs={2}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  textAlign: "center",
                                }}
                              >
                                Remark
                              </Grid>
                           

                              {itemjournallines &&
                                itemjournallines !== null &&
                                itemjournallines.length > 0 && itemjournallines.map((item, index) => {
                                                
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={3}>
                                        {item.Name}
                                       
                                        </Grid>
                                        <Grid item xs={2}>
                                          {item.Quantity}
                                        </Grid>
                                        <Grid item xs={2}>
                                          {item.IssuedQuantity}
                                        </Grid>
                                        <Grid item xs={2}>
                                          <TextField
                                       
                                            size="small"
                                            variant="standard"
                                            type="float"
                                            value={
                                              item.ReceivingQuantity
                                            }
                                            sx={{ width: "70px" }}
                                            onChange={(e) => {
                                              if((parseFloat(item.Quantity)-parseFloat(item.IssuedQuantity)-parseFloat(e.target.value))<0){
                                             item.ReceivingQuantity = (parseFloat(item.Quantity)-parseFloat(item.IssuedQuantity));
                                             }else{
                                             item.ReceivingQuantity = e.target.value;
                                              
                                             } 
                                              
                                              setItemJournalLines(
                                                itemjournallines.map((ltem) => {
                                                  return ltem;
                                                })
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>

                                        <Grid item xs={2}>
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            value={item.Remark}
                                            sx={{ width: "95%" }}
                                            onChange={(e) => {
                                              item.Remark = e.target.value;
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Grid>
                                       
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {itemjournallines &&
                              itemjournallines !== null &&
                              itemjournallines.length > 0 &&
                              itemjournallines
                                /* .filter(
                (it) =>
                  parseInt(it.IssuedToName) === parseInt(issueToName)
              ) */
                                .map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                            {item.Name}
                                        
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                            {/*   <TextField
                                                size="small"
                                                variant="standard"
                                                type="float"
                                                value={item.Quantity}
                                                maxRows={4}
                                                sx={{ width: "100%" }}
                                                onChange={(e) => {
                                                  item.Quantity =
                                                    e.target.value;
                                                  setItemJournalLines(
                                                    itemjournallines.map(
                                                      (litem) => {
                                                        return litem;
                                                      }
                                                    )
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              /> */}
                                            </Grid>

                                            <Grid item xs={4}>
                                              Remark :
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                              {item.Remark}
                                            </Grid>
                                           
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </DialogContentText>
             
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button  variant="contained" size="small" endIcon={<PreviewSharp />}>
            <InvoicePreview
              icon={
                <div style={{ fontSize: "10px", color: "white" }}>Preview</div>
              }
              data={[props.IssueOrder]}
              LineItems={itemjournallines/* .map(litem=>{
                var Newlineitem=litem;
                Newlineitem.Quantity=litem.ReceivingQuantity;
                return Newlineitem;
              }) */ }
              Type="IssueOrderIssuing"
              DocumentType={"Store " + props.IssueOrder.Status}
            />
          </Button>
          |
         
      {globalRoleSecurity.filter(sec=>(sec.Functionality==="Issued" && (sec.Security>1))).length>0 &&
<>
          <Button
            variant="contained" size="small"
            endIcon={<ApprovalSharp />}
            autoFocus
            onClick={() =>
              handleSubmit(
                "Issued",
                itemjournallines.filter((itt) => itt.Name && itt.Name.length > 0)
              )
            }
          >
            Issue
          </Button>
          |
          </>
}

{(props.IssueOrder.Status==='Request') && globalRoleSecurity.filter(sec=>(sec.Functionality===("Issue " + props.IssueOrder.Status) && (sec.Security>1))).length>0 &&
<>
          <Button
           variant="contained" size="small"
            endIcon={<DeleteForeverSharp />}
            autoFocus
            onClick={() => handleDelete(props.IssueOrder.Id)}
          >
            Delete
          </Button>
          |
          </>
}


          <Button
          variant="contained" size="small"
            endIcon={<Close />} 
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
