import { useState, useEffect, useContext } from "react";
import { Button, Autocomplete, TextField, Grid, MenuItem } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
//import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

import axios from "../../axiosInstances";
import { ShoppingCartContext } from "../Context/ShoppingCartContext"
export default function SalesRoutesModel(props) {

  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
const {globalToken}=useContext(ShoppingCartContext)
  const [id, setId] = useState(props.data.Id);
  const [name, setName] = useState(props.data.Name);
  const [deliveryPerson, setDeliveryPerson] = useState(props.data.DeliveryPersonName);
  const [salesRepresentative, setSalesRepresentative] = useState(props.data.SalesRepresentativeName);
  const [supervisor, setSupervisor] = useState(props.data.SupervisorName);
  const [pathDetail, setPathDetail] = useState(props.data.PathDetail);
  const [remark, setRemark] = useState(props.data.Remark);
  const [employeesList, setEmployeesList] = useState([]);
  
  const handleSubmit = () => {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Name", name);
      fd.append("DeliveryPerson", deliveryPerson);
      fd.append("SalesRepresentative", salesRepresentative);
      fd.append("Supervisor", supervisor);
      fd.append("RoutePath", pathDetail);
      fd.append("Remark", remark);

      axios.post("/SalesRoutes", fd, {
        headers: { "Content-Type": "application/json" , 
            token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };
  const handleDelete = () => {
    try {
      axios.delete("/SalesRoutes?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  const getEmployeeList = () => {
    try {
      axios.get("/suppliers/getByParent?Parent=" + props.data.Supplier, {
        headers: {
          token: globalToken,
        },
      })
      .then((result) => {
        setEmployeesList(result.data.data);
      });
    } catch (err) {}
  };
  console.log(employeesList)
  useEffect(() => {
    getEmployeeList();
   
  }, []);

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        {/* 
        <DialogTitle id="responsive-dialog-title"> </DialogTitle> */}
        <DialogContent>
          <DialogContentText>
           
              <h5 style={{ fontWeight: "bold" }}>{props.Title}</h5>
              <hr></hr>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label={t("Name")}
                    defaultValue={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
               
                   
                   <Grid item xs={12} md={6}>
                  <Autocomplete
                    //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                    disablePortal
                    selectOnFocus
                    autoHighlight
                    contentEditable={false}
                    value={employeesList.filter(cat=>parseInt(cat.Id)===parseInt(salesRepresentative)).length>0? employeesList.filter(cat=>parseInt(cat.Id)===parseInt(salesRepresentative))[0].Name:''}
                    id="salesRepresentative"
                    onSelect={(e) => {
                      setSalesRepresentative(employeesList.filter(cat=>cat.Name===e.target.value).length>0 ? employeesList.filter(cat=>cat.Name===e.target.value)[0].Id:0)
                    }
                    }
                    options={!(employeesList === undefined) &&
                      !(employeesList === null) &&
                      employeesList.length > 0
                        ? employeesList.map((item) => {
                            return item.Name;
                          })
                        : [""]
                    }
                    sx={{
                      width: "100%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="sales Representative"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                   </Grid>
                   
                   <Grid item xs={12} md={6}>
                  <Autocomplete
                    //disabled={((status === "Received") || (globalUser.Name===transferedTo)) ? true : false}
                    disablePortal
                    selectOnFocus
                    autoHighlight
                    contentEditable={false}
                    value={employeesList.filter(cat=>parseInt(cat.Id)===parseInt(supervisor)).length>0? employeesList.filter(cat=>parseInt(cat.Id)===parseInt(supervisor))[0].Name:''}
                    id="supervisor"
                    onSelect={(e) => {
                      setSupervisor(employeesList.filter(cat=>cat.Name===e.target.value).length>0 ? employeesList.filter(cat=>cat.Name===e.target.value)[0].Id:0)
                    }
                    }
                    options={!(employeesList === undefined) &&
                      !(employeesList === null) &&
                      employeesList.length > 0
                        ? employeesList.map((item) => {
                            return item.Name;
                          })
                        : [""]
                    }
                    sx={{
                      width: "100%",
                      marginBottom: 2,
                      borderRadius: "25%",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="supervisor"
                        variant="standard"
                        //error={!fromAirport ? true : false}
                      />
                    )}
                  />
                   </Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label="Remark"
                    defaultValue={remark}
                    multiline
                    maxRows={4}
                    sx={{ width: "95%", marginBottom: 2 }}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                </Grid>
            <hr></hr>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmit}>
            Save
          </Button>
          <Button autoFocus onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
