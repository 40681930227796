import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../axiosInstances";

import {
  Avatar,
  Typography,
  IconButton,
  Box,
  Grid,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonGroup,
} from "@mui/material";

import MaterialReactTable from "material-react-table";
import ImageGallery from "react-image-gallery";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import {Close} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

function ProductDetails(props) {
  const theme=useTheme();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [Images, setImages] = useState([]);
  const [StockBalances, setStockBalances] = useState([]);
  const [supplier, setSupplier] = useState();
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const { addtoCart,globalUser,allItems } = useContext(ShoppingCartContext);

  const columns = [
   
    {
      header: "Supplier Name",
      accessorKey: "SupplierName",
    },
    {
      header: "Unit Price",
      accessorKey: "Price",
    },
    {
      header: "Stock",
      accessorKey: "Quantity",
    },
   
    {
      header: "Expiry Date",
      accessorKey: "ExpiryDate",
    },
    {
      header: "Supplier",
      accessorKey: "Supplier",
    },
  ];
 /*  const loadproductsFromSuppliers = () => {
    axios
      .get("/stockbalances/ByItem?Item=" + props.Products[0].Id)
      .then((response) => {
        if (response.data.data) {
          setStockBalances(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const getSuppliersById = (id) => {
    axios
      .get("/suppliers/getById?Id=" + id)
      .then((response) => {
        if (response.data.data) {
          setSupplier(response.data.data[0]);
          //setglobalSelectedSuppliers(response.data.data[0])
        } else {
          alert("log out and log in again");
        }
      });
  }; */

  useEffect(() => {
    let images = [];

    if (props.Products[0].Picture && props.Products[0].Picture.split(",").length > 0) {
      props.Products[0].Picture.split(",").map((image) => {
        images.push(image)
        /* images.push({
          original: { image }.image,
          thumbnail: { image }.image,
        }); */
      });
    }else{
      images.push("/Images/Products/default.png")
     /*  images.push({
        original: "/Images/Products/default.png",
        thumbnail: "/Images/Products/default.png",
      }); */
    }
    setImages(images);
    setStockBalances(allItems.filter(item=>item.Item===props.Products[0].Id));
    //loadproductsFromSuppliers();
    //getSuppliersById(3);
  
  }, []);


  return (
    <div style={{height:'500'}}>
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{ backgroundColor: theme.palette.primary.main, height: '20px'}}>
          <Typography variant="body1" display="flex" fullWidth>
            <Avatar
            size='small'
              src={
                props.Products[0].Picture != null && props.Products[0].Picture.length > 0
                  ? props.Products[0].Picture.split(",")[0]
                  : "/Images/products/default.png"
              }
             sx={{marginRight:1,height:'35px'}}
            />
            {"    "}
            {props.Products[0].Name} {"    "}
            <span ml={4}> Price: {totalPrice} </span>
          </Typography>
         
        </DialogTitle>
     
          <DialogContentText>
          <hr></hr>
           
              <Grid container >
                <Grid item xs={12} md={6} p={1}>
                {/*   <ImageGallery
                    items={Images ? Images : ""}
                    additionalClass="ImageGallery"
                    
                  /> */}
                  <img src={Images[0]} />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ alignContent: "left", alignItems: "left" }}
                >
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      Product Category: <br></br>
                      {props.Products[0].CategoryName} <br></br>
                      Product Description: <br></br>
                      {props.Products[0].Description} <br></br>
                    </Typography>
                  </div>
                  <Typography>
                  <ButtonGroup>
                    Quantity:
                    <Button
                    variant="contained"
                    size="small"
                     sx={{marginLeft:4}}
                      onClick={() => {
                        setQuantity(quantity > 0 ? quantity - 1 : quantity);
                        setTotalPrice(quantity * props.Products[0].Price);
                      }}
                    >
                      -
                    </Button>
                    <input
                      type="number"
                      id="quantity"
                      style={{width:"50px" }} 
                      
                      value={quantity}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      ml={2}
                      onClick={() => {
                       
                        setQuantity(quantity + 1);
                        setTotalPrice(quantity * props.Products[0].Price);
                     
                      }}
                    >
                      +
                    </Button>
                    </ButtonGroup>
                    <br></br>
                    <Button
                     variant="contained"
                     size="small"
                     fullWidth
                      sx={{
                        marginTop:1
                      }}
                      ml={2}
                      onClick={() => {
                        if(quantity <= 0){
                          alert("check the quantity first first");
                          return;
                        }else if(props.Products[0].Suppplier===undefined){
                           alert('Select supplier first');
                          return;
                        }else{
                          addtoCart(
                              {
                                Id: 0,
                                Item: props.Products[0].Item,
                                Name: props.Products[0].Name,
                                Quantity:(quantity===0?1:quantity),
                                UnitPrice: props.Products[0].Price,
                                TotalPrice:props.Products[0].Price*quantity,
                                Supplier: props.Products[0].Supplier,
                                SupplierName: props.Products[0].SupplierName,
                                Image: props.Products[0].Picture,
                                TaxAmount: props.Products[0].VATInclusive
                                      ? props.Products[0].Price * 1 * 0.15
                                      : 0,
                                 
                              },
                             
                            );
                            setQuantity(0);
                            setOpen(false);
                        }
                           
                      
                      }}
                    >
                      {t("add cart")}
                    </Button>
                  </Typography>

                  <Tooltip title={t("substitute item")} color="primary">
                    <IconButton aria-label="Substitute">
                      <FindReplaceIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Mandatory products" color="primary">
                    <IconButton aria-label="Manadatory">
                      <CoPresentIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("favorite item")} color="primary">
                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("share item")} color="primary">
                    <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {(globalUser.Category==="Specific Category")?
                <Grid item xs={11} mt={1}>
                  <MaterialReactTable
                    columns={columns}
                    data={props.Products}
                     initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
                   /*  enableGlobalFilter
                    enableRowSelection //enable some features
                   
                    enableColumnOrdering
                    enableColumnResizing
                    enableRowNumbers 
                    enableColumnFilters={false}*/
                    enableRowActions
                    renderRowActions={(table) => (
                      <Box>
                        <Tooltip title={t("add cart")}>
                          <IconButton  onClick={() => {

                        if(quantity <= 0){
                          alert("check the quantity first");
                          return;
                        }else if(table.row.original.Supplier===undefined){
                          alert('Select supplier first');
                          return;
                        }else{
                          addtoCart(
                              {
                                Id: 0,
                                Item: props.Products[0].Id,
                                Name: props.Products[0].Name,
                                Quantity: quantity,
                                UnitPrice: table.row.original.Price,
                                TotalPrice:table.row.original.Price*quantity,
                                Supplier: table.row.original.Supplier,
                                SupplierName: table.row.original.SupplierName,
                                Image: props.Products[0].Picture,
                                TaxAmount: props.Products[0].VATInclusive
                                      ? table.row.original.Price * 1 * 0.15
                                      : 0,
                                 
                              },
                             
                            );                           
                        setQuantity(0);
                        setOpen(false);
                            }
                      }}>
                            <AddShoppingCartIcon
                              style={{ width: "20px", height: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  ></MaterialReactTable>
                </Grid>:''}
              </Grid>
         
          </DialogContentText>
      
        <DialogActions sx={{ backgroundColor: theme.palette.primary.main, height:'20px'}}>
          <Button size="small" color="inherit" endIcon={<Close />} onClick={() => setOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProductDetails;
