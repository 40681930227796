import {
  AddSharp, EditSharp, FileDownload, RefreshSharp
} from "@mui/icons-material";
import {
  Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MaterialReactTable from "material-react-table";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "../../axiosInstances";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import ItemsComp from "./ItemsComp";
import StoresModel from "./StoresModel";

function StoresComp(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [update, setUpdate] = useState(false);
  const { setPageHeader, globalUser, globalToken } =
    useContext(ShoppingCartContext);
  const { t, i18n } = useTranslation();
  const [storesList, setStoreList] = useState([]);

  const columns = useMemo(
    () => [
      /*  {
          header: "Store Id",
          accessorKey: "Id", 
        }, */
      {
        header: "Store Name",
        accessorKey: "Name",
      },
      {
        header: "Parent Store",
        accessorKey: "ParentStoreName",
      },
      /*  {
          header: "Supplier",
          accessorKey: "Supplier", 
        }, 
        {
          header: "Supplier Name",
          accessorKey: "SupplierName", 
        },*/

      {
        header: "Region",
        accessorKey: "Region",
        size: "120",
      },
      {
        header: "Zone",
        accessorKey: "Zone",
        size: "120",
      },
      {
        header: "Wereda",
        accessorKey: "Wereda",
        size: "120",
      },
      {
        header: "House No.",
        accessorKey: "HouseNo",
        size: "120",
      },
      {
        header: "Surrounding",
        accessorKey: "Surrounding",
      },
      {
        header: "Latitude",
        accessorKey: "Lat",
        size: "120",
      },
      {
        header: "Longtude",
        accessorKey: "Longt",
        size: "120",
      },
    ],
    []
  );

  const loadStoresList = () => {
    axios
      .get("/stores/getByParent?Parent=" + globalUser.Parent, {
        headers: {
          token: globalToken,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setStoreList(response.data.data);
        } else {
          alert("log out and log in again12345");
        }
      });
  };

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    var jsonData = XLSX.utils.sheet_to_json(worksheet);

    var fdata = new FormData();
    fdata.append("JsonData", JSON.stringify(jsonData));
    await axios.post("/items/Excel", fdata, {
      headers: {
        token: globalToken,
      },
    });
  };
  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(storesList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "stores");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "storesdata.xlsx");
  }

  useEffect(() => {
    if (!globalUser) {
      navigate("/");
      return;
    }
    setPageHeader("Stores");
    loadStoresList();
  }, [update]);
  return (
    storesList &&
    storesList.length > 0 && (
      <Grid container>
        <Grid item className="hideInMobile" m={1}>
          <MaterialReactTable
            columns={columns}
            data={storesList}
            initialState={{
              density: "compact",
              pagination: {
                pageIndex: 0,
                pageSize: 12,
              },
            }}
            enableGlobalFilter
            enableColumnOrdering
            enableColumnResizing
            enableColumnFilters
            enableRowNumbers
            muiTopToolbarProps={{
              sx: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
            renderTopToolbarCustomActions={() => (
              <Box>
                <Tooltip title={t("add new store")}>
                  <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "25px" }}
                    endIcon={<AddSharp />}
                  >
                    <StoresModel
                      icon={<div>Add</div>}
                      data=""
                      StoresList={storesList}
                      Title="ADD NEW STORE"
                      ParentUpdate={update}
                      setParentUpdate={setUpdate}
                    />
                  </Button>
                </Tooltip>
                |
                <Tooltip title={t("download")}>
                  <Button
                    size="small"
                    variant="standard"
                    type="file"
                    sx={{ borderRadius: "25px" }}
                    endIcon={<FileDownload />}
                    onClick={handleDownloadFile}
                  >
                    Download
                  </Button>
                </Tooltip>
                |
                <Tooltip title={t("refresh")}>
                  <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "25px" }}
                    endIcon={<RefreshSharp />}
                    onClick={() => setUpdate(!update)}
                  >
                    Refresh
                  </Button>
                </Tooltip>
              </Box>
            )}
            enableRowActions
            renderRowActions={(table) => (
              <Box>
                <Tooltip title={t("modify")}>
                  <IconButton>
                    <StoresModel
                      icon={
                        <EditSharp style={{ width: "20px", height: "20px" }} />
                      }
                      data={table.row.original}
                      Title="EDIT STORE"
                      StoresList={storesList}
                      ParentUpdate={update}
                      setParentUpdate={setUpdate}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          ></MaterialReactTable>
        </Grid>
        <Grid item xs={12} marginTop={1} className="hideInFull">
          <Box
            sx={{
              width: "100%",
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            <Tooltip title={t("add new store")}>
              <Button
                size="small"
                variant="standard"
                sx={{ borderRadius: "25px" }}
                endIcon={<AddSharp />}
              >
                <StoresModel
                  icon={<div>Add</div>}
                  data=""
                  StoresList={storesList}
                  Title="ADD NEW STORE"
                  ParentUpdate={update}
                  setParentUpdate={setUpdate}
                />
              </Button>
            </Tooltip>
            |
            <Tooltip title={t("download")}>
              <Button
                size="small"
                variant="standard"
                type="file"
                sx={{ borderRadius: "25px" }}
                endIcon={<FileDownload />}
                onClick={handleDownloadFile}
              >
                Download
              </Button>
            </Tooltip>
            |
            <Tooltip title={t("refresh")}>
              <Button
                size="small"
                variant="standard"
                sx={{ borderRadius: "25px" }}
                endIcon={<RefreshSharp />}
                onClick={() => setUpdate(!update)}
              >
                Refresh
              </Button>
            </Tooltip>
          </Box>
        </Grid>
        {!(storesList === undefined) &&
          storesList.length > 0 &&
          storesList.map((item) => {
            return (
              <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                <Card sx={{ borderRadius: 10, boxShadow: 3, width: "100%" }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        src={
                          item.Picture != null && item.Picture.length > 0
                            ? item.Picture.split(",")[0]
                            : "/Images/Products/default.png"
                        }
                      />
                    }
                    className="CardHeader"
                    title={"Name: " + item.Name} //.substring(0, 20)}
                  />

                  <CardContent>
                    <Typography variant="body2" align="left">
                      Surrounding: {item.Surrounding}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Region: {item.Region}
                    </Typography>
                    <Typography variant="body2" align="left">
                      Zone: {item.Zone}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    <Box>
                      <Tooltip title={t("Edit Store")}>
                        <IconButton>
                          <StoresModel
                            icon={
                              <EditSharp
                                style={{ width: "20px", height: "20px" }}
                              />
                            }
                            data={ItemsComp}
                            Title="EDIT STORE"
                            StoresList={storesList}
                            ParentUpdate={update}
                            setParentUpdate={setUpdate}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    )
  );
}

export default StoresComp;
