import { useState, useEffect,useContext } from "react";
import { Button, Autocomplete, TextField, Grid, RadioGroup,FormControlLabel,Radio,FormLabel } from "@mui/material";
import {CloseSharp, DeleteSharp, DetailsSharp, Preview, PreviewSharp, SaveAsSharp} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import {format} from 'date-fns'
import axios from "../../axiosInstances";
import { useTheme } from "@mui/material/styles";

export default function ChartOfAccountModel(props) {
const theme=useTheme();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const { globalUser,globalToken }=useContext(ShoppingCartContext)

  const [id, setId] = useState(props.data.Id);
  const [account, setAccount] = useState(props.data.Account);
  const [description, setDescription] = useState(props.data.Description);

  const [type, setType] = useState(props.data.Type);
  const [category, setCategory] = useState(props.data.Category);
  const [amount, setAmount] = useState(props.data?props.data.Debit>0?props.data.Debit:props.data.Credit:0);
  const [remark, setRemark] =useState(props.data.Remark);
 
  const handleSubmit = () => {
    
    if(account===null || account===0){
      alert('Account field is required');
      return;
    }
    if(description===null || description<=0){
      alert('Description field is required');
      return;
    }
 
    if (
      window.confirm(
        "Are you sure you want to pay?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id", id);
      fd.append("Account", account);
      fd.append("Description", description);
      fd.append("Type", type);
      fd.append("Category", category);
        fd.append("Amount", amount);
        fd.append("Parent", globalUser.Parent);
     fd.append("Remark", remark);
       
      axios.post("/chartofaccounts", fd, {
        headers: {"Content-Type": "application/json", 
            token: globalToken,
          },
      });
    } catch (err) {
    } finally {
      props.OrderData && props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
  const handleDelete = () => {
    try {
      axios.delete("/chartofaccounts?Id=" + id, {
        headers: {
          token: globalToken,
        },
      });
    } catch (err) {
    } finally {
      setOpen(false);
    }
  };

  return (
    <div width="500">
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
     
        <DialogTitle bgcolor={theme.palette.primary.main} color={theme.palette.primary.contrastText}>GL Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
         
              <hr></hr>

              <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label="Account"
                    defaultValue={account}
                    onChange={(e) => {
                      setAccount(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    variant="standard"
                    label="Description"
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </Grid>
               
                  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={type}
                        id="Type"
                        onSelect={(e) => {
                          setType(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={["Category","Posting"].map((item) => {
                             return item;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Account Type"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  <Autocomplete
                        disablePortal
                        selectOnFocus
                        autoHighlight
                        defaultValue={category}
                        id="category"
                        onSelect={(e) => {
                          setCategory(e.target.value);
                          //setPaidTo(globalCustomers && globalCustomers.filter(gp=>gp.Name===e.target.value).length>0?globalCustomers.filter(gp=>gp.Name===e.target.value)[0].Id:0)
                        }}
                        options={["Asset","Liability","Capital","Revenue","Production Cost","Operating Expense"].map((item) => {
                             return item;
                        })}
                        sx={{
                          width: "100%",
                          marginBottom: 2,
                          borderRadius: "25%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Account Category"
                            variant="standard"
                            //error={!fromAirport ? true : false}
                          />
                        )}
                      /> 
  </Grid>
  <Grid item xs={12} md={6}>
                  {(props.data.Debit>0?"Debit: ":"Credit: ") + amount?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}
                  </Grid>
  <Grid item xs={12} md={6}>
  <TextField
                    size="small"
                    type='number'
                    variant="standard"
                    label={t("Remark")}
                    value={remark}
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    sx={{ width: "95%", marginBottom: 2 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
  </Grid>

              </Grid>
           
          </DialogContentText>
        </DialogContent>
         <DialogActions  style={{ backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText,height:'20px' }}>
        <Button
        variant="standard"
          endIcon={<PreviewSharp />}
          >
                   <InvoicePreview 
                    icon={<div>Preview</div>}
                        data={
                        {   
                          "Id":id,
                          "Account": account,
                        "Description": description,
                        "Type": type,
                        "Category": category,
                          "Amount": amount,
                       
                        }
                        }
                        Type="Payment"
                        DocumentType={ " Payment"}
                        />
                    </Button>|
          <Button variant="standard" autoFocus onClick={()=>handleSubmit('Entries')} endIcon={<DetailsSharp />}>
            Entries
          </Button>|
          <Button variant="standard" autoFocus onClick={()=>handleSubmit()} endIcon={<SaveAsSharp />}>
            Save
          </Button>|
          <Button variant="standard" autoFocus onClick={handleDelete} endIcon={<DeleteSharp />}>
            Delete
          </Button>|
          <Button variant="standard" onClick={() => setOpen(false)} autoFocus endIcon={<CloseSharp />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
