import React, { useMemo, useEffect, useState, useContext } from "react";
import { Grid, Box, Button, IconButton, TextField } from "@mui/material";
import { AddSharp, EditSharp, RefreshSharp } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import { useTheme } from "@mui/material/styles";
import axios from "../../axiosInstances";
import { useNavigate } from "react-router-dom";
import NewVisit from "./NewVisit";
import { format } from "date-fns";
function ReceptonComp() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [update, setUpdate] = useState(false);
  const [visitDate, setVisitDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [appointmentDate, setAppointmentDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  const mainColumn = useMemo(
    () => [
      {
        header: "VisitId",
        accessorKey: "VisitId",
      },
      {
        header: "Name",
        accessorKey: "Name",
      },
      {
        header: "Mobile",
        accessorKey: "Mobile",
      },
      {
        header: "Sub Speciality",
        accessorKey: "SubSpeciality",
      },

      {
        header: "Assigned To",
        accessorKey: "AssignedToName",
      },
    ],
    []
  );
  const allColumn = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      {
        header: "Name",
        accessorKey: "Name",
      },
      {
        header: "Mobile",
        accessorKey: "Mobile",
      },
    ],
    []
  );
  const [allPatientsList, setAllPatientsList] = useState([]);
  const [activePatientsList, setActivePatientsList] = useState([]);
  const [appointedPatientsList, setAppointedPatientsList] = useState([]);
  const [physicians, setPhysicians] = useState([]);
  const [subSpecialities, setSubSpecialities] = useState([]);
  const { setPageHeader, globalUser, globalToken, globalRoleSecurity } =
    useContext(ShoppingCartContext);

  const loadAllPatientsList = () => {
    setAllPatientsList([]);
    axios
      .get(
        "/Suppliers/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=Customer",
        {
          headers: {
            "Content-Type": "application/json" ,
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setAllPatientsList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadActivePatientsList = () => {
    axios
      .get(
        "/hosvisits/getPatientByVisitDateAndAssignedTo?Parent=" +
          globalUser.Parent +
          "&VisitDate=" +
          visitDate +
          "&AssignedTo=",
        {
          headers: {
            "Content-Type": "application/json" ,
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setActivePatientsList(response.data.data.ActivePatients);
          setAppointedPatientsList(response.data.data.AppointedPatients);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadPhysicians = () => {
    axios
      .get(
        "/employees/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=Physician",
        {
          headers: {
            "Content-Type": "application/json" ,
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setPhysicians(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadSubSpecialities = () => {
    axios
      .get(
        "/settings/getByParentAndType?Parent=" +
          globalUser.Parent +
          "&Type=" +
          "Sub Speciality",
        {
          headers: {
            "Content-Type": "application/json" ,
            token: globalToken,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          setSubSpecialities(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };
  const handleUpdateAppointedToActive=(id)=>{
    if(window.confirm("Are you sure you want to add to active list?")){
try{
  axios
  .get(
    "/hosvisits/updateAppointedToActive?Id="+id,
    {
      headers: {
        "Content-Type": "application/json" ,
        token: globalToken,
      },
    }
  )
  .then(() => {
    setUpdate(!update)
  });
}catch(err){

}
    }
   
  }
  const handleNewVisit=(id)=>{
    if(window.confirm("Are you sure you want to add to Active?")){
      try{

      var fd = new FormData();
            fd.append("Id", 0);
            fd.append("Customer", id);
            fd.append("DiagnosisedBy",0);
            fd.append("VisitDate", format(new Date(), "yyyy-MM-dd"));
            fd.append("SubSpeciality", "");
            fd.append("Disease", "");
            fd.append("PreliminaryNotes", "");
            fd.append("ClinicalNotes", "");
            fd.append("Appointed", 0);
            fd.append("Active", 1);
            fd.append("Status", "New");
            fd.append("SummaryFindingDate", "");
            fd.append("Emergency", 0);
            fd.append("ApprovedEmergency", 0);
            fd.append("EmergencyApprovedBy", 0);
            fd.append("DischargeDate", "");
            fd.append("DischargeBy", 0);
            fd.append("DischargeReason", "");
            fd.append("Unconscious", 0);
            fd.append("ConsultationStatus", 0);
            fd.append("Order", 0);
            fd.append("StartDate", format(new Date(), "yyyy-MM-dd"));
            fd.append("User", globalUser.Id);
            fd.append("OPD", "");
            fd.append("Parent",globalUser.Parent);
            fd.append("Remark", "");
            axios.post("/hosvisits", fd, {
              headers: {
                "Content-Type": "application/json",
                token: globalToken,
              },
            }).then(()=>setUpdate(!update));
            
      }catch(err){
        
      }
    }
  }
  useEffect(() => {
    if (!globalUser) {
      navigate("/");
      return;
    }
    loadPhysicians();
    loadSubSpecialities();
    loadAllPatientsList();
    loadActivePatientsList();
    setPageHeader("Reception");
  }, [update]);
  useEffect(() => {
    loadActivePatientsList();
  }, [visitDate]);
  useEffect(() => {
    if (!globalUser) {
      navigate("/");
      return;
    }

    const IntervalId = setInterval(() => {
      loadActivePatientsList();
    }, 20000);
    return () => clearInterval(IntervalId);
  }, []);
  return (
    <Grid container spacing={2} mt={0.1}>
      <Grid item xs={8}>
     
            {physicians && physicians.length > 0 ? (
              subSpecialities && subSpecialities.length > 0 ? (
                <MaterialReactTable
                  sx={{ backgroundColor: "#00f" }}
                  columns={mainColumn}
                  data={activePatientsList}
                  initialState={{
                    density: "compact",
                    columnVisibility:{VisitId:false},
                    pagination: {
                      pageIndex: 0,
                      pageSize: 12,
                    },
                  }}
                  enableGlobalFilter
                  enableColumnResizing
                  enableRowNumbers
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  renderTopToolbarCustomActions={() => (
                    <Box>
                      <TextField
                        size="small"
                        label="Visit Date"
                        type="date"
                        value={visitDate}
                        onChange={(e) => {
                          setVisitDate(e.target.value);
                        }}
                        sx={{
                          width: "15",
                          height: "5",
                          marginBottom: 1,
                          marginLeft: 1,
                          color: theme.palette.primary.contrastText,
                        }}
                        InputLabelProps={{
                          sx: {
                            color: theme.palette.primary.contrastText,
                            height: "5",
                          },
                          shrink: true,
                        }}
                      />{" "}
                      |
                      <Button
                        size="small"
                        variant="standard"
                        sx={{ borderRadius: "25px" }}
                        endIcon={
                          <AddSharp style={{ width: "15px", height: "15px" }} />
                        }
                      >
                        <NewVisit
                          icon={<div>Add</div>}
                          data=""
                          SubSpecialities={subSpecialities}
                          Type="Customer"
                          Physicians={physicians}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />
                      </Button>
                      |<Button
                        size="small"
                        variant="standard"
                        sx={{ borderRadius: "25px" }}
                        endIcon={
                          <RefreshSharp style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={()=>setUpdate(!update)}
                      >Refresh
                      </Button>|                    
                     
                       {"     Active Patients"}
                    </Box>
                  )}
                  enableRowActions
                  renderRowActions={({ row }) => (
                    <Box>
                      <IconButton>
                        <NewVisit
                          Type="Customer"
                          Physicians={physicians}
                          SubSpecialities={subSpecialities}
                          icon={
                            <EditSharp
                              style={{ width: "15px", height: "15px" }}
                            />
                          }
                          data={row.original}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />
                      </IconButton>
                      
                    </Box>
                  )}
                ></MaterialReactTable>
              ) : (
                <div>There is no sub Speciality defined in this company</div>
              )
            ) : (
              <div>There is no physician in this company</div>
            )}
          </Grid>
        
      <Grid item xs={4} rowGap={2}>
        {physicians && physicians.length > 0 ? (
          subSpecialities && subSpecialities.length > 0 ? (
            <MaterialReactTable
              sx={{ backgroundColor: "#00f" }}
              columns={allColumn}
              data={allPatientsList}
              initialState={{
                density: "compact",
                columnVisibility:{Id:false},
                pagination: {
                  pageIndex: 0,
                  pageSize: 12,
                },
              }}
              enableGlobalFilter
              enableColumnResizing
              muiTopToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              muiBottomToolbarProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  <Button
                    size="small"
                    variant="standard"
                    sx={{ borderRadius: "25px" }}
                    endIcon={
                      <AddSharp style={{ width: "15px", height: "15px" }} />
                    }
                  >
                    <NewVisit
                      Type="Customer"
                      icon={<div>Add</div>}
                      data=""
                      Physicians={physicians}
                      SubSpecialities={subSpecialities}
                      setParentUpdate={setUpdate}
                      ParentUpdate={update}
                    />
                  </Button>
                  |<Button
                        size="small"
                        variant="standard"
                        sx={{ borderRadius: "25px" }}
                        endIcon={
                          <RefreshSharp style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={()=>setUpdate(!update)}
                      >Refresh
                      </Button>|{"  All Patients"} 
                </Box>
              )}
              enableRowActions
              renderRowActions={({ row }) => (
                <Box>
                  <IconButton>
                    <NewVisit
                      Type="Customer"
                      Physicians={physicians}
                      SubSpecialities={subSpecialities}
                      icon={
                        <EditSharp style={{ width: "15px", height: "15px" }} />
                      }
                      data={row.original}
                      setParentUpdate={setUpdate}
                      ParentUpdate={update}
                    />
                  </IconButton>
                  |
                  <IconButton onClick={()=>handleNewVisit(row.original.Id)}>
                    
                        <AddSharp style={{ width: "15px", height: "15px" }} />
                      
                   
                  </IconButton>
                </Box>
              )}
            ></MaterialReactTable>
          ) : (
            <div>There is no sub Speciality defined in this company</div>
          )
        ) : (
          <div>There is no physician in this company</div>
        )}
      </Grid>
      <Grid item xs={8}>
            {physicians && physicians.length > 0 ? (
              subSpecialities && subSpecialities.length > 0 ? (
                <MaterialReactTable
                  sx={{ backgroundColor: "#00f" }}
                  columns={mainColumn}
                  data={appointedPatientsList}
                  initialState={{
                    density: "compact",
                    pagination: {
                      pageIndex: 0,
                      pageSize: 12,
                    },
                  }}
                  enableGlobalFilter
                  enableColumnResizing
                  enableRowNumbers
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  renderTopToolbarCustomActions={() => (
                    <Box>
                      
                      <Button
                        size="small"
                        variant="standard"
                        sx={{ borderRadius: "25px" }}
                        endIcon={
                          <RefreshSharp style={{ width: "15px", height: "15px" }} />
                        }
                        onClick={()=>setUpdate(!update)}
                      >Refresh
                      </Button>|
                      {"  Appointed Patients"}
                    </Box>
                  )}
                  enableRowActions
                  renderRowActions={({ row }) => (
                    <Box>
                      <IconButton>
                        <NewVisit
                          Type="Customer"
                          Physicians={physicians}
                          SubSpecialities={subSpecialities}
                          icon={
                            <EditSharp
                              style={{ width: "15px", height: "15px" }}
                            />
                          }
                          data={row.original}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />
                      </IconButton>
                      |
                      <IconButton onClick={()=>{
                       
                        handleUpdateAppointedToActive(row.original.VisitId)
                      }}>                        
                       <AddSharp style={{ width: "15px", height: "15px" }}/>
                     </IconButton>
                    </Box>
                  )}
                ></MaterialReactTable>
              ) : (
                <div>There is no sub Speciality defined in this company</div>
              )
            ) : (
              <div>There is no physician in this company</div>
            )}
          </Grid>
    </Grid>
  );
}

export default ReceptonComp;
