import { useState, useContext } from "react";
import {
  Button,
  Grid,
Card,
  CardContent,
} from "@mui/material";
import {
  PreviewSharp,
  DeliveryDiningSharp,
  Close,
  ApprovalSharp,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";

import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function ItemJournalModelHistory(props) {
  const theme=useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { globalToken,globalUser,globalRoleSecurity } = useContext(ShoppingCartContext);
  const handleIssueReturn=()=>{
    if (
      window.confirm(
        "Are you sure you want to return the " + props.Type + " " + props.IssueOrder.Status +"?"
      )
    ) {
    try {
      var fd = new FormData();
      fd.append("Id",props.IssueOrder.Id)
      fd.append("ReturnedBy",globalUser.Id)
      fd.append("ReturnedDate",format(new Date(),"yyyy-MM-dd"))
      fd.append("Parent",props.IssueOrder.Parent)
      fd.append("Store",props.IssueOrder.Store)
      fd.append(
        "LineItems",
        JSON.stringify(
          props.ItemJournalLines
        )
      );
      axios.post("/itemJournal/ReturnOrder",fd, {
        headers: { 
          "Content-Type": "application/json" , 
            token: globalToken,
        },
      })
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  }
  const handleChangeStatus = (status) => {
    if (
      window.confirm(
        "are you sure you want to save the changes?"
      )
    ) {
    try {
      axios.get("/itemjournal/changeStatus?Id=" + props.IssueOrder.Id +"&Status="+status, {
        headers: {
          token: globalToken,
        },
      }); 
    } catch (err) {
    } finally {
      props.setParentUpdate(!props.ParentUpdate);
      setOpen(false);
    }
  }
  };
return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
                  <>
                  <DialogTitle style={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText, height:'15px'}} >
                          {"Issue " +props.IssueOrder.Status}
                 </DialogTitle>
                    <Grid container mt={1}>
                    

                      <Grid
                        item
                        xs={6}
                        /* style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }} */
                      >
                        <span style={{}}>
                          Doc. No.: {props.IssueOrder.Id}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      /*   style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }} */
                      >
                        <span style={{}}>
                          Status: {props.IssueOrder.Status}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                       
                      >
                        
                        {"Issued To: " + props.IssueOrder.IssuedToName}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                       
                      >
                        Issued By:{" "}
                        {props.IssueOrder.IssuedByName}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        mt={1}
                      
                      >
                        {"Issue Date: " + props.IssueOrder.OrderDate}
                      </Grid>
                     
                      <Grid
                        item
                        xs={6}
                      /*   style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }} */
                      >
                      
                      </Grid>
                      <Grid item xs={12}  /* style={{
                          display:
                            selectedVoucher === Ord.Id ? "block" : "none",
                        }} */>
                      {"Remark: " + props.IssueOrder.Remark}
                      </Grid>
                     
         

                      <Grid item xs={12}>
                        <Grid
                          direction="row"
                          container
                       /*    style={{
                            display:
                              selectedVoucher === Ord.Id ? "block" : "none",
                          }} */
                        >
                          <hr></hr>
                          <Grid item xs={12} className="hideInMobile">
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                No.
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                {" "}
                                Item Name
                              </Grid>

                              <Grid
                                item
                                xs={3}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                }}
                              >
                                Quantity
                              </Grid>
                             
                              <Grid
                                item
                                xs={4}
                                style={{
                                  paddingLeft: "5px",
                                  backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
                                  textAlign:'center'
                                }}
                              >
                                Remark
                              </Grid>
                             

                              {props.ItemJournalLines && props.ItemJournalLines!==null && props.ItemJournalLines.length > 0 &&
                                props.ItemJournalLines.map((item, index) => {
                                    return (
                                      <>
                                        <Grid item xs={12}>
                                          <hr></hr>
                                        </Grid>
                                        <Grid item xs={1} key={index}>
                                          {index + 1}
                                        </Grid>
                                        <Grid item xs={4}>
                                        {item.Name}
                                        </Grid>

                                        <Grid item xs={3}>
                                        {item.Quantity}
                                        </Grid>

                                        <Grid item xs={4}>
                                        {item.Remark}
                                         
                                        </Grid>
                                      
                                      </>
                                    );
                                  })}
                              <Grid item xs={12}>
                                <hr></hr>
                              </Grid>
                              <Grid item xs={5}></Grid>
                            


                            </Grid>
                          </Grid>
                          <Grid item className="hideInFull" m={1} xs={12}>
                            {props.ItemJournalLines && props.ItemJournalLines!==null && props.ItemJournalLines.length > 0 &&
                              props.ItemJournalLines
                             
                                .map((item, index) => {
                                  return (
                                    <>
                                      <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                                        <CardContent>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              No.:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                              {index + 1}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Product:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                            {item.Name}
                                            </Grid>
                                            <Grid item xs={4}>
                                              Quantity:
                                            </Grid>
                                            <Grid item xs={8} pl={0}>
                                            {item.Quantity}
                                            </Grid>
                                           
                                            <Grid item xs={4}>
                                              Remark :
                                            </Grid>
                                            <Grid item xs={8} pl={8}>
                                             {item.Remark}
                                            </Grid>
                                         
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                      <hr></hr>
                                    </>
                                  );
                                })}
                          
                          </Grid>
                        </Grid>
                       
                      </Grid>
                    </Grid>
                  </>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
color:theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button variant="standard" style={{ color: "white" }} endIcon={<PreviewSharp />}>
            <InvoicePreview
              icon={
                <div style={{ fontSize: "10px", color: "white" }}>Preview</div>
              }
              data={[props.IssueOrder]}
              LineItems={props.ItemJournalLines}
              Type="IssueOrder"
              DocumentType={"Issue " + props.IssueOrder.Status}
            />
          </Button>
          |
          {props.IssueOrder.Status==='Issued' && (globalRoleSecurity.filter(
                  (sec) =>
                    (sec.Functionality === ("Issue Return"))
                )[0].Security>1 && props.IssueOrder.Status==="Issued") &&
                    <>
          <Button
          variant="standard"
            style={{ fontSize: "10px", color: "white" }}
            endIcon={<DeliveryDiningSharp />}
            sx={{ alignSelf: "end" }}
            onClick={() => handleIssueReturn()}
          >
            Return
          </Button>|
          </>
}
{props.IssueOrder.Status==='Cleared' && globalRoleSecurity.filter(sec=>((sec.Functionality==="Issue Clear") && (sec.Security>1))).length>0 &&
<>
          <Button
          variant="standard"
            style={{ fontSize: "10px", color: "white" }}
            endIcon={<ApprovalSharp />}
            autoFocus
            onClick={() =>
              handleChangeStatus('Approved')
            }
          >
            Unclear
          </Button>
          |
          </>
}
          <Button
          variant="standard"
            style={{ fontSize: "10px", color: "white" }}
            endIcon={<Close />} 
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
