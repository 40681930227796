import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { ShoppingCartContext } from "./Context/ShoppingCartContext";
import {Typography} from '@mui/material'
function AboutusComp() {
    const {
        setPageHeader
        } = useContext(ShoppingCartContext);
        useEffect(()=>{
setPageHeader("About Us")
        },[])
    return ( 
        <div style={{margin:16}}>
              <Typography
            variant="body2"
            align="left"
          
            style={{ fontSize:24,textAlign: 'center',fontWeight:'bold',margin:12}}
          >
            What makes us who we are today…
            </Typography><br></br>
            <Typography
            variant="body2"
            align="left"
          
            sx={{ fontSize:18,textAlign: 'justify', columnCount: {xs:1,md:2} }}
          >
            <p>
At ARMA IT Solutions, we develop innovative and creative products and services that provide total communication and information solutions. Among a plethora of services, application design and development, tailor made applications, ERPs, CRMs, e-commerce solutions, business-to-business applications, business-to-client applications are few that we offer. Satisfied clients around the globe bear testimony to the quality of our work.
</p>
<p>
As a leader in technology exploring, ARMA is committed to exporting quality software worldwide.
</p>

<p>
<br></br>
The general purpose of ARMA IT Solution is to develop and promote advanced information technologies for multi-user operation.
</p>
<p>
ARMA's business philosophy is to assure the highest quality product, total client satisfaction, timely delivery of solutions and the best quality/price ratio found in the industry.
</p>
<p>
Our emphasis is on offering a high degree of product user friendliness through a positive, creative and focused company staff.        
</p>
</Typography>
        </div>
    )
}

export default AboutusComp;