import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
  IconButton,
  Button,
  Box,
  Tooltip,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  EditSharp,
  AddSharp,
  FileDownload,
  FileUpload,
  RefreshSharp,
  PreviewSharp,
} from "@mui/icons-material";
import MaterialReactTable from "material-react-table";
import { useState, useEffect, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import ItemTransferModel from "./ItemTransferModel";
import ItemTransferModelHistory from "./ItemTransferModelHistory";
import * as XLSX from "xlsx";
import axios from "../../axiosInstances";
import {format} from 'date-fns'
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import InvoicePreview from "../Reports/InvoicePreview";
import ItemTransferIssueModel from "./ItemTransferIssueModel";
import { ro } from "date-fns/locale";
//import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom'

function ItemTransferComp(props) {
  const navigate = useNavigate();
  const theme=useTheme();
  const [update, setUpdate] = useState(false);
  const location = useLocation();
  //const [SupplierCategoriesList, setSupplierCategoriesList] = useState([]);
  const [itemtransferList, setItemTransferList] = useState([]);
  const [itemTransferLineList, setItemTransferLineList] = useState([]);
  const [itemtransferIssuedList, setItemTransferIssuedList] = useState([]);
  const [itemTransferIssuedLines, setItemTransferIssuedLines] = useState([]);


  const { globalUser, setPageHeader,globalToken,globalRoleSecurity } = useContext(ShoppingCartContext);
  
  const [employeeList, setEmployeeList] = useState([]);
  const [storesList,setStoresList] = useState([]);
  

  //const { t, i18n } = useTranslation();
  const loadStoresList = () => {
    axios
      .get(
        "/stores/getByParent?Parent=" +
          globalUser.Parent, {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setStoresList(response.data.data);
        } else {
          alert("log out and log in again");
        }
      });
  };

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      {
            header: "Requested By",
            accessorKey: "RequestedByName",
          },
          {
            header: "Requested Date",
            accessorKey: "RequestedDate",
          },
          {
            header: "From Store",
            accessorKey: "FromStoreName",
          },
          {
            header: "To Store",
            accessorKey: "ToStoreName",
          },
      {
            header: "Approved By",
            accessorKey: "ApprovedByName",
          },
          {
            header: "Issued By",
            accessorKey: "IssuedByName",
          },
          {
            header: "Issued Date",
            accessorKey: "IssuedDate",
          },
     
    ],
    []
  );

  const loaditemtransfersList = () => {
    axios
      .get(
        "/itemTransfers/getByParentAndStatus?Parent=" +
          globalUser.Parent +"&Status=" + location.state.Status, {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setItemTransferList(response.data.data.Orders);
          setItemTransferLineList(response.data.data.ItemTransferLines);
        
          setItemTransferIssuedList(response.data.data.IssuedOrders);
          setItemTransferIssuedLines(response.data.data.ItemTransferIssuedLines);

        } else {
          alert("log out and log in again");
        }
      });
  };
  const loadEmployeeList = () => {
    axios
      .get(
        "/employees/getByParent?Parent=" +
          globalUser.Parent 
          , {
            headers: {
              token: globalToken,
            },
          }
      )
      .then((response) => {
        if (response.data.data) {
          setEmployeeList(response.data.data);
      
        } else {
          alert("log out and log in again");
        }
      });
  };
  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    var jsonData = XLSX.utils.sheet_to_json(worksheet);

    var fdata = new FormData();
    fdata.append("JsonData", JSON.stringify(jsonData));
    await axios.post("/itemtransfers/Excel", fdata, {
      headers: { "Content-Type": "application/json", 
          token: globalToken,
       },
    });
  };
  function handleDownloadFile() {
    const worksheet = XLSX.utils.json_to_sheet(itemtransferList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "itemtransfers");
    //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "itemtransfersdata.xlsx");
  }

  useEffect(() => {
    if(!globalUser){
      navigate('/');
      return;
    }
    loadStoresList();
    loaditemtransfersList();
    setPageHeader("Transfer " + location.state.Status);
    loadEmployeeList();
  }, [update,location.state.Status]);



  return (
    <div width="100%">
      {storesList !== undefined &&
      storesList !== null &&
      storesList.length &&
      employeeList !== undefined &&
      employeeList !== null &&
      employeeList.length > 0 ? (
        <Grid container>
          <Grid item className="hideInMobile" m={1}>
            <MaterialReactTable
              columns={columns}
              data={itemtransferList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
              // enableColumnOrdering
              
              enableColumnResizing
              enableRowNumbers
              renderDetailPanel={({ row }) => (
                <Grid
                  container
                  ml={2}
                  mr={4}
                  sx={{ backgroundColor: "smockwhite" }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "lightgreen",
                      fontWeight: "bold",
                    }}
                  >
                  
                      <IconButton>
                            <ItemTransferModel
                              icon={
                                <EditSharp
                                   style={{ width: "10px", height: "10px",margin:1 }}
                                />
                              }
                              StoresList={storesList}
                              TransferOrder={row.original}
                              ItemTransferLines={itemTransferLineList.filter(
                                (li) => li.Order === row.original.Id
                              )}
                              setParentUpdate={setUpdate}
                              ParentUpdate={update}
                            />
                 </IconButton>
                   Line Items
                  </Grid>
                  {itemTransferLineList &&
                    itemTransferLineList.filter(
                      (li) => li.Order === row.original.Id
                    ).map((litem, index) => {
                      var issuedQty=0;
                      itemTransferIssuedLines.filter(til=>(til.ParentOrder===row.original.Id)).map(til=>{
                        issuedQty=parseFloat(issuedQty)+parseFloat(til.Quantity)
                      })
                      
                      return (
                        <>
                          {index === 0 ? (
                            <>
                              <Grid
                                item
                                xs={4}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Item
                              </Grid>
                              <Grid
                                item
                                xs={2}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Issued Quantity
                              </Grid>
                              <Grid
                                item
                                xs={2}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Quantity
                              </Grid>
                             
                              
                              <Grid
                                item
                                xs={4}
                               pl={12}
                                sx={{backgroundColor: theme.palette.primary.dark,color:theme.palette.primary.contrastText }}

                              >
                                Remark
                              </Grid>
                           

                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Name}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {issuedQty?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                              
                            
                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Remark}
                              </Grid>
                              
                            </>
                          ) : (
                            <>
                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Name}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {issuedQty?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                              <Grid item xs={2} sx={{ border: 1 }} pl={2}>
                                {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                              </Grid>
                             
                              <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                                {litem.Remark}
                              </Grid>
                             

                            </>
                          )}
                        </>
                      );
                    })} 
              {itemtransferIssuedList && itemtransferIssuedList.filter(iti=>iti.Order===row.original.Id).map((ord,index)=>{
                  return(
                  <>
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "lightgreen",
                    fontWeight: "bold",
                  }}
                >
                
                    <IconButton>
                          <ItemTransferModelHistory
                            icon={
                              <EditSharp
                                 style={{ width: "10px", height: "10px",margin:1 }}
                              />
                            }
                            StoresList={storesList}
                            TransferOrder={ord}
                            ItemTransferLines={itemTransferIssuedLines.filter(
                              (li) => li.Order === ord.Id
                            )}
                            setParentUpdate={setUpdate}
                            ParentUpdate={update}
                          />
               </IconButton>
                 Issued Transfers | {"     Issued By: " + ord.IssuedByName} | {"     Issued Date: " + ord.IssuedDate}
                </Grid>
                {itemTransferIssuedLines &&
                  itemTransferIssuedLines.filter(
                    (li) => li.Order === ord.Id
                  ).map((litem, index) => {
                  
                    return (
                      <>
                        {index === 0 ? (
                          <>
                            <Grid
                              item
                              xs={4}
                              sx={{ border: 1 }}
                              pl={12}
                              style={{
                                backgroundColor: "lightgray",
                                color: "white",
                              }}
                            >
                              Item
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ border: 1 }}
                              pl={12}
                              style={{
                                backgroundColor: "lightgray",
                                color: "white",
                              }}
                            >
                              Issued Quantity
                            </Grid>
                          <Grid
                              item
                              xs={4}
                              sx={{ border: 1 }}
                              pl={12}
                              style={{
                                backgroundColor: "lightgray",
                                color: "white",
                              }}
                            >
                              Remark
                            </Grid>
                         

                            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                              {litem.Name}
                            </Grid>
                           
                            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                              {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                            </Grid>
                            
                          
                            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                              {litem.Remark}
                            </Grid>
                            
                          </>
                        ) : (
                          <>
                            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                              {litem.Name}
                            </Grid>
                            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                              {litem.Quantity?.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}
                            </Grid>
                           
                            <Grid item xs={4} sx={{ border: 1 }} pl={2}>
                              {litem.Remark}
                            </Grid>
                           

                          </>
                        )}
                      </>
                    );
                  })} 
            </>)}
              )}

                </Grid>
      )}
      muiTopToolbarProps= {{
        sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
      }}
              renderTopToolbarCustomActions={() => (
                <Box>
                  {location.state.Status==='Request' &&
                  globalRoleSecurity.filter(gs=>(gs.Functionality==='Transfer Request'  && gs.Security>2)).length>0 &&
                  <>
                  <Button
                         size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <AddSharp style={{ width: "20px", height: "20px" }} />
                    }
                  >
                      <ItemTransferModel
                        icon={<div>Add</div>}
                        StoresList={storesList}
                        TransferOrder={{Id:0,Status:'Request',RequestedBy:globalUser.Id,RequestedByName:globalUser.Name,OrderDate:format(new Date(),"yyyy-MM-dd"),FromStoreName:'',ToStoreName:''}}
                        ItemTransferLines={[{}]}
                        setParentUpdate={setUpdate}
                        ParentUpdate={update}
                      />
                   
                  </Button>| 
                  </>
              }
                   {globalRoleSecurity.filter(gs=>(gs.Functionality==="Transfer Direct"  && gs.Security>2)).length>0 &&
<>
                   <Button
                         size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <AddSharp style={{ width: "20px", height: "20px" }} />
                    }
                  >
                      <ItemTransferModel
                    icon={<div>Direct Transfer</div>}
                        StoresList={storesList}
                        TransferOrder={{Id:0,Status:'Direct',RequestedBy:globalUser.Id,RequestedByName:globalUser.Name,OrderDate:format(new Date(),"yyyy-MM-dd"),FromStoreName:'',ToStoreName:''}}
                        ItemTransferLines={[{}]}
                        setParentUpdate={setUpdate}
                        ParentUpdate={update}
                      />
                   
                  </Button>|
                  </>
              }
                  <Button
                         size="small"
                    variant="standard"
                    sx={{ borderRadius: "30px" }}
                    endIcon={
                      <FileDownload style={{ width: "20px", height: "20px" }} />
                    }
                    type="file"
                    onClick={handleDownloadFile}
                  >
                    Download
                  </Button>
                  |
                  <Tooltip title="refresh">
                    <Button
                           size="small"
                    variant="standard"
                      sx={{ borderRadius: "30px" }}
                      endIcon={<RefreshSharp />}
                      onClick={() => setUpdate(!update)}
                    >
                      Refresh
                    </Button>
                  </Tooltip>
                 
                </Box>
              )}
              enableRowActions              
              renderRowActionMenuItems={(table) => [
                 <Tooltip title="Transfer Order">              
                    <MenuItem>
                    {(table.row.original.Status=='Request' || table.row.original.Status==='Approved') &&
                    globalRoleSecurity.filter(gs=>((gs.Functionality===('Transfer ' + location.state.Status) && gs.Security>1)).length>0)?
                        <ItemTransferModel
                          icon={<div>
                            <EditSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4 }}                             
                            />{"Transfer "+location.state.Status}</div>
                          }
                          StoresList={storesList}
                          TransferOrder={table.row.original}
                          ItemTransferLines={itemTransferLineList.filter(
                            (li) => li.Order === table.row.original.Id
                          )}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />:
                        <ItemTransferModelHistory
                        icon={<div>
                          <EditSharp 
                          style={{ width: "13px", height: "13px",margin:1,marginRight:4 }}                             
                          />{"Transfer "+location.state.Status}</div>
                        }
                        StoresList={storesList}
                        TransferOrder={table.row.original}
                        ItemTransferLines={itemTransferLineList.filter(
                          (li) => li.Order === table.row.original.Id
                        )}
                        setParentUpdate={setUpdate}
                        ParentUpdate={update}
                      />
                        }
                    </MenuItem>
                   </Tooltip>,
                    ((table.row.original.Status=='Approved') && globalRoleSecurity.filter(gs=>(gs.Functionality==="Transfer Issue" && gs.Security>1)).length>0) &&
                   <Tooltip title="Transfer Issue"> 
                    <ItemTransferIssueModel
                          icon={<div>
                            <EditSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4 }} 
                            />{"Transfer Issue"}</div>
                          }
                          StoresList={storesList}
                          TransferOrder={table.row.original}
                          ItemTransferLines={itemTransferLineList.filter(
                            (li) => li.Order === table.row.original.Id
                          )}
                          ItemTransferIssuedLines={itemTransferIssuedLines.filter(
                            (li) => parseInt(li.ParentOrder) === parseInt(table.row.original.Id)
                          )}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />
                    </Tooltip>,
                           ((table.row.original.Status=='Issued') && globalRoleSecurity.filter(gs=>(gs.Functionality==="Transfer Receipt" && gs.Security>1)).length>0) &&
                           <Tooltip title="Transfer Receipt"> 
                            <ItemTransferIssueModel
                                  icon={<div>
                                    <EditSharp
                                    style={{ width: "13px", height: "13px",margin:1,marginRight:4 }} 
                                    />{"Transfer Receipt"}</div>
                                  }
                                  StoresList={storesList}
                                  TransferOrder={table.row.original}
                                  ItemTransferLines={itemTransferLineList.filter(
                                    (li) => li.Order === table.row.original.Id
                                  )}
                                  ItemTransferIssuedLines={itemTransferIssuedLines.filter(
                                    (li) => parseInt(li.ParentOrder) === parseInt(table.row.original.Id)
                                  )}
                                  setParentUpdate={setUpdate}
                                  ParentUpdate={update}
                                />
                            </Tooltip>,
                   <Tooltip title="Transfer Preview">              
                    <MenuItem>
                        <InvoicePreview
                          icon={<div>
                            <PreviewSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4 }}
                            />Transfer Preview</div>
                          }
                          data={[table.row.original]}
                          LineItems={itemTransferLineList.filter(ijl=>parseInt(ijl.Order)===parseInt(table.row.original.Id))}
                          Type="TransferOrder"
                          DocumentType={"Tansfer "+ location.state.Status}
                        />
                    </MenuItem>
                   </Tooltip>,
                        ]
              }
            ></MaterialReactTable>
          </Grid>
          <Grid item xs={12} mt={1} className="hideInFull">
            
          <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>

            {location.state.Status==='Request' &&
                  globalRoleSecurity.filter(gs=>(gs.Functionality==='Transfer Request'  && gs.Security>2)).length>0 &&
                  <>
              <Button
                
                     size="small"
                    variant="standard"
                sx={{ borderRadius: "30px" }}
                endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}
              >
               
                  <ItemTransferModel
                    icon={<div>
                      <EditSharp  style={{ width: "10px", height: "10px",margin:1 }} />
                      Add
                      </div>
                    }
                    StoresList={storesList}
                    TransferOrder={{Id:0,Status:'Draft',RequestedBy:globalUser.Id,RequestedByName:globalUser.Name,OrderDate:format(new Date(),"yyyy-MM-dd"),FromStoreName:'',ToStoreName:''}}
                    ItemTransferLines={[{}]}
                    setParentUpdate={setUpdate}
                    ParentUpdate={update}
                  />
               </Button>|
               </>
            }
              <Button
                
                     size="small"
                    variant="standard"
                sx={{ borderRadius: "30px" }}
                endIcon={
                  <FileDownload style={{ width: "20px", height: "20px" }} />
                }
                type="file"
                onClick={handleDownloadFile}
              >
                Download
              </Button>
              |
              <Tooltip title="refresh">
                <Button
                  
                       size="small"
                    variant="standard"
                  sx={{ borderRadius: "30px" }}
                  endIcon={<RefreshSharp />}
                  onClick={() => setUpdate(!update)}
                >
                  Refresh
                </Button>
              </Tooltip>
            </Box>
          </Grid>
          {!(itemtransferList === undefined) &&
            itemtransferList.length > 0 &&
            itemtransferList.map((item) => {
              return (
                <Grid item key={item.Id} xs={5} m={2} className="hideInFull">
                  <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                      avatar={
                        <Avatar
                          src={
                            item.Picture != null && item.Picture.length > 0
                              ? item.Picture.split(",")[0]
                              : "/Images/Products/default.png"
                          }
                        />
                      }
                      className="CardHeader"
                      title={item.FromStoreName} //.substring(0, 20)}
                      subheader={item.ToStoreName}
                    />

                    <CardContent>
                     
                      <Typography variant="body2" align="left">
                        Requested By: {item.RequestedByName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Requested Date: {item.RequestedDate}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Approved By: {item.ApprovedByName}
                      </Typography>
                       <Typography variant="body2" align="left">
                        Approved Date: {item.ApprovedDate}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Issued By: {item.IssuedByName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Issued Date: {item.IssuedDate}
                      </Typography>
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Stack direction='row'>
                 <Tooltip title="Transfer Order">              
                    <IconButton>
                    {(item.Status=='Request' || item.Status==='Approved') &&
                    globalRoleSecurity.filter(gs=>((gs.Functionality===('Transfer ' + location.state.Status) && gs.Security>1)).length>0)?
                        <ItemTransferModel
                          icon={<div>
                            <EditSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4 }}                             
                            />|</div>
                          }
                          StoresList={storesList}
                          TransferOrder={item}
                          ItemTransferLines={itemTransferLineList.filter(
                            (li) => li.Order === item.Id
                          )}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />:
                        <ItemTransferModelHistory
                        icon={<div>
                          <EditSharp 
                          style={{ width: "13px", height: "13px",margin:1,marginRight:4 }}                             
                          />|</div>
                        }
                        StoresList={storesList}
                        TransferOrder={item}
                        ItemTransferLines={itemTransferLineList.filter(
                          (li) => li.Order === item.Id
                        )}
                        setParentUpdate={setUpdate}
                        ParentUpdate={update}
                      />
                        }
                    </IconButton>
                   </Tooltip>
                    {((item.Status=='Approved') && globalRoleSecurity.filter(gs=>(gs.Functionality==="Transfer Issue" && gs.Security>1)).length>0) &&
                   <Tooltip title="Transfer Issue"> 
                   <IconButton>
                    <ItemTransferIssueModel
                          icon={<div>
                            <EditSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4 }} 
                            />|</div>
                          }
                          StoresList={storesList}
                          TransferOrder={item}
                          ItemTransferLines={itemTransferLineList.filter(
                            (li) => li.Order === item.Id
                          )}
                          ItemTransferIssuedLines={itemTransferIssuedLines.filter(
                            (li) => parseInt(li.ParentOrder) === parseInt(item.Id)
                          )}
                          setParentUpdate={setUpdate}
                          ParentUpdate={update}
                        />
                        </IconButton>
                    </Tooltip>}
                           {((item.Status=='Issued') && globalRoleSecurity.filter(gs=>(gs.Functionality==="Transfer Receipt" && gs.Security>1)).length>0) &&
                           <Tooltip title="Transfer Receipt"> 
                           <IconButton>
                            <ItemTransferIssueModel
                                  icon={<div>
                                    <EditSharp
                                    style={{ width: "13px", height: "13px",margin:1,marginRight:4 }} 
                                    />|</div>
                                  }
                                  StoresList={storesList}
                                  TransferOrder={item}
                                  ItemTransferLines={itemTransferLineList.filter(
                                    (li) => li.Order === item.Id
                                  )}
                                  ItemTransferIssuedLines={itemTransferIssuedLines.filter(
                                    (li) => parseInt(li.ParentOrder) === parseInt(item.Id)
                                  )}
                                  setParentUpdate={setUpdate}
                                  ParentUpdate={update}
                                />
                                </IconButton>
                            </Tooltip>
            }
                   <Tooltip title="Transfer Preview">              
                    <IconButton>
                        <InvoicePreview
                          icon={<div>
                            <PreviewSharp
                            style={{ width: "13px", height: "13px",margin:1,marginRight:4 }}
                            />|</div>
                          }
                          data={[item]}
                          LineItems={itemTransferLineList.filter(ijl=>parseInt(ijl.Order)===parseInt(item.Id))}
                          Type="TransferOrder"
                          DocumentType={"Tansfer "+ location.state.Status}
                        />
                    </IconButton>
                   </Tooltip>,
                        
                      </Stack>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <div>There is not employee in this company</div>
      )}
    </div>
  );
}

export default ItemTransferComp;
