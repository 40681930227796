import React,{useState,useMemo,useEffect,useContext} from 'react'
import { useTheme } from "@mui/material/styles";
import MaterialReactTable from "material-react-table";
import { format } from "date-fns";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
import axios from "../../axiosInstances";
import { Grid,TextField ,IconButton,Box,Tooltip,Button, } from '@mui/material';
import {ApprovalRounded, AttachFile, EditSharp,MedicationLiquid, SaveSharp} from "@mui/icons-material"
import NewVisit from './NewVisit';
function LaboratoryComp() {
  const theme = useTheme();
  
  const [update, setUpdate] = useState(false);
  const { globalUser,globalToken, globalRoleSecurity,setPageHeader } =  useContext(ShoppingCartContext);
  const [patientsList, setPatientsList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedLabOrders, setSelectedLabOrders] = useState([]);
  const mainColumn = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "Id",
      },
      {
        header: "Name",
        accessorKey: "Name",
      },
      {
        header: "Mobile",
        accessorKey: "Mobile",
        size:120
      },
    ],
    []
  );
  const [visitDate, setVisitDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [rowSelection, setRowSelection] = useState({});
  const handleSubmitResult=(status)=>{
    alert(status)
  }
  const getSelectedPatient = () => {
    Object.getOwnPropertyNames(rowSelection).map((item) => {
      if (item === "length") {
        setSelectedPatient(null);
      } else {
        setSelectedPatient(
          patientsList.filter(
            (ap) => parseInt(ap.Id) === parseInt(item)
          )[0]
        );
      }
    });
  };
  const loadActiveOrdersByType = () => {
     axios
      .get(
        "/hosorders/getActiveOrdersByType?Parent=" +
          globalUser.Parent +
          "&OrderDate=" +
          visitDate+
          "&OrderType="+"Laboratory",
        {
          headers: {
            "Content-Type": "application/json",
            token: globalToken,
          },
        }
      )
      .then((response) => {
        
        if (response.data.data) {
          setPatientsList(response.data.data.Patients);
          setOrdersList(response.data.data.Orders);
          setSelectedPatient(response.data.data.Patients.length>0 ?response.data.data.Patients[0]:null)
        } else {
          alert("log out and log in again");
        }
      });
  };
   useEffect(() => {
    if (Object.getOwnPropertyNames(rowSelection).length > 0) {
      getSelectedPatient();
    }
  }, [rowSelection]); 
  useEffect(()=>{
loadActiveOrdersByType();
setPageHeader("Laboratory")
  },[update,visitDate])
  return (
    <Grid container spacing={2} mt={1}>
       <Grid item xs={12} md={5}>
        <MaterialReactTable
          columns={mainColumn}
          data={patientsList}
          initialState={{
            density: "compact",
            columnVisibility: { Id: false },
            pagination: {
              pageIndex: 0,
              pageSize: 5,
            },
          }}
          enableGlobalFilter
          enableColumnResizing
          enableRowNumbers
          enableRowSelection
          enableMultiRowSelection={false}
          getRowId={(originalRow) => originalRow.Id}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          }}
          renderTopToolbarCustomActions={() => (
             <Box style={{ display: "flex", flexDirection: "row" }}>
             
              <TextField
                size="small"
                label="Visit Date"
                type="date"
                value={visitDate}
                onChange={(e) => {
                  setVisitDate(e.target.value);
                }}
                sx={{
                  width: "15",
                  height: "5",
                  marginBottom: 1,
                  marginLeft: 1,
                  color: theme.palette.primary.contrastText,
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.palette.primary.contrastText,
                     },
                  shrink: true,
                }}
              />
            </Box> 
          )}
         
         /*  enableRowActions
          renderRowActions={({ row }) => (
             <Box>
               <Tooltip title="Edit">
              <IconButton>
                <NewVisit
                  icon={<EditSharp style={{ width: "15px", height: "15px" }} />}
                  data={row.original}
                  SubSpecialities={[]}
                  Type="Customer"
                  Physicians={[]}
                  setParentUpdate={setUpdate}
                  ParentUpdate={update}
                />
              </IconButton>
              </Tooltip>
              
            
            </Box> 
          )} */
        ></MaterialReactTable>
      </Grid>
   <Grid item  xs={12} md={7} p={1}> 
   {selectedPatient &&
   <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
           <Button
                  variant="standard"
                  onClick={() =>
                    handleSubmitResult('Draft')
                  }
                  endIcon={<SaveSharp />}
                >
                  Save
                </Button> |
                <Button
                  variant="standard"
                  onClick={() =>
                    handleSubmitResult('Approved')
                  }
                  endIcon={<ApprovalRounded />}
                >
                  Approved
                </Button> |
   {selectedPatient && ("   Patient Name: " +  selectedPatient.Name)}
   </Box>
}
   {selectedPatient &&
        <Grid container spacing={2} pt={2} pl={2}>
        <table style={{width:'100%'}}>
                  <thead style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}>
                  <th style={{width:'5%',textAlign:'center'}}>No.</th>
                    <th style={{width:'15%',textAlign:'center'}}>Type</th>
                    <th style={{width:'35%',textAlign:'center'}}>Test Result</th>
                    <th style={{width:'10%',textAlign:'center'}}>Prev. Result</th>
                    <th style={{width:'10%',textAlign:'center'}}>Ref. Range</th>
                    <th style={{width:'20%',textAlign:'center'}}>Description</th>
                    <th style={{width:'5%',textAlign:'center'}}>Attach</th>
                  </thead>
          {selectedPatient && ordersList && 
            ordersList.filter(so=>parseInt(so.Patient)===parseInt(selectedPatient.Id)).map((test,index) => {
              
              return (
                <>
               <tr >
               <td style={{borderColor:theme.palette.primary.main,borderWidth:2}}>{index+1}</td>
               <td style={{borderColor:theme.palette.primary.main,borderWidth:2}}>{test.CategoryName}</td>
               <td>
                <TextField
                size="small"
                multiline
                label={test.Name}
                value={test.Result}
                placeholder="Insert Result here"
                onChange={(e) => {
                  test.Result=e.target.value
                }}
                sx={{
                  width:"95%",
                  margin:2,
                }}
                InputLabelProps={{
                                   shrink: true,
                }}
              /></td>
              <td></td>

              <td></td>
              <td>{test.Description}</td>
              <td><IconButton><AttachFile /></IconButton></td>
              </tr>
              <tr><td colSpan={7}><hr></hr></td></tr>
              </>
              );
            })}
             </table>
        </Grid>
}

   </Grid>
  
  </Grid>
  )
}

export default LaboratoryComp