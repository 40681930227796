import { useContext, useState } from "react";
import {
  Text,
  Page,
  Document,
  View,
  PDFViewer,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button,Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import font from '../fonts/washrab.ttf'

import { ShoppingCartContext } from "../Context/ShoppingCartContext"
const numberToText = require('number-to-text')
require('number-to-text/converters/en-us'); // load converter

Font.register({
  family: "washrab",
  format: "truetype",
  // src: `${process.env.PUBLIC_URL}/static/fonts/IRANSansWeb.ttf`
  src: font
});
const borderColor = "#90e5fc";
const styles = StyleSheet.create({
    page: {
        fontFamily: 'sans-serif,"Roboto","Helvetica","Arial"',
        fontSize: 11,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 500,
        height: 45,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    SignaturePic: {
      width: 80,
      height: 45,
  },
  remark: {
    left: 50,
},
washeraFont:{
  fontFamily:'washera'
},
  titleContainer: {
    flexDirection: "row",
  },
  reportTitle: {
    color: "#61dafb",
   
    letterSpacing: 4,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
  },
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "flex-end",
  
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    marginRight:4,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    width: "100%",
  },
  description: {
    width: "40%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  signature: {
    textAlign: "left",
    left:40
  },

  qty: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  rate: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
  total: {
    width: '50%',
    textAlign: 'right',
    paddingRight: 2,
    alignContent: 'right',
    alignItems:'right',
    alignSelf:'right'
},
totalAmount: {
  width: "50%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
jobdescription: {
  width: "50%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
jobRemark: {
  width: "30%",
  textAlign: "left",
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingLeft: 8,
},
reportFooter: {
    color: "#61dafb",
    width:'100%',
    letterSpacing: 4,
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
  Payment:{    
    letterSpacing: 4,
    fontSize: 12,
    textAlign: "left",
    textTransform: "uppercase",
    textDecoration:'underline',
    textAlign:"center"
  },
 
});

export default function InvoicePreview(props) {
  const [open, setOpen] = useState(false);
  const { globalUser,setPageHeader }=useContext(ShoppingCartContext)

  const MyPaymentDocument = (props) => (
    
    <Document style={{hidden:true}}>
           <Page size={[1200,400]} >
  
        <View style={[styles.headerContainer,{backgroundColor: 'transparent'}]}>
        <Text className="noPrint" style={{fontFamily:'washrab',marginTop:100,marginLeft:20}}>ለ</Text>
         <Text className="noPrint" style={{marginLeft:20}}>Pay: {"                   " + props.data.PaidToName}</Text>                 
          <Text style={{fontFamily:'washrab',marginLeft:20,marginTop:10}}>ብር</Text>
          <Text style={{fontFamily:'washrab',marginLeft:680,top:-30}}>ብር</Text>
          <Text style={{marginLeft:20}}>Birr: {"    "}<Text style={{textAlign:'justify',textDecoration:'underline'}}>{numberToText.convertToText((props.data.Amount?props.data.Amount:0).split('.')[0],{case:"upperCase"}) + " BIRR AND " + ((props.data.Amount?props.data.Amount:0).split('.').length>0?(props.data.Amount?props.data.Amount:0).split('.')[1]>0?numberToText.convertToText((props.data.Amount?props.data.Amount:0).split('.')[1],{case:"upperCase"}): " 00/100": " 00/100") +  " CENTS ONLY"}</Text>
          <Text>{"     "}Birr:{"     "}<Text style={styles.Payment}>{(props.data.mount?props.data.Amount:0)?.toLocaleString(undefined, {maximumFractionDigits:2,minimumFractionDigits:2})}</Text></Text>
          </Text>
          <Text style={{marginTop:80,marginLeft:250}}>Signature(s)</Text>
        </View>
       
      </Page>
   
    </Document>
  );

  const Apps = (props) => (
   
    <PDFViewer style={{width:'1800',height:'400',hidden:true}}>
   
      <MyPaymentDocument data={props.data} />
    </PDFViewer>
  );
 
  return (
    <div>
      <label onClick={() => setOpen(true)}>{props.icon}</label>
      <Dialog
        
        PaperProps={{
          sx: {
            width: "700px",
            height: "700px"
          }
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
              <div style={{width:'450',height:'50%', backgroundImage: `urls('/Images/dashin.png')`,backgroundRepeat:'no-repeat',backgroundSize: 'cover'}}>
      <Apps data={props.data} /> 
     

      </div>
         </DialogContent>
        <DialogActions style={{height:'15px'}}>
          <Box>
            <Button endIcon={<Close />} onClick={()=>setOpen(false)}>
              Close
              
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
