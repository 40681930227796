import { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Table,
  TextField,
  Tooltip,
  Card,
  CardContent,
  alertTitleClasses,
} from "@mui/material";
import {
  Close,
  Delete,
  Add,
  PreviewSharp,
  RequestQuoteSharp,
  EmailSharp,
  DraftsSharp,
  DeleteSharp,
  Send,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import InvoicePreview from "../Reports/InvoicePreview";
import PaymentsModel from "../PaymentsModel";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";

import { format } from "date-fns";
import axios from "../../axiosInstances";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

export default function SalesNewOrder(props) {
  const theme = useTheme();
  var fileObj = [];
  var fileArray = [];
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [orderDate, setOrderDate] = useState(
    props.data ? props.data.VoucherDate : format(new Date(), "yyyy-MM-dd")
  );
  const {
    addtoCart,
    setSelectedItems,
    removeFromCart,
    globalUser,
    selectedItems,
    allItems,
    globalCustomers,
    globalSelectedCustomers,
    setGlobalSelectedCustomers,
    globalRoleSecurity,
    globalStores,
    globalToken,
  } = useContext(ShoppingCartContext);

  const [lineItems, setLineItems] = useState([]);
  const [bankList, setBankList] = useState(props.BankList);

  const [selectedVoucher, setSelectedVoucher] = useState(
    props.data
      ? props.data.Supplier
      : globalSelectedCustomers && globalSelectedCustomers.length > 0
      ? globalSelectedCustomers[0].Id
      : globalCustomers[0].Id
  );

  const [customers, setCustomers] = useState(
    props.data
      ? [
          {
            ...props.data,
            Id: props.data.Supplier,
            Supplier: props.data.Supplier,
          },
        ]
      : globalSelectedCustomers && globalSelectedCustomers.length > 0
      ? globalSelectedCustomers
      : [
          {
            ...globalCustomers[0],
            VoucherDate: orderDate,
            Status: props.Status,
          },
        ]
  );
  const [Photofiles, setPhotofiles] = useState(splitPictures());

  const [productsList] = useState(props.ProductsList);
  const [sourceNo, setSourceNo] = useState(props.SourceNo ? props.SourceNo : 0);
  const [storeList, setStoreList] = useState(globalStores);
  const [store, setStore] = useState(
    props.data
      ? storeList.filter((st) => parseInt(st.Id) === parseInt(props.data.Store))
          .length > 0 &&
          storeList.filter(
            (st) => parseInt(st.Id) === parseInt(props.data.Store)
          )[0].Name
      : storeList.filter((st) => parseInt(st.Id) === parseInt(globalUser.Store))
          .length > 0 &&
          storeList.filter(
            (st) => parseInt(st.Id) === parseInt(globalUser.Store)
          )[0].Name
  );
  function splitPictures() {
    try {
      fileArray = [];
      props.data.Attachment.split(",").map(function (address, index) {
        if (!fileArray.includes(address) && address.length > 0) {
          fileArray.push(address);
          //document.getElementById('file').files.push(new Blob(address))
        }
      });
      return fileArray;
    } catch (err) {
      console.log(err);
    }
  }
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    setPhotofiles(fileArray);
  }

  const handleSubmit = (orderId, status, type, vDate, lItems) => {
    let testResult = false;
    customers &&
      customers.map((cust, index) => {
        if (cust.Id === undefined || cust.Id === null || cust.Id === 0) {
          alert(
            "First select the supplier for SO :" +
              (parseInt(index) + parseInt(1))
          );
          testResult = true;
          return;
        }

        if (
          cust.Store === undefined ||
          cust.Store === null ||
          cust.Store === false ||
          cust.Store === 0
        ) {
          alert(
            "First select the store for SO :" + (parseInt(index) + parseInt(1))
          );
          testResult = true;
          return;
        }
        if (
          lItems === undefined ||
          lItems.filter(
            (li) =>
              li.Name.length > 0 && parseInt(li.Supplier) === parseInt(cust.Id)
          ).length <= 0
        ) {
          alert(
            "First select the items in SO :" + (parseInt(index) + parseInt(1))
          );
          testResult = true;
          return;
        }

        lItems.map((li) => {
          if (isNaN(li.Quantity) || li.Quantity <= 0) {
            alert(
              "First insert the proper quantity of product: " +
                li.Name +
                " in  PO :" +
                (parseInt(index) + parseInt(1))
            );
            testResult = true;
            return;
          }

          if (isNaN(li.UnitPrice) || li.UnitPrice <= 0) {
            alert(
              "First insert the proper unit price of product: " +
                li.Name +
                " in  PO :" +
                (parseInt(index) + parseInt(1))
            );
            testResult = true;
            return;
          }
        });

        if (testResult) {
          return;
        }

        if (
          window.confirm(
            "Are you sure you want to save the " +
              props.Type +
              " " +
              status +
              "?"
          )
        ) {
          try {
            var fd = new FormData();
            fd.append("OrderId", orderId);
            fd.append("Supplier", globalUser.Parent);
            fd.append("VoucherDate", vDate);
            fd.append("Amount", cust.Amount.toLocaleString().replace(",", ""));
            fd.append(
              "VATAmount",
              cust.VATAmount.toLocaleString().replace(",", "")
            );
            fd.append(
              "TotalAmount",
              cust.TotalAmount.toLocaleString().replace(",", "")
            );
            fd.append("Customer", cust.Id);
            fd.append("SourceNo", sourceNo);
            fd.append("Status", status);
            fd.append("BillTo", globalUser.Id);

            fd.append("Store", cust.Store);
            fd.append("User", globalUser.Id);
            Array.from(document.getElementById("Attachment").files).map(
              (file) => {
                fd.append("Attachment", file);
              }
            );
            fd.append(
              "LineItems",
              JSON.stringify(
                lItems.filter(
                  (it) =>
                    it.Name.length > 0 &&
                    parseInt(it.Supplier) === parseInt(cust.Id)
                )
              )
            );
            fd.append("Type", type);
            axios.post("/vouchers", fd, {
              headers: {
                "Content-Type": "multipart/form-data",
                token: globalToken,
              },
            });
          } catch (err) {}
       
    if (!testResult) {
      setSelectedItems([]);
      setGlobalSelectedCustomers([]);
      setLineItems([]);
try{

        props.setParentUpdate(!props.ParentUpdate);
      
}catch(err){

}
      
      setOpen(false);
    }
  }
});
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        axios.delete("/vouchers?Id=" + id, {
          headers: {
            token: globalToken,
          },
        }); //add id
      } catch (err) {
      } finally {
        props.setParentUpdate(!props.ParentUpdate);
        setOpen(false);
      }
    }
  };
  const handleLineItemDelete = (id) => {
    try {
      axios
        .delete("/lineItems?Id=" + id, {
          headers: {
            token: globalToken,
          },
        })
        .then(() => setLineItems(lineItems.filter((li) => li.Id !== id))); //add id
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    try {
      if (
        customers === undefined ||
        customers === null ||
        customers.length <= 0
      ) {
        setCustomers(
          props.data
            ? [
                {
                  ...props.data,
                  Id: props.data.Supplier,
                  Supplier: props.data.Supplier,
                  Amount: 0,
                  VATAmount: 0,
                  TotalAmount: 0,
                  Store: props.data.Store,
                },
              ]
            : globalSelectedCustomers && globalSelectedCustomers.length > 0
            ? globalSelectedCustomers
            : [{ ...globalCustomers[0], Status: props.Status }]
        );

        setSelectedVoucher(
          props.data
            ? props.data.Supplier
            : globalSelectedCustomers && globalSelectedCustomers.length > 0
            ? globalSelectedCustomers[0].Id
            : 0
        );
      }
      if (!(props.data === undefined) && !(props.data === null)) {
        setLineItems([...props.LineItems, ...selectedItems]);
      } else {
        setLineItems(selectedItems.filter((itt) => itt.Type === "Sales"));
      }
    } catch {}
  }, [selectedItems, globalSelectedCustomers]);

  return (
    <div>
      <label
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.icon}
      </label>

      <Dialog
      open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
            {!(customers === undefined) &&
              !(customers === null) &&
              customers.length > 0 &&
              customers.map((cust, index) => {
                if (
                  cust &&
                  !(cust === null) &&
                  cust.Name &&
                  cust.Name.length > 0
                ) {
                  cust.VATAmount = 0;
                  cust.Amount = 0;
                  lineItems &&
                    lineItems
                      .filter(
                        (li) => parseInt(li.Supplier) === parseInt(cust.Id)
                      )
                      .map((it) => {
                        cust.VATAmount = cust.VATAmount + it.TaxAmount;
                        cust.Amount = cust.Amount + it.TotalPrice;
                      });
                  cust.TotalAmount = cust.Amount + cust.VATAmount;
                  return (
                    <>
                      <DialogTitle
                        style={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          height: "20px",
                        }}
                        onClick={() =>
                          cust.Id === selectedVoucher
                            ? setSelectedVoucher(
                                customers[
                                  index + 1 >= customers.length ? 0 : index + 1
                                ].Id
                              )
                            : setSelectedVoucher(cust.Id)
                        }
                      >
                        SO: {index + 1 + "              "} Customer:{" "}
                        {cust.Name + "      Total Aount: " + cust.TotalAmount}
                      </DialogTitle>
                      <Grid container mt={1} key={cust.Id}>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? "block" : "none",
                          }}
                        >
                          <Autocomplete
                            label="Customer"
                            id="Customer"
                            size="small"
                            variant="standard"
                            defaultValue={cust.Name}
                            onChange={(e) => {
                              customers[index] =
                                globalCustomers.filter(
                                  (ss) => ss.Name === e.target.textContent
                                ).length > 0 &&
                                globalCustomers.filter(
                                  (ss) => ss.Name === e.target.textContent
                                )[0];
                              setLineItems(
                                lineItems
                                  .filter((lil) => lil.Supplier === cust.Id)
                                  .map((li) => {
                                    li.Supplier = customers[index].Id;
                                    return li;
                                  })
                              );
                              setSelectedVoucher(customers[index].Id);

                              setCustomers(
                                customers.map((sp) => {
                                  return sp;
                                })
                              );
                            }}
                            options={
                              !(globalCustomers === undefined) &&
                              !(globalCustomers === null) &&
                              globalCustomers.length > 0 &&
                              globalCustomers.map((pro) => {
                                return pro.Name;
                              })
                            }
                            sx={{ width: "95%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Customer")}

                                //error={!from ? true : false}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? "block" : "none",
                          }}
                        >
                          <span style={{}}>
                            Doc. No.: {props.data ? props.data.OrderId : 0}
                            <br></br>
                            Status: {props.data ? props.data.Status : "Draft"}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          mt={1}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? "block" : "none",
                          }}
                        >
                          <Autocomplete
                            label="Issued To"
                            id="Store"
                            size="small"
                            variant="standard"
                            defaultValue={store}
                            onChange={(e) => {
                              setStore(e.target.textContent);
                              cust.Store =
                                storeList &&
                                storeList.length > 0 &&
                                storeList.filter(
                                  (st) => st.Name === e.target.textContent
                                ).length > 0 &&
                                storeList.filter(
                                  (st) => st.Name === e.target.textContent
                                )[0].Id;
                            }}
                            options={
                              !(storeList === undefined) &&
                              !(storeList === null) &&
                              storeList.length > 0 &&
                              storeList.map((pro) => {
                                return pro.Name;
                              })
                            }
                            sx={{ width: "95%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Store")}

                                //error={!from ? true : false}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          mt={1}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? "block" : "none",
                          }}
                        >
                          <TextField
                            size="small"
                            label="Order Date"
                            type="date"
                            value={
                              props.data ? props.data.VoucherDate : orderDate
                            }
                            onChange={(e) => {
                              setOrderDate(e.target.value);
                            }}
                            sx={{ width: "70%", marginBottom: 1 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? "block" : "none",
                          }}
                        ></Grid>

                        <Grid
                          item
                          xs={6}
                          style={{
                            display:
                              selectedVoucher === cust.Id ? "block" : "none",
                          }}
                        >
                          User:{" "}
                          {props.data ? props.data.UserName : globalUser.Name}
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            direction="row"
                            container
                            style={{
                              display:
                                selectedVoucher === cust.Id ? "block" : "none",
                            }}
                          >
                            <hr></hr>
                            <Grid item xs={12} className="hideInMobile">
                              <Grid container>
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  No.
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  Item Name
                                </Grid>

                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Quantity
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Unit Price
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  Total
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  style={{
                                    paddingLeft: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                   {globalRoleSecurity.filter(
                                              (sec) =>
                                                sec.Functionality ===
                                                  "Sales " +
                                                    (props.Status
                                                      ? props.Status
                                                      : "Draft") &&
                                                sec.Security > 1
                                            ).length > 0 && 
                                  <IconButton
                                    size="small"
                                    id={cust.Id}
                                    onClick={(e) => {
                                      if (
                                        lineItems &&
                                        lineItems.filter(
                                          (itt) =>
                                            itt.Item === "" &&
                                            parseInt(itt.Supplier) ===
                                              parseInt(cust.Id)
                                        ).length <= 0
                                      ) {
                                        let newLineItem = {};
                                        newLineItem.Item = "";
                                        newLineItem.Name = "";
                                        newLineItem.Type = "Sales";
                                        newLineItem.Supplier = cust.Id;
                                        newLineItem.UnitCost = 0;
                                        newLineItem.Quantity = 0;
                                        newLineItem.UnitPrice = 0;
                                        newLineItem.TotalPrice = 0;
                                        newLineItem.TaxAmount = 0;
                                        newLineItem.SupplierName = cust.Name;
                                 
                                        setSelectedItems((xx) => [
                                          ...xx,
                                          newLineItem,
                                        ]);
                                      } else {
                                        alert("First complete the data");
                                      }
                                    }}
                                  >
                                    <Add
                                      sx={{
                                        color:
                                          theme.palette.primary.contrastText,
                                      }}
                                    />
                                  </IconButton>
                }
                                </Grid>

                                {lineItems &&
                                  lineItems.length > 0 &&
                                  lineItems
                                    .filter(
                                      (it) =>
                                        parseInt(it.Supplier) ===
                                        parseInt(cust.Id)
                                    )
                                    .map((item, index) => {
                                      return (
                                        <>
                                          <Grid item xs={12}>
                                            <hr></hr>
                                          </Grid>
                                          <Grid item xs={1} key={index}>
                                            {index + 1}
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Autocomplete
                                              label="Items"
                                              id="Items"
                                              autoHighlight
                                              size="small"
                                              variant="standard"
                                              value={item.Name}
                                              onChange={(e) => {
                                                if (
                                                  lineItems &&
                                                  lineItems.length > 0 &&
                                                  lineItems === null &&
                                                  lineItems.filter(
                                                    (li) =>
                                                      li.Name ===
                                                        e.target.textContent &&
                                                      parseInt(li.Supplier) ===
                                                        parseInt(cust.Id)
                                                  ).length > 0
                                                ) {
                                                  item.Item = "";
                                                  item.Name = "";
                                                  item.Supplier = cust.Id;
                                                  item.Quantity = 0;
                                                  item.UnitPrice = 0;
                                                  item.UnitCost = 0;
                                                  item.TotalPrice = 0;
                                                  item.TaxAmount = 0;
                                                  setSelectedItems(
                                                    selectedItems.map(
                                                      (litem) => {
                                                        return litem;
                                                      }
                                                    )
                                                  );
                                                  //alert('The selected item is already registered')
                                                  return;
                                                } else {
                                                  let selectedProduct =
                                                    allItems.filter(
                                                      (selItem) =>
                                                        selItem.Name ==
                                                        e.target.textContent
                                                    );
                                                  if (
                                                    selectedProduct &&
                                                    selectedProduct.length > 0
                                                  ) {
                                                    item.Type = "Sales";
                                                    item.Quantity =
                                                      selectedProduct[0]
                                                        .DefaultOrderQty !== 0
                                                        ? selectedProduct[0]
                                                            .DefaultOrderQty
                                                        : 1;
                                                    item.UnitPrice =
                                                      selectedProduct[0].Price
                                                        ? selectedProduct[0]
                                                            .Price
                                                        : 0;
                                                    item.VATInclusive =
                                                      selectedProduct[0].VATInclusive;
                                                    item.TaxAmount =
                                                      item.VATInclusive
                                                        ? item.UnitPrice * 0.15
                                                        : 0;
                                                    item.Supplier = cust.Id;

                                                    item.UOM =
                                                      selectedProduct[0].UOM
                                                        ? selectedProduct[0].UOM
                                                        : "PCS";

                                                    item.Item =
                                                      selectedProduct[0].Item;
                                                    item.Name =
                                                      selectedProduct[0].Name;
                                                    item.TotalPrice =
                                                      item.UnitPrice *
                                                      item.Quantity;
                                                    item.TaxAmount =
                                                      selectedProduct[0]
                                                        .VATInclusive
                                                        ? item.UnitPrice *
                                                          item.Quantity *
                                                          0.15
                                                        : 0;
                                                    item.Image =
                                                      selectedProduct[0].Picture;
                                                    item.Supplier = cust.Id;
                                                    cust.VATAmount = 0;
                                                    cust.Amount = 0;
                                                    lineItems &&
                                                      lineItems
                                                        .filter(
                                                          (li) =>
                                                            parseInt(
                                                              li.Supplier
                                                            ) ===
                                                            parseInt(cust.Id)
                                                        )
                                                        .map((it) => {
                                                          cust.VATAmount =
                                                            cust.VATAmount +
                                                            it.TaxAmount;
                                                          cust.Amount =
                                                            cust.Amount +
                                                            it.TotalPrice;
                                                        });
                                                    cust.TotalAmount =
                                                      cust.Amount +
                                                      cust.VATAmount;
                                                    addtoCart(item);

                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem;
                                                        }
                                                      )
                                                    );
                                                  } else {
                                                    item.Quantity = 0;
                                                    item.UnitPrice = 0;
                                                    item.UnitCost = 0;
                                                    item.TotalPrice = 0;
                                                    item.TaxAmount = 0;
                                                    item.Item = "";
                                                    item.Name = "";
                                                    item.Supplier = cust.Id;

                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem;
                                                        }
                                                      )
                                                    );
                                                  }
                                                }
                                              }}
                                              options={
                                                !(allItems === undefined) &&
                                                !(allItems === null) &&
                                                allItems.length > 0
                                                  ? allItems.map((it) => {
                                                      return it.Name;
                                                    })
                                                  : [""]
                                              }
                                              sx={{ width: "95%", height: 5 }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={item.Name}
                                                  sx={{ height: 5 }}
                                                  //error={!from ? true : false}
                                                />
                                              )}
                                            />
                                          </Grid>

                                          <Grid item xs={2}>
                                            <TextField
                                              size="small"
                                              variant="standard"
                                              type="float"
                                              value={
                                                item.Quantity
                                                  ? item.Quantity.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                  : 0
                                              }
                                              maxRows={4}
                                              sx={{ width: "70px" }}
                                              onChange={(e) => {
                                                item.Quantity = e.target.value;
                                                item.TotalPrice =
                                                  item.Quantity *
                                                  item.UnitPrice;
                                                item.TaxAmount =
                                                  item.VATInclusive
                                                    ? item.TotalPrice * 0.15
                                                    : 0;
                                                cust.VATAmount = 0;
                                                cust.Amount = 0;
                                                lineItems &&
                                                  lineItems
                                                    .filter(
                                                      (li) =>
                                                        parseInt(
                                                          li.Supplier
                                                        ) === parseInt(cust.Id)
                                                    )
                                                    .map((it) => {
                                                      cust.VATAmount =
                                                        cust.VATAmount +
                                                        it.TaxAmount;
                                                      cust.Amount =
                                                        cust.Amount +
                                                        it.TotalPrice;
                                                    });
                                                cust.TotalAmount =
                                                  cust.Amount + cust.VATAmount;
                                                setSelectedItems(
                                                  selectedItems.map((litem) => {
                                                    return litem;
                                                  })
                                                );
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>

                                          <Grid item xs={2}>
                                            <TextField
                                              size="small"
                                              variant="standard"
                                              type="float"
                                              value={
                                                item.UnitPrice
                                                  ? item.UnitPrice.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                  : 0
                                              }
                                              sx={{ width: "70px" }}
                                              onChange={(e) => {
                                                item.UnitPrice = e.target.value;
                                                item.TotalPrice =
                                                  item.Quantity *
                                                  item.UnitPrice;
                                                item.TaxAmount =
                                                  item.VATInclusive
                                                    ? item.TotalPrice * 0.15
                                                    : 0;
                                                cust.VATAmount = 0;
                                                cust.Amount = 0;
                                                lineItems &&
                                                  lineItems
                                                    .filter(
                                                      (li) =>
                                                        parseInt(
                                                          li.Supplier
                                                        ) === parseInt(cust.Id)
                                                    )
                                                    .map((it) => {
                                                      cust.VATAmount =
                                                        cust.VATAmount +
                                                        it.TaxAmount;
                                                      cust.Amount =
                                                        cust.Amount +
                                                        it.TotalPrice;
                                                    });
                                                cust.TotalAmount =
                                                  cust.Amount + cust.VATAmount;
                                                setSelectedItems(
                                                  selectedItems.map((litem) => {
                                                    return litem;
                                                  })
                                                );
                                              }}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            {item.TotalPrice.toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                          </Grid>
                                          <Grid item xs={1}>
                                            {globalRoleSecurity.filter(
                                              (sec) =>
                                                sec.Functionality ===
                                                  "Sales " +
                                                    (props.Status
                                                      ? props.Status
                                                      : "Draft") &&
                                                sec.Security > 1
                                            ).length > 0 && (
                                              <Tooltip title="remove">
                                                <Button
                                                  endIcon={
                                                    <Delete
                                                      sx={{
                                                        color:
                                                          theme.palette.primary
                                                            .main,
                                                      }}
                                                    />
                                                  }
                                                  onClick={() => {
                                                    if (
                                                      lineItems.filter(
                                                        (litem) =>
                                                          parseInt(
                                                            litem.Item
                                                          ) ===
                                                            parseInt(
                                                              item.Item
                                                            ) &&
                                                          parseInt(
                                                            litem.Supplier
                                                          ) ===
                                                            parseInt(
                                                              item.Supplier
                                                            )
                                                      ).length > 0
                                                    ) {
                                                      handleLineItemDelete(
                                                        lineItems.filter(
                                                          (litem) =>
                                                            parseInt(
                                                              litem.Item
                                                            ) ===
                                                              parseInt(
                                                                item.Item
                                                              ) &&
                                                            parseInt(
                                                              litem.Supplier
                                                            ) ===
                                                              parseInt(
                                                                item.Supplier
                                                              )
                                                        )[0].Id
                                                      );
                                                    }
                                                    removeFromCart(item);

                                                    /*    setLineItems(
                                    lineItems.filter(
                                      (litem) => !(litem.Item === item.Item && parseInt(litem.Supplier)===parseInt(item.Supplier))
                                    )
                                  ); */
                                                    if (
                                                      lineItems.filter(
                                                        (litem) =>
                                                          parseInt(
                                                            litem.Supplier
                                                          ) ===
                                                          parseInt(cust.Id)
                                                      ).length <= 0
                                                    ) {
                                                      setCustomers(
                                                        customers.filter(
                                                          (ssp) =>
                                                            parseInt(ssp.Id) !==
                                                            parseInt(cust.Id)
                                                        )
                                                      );
                                                      /* setOpen(false);
                                                   let newLineItem = {};
                                    newLineItem.Item = "";
                                    newLineItem.Name = "";
                                    newLineItem.Supplier = cust.Id;
                                    newLineItem.UnitCost = 0;
                                    newLineItem.Quantity = 0;
                                    newLineItem.UnitPrice = 0;
                                    newLineItem.TotalPrice = 0;
                                    setLineItems((xx) => [...xx, newLineItem]); */
                                                    }
                                                  }}
                                                ></Button>
                                              </Tooltip>
                                            )}
                                          </Grid>
                                        </>
                                      );
                                    })}
                                <Grid item xs={12}>
                                  <hr></hr>
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  Sub Total:
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    textDecoration: "underline",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {cust.Amount?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>

                                <Grid item xs={5}></Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  VAT Amount:
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    textDecorationLine: "underline",
                                  }}
                                >
                                  {cust.VATAmount?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  Total Amount:
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    border: "solid 1",
                                    padding: "5px",
                                    textDecoration: "underline",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                  }}
                                >
                                  {" "}
                                  {cust.TotalAmount?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item className="hideInFull" m={1} xs={12}>
                              {lineItems &&
                                lineItems.length > 0 &&
                                lineItems
                                  .filter(
                                    (it) =>
                                      parseInt(it.Supplier) ===
                                      parseInt(cust.Id)
                                  )
                                  .map((item, index) => {
                                    return (
                                      <>
                                        <Card
                                          sx={{
                                            borderRadius: 10,
                                            boxShadow: 3,
                                            width: "100%",
                                          }}
                                        >
                                          <CardContent>
                                            <Grid container>
                                              <Grid item xs={4}>
                                                No.:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                {index + 1}
                                              </Grid>
                                              <Grid item xs={4}>
                                                Product:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <Autocomplete
                                                  label="Items"
                                                  id="Items"
                                                  size="small"
                                                  variant="standard"
                                                  value={item.Name}
                                                  onChange={(e) => {
                                                    if (
                                                      lineItems.filter(
                                                        (li) =>
                                                          li.Name ===
                                                          e.target.textContent
                                                      ).length > 0
                                                    ) {
                                                      item.Item = "";
                                                      item.Name = "";
                                                      item.Quantity = 0;
                                                      item.Supplier = cust.Id;
                                                      item.UnitPrice = 0;
                                                      item.UnitCost = 0;
                                                      item.TotalPrice = 0;
                                                      item.TaxAmount = 0;
                                                      setSelectedItems(
                                                        selectedItems.map(
                                                          (litem) => {
                                                            return litem;
                                                          }
                                                        )
                                                      );

                                                      return;
                                                    } else {
                                                      let selectedProduct =
                                                        productsList.filter(
                                                          (selItem) =>
                                                            selItem.Name ===
                                                            e.target.textContent
                                                        );
                                                      if (
                                                        selectedProduct &&
                                                        selectedProduct.length >
                                                          0
                                                      ) {
                                                        item.Quantity =
                                                          selectedProduct[0]
                                                            .DefaultOrderQty !==
                                                          0
                                                            ? selectedProduct[0]
                                                                .DefaultOrderQty
                                                            : 1;
                                                        item.UnitPrice =
                                                          selectedProduct[0]
                                                            .UnitPrice
                                                            ? selectedProduct[0]
                                                                .UnitPrice
                                                            : 0;
                                                        item.TaxAmount =
                                                          selectedProduct.VATInclusive
                                                            ? item.TotalPrice *
                                                              0.15
                                                            : 0;
                                                        item.Item =
                                                          selectedProduct[0].Item;
                                                        item.Name =
                                                          selectedProduct[0].Name;
                                                        item.Supplier = cust.Id;
                                                        item.TotalPrice =
                                                          item.UnitPrice *
                                                          item.Quantity;
                                                        setSelectedItems(
                                                          selectedItems.map(
                                                            (litem) => {
                                                              return litem;
                                                            }
                                                          )
                                                        );
                                                      } else {
                                                        item.Quantity = 0;
                                                        item.UnitPrice = 0;
                                                        item.UnitCost = 0;
                                                        item.TotalPrice = 0;
                                                        item.TaxAmount = 0;
                                                        item.Item = "";
                                                        item.Name = "";
                                                        item.Supplier = cust.Id;
                                                        setSelectedItems(
                                                          selectedItems.map(
                                                            (litem) => {
                                                              return litem;
                                                            }
                                                          )
                                                        );
                                                      }
                                                    }
                                                  }}
                                                  options={
                                                    !(allItems === undefined) &&
                                                    !(allItems === null) &&
                                                    allItems.length > 0
                                                      ? allItems.map((it) => {
                                                          return it.Name;
                                                        })
                                                      : [""]
                                                  }
                                                  sx={{ width: "100%" }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      label={item.Name}

                                                      //error={!from ? true : false}
                                                    />
                                                  )}
                                                />
                                              </Grid>
                                              <Grid item xs={4}>
                                                Quantity:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <TextField
                                                  size="small"
                                                  variant="standard"
                                                  type="float"
                                                  value={item.Quantity}
                                                  maxRows={4}
                                                  sx={{ width: "100%" }}
                                                  onChange={(e) => {
                                                    item.Quantity =
                                                      e.target.value;
                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem;
                                                        }
                                                      )
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </Grid>

                                              <Grid item xs={4}>
                                                Unit Price:
                                              </Grid>
                                              <Grid item xs={8} pl={0}>
                                                <TextField
                                                  size="small"
                                                  variant="standard"
                                                  type="float"
                                                  value={item.UnitPrice}
                                                  sx={{ width: "100%" }}
                                                  onChange={(e) => {
                                                    item.UnitPrice =
                                                      e.target.value;
                                                    setSelectedItems(
                                                      selectedItems.map(
                                                        (litem) => {
                                                          return litem;
                                                        }
                                                      )
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={4}>
                                                Total Price:
                                              </Grid>
                                              <Grid item xs={8} pl={8}>
                                                {(
                                                  item.UnitPrice * item.Quantity
                                                )?.toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                              </Grid>
                                              <Grid item xs={12}>
                                                <hr></hr>
                                                Remove: {"   "}
                                                <Tooltip title="remove">
                                                  <Button
                                                    endIcon={
                                                      <Delete
                                                        sx={{
                                                          color:
                                                            theme.palette
                                                              .primary.main,
                                                        }}
                                                      />
                                                    }
                                                    onClick={() => {
                                                      handleLineItemDelete(
                                                        lineItems.filter(
                                                          (litem) =>
                                                            litem.Item ===
                                                            item.Item
                                                        )[0].Id
                                                      );
                                                      removeFromCart(item);
                                                      /*   setLineItems(
                                    lineItems.filter(
                                      (litem) => !(litem.Item === item.Item)
                                    )
                                  ); */
                                                      if (
                                                        lineItems.filter(
                                                          (litem) =>
                                                            !(
                                                              litem.Item ===
                                                              item.Item
                                                            )
                                                        ).length <= 0
                                                      ) {
                                                        let newLineItem = {};
                                                        newLineItem.Item = "";
                                                        newLineItem.Name = "";
                                                        newLineItem.Supplier =
                                                          cust.Id;
                                                        newLineItem.UnitCost = 0;
                                                        newLineItem.Quantity = 0;
                                                        newLineItem.UnitPrice = 0;
                                                        newLineItem.TaxAmount = 0;
                                                        newLineItem.TotalPrice = 0;
                                                        setSelectedItems(
                                                          (xx) => [
                                                            ...xx,
                                                            newLineItem,
                                                          ]
                                                        );
                                                      }
                                                    }}
                                                  ></Button>
                                                </Tooltip>
                                              </Grid>
                                            </Grid>
                                          </CardContent>
                                        </Card>
                                        <hr></hr>
                                      </>
                                    );
                                  })}
                              <Table>
                                <tr>
                                  <td />
                                  <td hidden />
                                  <td />
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                  >
                                    {" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                    colSpan={2}
                                  >
                                    {" "}
                                    Sub Total:
                                  </td>

                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                      textDecoration: "underline",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                  >
                                    {cust.Amount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                  ></td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                    hidden
                                  >
                                    {" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                  >
                                    {" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                  >
                                    {" "}
                                  </td>

                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                    colSpan={2}
                                  >
                                    {" "}
                                    VAT Amount:
                                  </td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                      textDecorationLine: "underline",
                                    }}
                                  >
                                    {cust.VATAmount?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                  ></td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                    hidden
                                  >
                                    {" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                  >
                                    {" "}
                                  </td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                    }}
                                  >
                                    {" "}
                                  </td>

                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                    colSpan={2}
                                  >
                                    {" "}
                                    Total Amount:
                                  </td>
                                  <td
                                    style={{
                                      border: "solid 1",
                                      padding: "5px",
                                      textDecoration: "underline",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                    }}
                                  >
                                    {" "}
                                    {cust.TotalAmount.toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }
                                    )}{" "}
                                  </td>
                                </tr>
                              </Table>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            border={1}
                            style={{
                              display:
                                selectedVoucher === cust.Id ? "block" : "none",
                            }}
                          >
                            <label htmlFor="Attachment">
                              Uplaod necessary document here
                              <input
                                id="Attachment"
                                name="Attachment"
                                type="file"
                                multiple
                                hidden
                                onChange={uploadMultipleFiles}
                              />
                            </label>
                            {Photofiles && Photofiles.length > 0 && (
                              <Button
                                style={{ color: "red" }}
                                ml={4}
                                endIcon={<Close />}
                                onClick={() => {
                                  setPhotofiles([]);
                                }}
                              />
                            )}
                            <div className="form-group multi-preview">
                              {(Photofiles || []).map((address) => (
                                <img
                                  src={address}
                                  alt="..."
                                  style={{ width: "100%", height: "100" }}
                                />
                              ))}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                }
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize: "8px",
          }}
        >
          <hr></hr>
          <Button variant="standard" endIcon={<PreviewSharp />}>
            {customers && customers.length > 0 && (
              <InvoicePreview
                icon={<div style={{ fontSize: "10px" }}>Preview</div>}
                Suppliers={customers}
                LineItems={lineItems}
                DocumentType={
                  "Sales " + (props.Status ? props.Status : "Draft")
                }
                Type="Invoice"
              />
            )}
          </Button>
          |
          {props.Status !== "Direct" && (
            <>
              {props.data &&
                (props.data.Status === "Order" ||
                  props.data.Status === "Delivery") &&
                globalRoleSecurity.filter(
                  (sec) =>
                    sec.Functionality === "Sales Payment" && sec.Security > 1
                ).length > 0 && (
                  <>
                    <Button
                      variant="standard"
                      style={{ fontSize: "10px" }}
                      /*  endIcon={<Send />} */
                      autoFocus
                    >
                      <PaymentsModel
                        icon={<div>Payment</div>}
                        OrderData={props.data}
                        BankList={bankList}
                        Title="NEW PAYMENT"
                      />
                    </Button>{" "}
                    |
                  </>
                )}

              {globalRoleSecurity.filter(
                (sec) => sec.Functionality === "Sales Order" && sec.Security > 1
              ).length > 0 && (
                <>
                  <Button
                    variant="standard"
                    style={{ fontSize: "10px" }}
                    endIcon={<Send />}
                    autoFocus
                    onClick={() =>
                      handleSubmit(
                        props.data ? props.data.OrderId : 0,
                        "Order",
                        "Normal",
                        orderDate,
                        lineItems.filter(
                          (itt) => itt.Name && itt.Name.length > 0
                        )
                      )
                    }
                  >
                    Order
                  </Button>
                  |{" "}
                </>
              )}
              {globalRoleSecurity.filter(
                (sec) =>
                  sec.Functionality === "Sales Request" && sec.Security > 1
              ).length > 0 && (
                <>
                  <Button
                    variant="standard"
                    style={{ fontSize: "10px" }}
                    endIcon={<RequestQuoteSharp />}
                    autoFocus
                    onClick={() =>
                      handleSubmit(
                        props.data ? props.data.OrderId : 0,
                        "Proforma",
                        "Normal",
                        orderDate,
                        lineItems.filter(
                          (itt) => itt.Name && itt.Name.length > 0
                        )
                      )
                    }
                  >
                    Proforma
                  </Button>
                  |
                  {props.data && props.data.Status === "Proforma" && (
                    <>
                      <Button
                        variant="standard"
                        style={{ fontSize: "10px" }}
                        endIcon={<EmailSharp />}
                        autoFocus
                      >
                        Email
                      </Button>
                      |
                    </>
                  )}
                  <Button
                    variant="standard"
                    style={{ fontSize: "10px" }}
                    endIcon={<DraftsSharp />}
                    autoFocus
                    onClick={() =>
                      handleSubmit(
                        props.data ? props.data.OrderId : 0,
                        "Draft",
                        "Normal",
                        orderDate,
                        lineItems.filter(
                          (itt) => itt.Name && itt.Name.length > 0
                        )
                      )
                    }
                  >
                    Draft
                  </Button>
                  |
                </>
              )}
              {globalRoleSecurity.filter(
                (sec) =>
                  sec.Functionality === "Sales Request" && sec.Security > 2
              ).length > 0 && (
                <>
                  <Button
                    variant="standard"
                    style={{ fontSize: "10px" }}
                    endIcon={<DeleteSharp />}
                    autoFocus
                    onClick={() => handleDelete(props.data.OrderId)}
                  >
                    Delete
                  </Button>
                  |
                </>
              )}
            </>
          )}
          {props.Status === "Direct" && (
            <>
              <Button
                variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<Send />}
                autoFocus
                onClick={() =>
                  handleSubmit(
                    props.data ? props.data.OrderId : 0,
                    "Order",
                    "DO",
                    orderDate,
                    lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Direct Order
              </Button>
              |
              </>)
}
              
              {(props.Status === "Direct" || props.Status === "Direct Delivery") &&
          globalRoleSecurity.filter(
            (sec) =>
              sec.Functionality === "Purchase Direct" && sec.Security > 1
          ).length > 0 && (
            <>
              <Button
                variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<Send />}
                autoFocus
                onClick={() =>
                  handleSubmit(
                    props.data ? props.data.OrderId : 0,
                    "Order",
                    "DOD",
                    orderDate,
                    lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Direct Delivery
              </Button>
              |
              </>)
}
{(props.Status === "Direct" || props.Status === "Direct Delivery & Payment") &&
          globalRoleSecurity.filter(
            (sec) =>
              sec.Functionality === "Purchase Direct" && sec.Security > 1
          ).length > 0 && (
            <>
              <Button
                variant="standard"
                style={{ fontSize: "10px" }}
                endIcon={<Send />}
                autoFocus
                onClick={() =>
                  handleSubmit(
                    props.data ? props.data.OrderId : 0,
                    "Order",
                    "DODP",
                    orderDate,
                    lineItems.filter((itt) => itt.Name && itt.Name.length > 0)
                  )
                }
              >
                Direct Delivery & Payment
              </Button>
              |
            </>
          )}
          <Button
            variant="standard"
            style={{ fontSize: "10px" }}
            endIcon={<Close />}
            sx={{ alignSelf: "end" }}
            onClick={() => setOpen(false)}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
