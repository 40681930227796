import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  Grid,
    IconButton,
    Button,
    Box ,
    Tooltip,
  } from "@mui/material";
  import {
    EditSharp,
    AddSharp,
    FileDownload,
    PreviewSharp,
    RefreshSharp,
  } from "@mui/icons-material";
  import MaterialReactTable from "material-react-table";
  import { useState, useEffect, useMemo,useContext } from "react";
  import JobsModel from "./JobsModel";
  import SupplierModel from "../Purchase/SuppliersModel"
  import { useLocation } from "react-router-dom";
  import {format} from 'date-fns'
  import * as XLSX from "xlsx";
  import axios from "../../axiosInstances";
  import InvoicePreview from "../Reports/InvoicePreview";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
//import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import { useNavigate } from 'react-router-dom'

  function JobOrdersComp(props) {
    const navigate = useNavigate();
    const theme=useTheme();
    const [update, setUpdate] = useState(false);
    const location = useLocation();
    //const [SupplierCategoriesList, setSupplierCategoriesList] = useState([]);
    const [JobsList, setJobsList] = useState([]);
    const [JobsLines, setJobslines] = useState([]);
    const [LineItemsList, setLineItemsList] = useState([]);
    const { globalUser,setPageHeader,allItems,globalToken } =useContext(ShoppingCartContext)
   const [assignedEmployees,setAssignedEmployees]=useState([]);
    //const { t, i18n } = useTranslation();
 
    const columns = useMemo(
      () => [
        {
          header: "Id",
          accessorKey: "Id", 
          size: "100"
        },
       
        {
          header: "Ordered By",
          accessorKey: "OrderedByName", 
        }, 
        {
          header: "Approved By",
          accessorKey: "ApprovedByName", 
        },
        {
          header: "Assigned To",
          accessorKey: "AssignedToName", 
        },
      /*   {
          header: "Order",
          accessorKey: "Order", 
          size: "100"
        }, */
        {
          header: "Order Date",
          accessorKey: "StartDate", 
          size: "110"
        },   
        {
          header: "End Date",
          accessorKey: "EndDate", 
          size: "110"
        }, 
 {
          header: "Status",
          accessorKey: "Status", 
          size: "80"
        },
      
        
      ],
      []
    );  

  
    const loadJobsList =  () => {
   
     axios.get("/jobs/getByParentAndStatus?Parent=" + globalUser.Parent +"&Status="+ location.state.Status , {
      headers: {
        token: globalToken,
      },
    })
     .then(response=>{
          if (response.data.data){
            setLineItemsList(response.data.data.LineItems)
           setJobsList(response.data.data.Orders);
           setJobslines(response.data.data.JobsLines);
          
          }else{
            alert('log out and log in again')
          }
        })
        
      };
    
      const loadAssignedEmployeesList =  () => {
      
        setAssignedEmployees([]);
       axios.get("/employees/getByParentAndType?Parent=" + globalUser.Parent + "&Type=Employee", {
        headers: {
          token: globalToken,
        },
      }).then(response=>{
            if (response.data.data){
              setAssignedEmployees(response.data.data);
            }else{
              alert('log out and log in again')
            }
          })
          
        };
  
    const handleUploadFile = async (e) => {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = XLSX.utils.sheet_to_json(worksheet);
  
      var fdata = new FormData();
      fdata.append("JsonData", JSON.stringify(jsonData));
      await axios.post("/Jobs/Excel", fdata, {
        headers: { "Content-Type": "application/json", 
        token: globalToken,
          },
      });
    };
    function handleDownloadFile() {
      const worksheet = XLSX.utils.json_to_sheet(JobsList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "jobs");
      //let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "jobsdata.xlsx");
    }
    
  
  
  
    useEffect(() => {
      if(!globalUser){
        navigate('/');
        return;
      }
      loadJobsList();
      setPageHeader("Job "+location.state.Status)
      loadAssignedEmployeesList()
    }, [update,location.state]);
    return (
     /* !( LineItemsList===undefined) && LineItemsList.length>0 && */ assignedEmployees && assignedEmployees.length>0?
      <Grid container>
      <Grid item className="hideInMobile" m={1}>     
            <MaterialReactTable
              columns={columns}
              data={JobsList}
               initialState={{ 
                density: "compact",
                pagination:{
                  pageIndex:0,
                  pageSize:12,
                   }
              }}
              enableGlobalFilter
             // enableRowSelection //enable some features
             // enableColumnOrdering
              enableColumnResizing
              enableRowNumbers
              renderDetailPanel={({ row }) => (
                <Grid container ml={2} mr={4}>

                {JobsLines && JobsLines.filter(li=>li.Job===row.original.Id).map((litem,index)=>{
                 return (
                  <>
                  {index===0?
                     <>
                       <Grid item xs={2} pl={12} sx={{backgroundColor:theme.palette.primary.main,border:1}}>
                     No.
                     </Grid>
                     <Grid item xs={4}  pl={12} sx={{backgroundColor:theme.palette.primary.main,border:1}}>
                     Product
                     </Grid>
                     <Grid item xs={2}  pl={12} sx={{backgroundColor:theme.palette.primary.main,border:1}}>
                     Order Quantity
                       </Grid>
                       
                     
                       <Grid item xs={4}  pl={12} sx={{backgroundColor:theme.palette.primary.main,border:1}}>
                     Remark
                       </Grid>
                       <Grid item xs={2} sx={{border:1}} pl={2}>{index+1}</Grid>
                  <Grid item xs={4} sx={{border:1}} pl={2}>
                  {litem.Name}
                  </Grid>
                  <Grid item xs={2} sx={{border:1}} pl={2}>
                  {litem.OrderQuantity}
                    </Grid>
                   
                    <Grid item xs={4} sx={{border:1}} pl={2}>
                  {litem.Remark}
                    </Grid>
                    </>
                       :
                       <>
                       <Grid item xs={2} sx={{border:1}} pl={2}>
                        {index+1}
                       </Grid>
                  <Grid item xs={4} sx={{border:1}} pl={2}>
                  {litem.Name}
                  </Grid>
                  <Grid item xs={2} sx={{border:1}} pl={2}>
                  {litem.OrderQuantity}
                    </Grid>
                 
                    <Grid item xs={4} sx={{border:1}} pl={2}>
                  {litem.Remark}
                    </Grid>
                    </>
                  }
                  </>
                 )
                })

                }
               
               
               </Grid>
              )}
                muiTopToolbarProps= {{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              muiBottomToolbarProps={{
                sx: {backgroundColor: theme.palette.primary.main,color:theme.palette.primary.contrastText}
              }}
              renderTopToolbarCustomActions={() => (
                <Box>
                <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <JobsModel
                      icon={
                        <div>Add</div>
                      }
                      JobOrders={[{Id:0,OrderDate:format(new Date(),"yyyy-MM-dd")}]}
                    EmployeesList={assignedEmployees}
                    ProductsList={allItems}
                    Type={location.state.Type }
                    Jobslines={[{}]}
                    />
                  </Button>|
                    {/* <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                      Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>| */}
  
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
              )}
              enableRowActions
              renderRowActions={(table) => (
                <Box>
                  {/* {table.row.original.Status==='Draft'?
                  <IconButton > 
                   <NewOrder
                  icon={<EditSharp style={{ width: "15px", height: "15px" }} />}
                  data={table.row.original}
                  Joblines={LineItemsList.filter(li=>parseInt(li.Job)===parseInt(table.row.original.Id))}
                  LineItems={LineItemsList.filter(li=>li.Reference===table.row.original.Id)}
                  Title="EDIT Jobs"
                 
                />   
                  </IconButton>: */}
                  <Tooltip title="Job Order">
                     <IconButton > 
                     <JobsModel
                    icon={<EditSharp style={{ width: "15px", height: "15px" }} />}
                    JobOrders={[table.row.original]}
                    EmployeesList={assignedEmployees}
                    Type={location.state.Type }
                    Jobslines={JobsLines.filter(li=>li.Job===table.row.original.Id)}
                    LineItems={LineItemsList.filter(li=>li.Reference===table.row.original.Order)}
                    Title="EDIT Supplier"
                   
                  />   
                    </IconButton>
                    </Tooltip>
                    |
                    <Tooltip title="Job Preview">
                  <IconButton>
                
                <InvoicePreview
                  icon={<PreviewSharp style={{ width: "20px", height: "20px" }} />}
                  data={[table.row.original]}
                  Jobslines={JobsLines.filter(li=>li.Job===table.row.original.Id)}
                  LineItems={LineItemsList.filter(li=>li.Reference===table.row.original.Order)}
                  Type="JobOrder"
                  DocumentType={"Job " + location.state.Status}
                />
              </IconButton>
              </Tooltip>
              
                </Box>
              )}
            > 
            </MaterialReactTable> 
            </Grid>
            <Grid item xs={12} mt={1} className="hideInFull">
            <Box sx={{  width: "100%",backgroundColor: theme.palette.primary.main,color: theme.palette.primary.contrastText}}>

                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<AddSharp style={{ width: "20px", height: "20px" }} />}>
                    <SupplierModel
                      icon={
                        <div>Add</div>
                      }
                      data=""
                      Type={location.state.Type }
                      Title="ADD NEW Supplier"
                  
                    />
                  </Button>|
               {/*    <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileUpload style={{ width: "20px", height: "20px" }} />}>
                    <label htmlFor="file">
                      Upload
                    </label>
                    <input
                      id="file"
                      name="file"
                      hidden
                      type="file"
                      onChange={handleUploadFile}
                    />
                  </Button>| */}
  
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<FileDownload style={{ width: "20px", height: "20px" }} />} type="file" onClick={handleDownloadFile}>
                   Download
                  </Button>|
                  <Tooltip title="refresh">
                  <Button       size="small"
                    variant="standard" sx={{borderRadius:'30px'}} endIcon={<RefreshSharp />}  onClick={() => setUpdate(!update)}>
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
            </Grid>
          {!(JobsList === undefined) &&
            JobsList.length > 0 &&
            JobsList.map((item) => {
              return (
                <Grid item key={item.Id} xs={12} m={1} className="hideInFull">
                 <Card sx={{ borderRadius: 10, boxShadow: 3,width:"100%"}} >
                    <CardHeader
                     title={item.AssignedToName} //.substring(0, 20)}
                      subheader={"Order No: "+ item.Order}
                    />

                    <CardContent>
                      <Typography variant="body2" align="left">
                       Order Date: {item.OrderDate}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Order By: {item.OrderedByName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Approved By: {item.ApprovedByName}
                      </Typography>
                      <Typography variant="body2" align="left">
                        Status: {item.Status}
                      </Typography>
                    
                    </CardContent>
                    <CardActions
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}>
                      <Box>
                        <Tooltip title="Edit Payment">
                          <IconButton>
                          <JobsModel
                    icon={<EditSharp style={{ width: "15px", height: "15px" }} />}
                    JobOrders={[item]}
                    EmployeesList={assignedEmployees}
                    Type={location.state.Type }
                    Jobslines={JobsLines.filter(li=>li.Job===item.Id)}
                    LineItems={LineItemsList.filter(li=>li.Reference===item.Order)}
                    Title="EDIT Supplier"
                   
                  />   
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
        :'Loading'
      
    );
  }
  
  export default JobOrdersComp;
  