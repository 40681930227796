import { useState, useContext } from 'react'
import axios from '../../axiosInstances'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { format } from "date-fns";
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Autocomplete,
  Tooltip,
  alertTitleClasses,
} from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { AlternateEmail, DeleteSharp } from '@mui/icons-material'
import { ShoppingCartContext } from "../Context/ShoppingCartContext";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
}

function NewsEditComp(props) {
  const {globalUser,globalToken} =    useContext(ShoppingCartContext);
  var fileObj = [];
  const location = useLocation()
  const [story, setStory] = useState(location.state.Story)
  const [categoriesList, setCategoriesList] = useState(
    location.state.Categories,
  )
  const [category, setCategory] = useState(story?.Category)
  const [visibility, setVisibility] = useState(story?.Visibility)
  const [ImageFile, setImageFile] = useState(story?.Image)
/*   const [category, setCategory] = useState(story?.Category)

  const [Title, setTitle] = useState(story?.Title)

  
  const [Description, setDescription] = useState(story?.Description)
  const [ViewCount, setViewCount] = useState(story?.ViewCount)
  const [PublishedBy, setPublishedBy] = useState(story?.PublishedBy)
  const [PublishedByName, setPublishedByName] = useState(story?.PublishedByName)
  const [Status, setStatus] = useState(story?.Status)
  const [Visibility, setVisibility] = useState(story?.Visibility) */

  const navigate = useNavigate()
/*   const StoryStatus = [
     {
      value: 'On Research',
      label: 'On Research',
    }, 
  {
      value: 'On Review',
      label: 'On Review',
    },
    {
      value: 'On Finalization',
      label: 'On Finalization',
    },
    {
      value: 'On Published',
      label: 'On Published',
    },
    {
      value: 'On Modification',
      label: 'On Modification',
    }, 
    'On Compilation',
  'Published',
   
  ] */

  function HandleSubmit(e) {
    if (
      window.confirm(
        "Are you sure you want to save?"
      )
    ) {
  try{
    var fd = new FormData()

   
    fd.append('Id', story?.Id)
    fd.append('Title', story?.Title)
    fd.append('Category', category)
    fd.append('Description', story?.Description)
    fd.append('ViewCount', story?.ViewCount)
    fd.append('PublishedBy', globalUser.Id)
    fd.append('PublishedDate', format(new Date(),"yyyy-MM-dd"))
    fd.append('Status', story?.Status)
    fd.append('Visibility', visibility===true?1:0)
    fd.append('Images', document.getElementById('Images').files[0])
    axios.post('/news', fd, {
      headers: { "Content-Type": "multipart/form-data",  
          token: globalToken,
       },
    });
  }catch(err){
    console.log(err)
  }finally{
    navigate('/SingleStory', {state:{
      Story: story,
      Categories:categoriesList
    }})
  }
}
  }
  /*  function HandleIdChange(e) {
    setId(e);
  }
  function HandleTitlChange(e) {
    setTitle(e);
  }
  function HandleDescriptionChange(e) {
    setDescription(e);
  }
  function HandleCategoryChange(e) {
    setCategory(e);
  }
  function HandleViewCountChange(e) {
    setViewCount(e);
  }
  function HandlePublishedByChange(e) {
    setPublishedBy(e);
  }
  function HandleStatusChange(e) {
    setStatus(e);
  }
  function HandleVisibilityChange(e) {
    setVisibility(e);
  }
    function HandleImageFileChange(e) {
    setImageFile(e.target.files[0]);
    document.getElementById("NewsImage").src = URL.createObjectURL(
      e.target.files[0]
    );
  } */
  function uploadMultipleFiles(e) {
    fileObj.push(e.target.files)
   if(fileObj[0].length>0) {
    setImageFile(URL.createObjectURL(fileObj[0][0]))
    }
   
  }

  function LoadHtml() {
    return (
      <Grid container m={2}>
        <Grid item xs={12} mb={2} style={{backgroundColor:"white"}}>
        <label  htmlFor="Images">
            <h3>  Upload Image</h3>
              <input
                style={{ display: "none" }}
                type="file"
                id="Images"
                onChange={uploadMultipleFiles}
                multiple
              />
            </label> 
          
          
                <Grid item xs={10}>
                  <Stack direction="row">
                  <img
                    src={ImageFile}
                    alt="..."
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "fill",
                      marginBottom: "2px",
                    }}
                  />
                  <Tooltip title="Delete the picture">
                  <Button
                  endIcon={<DeleteSharp />}
                  style={{left:'-60px',top:'5px',color:'red',width:'20px',height:'20px'}}
                  id='remove'
                    onClick={(e) => {
                      setImageFile('')
                    }}
                  />
                  </Tooltip>
                  </Stack>
             
          </Grid>
        </Grid>
        <Grid item xs={10} mb={1}  style={{ backgroundColor: 'white' }}>
          <Typography variant="h6">News Title :</Typography>
          <br></br>
          <ReactQuill
            id="title"
            style={{ margin: 4 }}
            modules={modules}
            placeholder="Write news title here..."
            theme="snow"
            value={story?.Title}
            onChange={(e) => story.Title=e}
            
          />
        </Grid>
        <Grid item xs={2} pl={2}>
          <div className="StorySupportive">
            <b>Publisher:</b><br></br>
            {story?.PublishedByName}<br></br><br></br>
            views:<span id="ViewCount">{' ' + story?.ViewCount}</span>
            <br></br><br></br>
            {"Status: "+story?.Status}
           {/* <Autocomplete
               disablePortal
              selectOnFocus
              autoHighlight
              value={Status}
              id="status"
              onSelect={(e) => 
                setStatus(e.target.value)
              }
              options={
                StoryStatus.map(st=>{
                  return st;
                }) 

              }
              sx={{
                width: "80%",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  variant="standard"
                  //error={!fromAirport ? true : false}
                />
              )}
              >
              
              </Autocomplete> */}
          
          </div>
        </Grid>
        <Grid item xs={10} style={{ backgroundColor: 'white' }}>
          <Typography variant="h6">News Body :</Typography>
          <br></br>
          <ReactQuill
            id="description"
            style={{ margin: 4 }}
            modules={modules}
            theme="snow"
            placeholder="Write news body here..."
            value={story?.Description}
            onChange={(e) => story.Description=e}
          />
        </Grid>
        <Grid item xs={2} pl={2}>
          <div className="StorySupportive">
            <span>
              <b>Visible:</b><Checkbox checked={visibility} onChange={(e)=>setVisibility(e.target.checked)} />
            </span>
            <br></br>
            <b>Category :</b>
            <RadioGroup
              size="small"
              name="controlled-radio-buttons-group"
              value={category}
              onChange={(e) =>setCategory(e.target.value)}
            >
              {categoriesList?.map((cat, index) => {
                return (
                  <FormControlLabel
                    value={cat.Description}
                    control={<Radio size="small" />}
                    label={cat.Description}
                  />
                )
              })}
            </RadioGroup>
          </div>
        </Grid>
        <Grid
          item
          xs={10}
          mt={1}
          style={{ backgroundColor: 'white', padding: 2 }}
        >
          <Button  size='small' onClick={(e)=>{
            story.Status='Published'
            HandleSubmit(e);
          }}>Publish</Button>|
          <Button  size='small' onClick={(e)=>{
             story.Status='On Compilation';

             HandleSubmit(e);

          }}>On Compilation</Button>|
          <Button  size='small' onClick={(e)=>{}}>Delete</Button>
        </Grid>
      </Grid>
    )
  }

  return <div>{LoadHtml()}</div>
}

export default NewsEditComp
