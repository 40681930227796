import * as React from 'react';
import {Button,Grid} from '@mui/material';

import { useNavigate  } from 'react-router-dom'


import { useState, useEffect, useRef, useContext } from "react";
import axios from "../../axiosInstances";
import { ShoppingCartContext } from "../Context/ShoppingCartContext";


function LogInModel(props) {
  const userRef = useRef();
  const errRef = useRef();
  const {setGlobalUser,setGlobalProviders,setGlobalCustomers,setAllItems,setGlobalRoleSecurity,setGlobalStores,setGlobalToken,setGlobalSetting } = useContext(ShoppingCartContext);

  let history=useNavigate ();

  const [userName, setUserName] = useState('');
  const [password, setpassword] = useState("");
  const [errMsg, seterrMsg] = useState("");
  const [success, setsuccess] = useState(false);


  useEffect(() => {
    setOpen(true);
    seterrMsg("");
  }, [userName, password]);
  const loadParent = (parent,role,store,token) => {
    console.log("login100") 
    try {
      axios.get("/suppliers/getParentAndProviders?Parent="+ parent +"&Role="+role +"&Store="+ store,{
        headers:{
          token:token
        }
      })
      .then((result) => { 
        console.log("login1") 
        setGlobalProviders(result.data.data.Providers);
        setGlobalCustomers(result.data.data.Customers);
        setAllItems(result.data.data.Products)
        setGlobalRoleSecurity(result.data.data.Roles)
        setGlobalStores(result.data.data.Stores)
        setGlobalSetting(result.data.data.SystemSettings)
        console.log("login")
   console.log(result.data.data)
    
      });
    } catch (err) {
      console.log(err);
    }
  };

  
  const handleSummit = async (e) => {
 
    e.preventDefault();
    try {
  
      var formdata = new FormData();
      console.log('inside login111')
      formdata.append("UserName", document.getElementById('username').value );
      formdata.append("Password", document.getElementById('password').value );

      const response = await axios.post(
        "/employees/ByUserName",
        formdata
        , {
          headers: { "Content-Type": "application/json" },
        });
        

  if (response.data){
      const accessorToken = response.data;
  
      //sessionStorage.setItem("accessorToken", accessorToken.token)
          
      setUserName("");
      setpassword("");
      setsuccess(true);
        setGlobalToken(accessorToken.token);
        loadParent(accessorToken.CurrentUser.Parent,accessorToken.CurrentUser.Role,accessorToken.CurrentUser.Store,accessorToken.token)
        setGlobalUser(accessorToken.CurrentUser) 
     
        history("/Home");
        handleClose();
  }else{
 
    if (!response.status) {
        seterrMsg("No Server Response");
    } else if (response?.status === 400) {
      seterrMsg("Username and Password is Incorrect");
    } else if (response?.status === 401) {
      seterrMsg("Unauthorize");
    } else if (response?.status === 402) {
      seterrMsg("This user is inactive");
    } else {
      seterrMsg("Login Faild");
    } 
  }
    } catch (err) {
      sessionStorage.setItem("accessorToken", '')
      //console.log (err)
    if (!err?.response) {
    
        seterrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        seterrMsg("Username and Password is Incorrect");
      } else if (err.response?.status === 401) {
        seterrMsg("Unauthorize");
      } else if (err?.response.status === 402) {
        seterrMsg("This user is inactive");
      }else {
        seterrMsg("Login Faild");
      } 
    }
    //errRef.current.focus();

   
  };
  const [open, setOpen] = React.useState(false);


  const handleClose = () => {
    setOpen(false);
  };

  return (
   

              <Grid container mt={1}>
      <Grid item xs={12} md={3}>
      <div>
     
   <div id='loginform' >
   
   
    <form onSubmit={handleSummit} method="POST" id="myForm" className="row" >
    <h2 id='headerTitle'>Sign In</h2>
      <label htmlFor="username">Username: <input
        type="text"
        id="username"
        ref={userRef}
        autoComplete="off"
        onChange={(e) => setUserName(e.target.value)}
        value={userName}
        required
        mb={4}
      /></label>

     <br></br>

      <label htmlFor="password">Password: <input
        type="password"
        id="password"
        onChange={(e) => setpassword(e.target.value)}
        required
        value={password}
      /></label>
 <p
 style={{color:'red'}}
      ref={errRef}
      className={errMsg ? "errmsg" : "uffscreen"}
      aria-live="asserive"
    >
      {errMsg}
    </p>
     
      <br></br>
      <button type="submit" id='button'>Sign In</button>
      
    </form>
    
            </div>
            
    </div>
      </Grid>
      
    </Grid> 

   
  )  
}
export default LogInModel;
